import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import _ from 'lodash'
import { DatePicker, Input, Form, Select } from 'antd'
import moment from 'moment/moment'
import { MaskedInput } from 'antd-mask-input'
import './index.less'

const DynamicField = ({ data }) => {
  const dispatch = useDispatch()
  const { loading, canEditClientCard, fieldData, id, setClientData } = data
  const form = Form.useFormInstance()
  let component = null
  let initialValue = null
  const tasks = useSelector((state) => state.app.tasks)

  const onChange = async () => {
    let submitData = form.getFieldsValue(true).fields_group

    submitData?.forEach((i) => {
      i.fields.forEach((x) => {
        if (x.id === fieldData.id && x.type !== 'multi_select') {
          x.field_data_list = [
            {
              value: form.getFieldValue(fieldData.id),
            },
          ]
        } else if (x.id === fieldData.id && x.type === 'multi_select') {
          x.field_data_list = form.getFieldValue(fieldData.id).map((i) => {
            const value = i

            return { value }
          })
        }
      })
    })

    await dispatch(
      authorizationLayer({
        url: `clients/${id}`,
        method: 'patch',
        data: { fields_group: submitData },
      })
    ).then(({ data }) => {
      let newTasks = tasks?.map((i) => {
        if (i.client?.id === id) {
          i.client = data
        }

        return { ...i }
      })

      dispatch(SET_APP(['tasks'], newTasks))
      setClientData(data)
    })
  }

  if (fieldData.type === 'string') {
    component = (
      <Input
        disabled={loading || !canEditClientCard}
        className='dynamic-field'
        placeholder='Введите текст'
        onChange={_.debounce(() => onChange(), 1000)}
      />
    )
    initialValue = fieldData.field_data_list[0]?.value
  } else if (fieldData.type === 'number') {
    component = (
      <MaskedInput
        mask={[
          {
            mask: Number,
            min: 0,
          },
        ]}
        placeholder='Введите номер'
        disabled={loading || !canEditClientCard}
        className='dynamic-field'
        onChange={_.debounce(() => onChange(), 1000)}
      />
    )

    initialValue = fieldData.field_data_list[0]?.value
  } else if (fieldData.type === 'date') {
    component = (
      <DatePicker
        disabled={loading || !canEditClientCard}
        className='dynamic-field'
        placeholder='ДД.ММ.ГГГГ'
        format='DD.MM.YYYY'
        onChange={_.debounce(() => {
          onChange()
        }, 1000)}
        showToday={false}
        style={{ width: '100%' }}
      />
    )
    initialValue = fieldData.field_data_list[0]?.value
      ? moment(fieldData.field_data_list[0]?.value)
      : undefined
  } else if (fieldData.type === 'select') {
    component = (
      <Select
        disabled={loading || !canEditClientCard}
        allowClear
        className='dynamic-field'
        size='large'
        placeholder='Выберите'
        onChange={_.debounce(() => onChange(), 1000)}
      >
        {fieldData.field_options.map((i) => {
          return (
            <Select.Option key={i.id} value={i.value}>
              {i.value}
            </Select.Option>
          )
        })}
      </Select>
    )
    initialValue = fieldData.field_data_list[0]?.value
  } else if (fieldData.type === 'multi_select') {
    component = (
      <Select
        disabled={loading || !canEditClientCard}
        allowClear
        className='dynamic-field'
        size='large'
        placeholder='Выберите'
        mode='multiple'
        maxTagCount='responsive'
        onChange={_.debounce(() => onChange(), 1000)}
      >
        {fieldData.field_options.map((i) => {
          return (
            <Select.Option key={i.id} value={i.value}>
              {i.value}
            </Select.Option>
          )
        })}
      </Select>
    )

    initialValue = fieldData.field_data_list
  }

  return (
    <Form.Item
      label={fieldData.name}
      name={fieldData.id}
      initialValue={initialValue}
    >
      {component}
    </Form.Item>
  )
}

export default DynamicField
