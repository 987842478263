import React, { useContext, useRef, useState } from 'react'
import { Modal, Form, Input, notification } from 'antd'
import { Context } from '@views/Profile'
import { axios } from '@store/actions/methods'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const ModalComp = (props) => {
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState()
  const ctx = useContext(Context)
  const { modal, setModal } = ctx
  const { token } = useSelector((state) => state.app)

  const form = useRef()

  const onValuesChange = (field, fields) => {
    const fieldsValidation = fields.filter((f) => !!f.value)

    setDisabled(fieldsValidation.length !== 3)
  }

  const onSubmit = async () => {
    const values = await form.current.validateFields()

    await axios
      .post(
        'auth/change-password',
        {
          password: values.password,
          new_password: values.new_password,
          new_password_confirm: values.password_confirm,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        notification.success({
          message: t('profile.modal.pass.success'),
        })
        setModal(null)
      })
      .catch((error) => {
        if (error.response.status) {
          setError(
            error.response.data.validationErrors || error.response.data.error
          )
        }
      })
  }

  return (
    <>
      <Modal
        title={t('profile.modal.pass.title')}
        visible={modal === 'password'}
        onCancel={() => setModal(false)}
        okButtonProps={{ disabled }}
        okText={t('profile.modal.pass.okBtn')}
        onOk={onSubmit}
        destroyOnClose={true}
      >
        <Form layout='vertical' ref={form} onFieldsChange={onValuesChange}>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('profile.modal.pass.required'),
              },
            ]}
            name='password'
            label={t('profile.modal.pass.passLabel')}
          >
            <Input.Password
              placeholder={t('profile.modal.pass.passPlaceholder')}
            />
          </Form.Item>
          <Form.Item
            name='new_password'
            label={t('profile.modal.pass.newPassLabel')}
            rules={[
              { required: true, message: t('profile.modal.pass.required') },
            ]}
          >
            <Input.Password
              placeholder={t('profile.modal.pass.passPlaceholder')}
            />
          </Form.Item>
          <Form.Item
            name='password_confirm'
            rules={[
              {
                required: true,
                message: t('profile.modal.pass.required'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(t('profile.modal.pass.notMatch'))
                  )
                },
              }),
            ]}
            label={t('profile.modal.pass.newPassAgainLabel')}
          >
            <Input.Password
              placeholder={t('profile.modal.pass.passPlaceholder')}
            />
          </Form.Item>
          {error && <div style={{ color: '#ff4d4f' }}>{error}</div>}
        </Form>
      </Modal>
    </>
  )
}

export default ModalComp
