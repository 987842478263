import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import {
  Input,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Button,
} from 'antd'
import moment from 'moment'
import { Form } from 'antd'
import _ from 'lodash'
import './index.less'
import { ModuleContext } from '../../..'
import { forwardRef, useImperativeHandle } from 'react'
import { useCallback } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { modules } from '@/constants'

const { TextArea } = Input
const { Option } = Select
const formatTime = 'HH:mm'
// const formatDate = 'YYYY-MM-DD'
// const formatDate = 'DD-MM-YYYY'

const Forms = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { module, taskData, currentNeeds, cardTitle } =
    useContext(ModuleContext)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState([])
  const [fields, setFields] = useState([])
  const [inputVisible, setInputVisible] = useState(false)
  const [newValue, setNewValue] = useState('')
  const [newValueMulti, setNewValueMulti] = useState('')
  const [inputVisibleMulti, setInputVisibleMulti] = useState(false)

  const [form] = Form.useForm()

  useImperativeHandle(
    ref,
    () => ({
      getStatus: async () => {
        try {
          const values = await form.validateFields()
          return values
        } catch (e) {
          return null
        }
      },
    }),
    []
  )

  useEffect(() => {
    const getFormsSettings = async () => {
      await dispatch(
        authorizationLayer({
          url: `forms/${taskData?.process?.id}/${module?.id}`,
        })
      ).then(({ data }) => {
        setFormData(data)
        setFields(currentNeeds?.meta || [])

        let formObj = {}

        currentNeeds?.meta?.forEach((i) => {
          if (i.type === 'multi_select') {
            formObj[i.id] = i.fieldDataList || i.fieldData
          } else if (i.type === 'data') {
            formObj[i.id] = i.fieldData ? moment(i.fieldData) : undefined
          } else if (i.type === 'time') {
            let newDate = moment().format('YYYY-MM-DD')

            formObj[i.id] = i.fieldData
              ? moment(newDate + ' ' + i.fieldData + ':00')
              : undefined
          } else formObj[i.id] = i.fieldData
        })

        form.setFieldsValue(formObj)
      })
    }
    getFormsSettings()
  }, [])

  const onSubmit = useCallback(
    _.debounce(async (fields) => {
      await dispatch(
        authorizationLayer({
          url: `needs`,
          method: 'POST',
          data: {
            taskId: taskData?.id,
            moduleId: module?.id,
            mainTaskId: taskData?.mainTaskId,
            needs: [
              {
                type: modules.FORM,
                action: 'choice',
                meta: fields,
              },
            ],
          },
        })
      )
    }, 500),
    []
  )

  const onChange = (value, id, type, fieldName) => {
    let index = _.findIndex(fields, (i) => i.id === id)

    if (index !== -1) {
      fields[index] = {
        id: id,
        fieldData: value,
        type: type,
        name: fieldName,
      }

      setFields([...fields])
      onSubmit([...fields])
    } else {
      setFields([
        ...fields,
        {
          id: id,
          fieldData: value,
          type: type,
          name: fieldName,
        },
      ])
      onSubmit([
        ...fields,
        {
          id: id,
          fieldData: value,
          type: type,
          name: fieldName,
        },
      ])
    }
  }

  const onChangeOther = (e) => {
    e.preventDefault()
    setInputVisible(true)
  }

  const onChangeOtherMulti = (e) => {
    e.preventDefault()
    setInputVisibleMulti(true)
  }

  return (
    <>
      {cardTitle}
      <Form
        form={form}
        autoComplete='off'
        layout='vertical'
        name='form'
        className='forms__wrap'
      >
        {formData.fields?.map((field) => {
          let result = ''
          let fieldLists = []

          if (field.fieldLists?.length > 0) {
            fieldLists = field.fieldLists
          }

          const addValue = (e, formItemId) => {
            e.preventDefault()

            formData.fields.find((item) => item.id === field.id).fieldLists = [
              ...fieldLists,
              { id: 'other', value: newValue },
            ]

            form.setFieldValue(formItemId, newValue)

            onChange(newValue, formItemId, field.type, field.name)

            setNewValue('')
          }

          const addValueMulti = (e, formItemId) => {
            e.preventDefault()

            const currentFieldsData = formData.fields.find(
              (item) => item.id === field.id
            ).fieldLists

            let fieldListsIndex = _.findIndex(
              currentFieldsData,
              (item) => item.value === newValueMulti
            )

            if (fieldListsIndex === -1) {
              currentFieldsData.push({ id: 'other', value: newValueMulti })
            } else {
              currentFieldsData[fieldListsIndex] = {
                id: 'other',
                value: newValueMulti,
              }
            }

            const formValue = form.getFieldsValue([formItemId])[formItemId]

            let valueIndex = _.findIndex(
              formValue,
              (item) => item === newValueMulti
            )

            if (valueIndex === -1) {
              formValue.push(newValueMulti)
            } else {
              formValue[valueIndex] = newValueMulti
            }

            form.setFieldValue(formItemId, formValue)

            onChange(
              form.getFieldValue(formItemId),
              formItemId,
              field.type,
              field.name
            )

            setNewValueMulti('')
          }

          switch (field.type) {
            case 'string':
              result = (
                <Input
                  onChange={(e) =>
                    onChange(e.target.value, field.id, field.type, field.name)
                  }
                  className='forms__input'
                />
              )
              break
            case 'paragraph':
              result = (
                <TextArea
                  rows={3}
                  onChange={(e) =>
                    onChange(e.target.value, field.id, field.type, field.name)
                  }
                  className='forms__text-area'
                />
              )
              break
            case 'select':
              result = (
                <Select
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                  )}
                  onChange={(e) =>
                    onChange(e, field.id, field.type, field.name)
                  }
                  dropdownRender={(menu) => {
                    return (
                      <>
                        {menu}
                        {field.withOtherField && (
                          <div style={{ padding: '5px 12px' }}>
                            {!inputVisible ? (
                              <Button
                                type='link'
                                onClick={(e) => onChangeOther(e)}
                                className='forms__add-btn'
                              >
                                + {t('task.cancelModal.radioValueOther')}
                              </Button>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  flexDirection: 'column',
                                }}
                              >
                                <Input
                                  className='forms__input'
                                  placeholder={t(
                                    'task.cancelModal.textAreaPlaceholder'
                                  )}
                                  value={newValue}
                                  onChange={(e) => setNewValue(e.target.value)}
                                />
                                <Button
                                  type='link'
                                  style={{ padding: 0, marginTop: 8 }}
                                  disabled={!newValue}
                                  onClick={(e) => addValue(e, field.id)}
                                >
                                  {t('profile.saveBtn')}
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )
                  }}
                  className='forms__select'
                  options={fieldLists?.map((item) => ({
                    label: item.value,
                    value: item.value,
                  }))}
                />
              )
              break
            case 'multi_select':
              result = (
                <Select
                  mode='multiple'
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                  )}
                  optionLabelProp='label'
                  onChange={(e) =>
                    onChange(e, field.id, field.type, field.name)
                  }
                  className='forms__select'
                  options={fieldLists?.map((item, index) => ({
                    label: item.value,
                    value: item.value,
                    key: index,
                  }))}
                  dropdownRender={(menu) => {
                    return (
                      <>
                        {menu}
                        {field.withOtherField && (
                          <div style={{ padding: '5px 12px' }}>
                            {!inputVisibleMulti ? (
                              <Button
                                type='link'
                                onClick={(e) => onChangeOtherMulti(e)}
                                className='forms__add-btn'
                              >
                                + {t('task.cancelModal.radioValueOther')}
                              </Button>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  flexDirection: 'column',
                                }}
                              >
                                <Input
                                  className='forms__input'
                                  placeholder={t(
                                    'task.cancelModal.textAreaPlaceholder'
                                  )}
                                  value={newValueMulti}
                                  onChange={(e) =>
                                    setNewValueMulti(e.target.value)
                                  }
                                />
                                <Button
                                  type='link'
                                  style={{ padding: 0, marginTop: 8 }}
                                  disabled={!newValueMulti}
                                  onClick={(e) => addValueMulti(e, field.id)}
                                >
                                  {t('profile.saveBtn')}
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )
                  }}
                />
              )
              break
            case 'data':
              result = (
                <DatePicker
                  style={{ width: 128, height: 30 }}
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                  )}
                  format='DD-MM-YYYY'
                  onChange={(e) =>
                    onChange(
                      e ? moment(e).format('YYYY-MM-DD').toString() : null,
                      field.id,
                      field.type,
                      field.name
                    )
                  }
                  className='forms__date'
                />
              )
              break
            case 'time':
              result = (
                <TimePicker
                  style={{ width: 128, height: 30 }}
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                  )}
                  format='HH:mm'
                  popupClassName='timepicker'
                  onSelect={(e) => {
                    let obj = {}
                    fields?.forEach((i) => {
                      if (i.id === field.id) {
                        obj[i.id] = e
                      }
                    })

                    form.setFieldsValue(obj)

                    onChange(
                      e ? moment(e).format(formatTime).toString() : null,
                      field.id,
                      field.type,
                      field.name
                    )
                  }}
                  className='forms__date'
                />
              )
              break
            case 'number':
              result = (
                <InputNumber
                  style={{ width: 320, height: 30 }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                  onChange={(e) =>
                    onChange(e, field.id, field.type, field.name)
                  }
                />
              )
          }

          return (
            <React.Fragment key={field.id}>
              <Form.Item
                style={{
                  width: '320px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#293452',
                }}
                label={field.name}
                name={field.id}
                rules={[
                  {
                    required: module?.isRequired,
                    message: t('common.input.requiredMessage'),
                  },
                ]}
              >
                {result}
              </Form.Item>
            </React.Fragment>
          )
        })}
      </Form>
    </>
  )
})

export default Forms
