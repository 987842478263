import { stockTypes } from '@/constants'

export const getFilters = async (
  dispatch,
  authorizationLayer,
  stockType,
  carMarkId,
  carModelId,
  data
) => {
  let filtersData = {
    markData:
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
      ? data.reduce((result, item) => {
        if(!result.find(el => el.id === item?.carMark?.id)) {
          result.push({...item?.carMark})
        }
        return result;
      }, [])
      : null,
    statusesData:
      stockType === stockTypes.NEW_AUTO
        ? await dispatch(
          authorizationLayer({
            url: `wh-cars/references/car_status`,
          })
        ).then((statuses) => {
          return statuses.data.reduce((result, item) => {
            if(data.find(el => el.status === item?.short_name)) {
              result.push({...item})
            }
            return result;
          }, [])
        })
        : null,
    modelData:
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
      ? data.reduce((result, item) => {
        if(!result.find(el => el.id === item?.carModel?.id)) {
          result.push({...item?.carModel})
        }
        return result;
      }, [])
      : null,
    templatesData:
      stockType === stockTypes.NEW_AUTO
      ? data.reduce((result, item) => {
        if(!result.find(el => el.id === item?.carTemplate?.id)) {
          item?.carTemplate && result.push({...item?.carTemplate})
        }
        return result;
      }, [])
      : null,
    exteriorColors:
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
        ? data.reduce((result, item) => {
          if(!result.find(el => el.id === item?.carExteriorColor?.id)) {
            item?.carExteriorColor && result.push({...item?.carExteriorColor})
          }
          return result;
        }, [])
        : null,
    interiorColors:
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
        ? data.reduce((result, item) => {
          if(!result.find(el => el.id === item?.carInteriorColor?.id)) {
            item?.carInteriorColor && result.push({...item?.carInteriorColor})
          }
          return result;
        }, [])
        : null,
    yearData:
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
        ? data.reduce((result, item) => {
          if(!result.includes(item.year)) {
            result.push(item.year)
          }
          return result;
        }, [])
        : null,
    engineData:
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
        ? data.reduce((result, item) => {
          if(!result.includes(item.engine_capacity)) {
            result.push(item.engine_capacity)
          }
          return result;
        }, [])
        : null,
    conditions:
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
        ? [{ text: 'Нет условий', value: true }]
        : null,
    sparePartsCategory:
      stockType === stockTypes.SPARE_PARTS
        ? await dispatch(
            authorizationLayer({
              url: `wh-parts/spare_parts_category`,
            })
          )
        : null,

    servicesCategory:
      stockType === 'services'
        ? await dispatch(
            authorizationLayer({
              url: 'wh-services/categories',
            })
          )
        : null,
    carGearboxTypeData: 
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
      ? data.reduce((result, item) => {
        if(!result.find(el => el.id === item.carGearboxType?.id)) {
          result.push({...item.carGearboxType})
        }
        return result;
      }, [])
      : null,
    clientData: 
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
      ? data.reduce((result, item) => {
        if(!result.find(el => el.id === item.client?.id)) {
          item?.client && result.push({...item.client})
        }
        return result;
      }, [])
      : null,
    userData: 
      stockType === stockTypes.NEW_AUTO || stockType === stockTypes.USED_AUTO
      ? data.reduce((result, item) => {
        if(!result.find(el => el.id === item.user?.id)) {
          item?.user && result.push({...item.user})
        }
        return result;
      }, [])
      : null
  }

  return filtersData
}
