import React, { useContext, useRef, useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { LOGOUT } from '@store/actions/methods'

import { Modal, Form, Input } from 'antd'
import { Context } from '@views/Profile'
import { useTranslation } from 'react-i18next'

const ModalComp = (props) => {
  const [disabled, setDisabled] = useState(true)
  const ctx = useContext(Context)
  const { modal, setModal } = ctx
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const form = useRef()

  const onValuesChange = (field, fields) => {
    const fieldsValidation = fields.filter((f) => !!f.value)

    setDisabled(fieldsValidation.length !== 3)
  }

  const onSubmit = async () => {
    const values = await form.current.validateFields()
    await dispatch(
      authorizationLayer({
        url: 'users/current/email',
        method: 'PATCH',
        data: {
          email: values.email,
          password: values.password,
        },
      })
    ).then(async () => {
      await dispatch(LOGOUT())
    })
  }

  return (
    <>
      <Modal
        title={t('profile.modal.email.title')}
        visible={modal === 'email'}
        onCancel={() => setModal(false)}
        okButtonProps={{ disabled }}
        okText={t('profile.moda.email.okBtn')}
        onOk={onSubmit}
        destroyOnClose={true}
      >
        <Form layout='vertical' ref={form} onFieldsChange={onValuesChange}>
          <Form.Item
            rules={[
              {
                type: 'email',
                message: t('profile.modal.email.invalidEmail'),
              },
              {
                required: true,
                message: t('profile.modal.email.required'),
              },
            ]}
            name='email'
            label={t('profile.modal.email.emailLabel')}
          >
            <Input placeholder={t('profile.modal.email.inputPlaceholder')} />
          </Form.Item>
          <Form.Item
            name='email_confirm'
            rules={[
              {
                required: true,
                message: t('profile.modal.email.required'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(t('profile.modal.email.notMatch'))
                  )
                },
              }),
            ]}
            label={t('profile.modal.email.againEmailLabel')}
          >
            <Input placeholder={t('profile.modal.email.inputPlaceholder')} />
          </Form.Item>
          <Form.Item
            name='password'
            label={t('profile.modal.email.passLabel')}
            rules={[
              { required: true, message: t('profile.modal.email.required') },
            ]}
          >
            <Input.Password
              placeholder={t('profile.modal.email.passPlaceholder')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ModalComp
