import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import _ from 'lodash'
import { Button, Col, Input, Row } from 'antd'
import SkeletonTable from '@ui/SkeletonTable'
import { columns, columnsForSpareParts } from './columns'
import { numberWithSpaces } from '@utils'
import { useNavigate, useParams } from 'react-router'
import useTableActions from '@views/ChoiceProduct/hooks/useTableActions'
import './index.less'
import { useTranslation } from 'react-i18next'
import { modules, stockTypes } from '@/constants'

const ChoiceProduct = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id, moduleId } = useParams()
  const user = useSelector((state) => state.app.user)
  const filtersData = useSelector((state) => state.app.filtersData)
  const [taskData, setTaskData] = useState()
  const [module, setModule] = useState()
  const [moreDisabled, setMoreDisabled] = useState(false)
  const [setTotalPriceParts] = useState(0)
  const { t } = useTranslation()

  const getTaskData = async () => {
    await dispatch(
      authorizationLayer({
        url: `processes/tasks/${id}`,
      })
    ).then(async ({ data }) => {
      setTaskData(data.data)
      setModule(data.data.currentStage?.modules?.find((i) => i.id === moduleId))
    })
  }

  const {
    getData,
    loading,
    setQuery,
    query,
    markId,
    carModelId,
    data,
    selected,
    rowSelection,
    dataForSparePartNeeds,
    dataForSparePartTask,
    setDataForSparePartTask,
    setDataForSparePartNeeds,
    needsData,
  } = useTableActions(
    id,
    module?.settings.stockType,
    taskData?.allTasksIds,
    module?.component.componentType.name,
    taskData?.taskTree,
    taskData,
    module
  )

  const carsColumns = columns(
    dispatch,
    module?.settings.stockType,
    filtersData,
    markId,
    carModelId,
    query.filters,
    module?.settings.tableColumns,
    moreDisabled
  ).filter((col) => {
    return Object.keys(col).length !== 0
  })

  const partsColumns = columnsForSpareParts(
    dispatch,
    module?.settings.stockType,
    dataForSparePartTask,
    dataForSparePartNeeds,
    setDataForSparePartTask,
    setDataForSparePartNeeds,
    needsData,
    setTotalPriceParts,
    filtersData,
    module?.settings.tableColumns,
    moreDisabled
  )

  const handleTableChange = (pagination, filters, sorter) => {
    query.pagination.current = pagination.current
    query.pagination.pageSize = pagination.pageSize
    query.sorter.field = sorter.field
    query.sorter.order = sorter.order
    query.filters = filters
    setQuery(query)
    getData()
  }

  const handleSearch = (e) => {
    if (e.target.value.length >= 3 || e.target.value === '') {
      query.pagination.current = 1
      query.pagination.pageSize = 20
      query.searchValue = e.target.value.trim()
      setQuery(query)
      getData()
    }
  }

  const onSubmit = async () => {
    setMoreDisabled(true)
    selected?.forEach((i) => {
      i.userName = user?.full_name
      i.clientName = taskData?.client?.full_name
    })

    await dispatch(
      authorizationLayer({
        url: `needs`,
        method: 'POST',
        data: {
          taskId: taskData?.id,
          mainTaskId: taskData?.mainTaskId,
          moduleId: moduleId,
          needs: [
            {
              type: modules.CHOICE_PRODUCT,
              action: 'choice',
              stockType: module?.settings.stockType,
              meta:
                module?.settings.stockType !== stockTypes.SPARE_PARTS
                  ? selected
                  : dataForSparePartNeeds.filter(
                      (item) => item.orderAmount !== 0
                    ),
            },
          ],
        },
      })
    ).then((data) => {
      if (data.status === 201) navigate(-1)
    })

    setMoreDisabled(false)
  }

  const totalPricesParts = () => {
    let price = 0

    dataForSparePartNeeds?.forEach((item) => {
      price += item.orderAmount * item.price
    })

    return price
  }

  const showDisabledRow = (record) => {
    if (
      module?.settings.stockType === stockTypes.SPARE_PARTS &&
      dataForSparePartNeeds
        ?.filter((i) => i.orderAmount !== 0)
        .some((x) => x.id === record.id)
    ) {
      return 'ant-table-row-selected'
    }

    if (
      record &&
      record?.user_id &&
      record?.client_id &&
      record?.mainTaskId === taskData?.mainTaskId
    ) {
      return false
    } else if (
      record &&
      record?.user_id !== null &&
      record?.client_id !== null &&
      record?.mainTaskId !== taskData?.mainTaskId &&
      module?.settings.stockType !== stockTypes.SPARE_PARTS
    ) {
      return 'disabled-row'
    } else {
      return false
    }
  }

  const _totalPrice =
    module?.settings.stockType !== stockTypes.SPARE_PARTS
      ? _.sumBy(selected, 'price')
      : totalPricesParts()

  const columnsData =
    module?.settings.stockType !== stockTypes.SPARE_PARTS
      ? carsColumns
      : partsColumns

  const rowSelectionData =
    module?.settings.stockType !== stockTypes.SPARE_PARTS
      ? { type: 'checkbox', ...rowSelection }
      : false

  useEffect(() => {
    getTaskData()
  }, [])

  useEffect(() => {
    if (taskData && !taskData?.client?.id) navigate(-1)
  }, [taskData])

  return (
    <div style={{ padding: '16px 24px' }} id={'ChoiceProduct'}>
      <Row justify='space-between' align='center' style={{ marginBottom: 16 }}>
        <Col>
          <Input.Search
            placeholder={t('choiceProduct.table.searchPlaceholder')}
            allowClear
            style={{ width: 320 }}
            onChange={_.debounce(handleSearch, 500)}
            className='choice-product__input-search'
          />
        </Col>
        <Col>
          <Button
            shape='round'
            type='primary'
            onClick={onSubmit}
            disabled={moreDisabled}
          >
            {t('choiceProdict.table.selectBtn')}
          </Button>
        </Col>
      </Row>

      <SkeletonTable
        className='choice-product__table'
        columns={columnsData}
        data={data?.data}
        rowSelection={rowSelectionData}
        loading={loading}
        pagination
        width={document.body.offsetWidth - 56}
        height={document.body.offsetHeight - 260}
        xScroll={module?.settings.stockType !== stockTypes.SPARE_PARTS}
        currentPage={data?.page}
        total={data?.total}
        pageSize={data?.take}
        take={20}
        pageSizeOptions={[20, 50, 100]}
        tableChangeFunction={handleTableChange}
        disabledRow={showDisabledRow}
      />

      {module?.settings.stockType !== stockTypes.TEST_DRIVE && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            fontWeight: 500,
            fontSize: 20,
            padding: '18px 40px',
            width: '100%',
            background: '#fff',
            left: 0,
          }}
        >
          {t('warehouse.totalPrice')} {`${numberWithSpaces(_totalPrice)}`}
        </div>
      )}
    </div>
  )
}

export default ChoiceProduct
