import { useState } from 'react'

import { Typography } from 'antd'

import './styles.less'
import { ExternalCarScheme } from './CarScheme'
import { CarReceptionItemWrapper } from '../CarReceptionItemWrapper'

const CircularInspection = (props) => {
  // type can be 'external' || 'internal'
  const {
    externalInspection,
    internalInspection,
    titleItem,
    carMarkData,
    type = 'external',
  } = props

  const [state, setState] = useState({
    selectedElement: {
      external: null,
      internal: null,
    },
  })

  const setSelectedElement = (element, type) => {
    let external = state.selectedElement.external
    let internal = state.selectedElement.internal

    if (type === 'external') {
      external = element
    }
    if (type === 'internal') {
      internal = element
    }

    setState({
      ...state,
      selectedElement: {
        ...state.selectedElement,
        external: external,
        internal: internal,
      },
    })
  }

  const renderScheme = (arr, typeScheme) => {
    return arr.map((elem) => {
      return (
        <div
          className={'circular-inspection--content--scheme'}
          key={elem.id}
          id={elem.id}
        >
          {typeScheme === 'external' && (
            <ExternalCarScheme
              type={'external'}
              meta={elem.meta}
              selectedElement={state.selectedElement.external}
              setSelectedElement={setSelectedElement}
            />
          )}
          {typeScheme === 'internal' && (
            <ExternalCarScheme
              type={'internal'}
              meta={elem.meta}
              selectedElement={state.selectedElement.internal}
              setSelectedElement={setSelectedElement}
            />
          )}
        </div>
      )
    })
  }

  return (
    <CarReceptionItemWrapper titleItem={titleItem} id={'CircularInspection'}>
      <div className={'circular-inspection'}>
        <div className={'circular-inspection--title'}>
          {carMarkData?.fullName && carMarkData?.year && (
            <Typography.Text strong style={{ fontSize: '16px' }}>
              {carMarkData && `${carMarkData?.fullName}, ${carMarkData?.year}`}
            </Typography.Text>
          )}
        </div>
        {type === 'external' && (
          <>{externalInspection && renderScheme(externalInspection, type)}</>
        )}
        {type === 'internal' && (
          <>{internalInspection && renderScheme(internalInspection, type)}</>
        )}
      </div>
    </CarReceptionItemWrapper>
  )
}

export default CircularInspection
