import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Drawer, Form, Select } from 'antd'
import { authorizationLayer } from '@store/actions/methods'
import '../index.less'
import { useTranslation } from 'react-i18next'

const SwitchUserFromTask = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.app)
  const [form] = Form.useForm()
  const [users, setUsers] = useState([])
  const { t } = useTranslation()

  const getUsers = async () => {
    if (Object.keys(props.switchData).length !== 0) {
      await dispatch(
        authorizationLayer({
          url: `processes/process-user/${props.switchData?.process?.id}/users`,
        })
      ).then(({ data }) => {
        if (data.success) {
          let userData = []
          userData = data.data?.filter((item) => item.id !== user?.id)

          if (
            data.data?.length === 1 &&
            data.data?.some((x) => x.id === user?.id)
          )
            setUsers(userData)
          else
            setUsers([
              {
                id: null,
                label: t(
                  'request.actionHistory.changeUser.drawer.selectAutomatically'
                ),
              },
              ...userData,
            ])
        }
      })
    }
  }

  useEffect(() => {
    getUsers()
  }, [props.switchData])

  const userId = Form.useWatch('userId', form)

  const onSubmit = async () => {
    await dispatch(
      authorizationLayer({
        url: `/processes/tasks/change-user/${props.switchData?.id}`,
        method: 'PATCH',
        data: {
          userId: userId,
        },
      })
    ).then(({ data }) => {
      if (data.success) {
        props.cancelDrawer()
        if (data.id !== props.taskData?.id) navigate('/')
      }
    })
  }

  return (
    <Drawer
      title={t('request.actionHistory.changeUser')}
      placement='right'
      onClose={props.cancelDrawer}
      open={props.openDrawer}
      width={'448px'}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        onFinishFailed={(values) => console.log(values)}
        layout='vertical'
        autoComplete='off'
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        initialValues={{
          appeal_type: 'crm',
        }}
      >
        <p>{t('task.actionHistory.switchUserFromTask.processName')}</p>
        <p className='title-drawer'>{props.switchData?.process?.name}</p>
        <Form.Item name='userId' label={t('main.createTask.changeUserLabel')}>
          <Select
            placeholder={t('main.createTask.changeUserPlaceholder')}
            size='large'
            className='task-create__select'
            disabled={!props.switchData?.process?.id || props.loading}
          >
            {users &&
              users?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.full_name || item.label}
                  </Select.Option>
                )
              })}
          </Select>
        </Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          shape='round'
          style={{ width: '100%', marginTop: 'auto' }}
          size='large'
          loading={props.loading}
        >
          {t('task.actionHistory.switchUserFromTask.sendBtn')}
        </Button>
      </Form>
    </Drawer>
  )
}

export default SwitchUserFromTask
