import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react'
import { Menu, Layout, Row, Col, Typography, notification } from 'antd'
import { Space, Button } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import From from './Form'
import { useNavigate } from 'react-router-dom'
import { Email as EmailModal, Password as PasswordModal } from '@modals/profile'
import { useDispatch, useSelector } from 'react-redux'
import { SET_APP, authorizationLayer } from '@store/actions/methods'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../App'

const { Content, Sider, Header } = Layout

const { Title } = Typography

export const Context = createContext()

const Profile = () => {
  const [selectedKey, setSelectedKey] = useState('settings')
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({})
  const [submitStatus, setSubmitStatus] = useState(false)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { setAntdProviderLang, ruRU, enUS } = useContext(AppContext)

  const getItem = (label, key, icon, children, type) => ({
    key,
    icon,
    children,
    label,
    type,
  })

  const items = [
    getItem(t('profile.tab.accountSettings'), 'settings', <SettingOutlined />),
  ]

  const dispatch = useDispatch()

  const user = useSelector((state) => state.app.user)
  const [modal, setModal] = useState(null)

  const onClick = (e) => {
    setSelectedKey(e.key)
  }

  const getTranslate = async () => {
    if (values?.locale === i18n.language) dispatch(SET_APP(['loader'], true))
    await dispatch(
      authorizationLayer({
        url: 'translations',
      })
    )
      .then(({ data }) => {
        if (data.success) {
          i18n.addResourceBundle(
            i18n.language,
            'translation',
            data.data,
            true,
            true
          )
        }
      })
      .finally(() => dispatch(SET_APP(['loader'], false)))
  }

  const onSubmit = async () => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: 'users/current',
        method: 'PATCH',
        data: {
          last_name: values?.last_name,
          name: values?.name,
          middle_name: values?.middle_name,
          locale: values?.locale,
        },
      })
    )
    await i18n.changeLanguage(values?.locale)
    await getTranslate()
    moment.locale(values?.locale)

    const langs = [ruRU, enUS]
    const lang = langs.find((i) => i.locale === values?.locale)

    if (lang) setAntdProviderLang(lang)

    setLoading(false)

    dispatch(SET_APP(['user'], values))
    notification.success({
      message: t('profile.patch.successMessage'),
    })
  }

  useEffect(() => {
    setValues(user)
  }, [user])

  const Settings = (
    <>
      <From setSubmitStatus={setSubmitStatus} />
      <EmailModal />
      <PasswordModal />
    </>
  )

  return (
    <>
      <Header className='fixed-container'>
        <Row justify='space-between' align='middle'>
          <Col>
            <Title level={4} style={{ fontWeight: 500 }}>
              {t('profile.title')}
            </Title>
          </Col>
          <Col>
            <Space size={20}>
              <Button onClick={() => navigate(-1)} disabled={loading}>
                {t('profile.closedBtn')}
              </Button>
              <Button
                type='primary'
                disabled={submitStatus}
                onClick={!submitStatus ? onSubmit : false}
              >
                {t('profile.saveBtn')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Content>
          <Layout>
            <Sider
              style={{
                padding: '89px 0px 64px 0px',
                position: 'sticky',
              }}
              width={250}
            >
              <Menu
                onClick={onClick}
                defaultSelectedKeys={[selectedKey]}
                mode='inline'
                items={items}
              />
            </Sider>
            <div
              style={{
                overflowY: 'auto',
                height: '93vh',
                marginTop: 64,
                width: '100%',
              }}
            >
              <Content
                style={{
                  width: '100%',
                  padding: '24px 40px 56px 40px',
                }}
              >
                <Context.Provider
                  value={{
                    modal,
                    setModal,
                    setValues,
                    values,
                    loading,
                    setLoading,
                  }}
                >
                  {selectedKey === 'settings' && Settings}
                </Context.Provider>
              </Content>
            </div>
          </Layout>
        </Content>
      </Layout>
    </>
  )
}

export default Profile
