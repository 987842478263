import { Typography } from 'antd'

import './styles.less'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'

const RowApplication = (props) => {
  const { title, id = '', meta } = props

  const RESULT_ICON = {
    ACTIVE: <InfoCircleOutlined style={{ color: '#0989FF', fontSize: 16 }} />,
    COMPLETED: (
      <CheckCircleOutlined style={{ color: '#3BD378', fontSize: 16 }} />
    ),
    CANCELLED: (
      <CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }} />
    ),
  }

  return (
    <div className={'row'} id={id}>
      <div className={'row--text-container'}>
        <div className={'row--icon'}>{RESULT_ICON[meta.taskStatus]}</div>
        <div className={'row--text'}>
          <Typography.Text strong style={{ fontSize: 14 }}>
            {`${title}`}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>
            {meta.responsibleUser}
          </Typography.Text>
        </div>
      </div>
    </div>
  )
}

export default RowApplication
