import React from 'react'

import { Collapse, Image, Tooltip, Typography } from 'antd'

import './styles.less'
import { getNameModule } from '../../../../../../utils'
import WrapperItem from '../WrapperItem'
import doc from '@img/doc-icon.svg'
import xls from '@img/xls-icon.svg'
import pdf from '@img/pdf-icon.svg'

const { Panel } = Collapse

const UploadedFiles = (props) => {
  const { uploadFileList, allModules } = props
  const renderFiles = (meta) => {
    let content = [null, null]
    const photosTypes = ['png', 'jpg', 'jpeg', 'bmp']
    const filesTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf']

    meta?.forEach((item) => {
      const fileType = item.path.split('.').at(-1)

      if (fileType === 'doc' || fileType === 'docx') {
        item.icon = doc
      }
      if (fileType === 'xlsx' || fileType === 'xls') {
        item.icon = xls
      }
      if (fileType === 'pdf') {
        item.icon = pdf
      }

      if (photosTypes.includes(fileType)) {
        content[0] = (
          <Image.PreviewGroup>
            {meta
              ?.filter((filtered) => {
                return photosTypes.includes(filtered.path.split('.').at(-1))
              })
              .map((photos) => {
                return (
                  <div
                    style={{
                      width: 72,
                      height: 72,
                      marginRight: 8,
                      border: '1px solid #D9D9D9',
                      borderRadius: 2,
                      background: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginBottom: 8,
                    }}
                    key={photos.id}
                  >
                    <Image
                      src={photos.path}
                      style={{ width: 60, height: 60 }}
                    />
                  </div>
                )
              })}
          </Image.PreviewGroup>
        )
      }

      if (filesTypes.includes(fileType)) {
        content[1] = meta
          ?.filter((filtered) => {
            return filesTypes.includes(filtered.path.split('.').at(-1))
          })
          .map((files) => {
            return (
              <div
                style={{
                  width: 72,
                  height: 72,
                  marginRight: 8,
                  border: '1px solid #D9D9D9',
                  borderRadius: 2,
                  background: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginBottom: 8,
                  cursor: 'pointer',
                }}
                key={files.id}
              >
                <Tooltip title={files.name}>
                  <a href={files.path} download target='blank'>
                    <img src={files.icon} alt={'file'} />
                  </a>
                </Tooltip>
              </div>
            )
          })
      }
    })

    return { meta, content }
  }

  return (
    <WrapperItem title={'Загруженные файлы'}>
      <Collapse className={'uploaded'}>
        {uploadFileList.map((item) => {
          const { id, meta } = item
          return (
            <Panel
              header={
                <Typography.Text strong>
                  {getNameModule(item.module.id, allModules)}
                </Typography.Text>
              }
              key={id}
              id={id}
              className={'uploaded--panel'}
            >
              <div style={{ display: 'flex' }}>
                {renderFiles(meta)?.content?.map((i, index) => {
                  return <React.Fragment key={index}>{i}</React.Fragment>
                })}
              </div>
            </Panel>
          )
        })}
      </Collapse>
    </WrapperItem>
  )
}

export default UploadedFiles
