import React from 'react'

import { Collapse, Row, Col, Typography, Space } from 'antd'
import { numberWithSpaces } from '@utils'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse
const { Text, Title } = Typography

const Autos = ({ ...props }) => {
  const dataWithAutos = props.servicesData?.carSpecifications || null

  const { t } = useTranslation()

  return (
    <>
      {dataWithAutos?.length ? (
        <Collapse bordered={false} style={{ background: 'transparent' }}>
          {dataWithAutos?.map((item) => {
            return (
              <Panel
                header={
                  <Title level={5}>{`${item.mark} ${item.model}`}</Title>
                  // >{`${item.mark} ${item.model}, ${item.generation}, ${item.engine_type}, ${item.engineCapacity}`}</Title>
                }
                key={item.id}
              >
                <Row justify='space-between' style={{ padding: '12px 16px' }}>
                  <Col>
                    <Text type='secondary'>
                      {t('productDetails.cars.duration')}
                    </Text>
                  </Col>
                  <Col>
                    <Text>{item.duration}</Text>
                  </Col>
                </Row>
                <Row justify='space-between' style={{ padding: '12px 16px' }}>
                  <Col>
                    <Text type='secondary'>
                      {t('productDetails.cars.price')}
                    </Text>
                  </Col>
                  <Col>
                    <Text>{numberWithSpaces(item.cost)}</Text>
                  </Col>
                </Row>
              </Panel>
            )
          })}
        </Collapse>
      ) : (
        <>
          <Row justify='space-between' style={{ padding: '12px 16px' }}>
            <Col>
              <Text type='secondary'>{t('productDetails.cars.duration')}</Text>
            </Col>
            <Col>
              <Text>{props.servicesData?.duration}</Text>
            </Col>
          </Row>
          <Row justify='space-between' style={{ padding: '12px 16px' }}>
            <Col>
              <Text type='secondary'>{t('productDetails.cars.price')}</Text>
            </Col>
            <Col>
              <Text>{numberWithSpaces(props.servicesData?.cost)}</Text>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default Autos
