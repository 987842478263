import React, { useEffect, useState } from 'react'

import { Timeline } from 'antd'

import CircularInspection from './CircularInspection'
import CarOptions from './CarOptions'
import TestDrive from './TestDrive'
import WrapperItem from '../WrapperItem'
import CarCharacteristics from './CarCharacteristics'
import './styles.less'

const CarReception = (props) => {
  const { carReceptionList, title = '', taskID } = props
  const [state, setState] = useState({
    carReceptionObj: null,
    carMarkData: {
      fullName: null,
      name: null,
      model: null,
      year: null,
    },
  })
  const { carReceptionObj } = state
  const changeStructureToObj = (arr) => {
    return arr.reduce((result, item) => {
      if (result[item.subType]) {
        result[item.subType].push(item)
      } else {
        result[item.subType] = [item]
      }
      return result
    }, {})
  }

  const setCarMarkData = (carMarkData) => {
    setState({ ...state, carMarkData: carMarkData })
  }

  useEffect(() => {
    if (carReceptionList) {
      const result = changeStructureToObj(carReceptionList)

      setState({ ...state, carReceptionObj: result })
    }
  }, [carReceptionList])
  return (
    <WrapperItem title={title}>
      <div className={'car-reception--items'} id={'car-reception--items'}>
        <Timeline>
          {carReceptionObj && (
            <React.Fragment>
              {carReceptionObj.choiceCar && (
                <Timeline.Item className={'time-line'}>
                  <CarCharacteristics
                    titleItem={'Характеристики'}
                    choiceCar={state.carReceptionObj.choiceCar}
                    setCarMarkData={setCarMarkData}
                    taskID={taskID}
                  />
                </Timeline.Item>
              )}

              {/* В разработке*/}
              {/*<Timeline.Item className={'time-line'}>*/}
              {/*  {state.carReceptionObj && (*/}
              {/*    <AverageMarketValue titleItem={'Среднерыночная стоимость'} />*/}
              {/*  )}*/}
              {/*</Timeline.Item>*/}

              {carReceptionObj.externalInspection && (
                <Timeline.Item className={'time-line'}>
                  <CircularInspection
                    titleItem={'Круговой осмотр'}
                    type={'external'}
                    carMarkData={state.carMarkData}
                    externalInspection={
                      state.carReceptionObj.externalInspection
                    }
                  />
                </Timeline.Item>
              )}
              {carReceptionObj.internalInspection && (
                <Timeline.Item className={'time-line'}>
                  <CircularInspection
                    titleItem={'Внутренний осмотр'}
                    type={'internal'}
                    carMarkData={state.carMarkData}
                    internalInspection={
                      state.carReceptionObj.internalInspection
                    }
                  />
                </Timeline.Item>
              )}
              {carReceptionObj && carReceptionObj.checkList && (
                <Timeline.Item className={'time-line'}>
                  <CarOptions
                    checkList={state.carReceptionObj.checkList}
                    choiceCar={state.carReceptionObj.choiceCar}
                  />
                </Timeline.Item>
              )}
              {carReceptionObj && carReceptionObj.testDrive && (
                <Timeline.Item className={'time-line'}>
                  <TestDrive testDrive={state.carReceptionObj.testDrive} />
                </Timeline.Item>
              )}
            </React.Fragment>
          )}
        </Timeline>
      </div>
    </WrapperItem>
  )
}

export default CarReception
