import React from 'react'
import { Button, Form, Input, Typography } from 'antd'
import './index.less'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import berthaLogo from '@img/auth-img.svg'
import errorLogo from '@img/auth-error.svg'
import mailIcon from '@img/mail-icon.svg'
import i18n from '../../i18n'

export const renderContent = (
  step,
  setForgotStep,
  newPassword,
  isError,
  isMailError,
  passwordValidate
) => {
  const email = (marginTop) => {
    return (
      <>
        <Form.Item
          name='email'
          rules={[
            {
              type: 'email',
              message: '',
              required: true,
            },
          ]}
          className='auth__form-item'
          style={{ marginTop: marginTop }}
        >
          <Input
            style={{ height: 40 }}
            placeholder={i18n.t('default.changePass.email.placeholder')}
          />
        </Form.Item>
      </>
    )
  }
  if (step === 1) {
    return (
      <>
        {email(42)}
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          className='auth__form-item'
        >
          <Input.Password
            style={{ height: 40 }}
            placeholder={i18n.t('default.changePass.pass.placeholder')}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <div className='auth__control'>
          <div
            className='auth__error'
            style={!isError ? { opacity: 0 } : { opacity: 1 }}
          >
            <img src={errorLogo} alt='' /> {isError}
          </div>
          <Button
            className='auth__action'
            type='link'
            onClick={() => setForgotStep(2)}
          >
            {i18n.t('default.login.forgotPass')}
          </Button>
        </div>
      </>
    )
  } else if (step === 2) {
    return (
      <>
        {email(66)}
        <div
          className='auth__control'
          style={{ marginTop: -24, marginBottom: 5 }}
        >
          <div
            className='auth__error'
            style={!isMailError ? { opacity: 0 } : { opacity: 1 }}
          >
            <img src={errorLogo} alt='' /> {isMailError}
          </div>
        </div>
      </>
    )
  } else if (step === 4) {
    return (
      <>
        <Form.Item
          name='new_password'
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          className='auth__form-item'
          style={{ marginTop: 29 }}
        >
          <Input.Password
            style={{ height: 40 }}
            placeholder={i18n.t('default.changePass.newPass.placeholder')}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item
          name='new_password_again'
          rules={[
            {
              required: true,
              message: '',
              validator: (_, value) =>
                value && value === newPassword
                  ? Promise.resolve()
                  : Promise.reject(),
            },
          ]}
          className='auth__form-item'
        >
          <Input.Password
            style={{ height: 40 }}
            placeholder={i18n.t('default.changePass.newPassAgain.placeholder')}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        {passwordValidate && (
          <div
            className='auth__error'
            style={{ whiteSpace: 'pre-wrap', position: 'static' }}
          >
            <img src={errorLogo} alt='' /> {passwordValidate}
          </div>
        )}
      </>
    )
  }
}

export const renderTitle = (step) => {
  const img = (src = berthaLogo, className = 'auth__img') => {
    return <img src={src} alt='logo' className={className} />
  }

  const subtitle = (title, className) => {
    if (step !== 1) className = 'auth__card-subtitle auth__card-subtitle--large'
    return <p className={className}>{title}</p>
  }
  const title = (title) => {
    return <p className='auth__card-title'>{title}</p>
  }

  if (step === 1) {
    return (
      <>
        {img()}
        {title(i18n.t('default.login.title'))}
        {subtitle(i18n.t('default.login.subtitle'), 'auth__card-subtitle')}
      </>
    )
  } else if (step === 2) {
    return (
      <>
        {img()}
        {subtitle(i18n.t('default.forgot.step1.title'), 'auth__card-subtitle')}
      </>
    )
  } else if (step === 3) {
    return (
      <>
        {img(mailIcon, 'auth__img auth__img--mail')}
        {subtitle(i18n.t('default.forgot.step2.title'))}
        <Typography.Text
          type='secondary'
          style={{ textAlign: 'center', maxWidth: 387, marginTop: 27 }}
        >
          {i18n.t('default.forgot.step2.subtitle')}
        </Typography.Text>
      </>
    )
  } else if (step === 4) {
    return (
      <>
        {img()}
        {title(i18n.t('default.changePass.title'))}
        {subtitle(i18n.t('default.changePass.subtitle'))}
      </>
    )
  }
}

export const renderBtn = (step, setForgotStep, loading) => {
  const btn = (title, marginTop, marginBottom = 0) => {
    return (
      <Button
        type='primary'
        className='auth__submit'
        htmlType='submit'
        style={{ marginTop: marginTop, marginBottom: marginBottom }}
        disabled={loading}
      >
        {title}
      </Button>
    )
  }

  const backBtn = (
    <div
      style={{
        marginTop: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        type='link'
        className='auth__action'
        onClick={() => setForgotStep(1)}
      >
        {i18n.t('default.forgot.backBtn')}
      </Button>
    </div>
  )

  if (step === 1) {
    return btn(i18n.t('default.login.btn'), 30)
  } else if (step === 2) {
    return (
      <>
        {btn(i18n.t('default.forgot.step1.btn'), 0)}
        {backBtn}
      </>
    )
  } else if (step === 3) {
    return backBtn
  } else if (step === 4) {
    return btn(i18n.t('default.changePass.btn'), 20, 34)
  }
}
