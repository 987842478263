import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import queryString from 'query-string'
import { Typography, Button } from 'antd'
import useHeight from '@hooks/useHeight'
import moment from 'moment'
import Calendar from './components/Calendar/Calendar'
import PickedDate from './components/PickedDate'
import { useTranslation } from 'react-i18next'

import Loading from '@ui/Loading'

const { Text } = Typography

const PausedTasks = ({ setClientInfo, setVisibleDrawer }) => {
  const { bodyHeight } = useHeight(['ant-layout-header'])
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [week, setWeek] = useState(moment().week())
  const [filteredData, setFilteredData] = useState([])
  const [data, setData] = useState(null)
  const [getTask, setGetTask] = useState(moment())

  const [activeTab, setActiveTab] = useState('tab1')

  const weekDays = useMemo(() => {
    const start = moment().week(week).isoWeekday(1)

    return Array.from({ length: 7 }, (_, i) => moment(start).add(i, 'days'))
  }, [week])

  useEffect(() => {
    const getAllTask = async () => {
      try {
        setLoading(true)
        const from = moment(weekDays[0]).format('YYYY-MM-DD')
        const to = moment(weekDays[weekDays.length - 1]).format('YYYY-MM-DD')

        const q = {}

        if (from && to) q.from = from
        q.to = to

        const query = queryString.stringify(q)

        const { data: response } = await dispatch(
          authorizationLayer({ url: `processes/tasks/history?${query}` })
        )

        const data = response?.data

        setData(data)
        setFilteredData(data)

        if (!data) return
        setLoading(false)
      } catch (e) {
        console.log(e.message)
      }
    }
    getAllTask()
  }, [dispatch, weekDays])

  useEffect(() => {
    if (getTask) {
      setFilteredData(
        data?.filter(
          (item) =>
            moment(item.pausedTo).format('YYYY-MM-DD') ===
            getTask.format('YYYY-MM-DD')
        )
      )
    }
  }, [getTask, data])

  return (
    <div style={{ height: bodyHeight }}>
      {loading && <Loading spinner={true} />}
      {activeTab === 'tab1' && (
        <div className='pausedBox__tab-block'>
          <Text className='pausedBox__title'>
            {weekDays[3].format('MMMM, YYYY')?.charAt(0)?.toUpperCase() +
              weekDays[3].format('MMMM, YYYY')?.slice(1)}
          </Text>
          <button
            className='pausedBox__btn pausedBox__btn-calendar'
            onClick={() => setActiveTab('tab2')}
          >
            {t('main.tab.pendingRequests.calendar')}
          </button>
        </div>
      )}

      {activeTab === 'tab1' && (
        <PickedDate
          props={{
            data: data,
            getTask: getTask,
            setVisibleDrawer: setVisibleDrawer,
            setClientInfo: setClientInfo,
            filteredData: filteredData,
            setWeek: setWeek,
            weekDays: weekDays,
            setGetTask: setGetTask,
          }}
        />
      )}

      {activeTab === 'tab2' && (
        <div className='pausedBox__calendar-block '>
          <Calendar
            setActiveTab={setActiveTab}
            setVisibleDrawer={setVisibleDrawer}
            setClientInfo={setClientInfo}
          />
        </div>
      )}
    </div>
  )
}

export default PausedTasks
