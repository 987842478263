import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import './index.less'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import QRCode from 'qrcode.react'
import {
  Row,
  Checkbox,
  Col,
  Button,
  Input,
  Upload,
  message,
  Modal,
  Form,
  InputNumber,
} from 'antd'
import { InspectionContext } from '../..'
import error from '@img/auth-error.svg'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { MaskedInput } from 'antd-mask-input'
import data from '../Details/data'
import { modules } from '@/constants'

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const Defects = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { defects, category } = useSelector((state) => state.app)
  const {
    selected,
    values,
    setValues,
    type,
    comment,
    setComment,
    checked,
    setSelected,
    needsActions,
    lkpValue,
    setLkpValue,
  } = useContext(InspectionContext)
  const {
    taskId,
    moduleId,
    selectAuto,
    setChecked,
    fileList,
    setFileList,
    mainTaskId,
  } = useContext(InspectionContext)
  const items = useSelector((state) => state.app[`car_details_${type}`])
  const item = _.find(items, { short_name: selected })
  const docFormatArray = ['jpeg', 'jpg', 'png', 'bmp', 'jfif']
  const [previewImage, setPreviewImage] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const filled = _.find(values, (v) => {
    return v.carDetail.short_name === selected
  })

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async (file) => {
    if (!file.url) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  const uploadFile = async (files) => {
    setLoading(true)
    const formData = new FormData()

    files.forEach((file) => {
      let splitFileUrl = file.name.split('.')
      let currentDocFormat = splitFileUrl[splitFileUrl.length - 1]
      let ignoreStatus = !docFormatArray.includes(currentDocFormat)

      if (!ignoreStatus) formData.append('files', file)
    })

    const headers = {
      'Content-type': 'multipart/form-data',
    }

    await dispatch(
      authorizationLayer({
        url: `files/image`,
        method: 'POST',
        data: formData,
        headers,
      })
    )
      .then((res) => {
        if (res.status == 201) {
          setFileList([
            ...fileList,
            ...res.data.map((i) => {
              let photo_id = i.id
              let path = i.path
              let status = 'done'

              return {
                photo_id,
                path,
                status,
                uploaded: true,
              }
            }),
          ])
          message.success(
            t('task.moduleRender.module.carReception.inspection.photosSuccess')
          )
          saveData(checked, comment, [...fileList, ...res.data], lkpValue)
        } else {
          message.error(
            t('task.moduleRender.module.carReception.inspection.photosError')
          )
        }
      })
      .finally(() => setLoading(false))
  }

  const onRemove = (file) => {
    setLoading(true)
    setFileList(fileList?.filter((i) => i.photo_id !== file.id))
    if (
      !fileList?.filter((i) => i.photo_id !== file.id)?.length &&
      !lkpValue &&
      !checked.length &&
      !comment
    )
      onClear()
    else
      saveData(
        checked,
        comment,
        fileList?.filter((i) => i.photo_id !== file.id),
        lkpValue
      )
    setLoading(false)
  }

  useEffect(() => {
    if (filled) {
      const { carConfirmations, comment, photos, lkp } = filled
      setChecked(carConfirmations)
      setFileList(photos)
      setComment(comment)
      form.setFieldsValue({ comment })
      setLkpValue(lkp)
    } else {
      setChecked([])
      setFileList([])
      setComment('')
      form.setFieldsValue({ comment: '' })
      setLkpValue('')
    }
  }, [selected, type])

  const saveData = async (checked, comment, fileList, lkp) => {
    const newValues = {
      carDetail: item,
      carConfirmations: checked,
      comment: comment,
      photos: fileList?.map((p) => {
        let photo = p.path || p.photo
        let photo_id = p.id || p.photo_id

        return {
          photo,
          photo_id,
        }
      }),
      lkp: lkp,
    }

    if (filled) {
      const index = _.findIndex(values, (v) => {
        return v.carDetail.short_name === selected
      })
      values[index] = newValues

      setValues([...values])
    } else {
      setValues([...values, newValues])
    }

    await dispatch(
      authorizationLayer({
        url: `needs`,
        method: 'POST',
        data: {
          taskId,
          moduleId,
          mainTaskId,
          needs: [
            {
              type: modules.CAR_RECEPTION,
              action: 'choice',
              meta: filled
                ? [...values].filter((item) => {
                    if (items.some((i) => i.id === item.carDetail.id)) {
                      return true
                    } else {
                      return false
                    }
                  })
                : [...values, newValues].filter((item) => {
                    if (items.some((i) => i.id === item.carDetail.id)) {
                      return true
                    } else {
                      return false
                    }
                  }),
              subType:
                type === 'external'
                  ? 'externalInspection'
                  : 'internalInspection',
            },
          ],
        },
      })
    ).then(({ data }) => {
      needsActions(data)
    })
  }

  const onCheck = useMemo(
    () =>
      _.debounce((e) => {
        if (!lkpValue && !e.length && !comment && !fileList.length) {
          onClear()
        } else {
          saveData(e, comment, fileList, lkpValue)
          setChecked(e)
        }
      }, 300),
    [checked, comment, fileList, filled, lkpValue]
  )

  const onChangeComments = useMemo(
    () =>
      _.debounce((e) => {
        if (
          !lkpValue &&
          !checked.length &&
          !e.target.value &&
          !fileList.length
        ) {
          onClear()
        } else {
          setComment(e.target.value.trim())
          saveData(checked, e.target.value.trim(), fileList, lkpValue)
        }
      }, 500),
    [comment, checked, fileList, filled, lkpValue]
  )

  const onChangeLkp = useMemo(
    () =>
      _.debounce((e) => {
        saveData(checked, comment, fileList, e)
      }, 500),
    [comment, checked, fileList, filled]
  )

  const onClear = async () => {
    if (!filled) return

    const newValues = _.filter(values, (v) => {
      return v.carDetail.short_name !== selected
    })
    setValues(newValues)

    await dispatch(
      authorizationLayer({
        url: `needs`,
        method: 'POST',
        data: {
          taskId,
          moduleId,
          mainTaskId,
          needs: [
            {
              type: modules.CAR_RECEPTION,
              action: 'choice',
              meta: newValues.filter((item) => {
                if (items.some((i) => i.id === item.carDetail.id)) {
                  return true
                } else {
                  return false
                }
              }),
              subType:
                type === 'external'
                  ? 'externalInspection'
                  : 'internalInspection',
            },
          ],
        },
      })
    ).then(({ data }) => {
      needsActions(data)
    })
    setChecked([])
    setFileList([])
    setComment('')
    setSelected(null)
    setLkpValue('')
  }

  const files = useMemo(
    () =>
      fileList?.map((f) => {
        if (f.photo || f.path) {
          const { photo, id, uploaded, path, photo_id } = f
          return {
            url: photo || path,
            id: photo_id,
            uid: photo_id,
            status: 'done',
            uploaded,
          }
        }
        return f
      }),
    [fileList]
  )

  const filesRef = useRef()

  const onChange = (e) => {
    clearTimeout(filesRef.current)
    filesRef.current = setTimeout(() => {
      uploadFile(e)
    }, 500)
  }

  const isLkpDisabled = !data.external.find((x) => x.name === selected)?.lkp

  return (
    <div className='defects'>
      <Row style={{ width: '100%' }} justify='space-between'>
        <Col>
          <div className='defects__title'>{item?.name}</div>
        </Col>
        <Col>
          <Button
            type='link'
            className='defects__item-clear'
            onClick={() => setVisible(true)}
            disabled={
              !_.find(values, (v) => {
                return v.carDetail.short_name === selected
              })
            }
          >
            {t('task.moduleRender.module.carReception.checkList.reset')}
          </Button>
        </Col>
      </Row>
      {type === 'external' && (
        <InputNumber
          className='defects__lkp'
          placeholder={t('task.moduleRender.module.carReception.checkList.lkp')}
          disabled={isLkpDisabled}
          onChange={(e) => {
            if (!e && !checked.length && !comment && !fileList.length) {
              onClear()
            } else {
              setLkpValue(e)
              onChangeLkp(e)
            }
          }}
          onStep={(e) => {
            if (!e && !checked.length && !comment && !fileList.length) {
              onClear()
            } else {
              setLkpValue(e)
              onChangeLkp(e)
            }
          }}
          value={lkpValue}
          min={0}
          max={9999}
        />
      )}
      <Checkbox.Group
        className='defects__checkbox-wrap'
        onChange={(e) => {
          onCheck(defects?.filter((i) => e.includes(i.id)))
        }}
        value={checked?.map((i) => i.id)}
      >
        <Row gutter={24}>
          {defects?.map((v) => (
            <Col key={v.id}>
              <Checkbox value={v.id}>{v.name}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Form initialValues={{ comment: comment }} form={form}>
        <Form.Item name='comment'>
          <Input.TextArea
            onChange={(e) => onChangeComments(e)}
            placeholder={t(
              'task.moduleRender.module.carReception.checkList.problemDescPlaceholder'
            )}
            showCount
            maxLength={1000}
            className='defects__comment'
          />
        </Form.Item>
      </Form>

      <Row gutter={6} className='defects__btn-wrap'>
        <Col>
          <Upload
            accept='.jpg, .jpeg, .png, .jfif'
            beforeUpload={(file, fileList) => {
              let splitFileUrl = file.name.split('.')
              let currentDocFormat = splitFileUrl[splitFileUrl.length - 1]
              let ignoreStatus = !docFormatArray.includes(currentDocFormat)

              if (ignoreStatus) {
                message.error(
                  `${t(
                    'task.moduleRender.module.carReception.inspection.photosValidateMessage'
                  )} ${docFormatArray?.join(', ')} `
                )
              } else {
                onChange(fileList)
              }

              return ignoreStatus
            }}
            fileList={[]}
            multiple
          >
            <Button
              shape='round'
              type='primary'
              ghost
              loading={loading}
              disabled={!checked?.length && !comment && !lkpValue}
            >
              {t('task.moduleRender.module.uploadPhoto')}
            </Button>
          </Upload>
        </Col>
        {/* <Col>
          <Button type='link' disabled>
            {t('task.moduleRender.module.showQR')}
          </Button>
        </Col> */}
      </Row>
      <Upload
        listType='picture-card'
        fileList={files}
        onPreview={handlePreview}
        onRemove={onRemove}
      />
      <Modal
        open={previewVisible}
        footer={null}
        onCancel={handleCancel}
        bodyStyle={{ padding: 40 }}
      >
        <img
          src={previewImage}
          style={{
            width: '100%',
          }}
        />
      </Modal>
      <Modal
        visible={visible}
        wrapClassName='details__modal'
        width={416}
        bodyStyle={{ padding: '32px 32px 32px 70px' }}
        closable={false}
        onCancel={() => setVisible(false)}
        okText={t('task.moduleRender.module.carReception.checkList.reset')}
        onOk={() => {
          onClear()
          setVisible(false)
        }}
        okButtonProps={{ type: 'danger' }}
      >
        <ExclamationCircleOutlined
          style={{
            color: '#FF4D4F',
            fontSize: 22,
            position: 'absolute',
            left: 32,
          }}
        />
        <div style={{ fontSize: 16, fontWeight: 700 }}>
          {t('task.moduleRender.module.carReception.checkList.reset')} «
          {item?.name}»?
        </div>
        <div style={{ marginTop: 8 }}>
          {t('task.moduleRender.module.carReception.checkList.confirmDesc')}
        </div>
      </Modal>
    </div>
  )
}

export default Defects
