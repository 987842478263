import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { ModuleContextConfirm } from '../../render'
import { Typography, Divider, Row, Col } from 'antd'
import _ from 'lodash'
import { numberWithSpaces } from '@utils'
import { modules, stockTypes } from '@/constants'
import { useTranslation } from 'react-i18next'
import SkeletonTable from '@ui/SkeletonTable'
import {
  newColumns,
  testDriveColumns,
  usedColumns,
  partsColumns,
} from './columns'
import './index.less'

const ChoiceProduct = () => {
  const { data } = useContext(ModuleContextConfirm)
  const { module, key } = data
  const { needs, moduleItem, stockType } = module
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sum = () => {
    if (stockType === stockTypes.SPARE_PARTS) {
      return _.sumBy(needs?.meta, (i) => {
        return i.orderAmount * i.price
      })
    }

    return _.sumBy(needs?.meta, 'price')
  }

  const setContent = () => {
    let title = null
    let columns = null
    if (stockType === stockTypes.NEW_AUTO) {
      title = `${t('task.moduleRender.module.choiceProduct.selectedCars')} (${t(
        'clientCard.history.newAuto'
      )})`
      columns = newColumns(dispatch)
    } else if (stockType === stockTypes.USED_AUTO) {
      title = `${t('task.moduleRender.module.choiceProduct.selectedCars')} (${t(
        'clientCard.history.usedAuto'
      )})`
      columns = usedColumns(dispatch)
    } else if (stockType === stockTypes.TEST_DRIVE) {
      title = `${t('task.moduleRender.module.choiceProduct.selectedCars')} (${t(
        'clientCard.history.testDrive'
      )})`
      columns = testDriveColumns(dispatch)
    } else if (stockType === stockTypes.SPARE_PARTS) {
      title = `${t('clientCard.history.selected')} ${t(
        'clientCard.history.parts'
      )}`

      columns = partsColumns(dispatch)
    }

    return { title, columns }
  }

  return (
    <>
      <Row justify='space-between'>
        <Col>
          <Typography.Title level={5}>{setContent().title}</Typography.Title>{' '}
        </Col>
        <Col>
          <span style={{ fontSize: 16 }}>{numberWithSpaces(sum())}</span>
        </Col>
      </Row>
      <Divider />
      <SkeletonTable
        data={needs?.meta || []}
        columns={setContent().columns}
        className='confirm-choice-product__table'
        width='calc(100% - 449px - 100px)'
      />
    </>
  )
}

export default ChoiceProduct
