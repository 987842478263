import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { PageHeader, Button, Form, Select, Input, DatePicker } from 'antd'
import { Typography, AutoComplete } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import Timeline from './Timeline'
import { authorizationLayer } from '@store/actions/methods'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
// import { users, items } from "./data";
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const format = 'YYYY-MM-DD'

const Board = (props) => {
  const [date, setDate] = useState(moment().format(format))
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [positions, setPositions] = useState([])
  const [position, setPosition] = useState('0')
  const [search, setSearch] = useState(null)
  const [users, setUsers] = useState([])
  const { user } = useSelector((state) => state.app)
  const [update, setUpdate] = useState()

  const dispatch = useDispatch()

  const setDay = useCallback(
    (value) => {
      const $date = moment(date).add(value, 'day')
      setDate($date.format(format))
    },
    [date, update]
  )

  const getUsers = async () => {
    try {
      const { data } = await dispatch(
        authorizationLayer({ url: 'wh-services/wh-services-tasks/users' })
      )
      const positions = []
      data.data?.forEach((user) => {
        const { position } = user
        const item = _.find(positions, { id: position.id })
        if (!item) {
          positions.push(position)
        }
      })
      setPositions(positions)
      setUsers(data.data)
    } catch (e) {}
  }

  const getData = async () => {
    try {
      setLoading(true)
      const query = queryString.stringify({
        date,
      })
      const { data } = await dispatch(
        authorizationLayer({ url: `wh-services/wh-services-tasks?${query}` })
      )
      setLoading(false)
      setData(data.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getUsers()
    getData()
  }, [date, update])

  const onChange = (date) => setDate(date)

  const filteredUsers = () => {
    if (position !== '0') {
      return _.filter(users, (u) => u.position.id === position)
    }
    return users
  }

  const filteredData = () => {
    if (search) {
      return _.filter(data, (u) => u.whServiceTask.wh_service.name === search)
    }

    return data
  }

  return (
    <>
      <PageHeader
        title={
          <Filter
            positions={positions}
            setPosition={setPosition}
            data={data}
            setSearch={setSearch}
          />
        }
        extra={[
          <Button
            key='left'
            style={{
              height: '30px',
            }}
            onClick={() => setDay(-1)}
          >
            <LeftOutlined />
          </Button>,
          <DatePicker
            style={{
              height: '30px',
            }}
            key='date'
            onChange={onChange}
            value={moment(date)}
            format='DD.MM.YYYY'
            allowClear={false}
          />,
          <Button
            key='right'
            style={{
              height: '30px',
            }}
            onClick={() => setDay(1)}
          >
            <RightOutlined />
          </Button>,
        ]}
      />
      <Timeline
        users={filteredUsers()}
        data={filteredData()}
        loading={loading}
      />
    </>
  )
}

const Filter = (props) => {
  const { positions, setPosition, data, setSearch } = props
  const filter = useRef()
  const [options, setOptions] = useState(data)
  const { t } = useTranslation()

  useEffect(() => {
    filter.current.setFieldsValue({ position: '0' })
  }, [])

  const findOption = (search) => (o) => {
    return (
      o.whServiceTask.wh_service.name
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    )
  }

  const formatOption = (o) => {
    return {
      label: o.whServiceTask.wh_service.name,
      value: o.whServiceTask.wh_service.name,
    }
  }

  const onValuesChange = ({ search, position }) => {
    if (!_.isUndefined(search)) {
      if (search) {
        const filteredOptions = _.filter(data, findOption(search))
        const formattedOption = _.map(filteredOptions, formatOption)
        setOptions(formattedOption)
      } else setOptions([])
    }

    if (!_.isUndefined(position)) {
      setPosition(position)
    }
  }

  const onSelect = (val) => setSearch(val)

  return (
    <Form onValuesChange={onValuesChange} ref={filter} layout='inline'>
      <Form.Item name='search'>
        <AutoComplete
          options={options}
          style={{ width: 400 }}
          onSelect={onSelect}
        >
          <Input
            placeholder={t('calendar.board.searchPlaceholder')}
            allowClear
            suffix={<SearchOutlined />}
          />
        </AutoComplete>
      </Form.Item>
      <Form.Item name='position'>
        <Select style={{ width: 300 }}>
          <Select.Option value='0'>
            {t('calendar.board.allUsers')}
          </Select.Option>
          {positions.map((p) => (
            <Select.Option key={p.id} value={p.id}>
              {p.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default Board
