import { memo } from 'react'

import { Typography } from 'antd'

import './styles.less'

const WrapperItem = memo((props) => {
  const { title, price, children } = props
  return (
    <div className={'wrapper-item'} id={'SelectedItem'}>
      <div className={'wrapper-item--header'}>
        <Typography.Text strong>{title}</Typography.Text>
        {price && <Typography.Text>{price}</Typography.Text>}
      </div>
      <div>{children}</div>
    </div>
  )
})

export default WrapperItem
