import Header from '@ui/Header'
import React, { useState, useEffect } from 'react'
import { useLocation, Outlet, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Menu, Button } from 'antd'
import { MenuFoldOutlined, HomeOutlined } from '@ant-design/icons'
import { InboxOutlined, CalendarOutlined } from '@ant-design/icons'
import { FundOutlined } from '@ant-design/icons'
import { authorizationLayer } from '@store/actions/methods'
import { useTranslation } from 'react-i18next'

const { Content, Sider } = Layout

const MainLayout = ({ notification }) => {
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(true)
  const permissions = useSelector((state) => state.app.permissions)
  const { t } = useTranslation()

  const navigation = () => {
    let data = [
      {
        label: t('main.title'),
        key: '/',
        icon: (
          <Link to='/'>
            <HomeOutlined />
          </Link>
        ),
      },
    ]

    if (permissions?.find((p) => p.name.includes('wh'))) {
      data.push({
        label: t('common.tab.whCarsAndService'),
        key: '/warehouse',
        icon: (
          <Link to='/warehouse'>
            <InboxOutlined />
          </Link>
        ),
      })
    }

    if (permissions?.find((p) => p.name.includes('statistic'))) {
      data.push({
        label: t('common.tab.statistics'),
        key: '/statistics',
        icon: (
          <Link to='/statistics'>
            <FundOutlined />
          </Link>
        ),
      })
    }

    return data
  }

  const contextData = {
    cardEmptyStyle: {
      height: 616,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    notification: notification,
  }

  return (
    <>
      <Header />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider
          style={{ paddingTop: 64, height: '100vh' }}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          theme='light'
          trigger={null}
          collapsedWidth={48}
          width={208}
        >
          <Menu
            mode='inline'
            selectedKeys={[location.pathname]}
            items={navigation()}
          />
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              borderTop: '1px solid #F0F0F0',
              width: '100%',
            }}
          >
            <Button
              style={{
                width: '48px',
                height: '40px',
                border: 'none',
              }}
              icon={
                collapsed ? (
                  <MenuFoldOutlined
                    style={{ fontSize: '16px', transform: 'rotate(180deg)' }}
                  />
                ) : (
                  <MenuFoldOutlined style={{ fontSize: '16px' }} />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
        </Sider>
        <Content>
          <div
            style={{
              overflowY: 'auto',
              height: 'calc(100vh - 64px)',
              width: '100%',
              marginTop: 64,
            }}
          >
            <Outlet context={contextData} />
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default MainLayout
