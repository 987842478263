import React from 'react'
import { Empty, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import './index.less'

const ChoiceCar = ({ data }) => {
  const { meta, title } = data
  const { t } = useTranslation()

  const content = [
    {
      id: 0,
      title: t('warehouse.tab.cars.column.year'),
      desc: meta?.year,
    },
    {
      id: 1,
      title: t('productDetails.cars.body'),
      desc: meta?.carBodyType?.name,
    },
    {
      id: 2,
      title: t('productDetails.cars.generation'),
      desc: meta?.carGeneration?.name,
    },
    {
      id: 3,
      title: t('warehouse.tab.cars.column.engine'),
      desc: meta?.carEngineType?.name,
    },
    {
      id: 4,
      title: t('productDetails.cars.drive'),
      desc: meta?.carDriveType?.name,
    },

    {
      id: 5,
      title: t('productDetails.cars.transmission'),
      desc: meta?.carGearboxType?.name,
    },
    {
      id: 6,
      title: t('productDetails.cars.rudder'),
      desc:
        meta?.steering_wheel === 'left'
          ? t('productDetails.cars.left')
          : t('productDetails.cars.right'),
    },
    {
      id: 7,
      title: t('productDetails.cars.modification'),
      desc: !meta?.carModification?.name
        ? meta?.engine_capacity
        : meta?.carModification?.name,
    },
    {
      id: 8,
      title: t('warehouse.tab.cars.column.mileage'),
      desc: meta?.mileage,
    },
    {
      id: 9,
      title: t('warehouse.tab.cars.column.bodyColor'),
      desc: meta?.carExteriorColor?.name,
    },
    {
      id: 10,
      title: t('warehouse.tab.cars.column.interiorColor'),
      desc: meta?.carInteriorColor?.name,
    },
    {
      id: 11,
      title: t('productDetails.cars.vin'),
      desc: meta?.vin_code,
    },
  ]

  return (
    <>
      <Space size={8} direction='vertical' style={{ width: '100%' }}>
        <Typography.Title style={{ fontWeight: 400 }} level={5}>
          {title}
        </Typography.Title>
        {meta && (
          <Typography.Text>
            {meta?.carMark?.name && `${meta?.carMark?.name}`}{' '}
            {meta?.carModel?.name && `${meta?.carModel?.name}`}
          </Typography.Text>
        )}
        <div className='confirm-car-reception__choice-car-list'>
          {meta ? (
            content?.map((i) => {
              return (
                <div
                  className='confirm-car-reception__choice-car-item'
                  key={i.id}
                >
                  <Typography.Text type='secondary'>{i.title}</Typography.Text>{' '}
                  <Typography.Text strong> {i.desc}</Typography.Text>
                </div>
              )
            })
          ) : (
            <Empty />
          )}
        </div>
      </Space>
    </>
  )
}

export default ChoiceCar
