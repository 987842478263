import React, { memo } from 'react'

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'

import './index.less'

const BSteps = memo((props) => {
  const { stages, currentOrder, status } = props

  const showCompletedStatus = (item, status, order) => {
    if (status === 'COMPLETED' || order > item.order) {
      return <CheckCircleOutlined className={'circleOutlined done'} />
    } else {
      if (status === 'CANCELLED' && order === item.order) {
        return <CloseCircleOutlined className={'circleOutlined cancelled'} />
      } else if (order === item.order) {
        return <InfoCircleOutlined className={'circleOutlined current'} />
      } else {
        return <InfoCircleOutlined className={'circleOutlined disabled'} />
      }
    }
  }

  return (
    <div className={'b-steps'}>
      {stages.map((item, index) => {
        const showActiveStep = stages.length !== index + 1
        const isDisabledLine =
          status === 'COMPLETED' || currentOrder > item.order
        return (
          <React.Fragment key={item.id}>
            <div className={'b-step'} id={item.id}>
              <div className={'b-step--icon'}>
                {showCompletedStatus(item, status, currentOrder)}
              </div>
              <div className={'b-step--title'}>{item.name}</div>
            </div>
            {showActiveStep && (
              <div className={'b-step--line'}>
                <div
                  className={`b-step--line--border ${
                    isDisabledLine ? 'line--blue' : 'line--disabled'
                  }`}
                />
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
})

export default BSteps
