import React, { useState, useEffect, useCallback } from 'react'
import { Button, Dropdown, Drawer, Menu, Slider, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import './index.less'
import AddContacts from '../AddContacts'
import Loading from '@ui/Loading'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import CardInClient from '../CardInClient'

const Contacts = ({ id, canEditClientCard }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [openClient, setClientOpen] = useState(false)
  const [clientId, setClientId] = useState()

  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = async () => {
    setOpen(false)
    await getData()
  }

  const dispatch = useDispatch()

  const getData = useCallback(async () => {
    setLoading(true)

    const { data } = await dispatch(
      authorizationLayer({
        url: `clients/linked/${id}`,
        method: 'get',
      })
    )
    setData(data)
    setLoading(false)
  }, [dispatch, id])

  useEffect(() => {
    getData()
  }, [dispatch, id, getData])

  const getNumber = (item) => {
    return item?.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      '$1 $2 $3 $4 $5'
    )
  }

  const actionDropdownMenu = (e) => {
    return (
      <Menu
        items={[
          {
            label: (
              <span
                onClick={() => {
                  deleteContact(e)
                }}
              >
                {t('clientCard.contacts.deleteBtn')}
              </span>
            ),
            key: 1,
          },
        ]}
      />
    )
  }

  const deleteContact = async (e) => {
    Modal.confirm({
      title: t('clientCard.contacts.deleteConfirmTitle'),
      icon: <ExclamationCircleOutlined style={{ color: '#FF4D4F' }} />,
      okText: t('clientCard.contacts.deleteConfirmOk'),
      okType: 'danger',
      cancelText: t('clientCard.contacts.deleteConfirmCancel'),
      onOk: async () => {
        await dispatch(
          authorizationLayer({
            url: `clients/${id}/assign`,
            method: 'DELETE',
            data: {
              id: e,
            },
          })
        ).then(() => {
          getData()
        })
      },
    })
  }

  return (
    <div className='contacts'>
      {loading && <Loading spinner={true} />}
      <div className='contacts__list'>
        <div>
          {data.length !== 0 ? (
            data?.map((item, index) => (
              <div
                className='task__client-input-wrap'
                style={{
                  padding: '20px 30px ',
                  boxShadow: '-3px 0px 14px rgba(212, 216, 220, 0.19)',
                }}
                key={index}
              >
                <div className='task__found-name-box'>
                  <div className='task__full-name'>
                    {item?.full_name || item?.name}
                  </div>

                  <Dropdown
                    placement='bottomLeft'
                    overlay={() => actionDropdownMenu(item?.id)}
                    trigger={['click']}
                  >
                    <button className='task__btn task__btn-drop'>
                      <img
                        className='task__img'
                        src='https://minio.jcloud.kz/bertha-development/8fe46d80-ad72-4e84-a0af-94f3b0238669-threedots.svg'
                        alt='dots'
                      />
                    </button>
                  </Dropdown>
                </div>
                <div className='task__num-box'>
                  <p className='task__num'>+{getNumber(item?.phone_number)}</p>
                  {/*<a
                    target='_blank'
                    rel='noreferrer'
                    className='task__wpp-btn'
                    href={`https://api.whatsapp.com/send?phone=${item?.phone_number}`}
                  >
                    <img
                      className='task__img'
                      src='https://minio.jcloud.kz/bertha-development/813c19fa-f5a2-46ef-b3bd-7023802aa489-whatsapp.svg'
                      alt='whatsapp'
                    />
                  </a>*/}
                </div>
                <Button
                  className='task__client-card'
                  onClick={() => {
                    setClientOpen(true)
                    setClientId(item?.id)
                  }}
                >
                  {t('clientCard.addContacts.btnToClientCard')}{' '}
                </Button>
                {item?.data_fullness !== 100 && (
                  <div className='task__completed'>
                    <p className='task__completed-text'>
                      {t('request.clientInfo.cardDataFuiel')}{' '}
                      {item?.data_fullness}%
                    </p>

                    <div className='task__progress'>
                      <Slider
                        className='task__slider'
                        style={{ pointerEvents: 'none' }}
                        handleStyle={{ display: 'none' }}
                        value={item?.data_fullness}
                        trackStyle={
                          item?.data_fullness <= 50
                            ? { backgroundColor: '#ff9900' }
                            : { backgroundColor: '#1890FF' }
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div
              style={{
                color: 'rgba(0, 0, 0, 0.25)',
                textAlign: 'center',
                marginTop: '50px',
              }}
            >
              {t('clientCard.contacts.noContacts')}
            </div>
          )}
        </div>
      </div>

      <div style={{ padding: '20px 30px 0px' }}>
        {canEditClientCard && (
          <Button
            style={{ borderRadius: '26px', height: '40px', width: '100%' }}
            type='primary'
            onClick={showDrawer}
          >
            {t('clientCard.contacts.addBtn')}
          </Button>
        )}
      </div>
      <Drawer
        title={t('clientCard.contacts.addContactsTitle')}
        placement='right'
        onClose={onClose}
        open={open}
        width={450}
        bodyStyle={{ padding: '0' }}
        destroyOnClose={true}
      >
        <AddContacts id={id} setOpen={setOpen} getData={getData} />
      </Drawer>

      <CardInClient
        openClient={openClient}
        setClientOpen={setClientOpen}
        clientId={clientId}
        setClientId={setClientId}
      />
    </div>
  )
}

export default Contacts
