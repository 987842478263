import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { Board, Calendar } from './Tabs'
import './index.less'
import { t } from 'i18next'

const { TabPane } = Tabs

const tabs = [
  { name: t('calendar.tabs.board'), key: 'board' },
  { name: t('calendar.tabs.personal'), key: 'calendar' },
]

const LS_NAME = 'calendar-tab'

const Screen = (props) => {
  const [tab, setTab] = useState(tabs[0].key)
  const onChange = async (tab) => {
    setTab(tab)
    await localStorage.setItem(LS_NAME, tab)
  }

  useEffect(() => {
    const getTab = async () => {
      let tab = await localStorage.getItem(LS_NAME)
      if (tab) setTab(tab)
    }
    getTab()
  }, [])

  return (
    <>
      <div style={{ backgroundColor: 'white', padding: '0 40px' }}>
        <Tabs activeKey={tab} onChange={onChange}>
          {tabs.map((tab) => (
            <TabPane tab={tab.name} key={tab.key} />
          ))}
        </Tabs>
      </div>
      <div style={{ padding: '24px 40px' }}>
        {tab === 'board' && <Board />}
        {tab === 'calendar' && <Calendar />}
      </div>
    </>
  )
}

export default Screen
