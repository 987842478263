import React, { useContext } from 'react'
import { ModuleContextConfirm } from '../../render'
import { Row, Col, Typography, Divider, List, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { CloseCircleOutlined } from '@ant-design/icons'
import { modules } from '@/constants'
import './index.less'

const icons = [
  {
    type: 'ACTIVE',
    icon: <InfoCircleOutlined style={{ color: '#0989FF', fontSize: 16 }} />,
  },

  {
    type: 'COMPLETED',
    icon: <CheckCircleOutlined style={{ color: '#3BD378', fontSize: 16 }} />,
  },
  {
    type: 'CANCELLED',
    icon: <CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }} />,
  },
]

const IWD = ({ type }) => {
  const { data } = useContext(ModuleContextConfirm)
  const { t } = useTranslation()

  const getData = () => {
    const items = []

    if (data.process && _.isArray(data.process?.stages)) {
      data.process?.stages.forEach((stage) => {
        if (_.isArray(stage?.modules)) {
          stage?.modules?.forEach((module) => {
            if (
              module?.component?.componentType?.name === type &&
              data.module?.needs?.find((i) => i.module?.id === module?.id)
            ) {
              items.push(module)
            }
          })
        }
      })
    }

    return items
  }

  const renderStatus = (moduleId) => {
    let status = icons?.find(
      (iconItem) =>
        data.module?.needs?.find((i) => i.module?.id === moduleId)?.meta
          .taskStatus === iconItem?.type
    )?.icon

    return status ?? icons?.find((i) => i.type === 'ACTIVE').icon
  }

  const renderDesc = (moduleId) => {
    let userName = data.module?.needs?.find((i) => i.module?.id === moduleId)
      ?.meta.responsibleUser

    return { userName }
  }

  return (
    <>
      <Row justify='space-between'>
        <Col>
          <Typography.Title level={5}>
            {type === modules.IWD
              ? t('task.actionHistory.commentType.interectionWithDepartments')
              : t('task.actionHistory.commentType.agreement')}
          </Typography.Title>{' '}
        </Col>
      </Row>
      <Divider />
      {getData()?.length ? (
        getData()?.map((item) => {
          return (
            <div key={item.id}>
              <List.Item className='IWD__list-item'>
                <List.Item.Meta
                  avatar={renderStatus(item.id)}
                  title={item.name}
                  description={renderDesc(item.id).userName}
                />
              </List.Item>
            </div>
          )
        })
      ) : (
        <Empty />
      )}
    </>
  )
}

export default IWD
