import React from 'react'
import { Input, Space, Typography, Checkbox, Row, Col, Image } from 'antd'
import { Empty } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Info = ({ data }) => {
  const { type, meta, selected } = data
  const selectedItem = meta?.find((i) => i.carDetail.short_name === selected)
  const defects = useSelector((state) => state.app.defects)
  const { t } = useTranslation()

  if (!selected && meta?.length)
    return (
      <div
        style={{
          marginLeft: 80,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {t('confirm.carReception.inspection.empty')}
      </div>
    )

  if (!meta?.length) {
    return (
      <div
        style={{
          marginLeft: 80,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Empty />
      </div>
    )
  }
  return (
    <div style={{ marginLeft: 80, width: '100%' }}>
      <Typography.Title level={5}>
        {selectedItem?.carDetail?.name}
      </Typography.Title>
      {type === 'external' && (
        <Input disabled value={selectedItem?.lkp} className='defects__lkp' />
      )}
      <Checkbox.Group
        className='defects__checkbox-wrap'
        value={selectedItem?.carConfirmations?.map((i) => i.id)}
        disabled
      >
        <Row gutter={24}>
          {defects?.map((v) => (
            <Col key={v.id}>
              <Checkbox value={v.id}>{v.name}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Space
        direction='vertical'
        size={20}
        style={{
          width: '100%',
          marginTop: 20,
        }}
      >
        <Space size={5} style={{ alignItems: 'flex-start' }}>
          <Typography.Text style={{ whiteSpace: 'nowrap' }}>
            {t('task.actionHistory.modal.checkList.comment')}:
          </Typography.Text>
          <Typography.Text>{selectedItem?.comment}</Typography.Text>
        </Space>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Image.PreviewGroup>
            {selectedItem?.photos?.map((i) => {
              return (
                <div
                  style={{
                    width: 72,
                    height: 72,
                    marginRight: 8,
                    border: '1px solid #D9D9D9',
                    borderRadius: 2,
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: 8,
                  }}
                  key={i.photo_id}
                >
                  <Image src={i.photo} style={{ width: 60, height: 60 }} />
                </div>
              )
            })}
          </Image.PreviewGroup>
        </div>
      </Space>
    </div>
  )
}

export default Info
