import { Typography } from 'antd'

import './styles.less'

const ManagerHeaderOperation = (props) => {
  const { managerName, operation, position, title, item } = props
  return (
    <div className={'manager'} id={'ManagerHeaderOperation'}>
      <div className={'manager--title'}>
        <Typography.Text
          strong
          disabled={!managerName}
          style={{ cursor: 'default' }}
        >
          {`${managerName ? managerName : 'Без имени'} - ${
            position ? position : 'Нет данных'
          }`}
        </Typography.Text>
        <Typography.Text type={'secondary'} className={'manager--info'}>
          {operation}
        </Typography.Text>
      </div>
      <div className={'manager--price'}>
        <Typography.Text type={'secondary'} className={'manager--info'}>
          {title}
        </Typography.Text>
        <Typography.Text strong>{item ? item : 'Нет данных'}</Typography.Text>
      </div>
    </div>
  )
}

export default ManagerHeaderOperation
