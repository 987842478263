import { Popover, Typography, Row, Col, Space, Badge, Tooltip } from 'antd'
import moment from 'moment'
import { phoneFormat, openModals } from '@utils'
import { t } from 'i18next'

const { Text } = Typography

const getPopoverContent = (
  autoDataNew,
  autoDataUsed,
  autoDataTestDrive,
  partsData,
  servicesData
) => {
  return (
    <div
      style={{
        maxHeight: '100px',
        overflowY: 'auto',
      }}
      onClick={(e) => e.stopPropagation()}
      className='custom-popover-inner'
    >
      {autoDataNew?.length ? (
        <Text type='secondary'>{t('clientCard.history.newAuto')}</Text>
      ) : null}
      {autoDataNew?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataUsed?.length ? (
        <Text type='secondary'>{t('clientCard.history.usedAuto')}</Text>
      ) : null}
      {autoDataUsed?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataTestDrive?.length ? (
        <Text type='secondary'>{t('clientCard.history.testDrive')}</Text>
      ) : null}
      {autoDataTestDrive?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {partsData?.length ? (
        <Text type='secondary'>{t('clientCard.history.parts')}</Text>
      ) : null}
      {partsData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {servicesData?.length ? (
        <Text type='secondary'>{t('clientCard.history.service')}</Text>
      ) : null}
      {servicesData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

export const columns = (
  dispatch,
  user,
  navigate,
  setClientData,
  setVisibleDrawer,
  setClientInfo,
  setHistory,
  setVisibleHistory,
  getInfo,
  t
) => {
  let data = [
    {
      title: t('main.table.column.IdRequest'),
      dataIndex: '',
      width: 200,
      render: (text, record) => {
        return (
          <div>
            <Space direction='vertical' size={0}>
              <Text>{record.inner_id}</Text>
              <Text type='secondary' style={{ fontSize: 12 }}>
                {moment(record.created_at).format('DD MMMM YYYY, HH:mm')}
              </Text>
            </Space>
          </div>
        )
      },
    },
    {
      title: t('main.table.column.client'),
      dataIndex: 'client',
      width: 380,
      render: (text, record) => {
        return (
          <div>
            {record.client?.phone_number ? (
              <Typography.Text
              // className='link-decoration'
              // onClick={(e) => {
              //   e.stopPropagation()
              //   setClientInfo(record)
              //   setVisibleDrawer(true)
              // }}
              >
                {record.client?.full_name ||
                record.client?.name ||
                record.client.phone_number
                  ? record.client?.full_name ||
                    record.client?.name ||
                    `+${phoneFormat(record.client?.phone_number)}`
                  : t('main.table.column.client.unnamed')}
              </Typography.Text>
            ) : (
              <Typography.Text
              // style={{
              //   wordWrap: 'break-word',
              //   wordBreak: 'break-word',
              // }}
              // className='link-decoration'
              >
                {t('main.table.column.client.unnamed')}
              </Typography.Text>
            )}
          </div>
        )
      },
    },
    // Временно!...
    // {
    //   title: 'Потребность',
    //   dataIndex: 'clientData',
    //   width: 366,
    //   render: (needs, record) => {
    //     let autoDataNew = record.clientData ? record.clientData.cars.new : []
    //     let autoDataUsed = record.clientData ? record.clientData.cars.used : []
    //     let autoDataTestDrive = record.clientData
    //       ? record.clientData.cars.test_drive
    //       : []

    //     let partsData = record.clientData ? record.clientData?.parts : []
    //     let servicesData = record.clientData ? record.clientData?.services : []
    //     return (
    //       <span
    //         style={{
    //           wordWrap: 'break-word',
    //           wordBreak: 'break-word',
    //         }}
    //       >
    //         {!autoDataNew?.length &&
    //         !autoDataUsed?.length &&
    //         !autoDataTestDrive?.length &&
    //         !partsData?.length &&
    //         !servicesData?.length ? (
    //           <Text type='secondary'>{{t('main.tab.createdByMe.noData')}}</Text>
    //         ) : (
    //           <Popover
    //             overlayClassName='custom-popover'
    //             trigger='hover'
    //             placement='bottomLeft'
    //             title={
    //               <div onClick={(e) => e.stopPropagation()}>{`Выбранные ${
    //                 autoDataNew?.length +
    //                 partsData?.length +
    //                 servicesData?.length +
    //                 autoDataUsed?.length +
    //                 autoDataTestDrive?.length
    //               }`}</div>
    //             }
    //             content={getPopoverContent(
    //               autoDataNew,
    //               autoDataUsed,
    //               autoDataTestDrive,
    //               partsData,
    //               servicesData
    //             )}
    //           >
    //             <Text
    //               style={{
    //                 cursor: 'pointer',
    //               }}
    //               // type='secondary'
    //             >{`Выбранная потребность: ${
    //               autoDataNew?.length +
    //               partsData?.length +
    //               servicesData?.length +
    //               autoDataUsed?.length +
    //               autoDataTestDrive?.length
    //             }`}</Text>
    //           </Popover>
    //         )}
    //       </span>
    //     )
    //   },
    // },
    {
      title: t('main.table.column.author'),
      dataIndex: 'userCreated',
      render: (user) => {
        return (
          <span
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {user ? user.full_name : t('main.table.column.author.noAuthor')}
          </span>
        )
      },
      width: 360,
    },
    {
      title: t('main.table.column.stage'),
      dataIndex: 'currentStage',
      render: (stage, record) => (
        <span
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}
        >
          {record.status?.code === 'COMPLETED' ? (
            <div style={{ paddingLeft: 8, display: 'inline' }}>
              {t('main.table.column.stageType.completed')}
            </div>
          ) : (record.status?.code === 'ACTIVE' ||
              record.status?.code === 'NEW') &&
            record.coordinationUsers?.length > 0 ? (
            <>
              <div style={{ paddingLeft: 8, display: 'inline' }}>
                {t('main.table.column.stageType.forApproval')}
              </div>
            </>
          ) : (
            <>
              {record.status?.code === 'CANCELLED' && (
                <Tooltip
                  title={record?.cancelComment || record?.cancelReason?.name}
                  placement='top'
                >
                  <Badge
                    dot
                    style={{
                      width: 10,
                      height: 10,
                      position: 'absolute',
                      left: -13,
                      top: -1,
                    }}
                  />
                </Tooltip>
              )}
              <div style={{ paddingLeft: 8, display: 'inline' }}>
                {stage?.name}
              </div>
            </>
          )}
        </span>
      ),
      width: 200,
    },
    {
      title: t('main.table.column.caseType'),
      dataIndex: 'typeTreatment',
      render: (text) => {
        let type = ''

        if (text === 'crm') type = t('main.table.column.caseType.visit')
        else if (text === 'call') type = t('main.table.column.caseType.call')
        else if (text === 'web') type = t('main.table.column.caseType.internet')
        else if (text === 'callout')
          type = t('main.table.column.caseType.callOut')
        return (
          <span
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {type}
          </span>
        )
      },
      width: 161,
    },
  ]

  data.forEach((i) => {
    i.onCell = (record) => {
      return {
        style: { cursor: 'pointer' },
        onClick: () => {
          if (record.process !== null) {
            navigate(`task/${record.id}`)
          } else {
            let data = record.client
            data.typeTreatment = record.typeTreatment
            data.taskId = record.id
            setClientData(data)
          }
          // if (
          //   record.status.code !== 'CANCELLED' &&
          //   record.status.code !== 'COMPLETED' &&
          //   record.userId === user?.id &&
          //   record.process !== null &&
          //   !record.coordinationUsers?.length
          // ) {
          //   navigate(`task/${record.id}`)
          //   // openModals(dispatch, { type: 'task', id: record.id })
          // } else if (
          //   record.status.code !== 'CANCELLED' &&
          //   record.status.code !== 'COMPLETED' &&
          //   record.userId === user?.id &&
          //   record.process === null &&
          //   !record.coordinationUsers?.length
          // ) {
          //   let data = record.client
          //   data.typeTreatment = record.typeTreatment
          //   data.taskId = record.id
          //   setClientData(data)
          //   // } else openModals(dispatch, { type: 'activity', id: record.id })
          // } else if (
          //   (record.status.code === 'CANCELLED' &&
          //     !record.coordinationUsers?.length) ||
          //   (record.status.code === 'COMPLETED' &&
          //     !record.coordinationUsers?.length)
          // ) {
          //   setHistory(record)
          //   setVisibleHistory(true)
          // } else if (record.coordinationUsers?.length) {
          //   // getInfo(record)
          //   // setHistory(record)
          //   // setVisibleHistory(true)
          //   navigate(`task/${record.id}`)
          // }
        },
      }
    }
  })

  return data
}
