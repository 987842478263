import { Tooltip } from 'antd'

export const columnsMethods = {
  drawTooltip: (title = '', placement = 'topLeft') => {
    return (
      <Tooltip placement={placement} title={title}>
        {title}
      </Tooltip>
    )
  },
  findFilterData: (dataList, child = '') => {
    return Array.isArray(dataList) && dataList.length
      ? dataList.map((item) => {
          const text = item.name
          const value = item.id

          let children = null
          if (child) {
            children =
              Array.isArray(item[child]) &&
              child.map((el) => {
                return { text: el.name, value: el.id }
              })
          }
          return { text, value, children }
        })
      : []
  },

  showTitle: (title) => {
    return title ? title : ''
  },
  issueObject: {
    sorter: true,
    ellipsis: {
      showTitle: false,
    },
  },
}
