import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import { Button, Space, Alert, Slider, Menu } from 'antd'
import { Form, Input } from 'antd'
import InputMask from 'react-input-mask'
import { parsePhoneNumber } from 'awesome-phonenumber'
import Loading from '@ui/Loading'
import { useTranslation } from 'react-i18next'
import CardInClient from '../CardInClient'

const AddContacts = ({ id, setOpen, getData }) => {
  const { t } = useTranslation()
  const [client, setClient] = useState()
  const [form] = Form.useForm()
  const [error, setError] = useState(null)
  const [currentStep, setCurrentStep] = useState(1)
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const [openClient, setClientOpen] = useState(false)
  const [clientId, setClientId] = useState()

  const handleSearchModal = async (phoneNumber) => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `clients/phone/${phoneNumber}`,
        method: 'get',
      })
    )
      .then((res) => {
        if (res.data) setClient(res.data)
        else {
          setCurrentStep(2)
        }
      })
      .finally(() => setLoading(false))
  }

  const handleCreate = async (phoneNumber) => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `clients`,
        method: 'post',
        data: {
          phone_number: phoneNumber,
          name: form.getFieldValue('name') || '',
        },
      })
    )
      .then(async (res) => {
        setClient(res.data)

        await dispatch(
          authorizationLayer({
            url: `clients/${id}/assign`,
            method: 'POST',
            data: {
              id: res.data.id,
            },
          })
        )
        setLoading(true)
        setOpen(false)
        getData()
      })
      .finally(() => setOpen(false))
  }

  const onFinish = (values) => {
    const phone = values.tel.replace(/\s/g, '')
    const phoneNumber = values.tel.replace(/[^0-9]/g, '')
    const pn = parsePhoneNumber(phone)

    setIsValid(pn.isValid())

    if (!pn.isValid() && phoneNumber.length === 11) {
      setError({
        validateStatus: 'error',
        help: t('clientCard.phoneValidate'),
      })
    } else {
      setError(null)
    }

    if (pn.isValid() && currentStep === 1) handleSearchModal(phoneNumber)
    else if (currentStep === 2) handleCreate(phoneNumber)
  }

  const getNumber = (item) => {
    return item?.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      '$1 $2 $3 $4 $5'
    )
  }

  const submitContact = async () => {
    await dispatch(
      authorizationLayer({
        url: `clients/${id}/assign`,
        method: 'POST',
        data: {
          id: client.id,
        },
      })
    ).then(() => {
      setOpen(false)
      getData()
    })
  }

  return (
    <div>
      {loading && <Loading spinner={true} />}
      {!client ? (
        <Form
          form={form}
          layout='vertical'
          autoComplete='off'
          onFinish={onFinish}
          style={{ width: '100%' }}
          className='task__form'
        >
          <div className='task__client-input-wrap'>
            <Form.Item name='tel' {...error}>
              <InputMask mask='+7 999 999 99 99' maskChar={null} autoFocus>
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    placeholder={t(
                      'clientCard.addContacts.form.phonePlaceholder'
                    )}
                    className='task__client-input'
                    size='large'
                  />
                )}
              </InputMask>
            </Form.Item>
            {currentStep === 2 && (
              <Space size={13} direction='vertical' style={{ width: '100%' }}>
                <Alert
                  className='task__alert'
                  message={t('clientCard.addContacts.form.alert')}
                  type='warning'
                  showIcon
                />
                <Form.Item
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: t('clientCard.addContacts.form.nameValidate'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'clientCard.addContacts.form.namePlaceholder'
                    )}
                    className='task__client-input'
                  />
                </Form.Item>
              </Space>
            )}
            <Button
              type='primary'
              className='task__client-btn task__client-btn--find'
              htmlType='submit'
            >
              {currentStep === 1
                ? t('clientCard.addContacts.form.searchBtn')
                : t('clientCard.addContacts.form.saveBtn')}
            </Button>
          </div>
        </Form>
      ) : (
        <div className='task__client-input-wrap'>
          <div className='task__found-name-box'>
            <div className='task__full-name'>
              {client?.full_name || client?.name}
            </div>
          </div>
          <div className='task__num-box'>
            <p className='task__num'>+{getNumber(client?.phone_number)}</p>
            {/*<a
              target='_blank'
              rel='noreferrer'
              className='task__wpp-btn'
              href={`https://api.whatsapp.com/send?phone=${client?.phone_number}`}
            >
              <img
                className='task__img'
                src='https://minio.jcloud.kz/bertha-development/813c19fa-f5a2-46ef-b3bd-7023802aa489-whatsapp.svg'
                alt='whatsapp'
              />
            </a>*/}
          </div>

          <Button
            className='task__client-card'
            onClick={() => {
              setClientOpen(true)
              setClientId(client?.id)
            }}
          >
            {t('clientCard.addContacts.btnToClientCard')}
          </Button>
          {client?.data_fullness !== 100 && (
            <div className='task__completed'>
              <p className='task__completed-text'>
                {t('request.clientInfo.cardDataFuiel')} {client?.data_fullness}%
              </p>

              <div className='task__progress'>
                <Slider
                  className='task__slider'
                  style={{ pointerEvents: 'none' }}
                  handleStyle={{ display: 'none' }}
                  value={client?.data_fullness}
                  trackStyle={
                    client?.data_fullness <= 50
                      ? { backgroundColor: '#ff9900' }
                      : { backgroundColor: '#1890FF' }
                  }
                />
              </div>
            </div>
          )}

          <Button
            type='primary'
            className='task__client-btn '
            htmlType='submit'
            style={{ marginTop: '50px' }}
            onClick={submitContact}
          >
            {t('clientCard.addContacts.saveBtn')}
          </Button>
        </div>
      )}

      <CardInClient
        openClient={openClient}
        setClientOpen={setClientOpen}
        clientId={clientId}
        setClientId={setClientId}
      />
    </div>
  )
}

export default AddContacts
