import React, { useEffect, useState } from 'react'
import { Radio, Typography, Form, Input, Select, Switch, Button, DatePicker } from 'antd'
import { Divider } from 'antd'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import Loading from '@ui/Loading'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { DynamicField } from '../index'
import moment from 'moment'
import axios from 'axios'

import { parsePhoneNumber } from 'awesome-phonenumber'

import './index.less'

const { Title, Text } = Typography

const FormClient = ({ id, canEditClientCard, setClientData }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [client, setClient] = useState(null)
  const { tasks } = useSelector((state) => state.app)
  const { t } = useTranslation()

  const client_type = Form.useWatch('client_type', form)

  const checkValidateNumber = (e) => {
    const phone = e.replace(/\s/g, '')

    const pn = parsePhoneNumber(phone)

    setIsValid(pn.isValid())

    if (!pn.isValid() && phone) {
      setError({
        validateStatus: 'error',
        help: t('clientCard.phoneValidate'),
      })
    } else {
      setError(null)
    }

    if (pn.isValid()) {
      handleBlur('phone_number')
    }
  }

  const getData = async () => {
    try {
      setLoading(true)
      const { data } = await dispatch(
        authorizationLayer({
          url: `clients/${id}`,
          method: 'get',
        })
      )

      setClient(data)
      data.birth_date = data.birth_date ? moment(data.birth_date) : null
      data.document_date_from = data.document_date_from ? moment(data.document_date_from) : null
      data.document_date_to = data.document_date_to ? moment(data.document_date_to) : null
      form.setFieldsValue(data)

      setLoading(false)
    } catch (e) {
      console.log(e.message)
    }
  }

  useEffect(() => {
    getData()
  }, [dispatch, id, form])

  const handleBlur = async (name) => {
    setLoading(true)

    const submitData = {
      [name]: form.getFieldValue(name),
    }

    if (name === 'phone_number') {
      submitData[name] = submitData[name].replace(/\s+/g, '').replace(/\+/g, '')
    }

    await dispatch(
      authorizationLayer({
        url: `clients/${id}`,
        method: 'patch',
        data: submitData,
      })
    ).then(({ data }) => {
      let newTasks = tasks?.map((i) => {
        if (i.client?.id === id) {
          i.client = data
        }

        return { ...i }
      })

      dispatch(SET_APP(['tasks'], newTasks))
      setClientData(data)
      if (name === 'client_type') {
        getData()
      }
    })
    setLoading(false)
  }

  const getInfoByClient = async () => {
    try {
      setLoading(true)
      axios.get(`http://localhost:9010/getState`)
        .then(res => {
          const data = res.data;
          if (data.state === 'card_present') {
            axios.get(`http://localhost:9010/getInfo`)
              .then(async res => {
                const data = res.data;
                let bdate = data.bdate.split('.')
                      let valid_from = data.valid_from.split('.')
                      let valid_to = data.valid_to.split('.')
                      let birth_date = bdate[2] + '-' + bdate[1] + '-' + bdate[0];
                      let document_date_from = valid_from[2] + '-' + valid_from[1] + '-' + valid_from[0];
                      let document_date_to = valid_to[2] + '-' + valid_to[1] + '-' + valid_to[0];

                      form.setFieldValue('name', data.fname)
                      form.setFieldValue('middle_name', data.sname)
                      form.setFieldValue('last_name', data.lname)
                      form.setFieldValue('gender', data.sex === 'M' ? 'male' : 'female')
                      form.setFieldValue('iin', data.iin)
                      form.setFieldValue('birth_date', moment(birth_date))
                      form.setFieldValue('document_number', data.docnum)
                      form.setFieldValue('nation', data.nation)
                      form.setFieldValue('valid_from', moment(document_date_to))
                      form.setFieldValue('valid_to', moment(document_date_from))

                      await dispatch(
                        authorizationLayer({
                          url: `clients/${id}`,
                          method: 'patch',
                          data: {
                            'name': data.fname,
                            'middle_name': data.sname,
                            'last_name': data.lname,
                            'gender': data.sex === 'M' ? 'male' : 'female',
                            'iin': data.iin,
                            'birth_date': moment(birth_date).format(),
                            'document_number': data.docnum,
                            'nationality': data.nation,
                            'document_date_from': moment(document_date_from).format(),
                            'document_date_to': moment(document_date_to).format(),
                          },
                        })
                      ).then(({ data }) => {
                        let newTasks = tasks?.map((i) => {
                          if (i.client?.id === id) {
                            i.client = data
                          }

                          return { ...i }
                        })

                        dispatch(SET_APP(['tasks'], newTasks))
                        setClientData(data)
                        getData()
                      })
                    })
          }
        })

      setLoading(false)
      // dispatch(
      //   authorizationLayer({
      //     url: `http://localhost:9010/getState`,
      //     method: 'get',
      //   })
      // ).then(async (state) => {
      //   if (state.data.state === 'card_present') {
      //     dispatch(
      //       authorizationLayer({
      //         url: `http://localhost:9010/getInfo`,
      //         method: 'get',
      //       })
      //     ).then(async (data) => {
      //       console.log(data)
      //
      //       let bdate = data.bdate.split('.')
      //       let valid_from = data.valid_from.split('.')
      //       let valid_to = data.valid_to.split('.')
      //       let birth_date = bdate[2] + '-' + bdate[1] + '-' + bdate[0];
      //       let document_date_from = valid_from[2] + '-' + valid_from[1] + '-' + valid_from[0];
      //       let document_date_to = valid_to[2] + '-' + valid_to[1] + '-' + valid_to[0];
      //
      //       form.setFieldValue('name', data.fname)
      //       form.setFieldValue('middle_name', data.sname)
      //       form.setFieldValue('last_name', data.lname)
      //       form.setFieldValue('gender', data.sex === 'M' ? 'male' : 'female')
      //       form.setFieldValue('iin', data.iin)
      //       form.setFieldValue('birth_date', moment(birth_date))
      //       form.setFieldValue('document_number', data.docnum)
      //       form.setFieldValue('nation', data.nation)
      //       form.setFieldValue('valid_from', moment(document_date_to))
      //       form.setFieldValue('valid_to', moment(document_date_from))
      //
      //       await dispatch(
      //         authorizationLayer({
      //           url: `clients/${id}`,
      //           method: 'patch',
      //           data: {
      //             'name': data.fname,
      //             'middle_name': data.sname,
      //             'last_name': data.lname,
      //             'gender': data.sex === 'M' ? 'male' : 'female',
      //             'iin': data.iin,
      //             'birth_date': moment(birth_date).format(),
      //             'document_number': data.docnum,
      //             'nationality': data.nation,
      //             'document_date_from': moment(document_date_from).format(),
      //             'document_date_to': moment(document_date_to).format(),
      //           },
      //         })
      //       ).then(({ data }) => {
      //         let newTasks = tasks?.map((i) => {
      //           if (i.client?.id === id) {
      //             i.client = data
      //           }
      //
      //           return { ...i }
      //         })
      //
      //         dispatch(SET_APP(['tasks'], newTasks))
      //         setClientData(data)
      //         getData()
      //       })
      //     })
      //     setLoading(false)
      //   }
      // })
    } catch (e) {
      console.log(e.message)
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        marginTop: '12px',
        padding: '0px 20px 20px 20px',
        height: '85vh',
        overflowY: 'auto',
      }}
      className='form__scroll'
    >
      {loading && <Loading spinner={true} />}

      <Title style={{ fontSize: '15px', fontWeight: '500' }} level={5}>
        {t('clientCard.formClient.title')}
      </Title>

      <Form
        form={form}
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '10px',
        }}
        layout='vertical'
        autoComplete='off'
        initialValues={{
          client_type: 'individual',
        }}
      >
        <div className='form__type'>
          <Text>{t('clientCard.formClient.clientType')}</Text>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='client_type'
            onChange={() => handleBlur('client_type')}
          >
            <Radio.Group className='form__radio-list'>
              <Radio.Button className='form__radio' value='individual'>
                {t('clientCard.formClient.individual')}
              </Radio.Button>
              <Radio.Button className='form__radio' value='legal'>
                {t('clientCard.formClient.legal')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            {...error}
            name='phone_number'
            label={t('clientCard.formClient.phoneLabel')}
            rules={[
              { required: true, message: t('clientCard.formClient.required') },
            ]}
          >
            <InputMask
              mask='+7 999 999 99 99'
              maskChar={null}
              style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
              placeholder='+7(___) ___-__-__'
              onChange={_.debounce(
                (e) => checkValidateNumber(e.target.value),
                1000
              )}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>

          {client_type === 'individual' && (
            <Form.Item name='name' label={t('clientCard.formClient.nameLabel')}>
              <Input
                placeholder={t('clientCard.formClient.namePlaceholder')}
                disabled={loading || !canEditClientCard}
                style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
                onChange={_.debounce(() => handleBlur('name'), 1000)}
              />
            </Form.Item>
          )}

          {client_type === 'individual' && (
            <Form.Item
              name='last_name'
              label={t('clientCard.formClient.surnameLabel')}
            >
              <Input
                placeholder={t('clientCard.formClient.surnamePlaceholder')}
                disabled={loading || !canEditClientCard}
                style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
                onChange={_.debounce(() => handleBlur('last_name'), 1000)}
              />
            </Form.Item>
          )}

          {client_type === 'individual' && (
            <Form.Item
              name='middle_name'
              label={t('clientCard.formClient.middleNameLabel')}
            >
              <Input
                placeholder={t('clientCard.formClient.middleNamePlaceholder')}
                disabled={loading || !canEditClientCard}
                style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
                onChange={_.debounce(() => handleBlur('middle_name'), 1000)}
              />
            </Form.Item>
          )}

          {client_type === 'legal' && (
            <Form.Item
              name='business_type'
              label={t('clientCard.formClient.businessTypeLabel')}
            >
              <Select
                placeholder={t('clientCard.formClient.businessTypePlaceholder')}
                size='large'
                className='task-create__select'
                disabled={loading || !canEditClientCard}
                onBlur={() => handleBlur('business_type')}
              >
                <Select.Option value='ip'>
                  {t('clientCard.formClient.IP')}
                </Select.Option>
                <Select.Option value='too'>
                  {t('clientCard.formClient.LLP')}
                </Select.Option>
                <Select.Option value='ao'>
                  {t('clientCard.formClient.JSC')}
                </Select.Option>
              </Select>
            </Form.Item>
          )}

          {client_type === 'legal' && (
            <Form.Item
              name='name'
              label={t('clientCard.formClient.companyNameLabel')}
            >
              <Input
                disabled={loading || !canEditClientCard}
                style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
                placeholder={t('clientCard.formClient.companyNamePlaceholder')}
                onChange={_.debounce(() => handleBlur('name'), 1000)}
              />
            </Form.Item>
          )}

          {client_type === 'individual' && (
            <Form.Item
              name='gender'
              label={t('clientCard.formClient.genderLabel')}
            >
              <Select
                disabled={loading || !canEditClientCard}
                placeholder={t('clientCard.formClient.genderPlaceholder')}
                size='large'
                className='task-create__select'
                onBlur={() => handleBlur('gender')}
              >
                <Select.Option value='male'>
                  {t('clientCard.formClient.male')}
                </Select.Option>
                <Select.Option value='female'>
                  {t('clientCard.formClient.female')}
                </Select.Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name='address'
            label={t('clientCard.formClient.adressLabel')}
          >
            <Input
              disabled={loading || !canEditClientCard}
              placeholder={t('clientCard.formClient.adressPlaceholder')}
              style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
              onChange={_.debounce(() => handleBlur('address'), 1000)}
            />
          </Form.Item>

          {client_type === 'individual' && client?.dealer_meta?.can_scan && (
            <>
              <Form.Item
                name='iin'
                label={'ИИН'}
              >
                <Input
                  disabled={loading || !canEditClientCard}
                  placeholder={'ИИН'}
                  style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
                  onChange={_.debounce(() => handleBlur('iin'), 1000)}
                />
              </Form.Item>
              <Form.Item
                name='birth_date'
                label={'Дата рождения'}
              >
                <DatePicker
                  disabled={loading || !canEditClientCard}
                  className='dynamic-field'
                  placeholder='ДД.ММ.ГГГГ'
                  format='DD.MM.YYYY'
                  onChange={_.debounce(() => handleBlur('birth_date'), 1000)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name='document_number'
                label={'Номер документа'}
              >
                <Input
                  disabled={loading || !canEditClientCard}
                  placeholder={'Номер документа'}
                  style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
                  onChange={_.debounce(() => handleBlur('document_number'), 1000)}
                />
              </Form.Item>
              <Form.Item
                name='nationality'
                label={'Национальность'}
              >
                <Input
                  disabled={loading || !canEditClientCard}
                  placeholder={'Национальность'}
                  style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
                  onChange={_.debounce(() => handleBlur('nationality'), 1000)}
                />
              </Form.Item>
              <Form.Item
                name='document_date_from'
                label={'Дата выдачи'}
              >
                <DatePicker
                  disabled={loading || !canEditClientCard}
                  className='dynamic-field'
                  placeholder='ДД.ММ.ГГГГ'
                  format='DD.MM.YYYY'
                  onChange={_.debounce(() => handleBlur('document_date_from'), 1000)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name='document_date_to'
                label={'Срок действия'}
              >
                <DatePicker
                  disabled={loading || !canEditClientCard}
                  className='dynamic-field'
                  placeholder='ДД.ММ.ГГГГ'
                  format='DD.MM.YYYY'
                  onChange={_.debounce(() => handleBlur('document_date_to'), 1000)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
          </>
          )}

          <Form.Item
            label={t('clientCard.formClient.consentLabel')}
            name='data_collection_consent'
            valuePropName='checked'
          >
            <Switch
              disabled={loading || !canEditClientCard}
              onBlur={() => handleBlur('data_collection_consent')}
            />
          </Form.Item>

          {client_type === 'individual' && client?.dealer_meta?.can_scan && (
            <Button
              type='primary'
              onClick={() => getInfoByClient()}
              key='getInfoByClientBtn'
            >
              Отсканировать удостоверение
            </Button>
          )}

          {client?.fields_group.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <Divider />
                <Text style={{ marginBottom: 24, display: 'block' }} strong>
                  {item.name}
                </Text>
                {item.fields?.map((i) => {
                  return (
                    <DynamicField
                      key={i.id}
                      data={{
                        loading,
                        canEditClientCard,
                        fieldData: i,
                        id,
                        setClientData,
                      }}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        </div>
      </Form>
    </div>
  )
}

export default FormClient
