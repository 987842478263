import React from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Layout, Typography, Button } from 'antd'
import { useTranslation } from 'react-i18next'
const { Content } = Layout
const { Title } = Typography

const Error = () => {
  const navigate = useNavigate()
  const { status } = useParams()
  const { t } = useTranslation()

  return (
    <Layout
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '95vh',
      }}
    >
      <Content
        style={{
          maxHeight: 600,
          width: 600,
          background: '#FFFFFF',
          borderRadius: '6px',
          padding: '70px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            fontSize: 104,
            fontWeight: 700,
            marginBottom: 34,
            color: '#0989FF',
          }}
        >
          {status == 500 ? 500 : 404}
        </div>
        <img
          src='https://minio.jcloud.kz/bertha-development/8ee0d46c-2778-4d85-a002-23e1f28aee51-error-page.svg'
          alt=''
          style={{ width: '188px', height: '188px', marginTop: '35px' }}
        />
        <Title
          style={{
            color: 'rgba(41, 52, 82, 0.4)',
            fontWeight: '400',
            marginTop: '35px',
          }}
          level={5}
        >
          {status == 500 ? t('error.serverError') : t('error.notFound')}
        </Title>
        <Button
          onClick={() => {
            navigate(status == 500 ? -1 : '/')
          }}
          style={{ marginTop: '35px' }}
          type='primary'
        >
          {status == 500 ? t('error.reload') : t('error.toMain')}
        </Button>
      </Content>
    </Layout>
  )
}

export default Error
