import { Button, Space, Typography } from 'antd'
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons'

import moment from 'moment'
import { t } from 'i18next'

import { numberWithSpaces, openModals } from '@utils'
import { stockTypes } from '@/constants'
import { columnsMethods } from '../../../../../../ChoiceProduct/columnsMethods'

const { Text } = Typography

export const autoColumns = (dispatch, stockType, tableSettings) => {
  const { drawTooltip, showTitle, issueObject } = columnsMethods

  const findTitle = (text) => {
    if (text === 'in_stock') {
      return t('warehouse.tab.cars.column.inStockStatus')
    } else if (text === 'in_transit') {
      return t('warehouse.tab.cars.column.inTransitStatus')
    } else if (text === 'in_production') {
      return t('warehouse.tab.cars.column.inProductionStatus')
    }
  }
  let data = [
    {
      title: t('warehouse.tab.cars.column.brand'),
      dataIndex: 'carMark',
      width: 140,
      fixed: 'left',
      ...issueObject,
      render: (text, record) => {
        const title = showTitle(record?.carMark?.name)
        return drawTooltip(title)
      },
    },
    {
      title: t('warehouse.tab.cars.column.model'),
      dataIndex: 'carModel',
      width: 140,
      ...issueObject,
      render: (text, record) => {
        const title = showTitle(record?.carModel?.name)
        return drawTooltip(title)
      },
    },
    stockType === stockTypes.NEW_AUTO
      ? {
          title: t('warehouse.tab.cars.column.equipment'),
          dataIndex: 'carTemplate',
          width: 200,
          ...issueObject,
          render: (text, record) => {
            const title = showTitle(record?.carTemplate?.name)
            return drawTooltip(title)
          },
        }
      : {},
    {
      title: t('warehouse.tab.cars.column.vin'),
      dataIndex: 'vin_code',
      width: 180,
      ...issueObject,
      render: (text) => {
        return drawTooltip(text)
      },
    },
    {
      title: t('warehouse.tab.cars.column.daysInStoke'),
      dataIndex: 'created_at',
      width: 180,
      ...issueObject,
      render: (text, record) => {
        const title = record?.stock_date
          ? moment(new Date()).diff(moment(record?.stock_date), 'days') - 1
          : ''
        return drawTooltip(`${title}`)
      },
    },
    {
      title: t('warehouse.tab.cars.column.bodyColor'),
      dataIndex: 'carExteriorColor',

      width: 160,
      ...issueObject,
      render: (text, record) => {
        const title = showTitle(record?.carExteriorColor?.name)
        return drawTooltip(title)
      },
    },
    {
      title: t('warehouse.tab.cars.column.interiorColor'),
      dataIndex: 'carInteriorColor',
      width: 160,
      ...issueObject,
      render: (text, record) => {
        const title = showTitle(record?.carInteriorColor?.name)
        return drawTooltip(title)
      },
    },
    {
      title: t('warehouse.tab.cars.column.year'),
      dataIndex: 'year',
      width: 160,
      ...issueObject,
      render: (text) => {
        return drawTooltip(text)
      },
    },
    stockType === stockTypes.NEW_AUTO
      ? {
          title: t('warehouse.tab.cars.column.status'),
          dataIndex: 'status',
          width: 140,
          ...issueObject,
          render: (text) => {
            const title = findTitle(text)
            return drawTooltip(title)
          },
        }
      : {},
    stockType === stockTypes.NEW_AUTO
      ? {
          title: t('warehouse.tab.cars.column.arrivalDate'),
          dataIndex: 'stock_date',
          width: 165,
          ...issueObject,
          render: (text) => {
            return drawTooltip(text ? moment(text).format('DD MMMM YYYY') : '')
          },
        }
      : {},

    {
      title: t('warehouse.tab.cars.column.responsiblePerson'),
      dataIndex: 'user',
      width: 200,
      ...issueObject,
      render: (text, record) => {
        const title = showTitle(record?.userName)
        return drawTooltip(title)
      },
    },

    {
      title: t('warehouse.tab.cars.column.client'),
      dataIndex: 'client',
      width: 150,
      ...issueObject,
      render: (text, record) => {
        const title = showTitle(record?.clientName)
        return drawTooltip(title)
      },
    },

    stockType !== stockTypes.TEST_DRIVE
      ? {
          title: t('warehouse.tab.cars.column.terms'),
          dataIndex: 'conditions',
          width: 140,
          ...issueObject,
          render: (text, record) => {
            const title =
              record?.conditions !== null
                ? record?.conditions?.map((item, index) => {
                    return <Text key={index}>{item}</Text>
                  })
                : ''
            return drawTooltip(title)
          },
        }
      : {},

    stockType !== stockTypes.TEST_DRIVE
      ? {
          title: t('warehouse.tab.cars.column.mileage'),
          dataIndex: 'mileage',
          width: 140,
          ...issueObject,
          render: (text) => {
            const title = showTitle(`${numberWithSpaces(text)}`)
            return drawTooltip(title)
          },
        }
      : {},

    stockType !== stockTypes.TEST_DRIVE
      ? {
          title: t('warehouse.tab.cars.column.price'),
          dataIndex: 'price',
          width: 160,
          ...issueObject,
          render: (text) => {
            const title = showTitle(`${numberWithSpaces(text)}`)
            return drawTooltip(title)
          },
        }
      : {},

    {
      title: t('warehouse.tab.cars.column.engine'),
      dataIndex: 'engine_capacity',
      width: 160,
      ...issueObject,
      render: (text) => {
        return drawTooltip(text)
      },
    },
    {
      title: t('warehouse.tab.cars.column.gearboxType'),
      dataIndex: 'carGearboxType',
      width: 120,
      ...issueObject,
      render: (text, record) => {
        const title = showTitle(record?.carGearboxType?.short_value)
        return drawTooltip(title)
      },
    },
    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      width: 140,
      fixed: 'right',
      render: (text, record) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          onClick={() =>
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: stockType === stockTypes.SPARE_PARTS,
            })
          }
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
    },
  ]

  tableSettings &&
    stockType !== stockTypes.TEST_DRIVE &&
    tableSettings.push('created_at')

  data = data.filter(
    (item) =>
      tableSettings?.includes(item.dataIndex) || item.dataIndex === 'action'
  )

  return data
}

export const columnsForSpareParts = (dispatch, stockType, tableSettings) => {
  const { drawTooltip, showTitle, issueObject } = columnsMethods

  let data = [
    {
      title: '',
      dataIndex: 'count',
      width: 120,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button type='link' disabled={true}>
              <MinusSquareOutlined style={{ fontSize: 20 }} />
            </Button>
            <Text type='secondary' style={{ whiteSpace: 'nowrap' }}>
              {record.orderAmount}
            </Text>
            <Button type='link' disabled={true}>
              <PlusSquareOutlined style={{ fontSize: 20 }} />
            </Button>
          </Space>
        )
      },
    },
    {
      title: t('warehouse.tab.parts.column.category'),
      dataIndex: 'sparePartCategory',
      width: 220,
      ...issueObject,
      render: (text, record) => {
        const title = showTitle(record?.sparePartCategory?.name)
        return drawTooltip(title)
      },
    },
    {
      title: t('warehouse.tab.parts.column.name'),
      dataIndex: 'name',
      width: 280,
      ...issueObject,
      render: (text) => {
        return drawTooltip(text)
      },
    },
    {
      title: t('warehouse.tab.parts.column.article'),
      dataIndex: 'article_number',
      width: 160,
      ...issueObject,
      render: (text) => {
        return drawTooltip(text)
      },
    },
    {
      title: t('warehouse.tab.parts.column.inStock'),
      dataIndex: 'amount',
      width: 160,
      ...issueObject,
      render: (text) => {
        const title = `${numberWithSpaces(text)}`
        return drawTooltip(title)
      },
    },
    {
      title: t('warehouse.tab.parts.column.reserve'),
      dataIndex: 'reserve',
      width: 160,
      ...issueObject,
      render: (text) => {
        const title = `${numberWithSpaces(text)}`
        return drawTooltip(title)
      },
    },
    {
      title: t('warehouse.tab.parts.column.price'),
      dataIndex: 'price',
      width: 160,
      ...issueObject,
      render: (text) => {
        const title = `${numberWithSpaces(text)}`
        return drawTooltip(title)
      },
    },

    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          onClick={() =>
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: stockType === stockTypes.SPARE_PARTS,
            })
          }
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
    },
  ]

  data = data.filter(
    (item) =>
      tableSettings?.includes(item.dataIndex) ||
      item.dataIndex === 'action' ||
      item.dataIndex === 'count'
  )

  return data
}
