import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'

import { modules, stockTypes } from '@/constants'
import { TaskContext } from '../..'

import {
  Button,
  Col,
  Input,
  message,
  Modal,
  notification,
  Row,
  Typography,
} from 'antd'

import TaskHistoryModal from '../TaskHistory/TaskHistoryModal'

import './index.less'
import TaskHistoryItems from '../TaskHistory'

const Confirm = (props) => {
  const { showTaskHistoryModal, closeTaskHistory } = props
  const { allModules, taskData, needsData, id, mainTaskId } =
    useContext(TaskContext)
  const [currenStepStage, setCurrentStepStage] = useState(0)
  const [filteredModules, setFilteredModules] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loadingCP, setLoadingCP] = useState(false)

  const sequenceData = [
    { key: 'SUM', appear: true },
    { key: modules.CHOICE_PRODUCT, type: stockTypes.NEW_AUTO, appear: false },
    { key: modules.CHOICE_PRODUCT, type: stockTypes.USED_AUTO, appear: false },
    {
      key: modules.CHOICE_PRODUCT,
      type: stockTypes.SPARE_PARTS,
      appear: false,
    },
    { key: modules.CHOICE_PRODUCT, type: stockTypes.TEST_DRIVE, appear: false },
    { key: modules.CAR_RECEPTION, appear: false },
    { key: modules.IWD, appear: false },
    { key: modules.COORD, appear: false },
    { key: modules.FORM, appear: false },
    { key: modules.UPLOAD_FILE, appear: false },
  ]

  useEffect(() => {
    taskData?.process?.stages?.find((item, index) => {
      if (item.id === taskData?.currentStage?.id) {
        setCurrentStepStage(index)
      }
    })

    const getData = async () => {
      if (!taskData?.process?.id) return
      setLoading(true)
      await dispatch(
        authorizationLayer({
          url: `processes/show/${taskData?.process?.id}`,
        })
      )
        .then(({ data }) => {
          const filteredModules = [...sequenceData]
          if (data.data && Array.isArray(data.data.stages)) {
            filteredModules.forEach((m, i) => {
              for (let j = 0; j < taskData?.currentStage.order; j++) {
                const stage = data.data.stages[j]
                stage.modules.forEach((module) => {
                  const name = module?.component?.componentType?.name
                  const stockType = module?.settings?.stockType

                  if (
                    name === modules.CHOICE_PRODUCT &&
                    stockType &&
                    stockType === m.type
                  ) {
                    filteredModules[i].appear = true
                    filteredModules[i].data = {
                      moduleItem: module,
                      needs: needsData.find(
                        (i) =>
                          i.type === modules.CHOICE_PRODUCT &&
                          i.module.settings.stockType === m.type
                      ),
                      stockType,
                    }
                  } else if (
                    name === m.key &&
                    name !== modules.CHOICE_PRODUCT &&
                    name !== modules.CAR_RECEPTION
                  ) {
                    filteredModules[i].appear = true
                    filteredModules[i].data = {
                      moduleItem: module,
                      needs: needsData,
                    }
                    filteredModules[i].process = data.data
                  }

                  if (m.key === 'SUM')
                    filteredModules[i].data = {
                      taskData,
                      needs: needsData?.filter(
                        (i) => i.type === modules.CHOICE_PRODUCT
                      ),
                    }

                  if (
                    m.key === modules.CAR_RECEPTION &&
                    name === modules.CAR_RECEPTION
                  ) {
                    filteredModules[i].appear = true
                    filteredModules[i].data = {
                      moduleItem: module,
                      needs: needsData.filter(
                        (i) => i.type === modules.CAR_RECEPTION
                      ),
                    }
                  }
                })
              }
            })
          }
          setFilteredModules(filteredModules)
        })
        .finally(() => setLoading(false))
    }

    getData()
  }, [taskData])

  const confirmActions = (action) => {
    dispatch(
      authorizationLayer({
        url: `processes/tasks/change-coordination-status/${taskData?.id}`,
        method: 'PATCH',
        data: {
          coordinationStatus: action,
          cancelComment: !action ? comment.trim() : undefined,
        },
      })
    ).then(({ data }) => {
      if (data.success) {
        if (
          data?.data?.code === 'COMPLETED' ||
          data?.data?.code === 'CANCELLED'
        ) {
          notification.error({
            message: t('task.actionHistory.module.errorMessage'),
          })
          setOpen(false)
        } else navigate('/')
      }
    })
  }

  const showCommercialProposal = async () => {
    setLoadingCP(true)
    await dispatch(
      authorizationLayer({
        url: `documentation-flow/${taskData.mainTaskId}/kp`,
        method: 'get',
      })
    )
      .then((res) => {
        if (res !== undefined) {
          let pdfInBase64 = res.data.data.base64

          let URL = window.URL || window.webkitURL,
            byteChars = window.atob(pdfInBase64),
            bytes = [],
            i = 0

          for (; i < byteChars.length; i++) bytes[i] = byteChars.charCodeAt(i)

          let blob = new Blob([new Uint8Array(bytes)], {
            type: 'application/pdf',
          })
          let downloadUrl = URL.createObjectURL(blob)

          let fileLink = document.createElement('a')
          fileLink.href = downloadUrl
          fileLink.download = taskData.inner_id + '.pdf'
          fileLink.click()

          if (window.navigator && window.navigator.msSaveOrOpenBlob)
            window.navigator.msSaveOrOpenBlob(blob)
          else {
            let newWin = window.open(
              downloadUrl,
              'width=800,height=800,menubar=yes,scrollbars=yes,status=yes,resizable=yes'
            )
            newWin.focus()
            URL.revokeObjectURL(downloadUrl)
          }
        } else {
          message.error('Не все данные заполнены для формирования КП')
        }
      })
      .finally(() => setLoadingCP(false))
  }

  return (
    <div className='confirm'>
      <TaskHistoryItems
        taskData={taskData}
        currenStepStage={currenStepStage}
        loading={loading}
        filteredModules={filteredModules}
      />
      {taskData?.status?.code !== 'CANCELLED' &&
      taskData?.status?.code !== 'COMPLETED' &&
      taskData?.coordinationUsers?.length ? (
        <div className='confirm__footer'>
          <Row justify='space-between' className='confirm__footer-row'>
            <Col>
              <Button shape='round' danger onClick={() => setOpen(true)}>
                {t('request.common.button.reject')}
              </Button>
              {(taskData.process.id ===
                'be0ae2dc-b374-437d-a712-0134fe958c62' ||
                taskData.process.id ===
                  '78af5d2d-b5c6-4001-89e6-4f66b1d475eb' ||
                taskData.process.id ===
                  'cda2cc59-f3cd-48ca-a784-d10a9790c2b8' ||
                taskData.process.id ===
                  '06c02ade-4826-49ec-856f-4a0338cabef9') && (
                <Button
                  shape='round'
                  onClick={showCommercialProposal}
                  className='footer__commercial-btn'
                  loading={loadingCP}
                  style={{ marginLeft: 6 }}
                >
                  {t('request.common.button.commercialProposal')}
                </Button>
              )}
            </Col>
            <Col>
              <Button
                shape='round'
                type='primary'
                onClick={() => confirmActions(true)}
              >
                {t('task.actionHistory.module.agreementSuccessBtn')}
              </Button>
            </Col>
          </Row>
        </div>
      ) : null}
      <Modal
        title={t('confirm.reject.modal.title')}
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        okText={t('task.footer.modal.OkText')}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => confirmActions(false)}
        children={
          <>
            <Typography.Text>
              {t('confirm.reject.modal.inputLabel')}:
            </Typography.Text>
            <Input.TextArea
              onChange={(e) => setComment(e.target.value)}
              showCount
              maxLength={1000}
              placeholder={t('task.cancelModal.textAreaPlaceholder')}
              className='confirm__modal-input'
            />
          </>
        }
        className='confirm__modal'
      />
      {showTaskHistoryModal && (
        <TaskHistoryModal
          needsData={needsData}
          taskData={taskData}
          currenStepStage={currenStepStage}
          taskID={id}
          allModules={allModules}
          open={showTaskHistoryModal}
          closeTaskHistory={closeTaskHistory}
          mainTaskId={mainTaskId}
        />
      )}
    </div>
  )
}

export default Confirm
