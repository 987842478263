import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useCallback } from 'react'
import _ from 'lodash'
import { useEffect } from 'react'

const useBrands = () => {
  const { brands } = useSelector((state) => state.app)
  const dispatch = useDispatch()
  const [brandsWithQuery, setBrandsWithQuery] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setBrandsWithQuery(brands)
  }, [brands])

  const getBrands = async (query) => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `wh-cars/references/car_marks/f9ca62d0-4eba-402f-8d6c-010102ddc59a${
          query?.trim() ? `?name=${query?.trim()}` : ''
        }`,
      })
    )
      .then((res) => {
        if (!query?.trim()) dispatch(SET_APP(['brands'], res.data))
        else setBrandsWithQuery(res.data)
      })
      .finally(() => setLoading(false))
  }

  const onChangeBrands = useCallback(
    _.debounce((query) => getBrands(query), 500),
    []
  )

  return {
    onChangeBrands,
    brandsWithQuery,
    loading,
    setLoading,
  }
}

export default useBrands
