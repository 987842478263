import './styles.less'
import { Card } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Render from '../Confirm/render'
import BSteps from '../../../../ui/BSteps'
import React from 'react'

const TaskHistoryItems = (props) => {
  const { taskData, currenStepStage, loading, filteredModules } = props
  return (
    <div>
      {taskData?.process && taskData?.status && (
        <div style={{ margin: '20px 0 0 0' }}>
          <BSteps
            stages={taskData?.process?.stages}
            status={taskData?.status?.code}
            currentOrder={currenStepStage}
          />
        </div>
      )}

      <div
        className='steps'
        style={{
          height:
            taskData?.status?.code !== 'CANCELLED' &&
            taskData?.status?.code !== 'COMPLETED'
              ? 'calc(100vh - 126px - 52px - 32px - 28px)'
              : 'calc(100vh - 126px - 52px)',
        }}
      >
        {!loading ? (
          filteredModules?.map((i, index) => {
            if (i.appear)
              return (
                <Card
                  key={index}
                  style={{
                    marginBottom: 12,
                    borderRadius: 6,
                    boxShadow: '0px 2px 12px rgba(130, 153, 219, 0.08)',
                    border: '1px solid #E3E6E9',
                  }}
                  bodyStyle={{ padding: 20 }}
                >
                  <Render
                    data={{ module: i.data, key: i.key, process: i.process }}
                  />
                </Card>
              )
          })
        ) : (
          <LoadingOutlined
            style={{
              color: '#0989FF',
              fontSize: 36,
              position: 'absolute',
              left: '45%',
              top: '45%',
            }}
          />
        )}
      </div>
    </div>
  )
}

export default TaskHistoryItems
