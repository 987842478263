import React, { useContext, useEffect, useRef } from 'react'
import _ from 'lodash'
import { Button, Card, Col, Form, Input, notification } from 'antd'
import { List, Row, Select } from 'antd'
import { axios } from '@store/actions/methods'
import { Context } from './'
import { t } from 'i18next'
import './index.less'

const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 8 },
  labelAlign: 'left',
  colon: false,
}

const FormComp = (props) => {
  const ctx = useContext(Context)
  const { setModal, values, setValues, loading } = ctx
  const { setLoading } = ctx

  const form = useRef()

  useEffect(() => {
    form.current.setFieldsValue(values)
  }, [values])

  const onValuesChange = (value) => {
    const test = { ...values, ...value }
    setValues(test)
  }

  const onRemindPassword = async () => {
    setLoading(true)
    await axios
      .post('auth/forgot-password', {
        email: values.email,
      })
      .then(({ data }) => {
        if (data.success) {
          notification.success({
            message: t('profile.success.message'),
            description: t('profile.succes.desc'),
          })
        }
      })
    setLoading(false)
  }

  useEffect(() => {
    props.setSubmitStatus(
      !form.current.getFieldValue('last_name') ||
        !form.current.getFieldValue('name')
    )
  }, [props])

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Card title={t('profile.block.main.title')}>
            <Form ref={form} {...formLayout} onValuesChange={onValuesChange}>
              <Form.Item
                name='last_name'
                label={t('profile.block.main.surname')}
                rules={[
                  {
                    required: true,
                    message: t('profile.surnameValidateMessage'),
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  placeholder={t('profile.surnamePlaceholder')}
                  className='form-field'
                />
              </Form.Item>
              <Form.Item
                name='name'
                label={t('profile.block.main.name')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  placeholder={t('profile.namePlaceholder')}
                  className='form-field'
                />
              </Form.Item>
              <Form.Item
                name='middle_name'
                label={t('profile.block.main.middleName')}
              >
                <Input
                  disabled={loading}
                  placeholder={t('profile.middleNamePlaceholder')}
                  className='form-field'
                />
              </Form.Item>
              <Form.Item
                name='position'
                label={t('profile.block.main.position')}
              >
                <span style={{ fontWeight: 'bold' }}>
                  {!_.isEmpty(values) &&
                    values.position &&
                    values.position.name}
                </span>
              </Form.Item>
              <Form.Item name='locale' label={t('profile.block.main.locale')}>
                <Select
                  disabled={loading}
                  options={[
                    {
                      label: 'Русский',
                      value: 'ru',
                    },
                    {
                      label: 'English',
                      value: 'en',
                    },
                  ]}
                  className='form-field'
                ></Select>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
          <Card title={t('profile.block.accountLoginDetails.title')}>
            <Form {...formLayout}>
              <Form.Item label={t('profile.block.accountLoginDetails.email')}>
                <List.Item
                  actions={[
                    <Button
                      type='link'
                      key='edit'
                      onClick={() => setModal('email')}
                      disabled={loading}
                    >
                      {t('profile.block.accountLoginDetails.change')}
                    </Button>,
                  ]}
                >
                  <div style={{ fontWeight: 'bold' }}>
                    {!_.isEmpty(values) && values.email}
                  </div>
                </List.Item>
              </Form.Item>
              <Form.Item
                label={t('profile.block.accountLoginDetails.password')}
              >
                <List.Item
                  actions={[
                    <Button
                      disabled={loading}
                      type='link'
                      key='edit'
                      onClick={() => onRemindPassword()}
                    >
                      {t('profile.block.accountLoginDetails.change')}
                    </Button>,
                  ]}
                >
                  <div style={{ fontWeight: 'bold' }}>∙∙∙∙∙∙∙∙</div>
                </List.Item>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
          <Card title={t('profile.block.linkedProcesses.title')}>
            {!_.isEmpty(values.processes) ? (
              <Col span={12}>
                {values.processes.map((process) => (
                  <List.Item key={process.id}>
                    <List.Item.Meta title={process.name} />
                    <span>
                      {process.stagesCount}{' '}
                      {t('profile.block.linkedProcesses.stages')}
                    </span>
                  </List.Item>
                ))}
              </Col>
            ) : (
              t('profile.processEmpty')
            )}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormComp
