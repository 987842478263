import React, { useState, useEffect, useContext } from 'react'
import { Form, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import './index.less'
import { CharacteristicsContext } from '../../index'
import { useTranslation } from 'react-i18next'
import { verifyVIN } from '../../../../../../../../../../../utils/veriflyVin'
import { LoadingOutlined } from '@ant-design/icons'

const {Search} = Input;

const SearchBarVin = () => {
  const { updateInfo, brandId, modelId, setVinCode, vinCode , selectAuto, getDataCarsById } = useContext(CharacteristicsContext)
  const form = Form.useFormInstance()
  const { t } = useTranslation()
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [input, setInput] = useState("");
  const dispatch = useDispatch()
  const [loading , setLoading] = useState(false)
  useEffect(() => {
    if (vinCode === undefined) {
      setInput(selectAuto?.vin_code?.toUpperCase())
    } else {
      setInput(vinCode)
    }
  }, [selectAuto])

  const getDataVinCodes = async (value) => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `wh-cars/cars/vin/${value}`,
      })
    ).then(({ data }) => {
      setData(data);
      setFilteredData(data);
      if (data?.length > 0) {
        getDataCarsById(data[0].id, data[0].vin_code)
        setVinCode(data[0].vin_code.toUpperCase())
        setInput(data[0].vin_code.toUpperCase())
        setFilteredData([])
      }else {
        setVinCode(value)
        setInput(value)
      }
      setLoading(false)
    }).catch((err) => {
      console.log(err);
      setLoading(false)
    });
  }

  const handleFilter = async (e) => {
    const searchInput = e.target.value;
    setInput(searchInput);
    if (searchInput?.length === 17){
      await getDataVinCodes(searchInput)
    }
    const newFilter = data?.filter((value) => {
      return value.vin_code.toUpperCase().includes(searchInput.toUpperCase());
    });
    if (searchInput === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  return(
    <Form.Item
      label={t('warehouse.tab.cars.column.vin')}
      name='vin'
      rules={[
        {
          required: true,
          message: t('common.input.requiredMessage'),
        },
        {
          validator: (rule, value) => {
            if (verifyVIN(value) && value.length === 17) {
              return Promise.resolve()
            } else {
              return Promise.reject(
                new Error(t('task.moduleRender.module.vinValidateMessage'))
              )
            }
          },
        },
      ]}
    >
      <div className="search">
        <div className="searchInputs">
          <Search
            id='vin'
            value={input}
            loading={loading}
            placeholder="Введите VIN"
            className='customSearchVin'
            style={{
              width: '400px',
              marginRight: '20px',
            }}
            onChange={async (e) => {
              if (e.target.value) {
                await handleFilter(e)
                setVinCode(e.target.value.toUpperCase())
                setInput(e.target.value.toUpperCase())
                if (e.target.value !== '' && e.target.value?.length > 10 && e.target.value?.length < 18) {
                  updateInfo(brandId, modelId)
                }
              } else {
                setInput('')
              }
            }}
            onInput={(e) => (event) => {
              const value = event.target.value

              event.target.value =
                value.length !== 18 ? value.toUpperCase() : value.slice(0, -1)
            }}
          />
        </div>
        {loading ? (
          <LoadingOutlined style={{ color: '#0989ff', fontSize: 36 }} spin />
        ) : null
        //   (
        //   <>
        //   {filteredData?.length > 0 && (
        //     <div className="dataResult">
        //       {filteredData?.slice(0, 10).map((value, index) => {
        //         return (
        //           <div className="dataItem" key={value.id} onClick={() => {
        //             console.log('test', value.id)
        //             getDataCarsById(value.id, value.vin_code)
        //             setVinCode(value.vin_code.toUpperCase())
        //             setInput(value.vin_code.toUpperCase())
        //             setFilteredData([])
        //           }}>
        //             <p>{value.vin_code}</p>
        //           </div>
        //         );
        //       })}
        //     </div>
        //   )}
        //   </>
        // )
        }
      </div>
    </Form.Item>
  )
}
export default SearchBarVin