import React, { useEffect, useState } from 'react'
import { Button, Calendar, Modal, PageHeader, Typography } from 'antd'
import { Card } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import moment from 'moment'
import { authorizationLayer } from '@store/actions/methods'
import { useTranslation } from 'react-i18next'
import ActiveTasksTable from '@ui/ActiveTasksTable'

import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import _ from 'lodash'

const { Title } = Typography

const format = 'YYYY-MM-DD'

const Tab = () => {
  const [date, setDate] = useState(moment())
  const [activeData, setActiveData] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState({})
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    const getData = async () => {
      const from = moment(date).startOf('month').format(format)
      const to = moment(date).endOf('month').format(format)
      const query = queryString.stringify({
        from,
        to,
      })
      try {
        setLoading(true)
        const {
          data: { data },
        } = await dispatch(
          authorizationLayer({
            url: `processes/tasks/history?${query}`,
          })
        )
        let formattedData = {}

        data.forEach((item) => {
          const date = moment(item.pausedTo).format(format)
          if (_.has(formattedData, date)) {
            formattedData[date].push(item)
          } else {
            formattedData[date] = [item]
          }
        })
        setLoading(false)
        setItems(formattedData)
        setData(data)
      } catch (e) {}
    }

    getData()
  }, [date, dispatch])

  const setMonth = (value) => {
    setDate(moment(date).add(value, 'M'))
  }

  const header = ({ value }) => {
    const month = value.format('MMMM')
    return (
      <PageHeader
        title={
          loading
            ? null
            : `${t('calendar.calendar.tasksNumber')} - ${data.length}`
        }
        extra={[
          <Button key='left' size='small' onClick={() => setMonth(-1)}>
            <LeftOutlined />
          </Button>,
          <Title
            key='month'
            style={{
              padding: '0 12px',
              textTransform: 'capitalize',
              width: 150,
              textAlign: 'center',
            }}
            level={4}
          >
            {month}
          </Title>,
          <Button key='right' size='small' onClick={() => setMonth(1)}>
            <RightOutlined />
          </Button>,
        ]}
      />
    )
  }

  const dateCellRender = (date) => {
    const value = moment(date).format(format)
    if (items[value]) {
      return <Title style={{ textAlign: 'right' }}>{items[value].length}</Title>
    }
    return null
  }

  const onSelect = (date) => {
    const value = moment(date).format(format)
    if (items[value]) {
      const activeData = _.filter(data, (o) => {
        const date = moment(o.pausedTo).format(format)
        return date === value
      })
      setActiveData(activeData)
      setSelected(value)
    }
  }

  return (
    <Card>
      <Modal
        title={t('calendar.calendar.pendingTasks')}
        width={1000}
        visible={!!selected}
        onCancel={() => setSelected(false)}
        footer={null}
      >
        <ActiveTasksTable
          data={activeData}
          loading={false}
          onCancel={() => setSelected(false)}
        />
        ;
      </Modal>
      <Calendar
        value={date}
        headerRender={header}
        dateCellRender={dateCellRender}
        onSelect={onSelect}
      />
    </Card>
  )
}

export default Tab
