import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router'
import _ from 'lodash'
import moment from 'moment'
import SkeletonTable from '@ui/SkeletonTable'
import { Input, Row, Col, Button, Typography, Skeleton } from 'antd'
import { Space, DatePicker, Tag } from 'antd'
import { columns } from '@views/NewTasks/components.settings/Table/columns'
import useTableActions from '@views/NewTasks/hooks/useTableActions'
import { numberWithSpaces } from '@utils'
import './index.less'
import useHeight from '@hooks/useHeight'
import { TasksContext } from '../..'
import { SSE } from '@utils'
import { useRef } from 'react'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { useTranslation } from 'react-i18next'

const Table = () => {
  const {
    setOpen,
    setCallClientInfo,
    setVisibleDrawer,
    setClientInfo,
    setHistory,
    setVisibleHistory,
  } = useContext(TasksContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.app.user)
  const tasks = useSelector((state) => state.app.tasks)
  const tasksMeta = useSelector((state) => state.app.tasksMeta)
  const statuses = useSelector((state) => state.app.statuses)
  const tasksQuery = useSelector((state) => state.app.tasksQuery)
  const { loading, getTasks, setQuery, query, statusesLoading, getStatuses } =
    useTableActions()
  const { setUpdateTasks, setUpdateTaskData } = useTableActions()

  const { bodyHeight } = useHeight(['ant-layout-header', 'ant-row'])
  const sse = useRef()
  const MY_TASK_CREATED = `MY_TASK_CREATED_${user?.id}`
  const MY_TASK_UPDATED = `MY_TASK_UPDATED_${user?.id}`
  const { t } = useTranslation()

  useEffect(() => {
    getTasks()
    getStatuses()
  }, [])

  // const interval = useRef()

  // useEffect(() => {
  //   interval.current = setInterval(async () => {
  //     await dispatch(
  //       authorizationLayer({
  //         url: 'processes/statuses',
  //       })
  //     ).then(({ data }) => dispatch(SET_APP(['statuses'], data.data)))
  //   }, 5000)
  //   return () => {
  //     if (interval.current) {
  //       clearInterval(interval.current)
  //     }
  //   }
  // }, [])

  const onTaskCreated = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event
    setUpdateTasks(data)
  }

  const onTaskUpdated = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event

    setUpdateTaskData(data)
  }

  useEffect(() => {
    const sse_init = async () => {
      sse.current = await dispatch(SSE())
      sse.current.addEventListener(MY_TASK_CREATED, onTaskCreated)
      sse.current.addEventListener(MY_TASK_UPDATED, onTaskUpdated)
    }
    sse_init()
    return () => {
      if (sse.current) {
        sse.current.removeEventListener(MY_TASK_CREATED, onTaskCreated)
        sse.current.removeEventListener(MY_TASK_UPDATED, onTaskUpdated)
      }
    }
  }, [])

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day')
  }
  const handleTableChange = (pagination) => {
    query.pagination.current = pagination.current
    query.pagination.pageSize = pagination.pageSize
    setQuery(query)
    getTasks()
  }

  const handleCalendarChange = (date) => {
    query.pagination.current = 1
    query.pagination.pageSize = 10
    query.calendarValue = date
    setQuery(query)
    getTasks()
  }

  const handleSearch = (e) => {
    if (e.target.value.length >= 3 || e.target.value === '') {
      query.pagination.current = 1
      query.pagination.pageSize = 10
      query.searchValue = e.target.value.trim()
      setQuery(query)
      getTasks()
    }
  }

  const handleStatusesChange = (checked, item) => {
    query.pagination.current = 1
    query.pagination.pageSize = 10
    const nexSelectedTags = checked
      ? [...query.statuses, item.code]
      : query.statuses?.filter((t) => t !== item.code)

    query.statuses = nexSelectedTags
    setQuery(query)
    getTasks()
  }

  const setClientData = (data) => {
    setCallClientInfo(data)
    setOpen(true)
  }

  const getInfo = (record) => {
    dispatch(
      authorizationLayer({
        url: `processes/tasks/${record?.id}`,
        method: 'get',
      })
    )
  }

  return (
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      <Row gutter={16} className='actions-row'>
        <Col>
          {!statusesLoading ? (
            <Space size={16}>
              {statuses?.map((item) => {
                return (
                  <Tag.CheckableTag
                    style={{
                      padding: 0,
                      borderRadius: 20,
                      margin: 0,
                    }}
                    key={item.code}
                    checked={query.statuses?.indexOf(item.code) > -1}
                    onChange={(checked) => {
                      handleStatusesChange(checked, item)
                    }}
                  >
                    <Button className='status' shape='round'>
                      <div
                        className={
                          query.statuses?.indexOf(item.code) > -1
                            ? 'status__icon is-active'
                            : 'status__icon'
                        }
                      ></div>
                      {item.name}&nbsp;
                      {/* {item.count ? (
                        <Typography.Text style={{ fontWeight: 500 }}>
                          {numberWithSpaces(item.count)}
                        </Typography.Text>
                      ) : null} */}
                    </Button>
                  </Tag.CheckableTag>
                )
              })}
            </Space>
          ) : (
            <Space size={16}>
              <Skeleton.Button active shape='round' style={{ width: 145 }} />
              <Skeleton.Button active shape='round' style={{ width: 125 }} />
            </Space>
          )}
        </Col>
        <Col>
          <Input.Search
            placeholder={t('main.search')}
            allowClear
            style={{
              width: 200,
              borderRadius: 6,
            }}
            onChange={_.debounce(handleSearch, 500)}
            defaultValue={tasksQuery?.searchValue}
            className='search-input'
          />
        </Col>

        <Col>
          <DatePicker.RangePicker
            format='DD.MM.YYYY'
            placeholder={[t('main.filter.start'), t('main.filter.end')]}
            size='12'
            defaultValue={tasksQuery?.calendarValue}
            onChange={(e) => handleCalendarChange(e)}
            style={{
              height: 32,
              width: '100%',
              borderRadius: 6,
            }}
            disabledDate={disabledDate}
            inputReadOnly
          />
        </Col>

        <Col style={{ marginLeft: 'auto' }}>
          <Button type='primary' shape='round' onClick={() => setOpen(true)}>
            + {t('main.button.createRequest')}
          </Button>
        </Col>
      </Row>
      <SkeletonTable
        className='tasks-table'
        columns={columns(
          dispatch,
          user,
          navigate,
          setClientData,
          setVisibleDrawer,
          setClientInfo,
          setHistory,
          setVisibleHistory,
          getInfo,
          t
        )}
        width={1064}
        height={bodyHeight - 70}
        data={tasks}
        pagination
        total={tasksMeta?.total}
        currentPage={tasksMeta?.page}
        pageSize={tasksMeta?.limit}
        tableChangeFunction={handleTableChange}
        loading={loading}
        emptyData={{
          title: t('main.table.noData.title'),
          desc: <div>{t('main.table.noData.description')}</div>,
          link: '',
        }}
        activeRow={(record) => {
          if (record.status?.code === 'NEW') return 'active-row'
        }}
      />
    </Space>
  )
}

export default Table
