import React, { useContext, useMemo } from 'react'
import { MaskedInput } from 'antd-mask-input'
import { Form, Radio } from 'antd'
import { CharacteristicsContext } from '../../../Characteristics'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const Mileage = () => {
  const { mileageError, updateInfo, mileage, brandId, modelId } = useContext(
    CharacteristicsContext
  )
  const { t } = useTranslation()

  return (
    <div className='car-info__mileage-block'>
      <Form.Item
        style={{ maxWidth: 400 }}
        label={t('warehouse.tab.cars.column.mileage')}
        name='mileage'
        rules={[
          {
            required: true,
            message: t('common.input.requiredMessage'),
          },
        ]}
        className='car-info__mileage-input'
        extra={
          <Form.Item
            name='mileage_type'
            className='car-info__mileage-type'
            style={{ position: 'absolute', top: 0, right: -200 }}
          >
            <Radio.Group onChange={(e) => updateInfo(brandId, modelId)}>
              <Radio.Button value='km'>
                {t('task.moduleRender.module.carReception.milleageTypeKM')}
              </Radio.Button>
              <Radio.Button value='mile'>
                {t('task.moduleRender.module.carReception.milleageTypeMiles')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        }
        shouldUpdate
      >
        <MaskedInput
          mask={[
            {
              mask: Number,
              min: 0,
              radix: '.',
              thousandsSeparator: ' ',
            },
          ]}
          status={mileageError ? 'error' : false}
          allowClear
          value={mileage}
          onChange={(e) => updateInfo(brandId, modelId)}
          maxLength={9}
        />
      </Form.Item>
    </div>
  )
}

export default Mileage
