import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { authorizationLayer } from '@store/actions/methods'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import SkeletonTable from '@ui/SkeletonTable'
import { columnsForSpareParts } from '@views/Warehouse/methods/columns'
import { getFilters } from '@views/Warehouse/methods/filters'
import { Input } from 'antd'
import { openModals } from '@utils'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const T = (props) => {
  const { t } = useTranslation()
  const { stockType, bodyHeight } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [filtersData, setFiltersData] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [meta, setMeta] = useState(null)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
  })

  const [filters, setFilters] = useState(null)
  const [sorter, setSorter] = useState({
    field: 'sparePartCategory',
    order: 'ascend',
  })

  useEffect(async () => {
    const q = {
      page: pagination.current,
      take: pagination.pageSize,
    }
    const query = queryString.stringify(q)
    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-parts/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }, [dispatch])

  const handleTableChange = async (pagination, filters, sorter, extra) => {
    setSorter({
      field: sorter.field,
      order: sorter.order,
    })

    setFilters(filters)

    setPagination({
      current: 1,
      pageSize: 15,
    })
    let q = {
      page: 1,
      take: 15,
    }

    if (searchValue.length) q.keyword = searchValue

    if (Object.keys(sorter)) {
      q.sortElement = sorter.field
      q.sortBy = sorter.order === 'ascend' || !sorter.order ? 'ASC' : 'DESC'
    }

    if (filters) {
      Object.values(filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (filters?.price) {
            q.price_from = filters?.price[0]
            q.price_to = filters?.price[1]
          }

          if (filters?.sparePartCategory) {
            q.category_id = filters?.sparePartCategory
          }
        }
      })
    }

    const query = queryString.stringify(q, { arrayFormat: 'comma' })

    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-parts/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }
  const handleSearch = async (value) => {
    if (value) setSearchValue(value.target.value.trim())

    setPagination({
      current: 1,
      pageSize: 15,
    })

    let q = {
      page: 1,
      take: 15,
    }

    if (Object.keys(sorter)) {
      q.sortElement = sorter.field
      q.sortBy = sorter.order === 'ascend' || !sorter.order ? 'ASC' : 'DESC'
    }

    if (value && value.target.value.length)
      q.keyword = value.target.value.trim()

    if (filters) {
      Object.values(filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (filters?.price) {
            q.price_from = filters?.price[0]
            q.price_to = filters?.price[1]
          }

          if (filters?.sparePartCategory) {
            q.category_id = filters?.sparePartCategory
          }
        }
      })
    }

    const query = queryString.stringify(q, { arrayFormat: 'comma' })
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `wh-parts/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }

  useEffect(async () => {
    setLoading(true)

    const filters = await getFilters(dispatch, authorizationLayer, stockType)

    setFiltersData(filters)
  }, [getFilters, dispatch])

  const infinityFunction = async () => {
    pagination.current = pagination.current + 1

    let q = {
      page: pagination.current || 1,
      take: pagination.pageSize || 15,
    }

    if (searchValue.length) q.keyword = searchValue

    if (Object.keys(sorter)) {
      q.sortElement = sorter.field
      q.sortBy = sorter.order === 'ascend' || !sorter.order ? 'ASC' : 'DESC'
    }

    if (filters) {
      Object.values(filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (filters?.price) {
            q.price_from = filters?.price[0]
            q.price_to = filters?.price[1]
          }

          if (filters?.sparePartCategory) {
            q.category_id = filters?.sparePartCategory
          }
        }
      })
    }

    const query = queryString.stringify(q, { arrayFormat: 'comma' })

    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-parts/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData([...data, ...res.data.data])
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <Search
        placeholder={t('main.search')}
        allowClear
        style={{ width: 400, marginBottom: 15 }}
        onChange={_.debounce(handleSearch, 500)}
      />
      <SkeletonTable
        height={bodyHeight}
        columns={columnsForSpareParts(filtersData)}
        data={data}
        loading={loading}
        className='xScroll-custom-table--warehouse'
        total={meta?.total}
        currentPage={meta?.page}
        pageSize={meta?.take}
        tableChangeFunction={(_, filters, sorter) =>
          handleTableChange(_, filters, sorter)
        }
        pageSizeOptions={[10, 20, 50, 100]}
        onRowClickTrue={(e) => {
          openModals(dispatch, {
            type: 'product-details',
            id: e,
            spareStatus: true,
          })
        }}
        onRowClickStatus={() => true}
        cursorPointer
        infinityFunction={infinityFunction}
      />
    </div>
  )
}

export default T
