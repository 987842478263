import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { authorizationLayer } from '@store/actions/methods'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import SkeletonTable from '@ui/SkeletonTable'
import { columns } from '@views/Warehouse/methods/columns'
import { useFilteredData } from '@views/Warehouse/hooks/useFilteredData'
import { getFilters } from '@views/Warehouse/methods/filters'
import { Button, Input, Tag, Typography } from 'antd'
import { openModals } from '@utils'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const T = (props) => {
  const { t } = useTranslation()
  const { stockType, bodyHeight } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [markId, setMarkId] = useState(null)
  const [carModelId, setCarModelId] = useState(null)
  const [filtersData, setFiltersData] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [meta, setMeta] = useState(null)
  const [countCars, setCountCars] = useState({
    notReservedCount: 0,
    reservedCount: 0,
  })
  const [reservedCheck, setReservedCheck] = useState(false)
  const [notReservedCheck, setNotReservedCheck] = useState(false)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1000,
  })

  const [filters, setFilters] = useState(null)
  const [sorter, setSorter] = useState({
    field: 'carMark',
    order: 'ascend',
  })
  const filteredData = useFilteredData(data, filters, sorter)

  const getCountCars = async () => {
    await dispatch(
      authorizationLayer({
        url: `wh-cars/cars/states_count/new`,
      })
    ).then(async ({ data }) => {
      setCountCars(data)
    })
  }

  useEffect(async () => {
    const q = {
      page: pagination.current,
      take: pagination.pageSize,
    }
    const query = queryString.stringify(q)
    setLoading(true)
    getCountCars()

    await dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (!filters?.carMark && filters) {
      filters.carModel = undefined
    }
  }, [markId])

  useEffect(() => {
    if (!filters?.carModel && filters) {
      filters.carTemplate = undefined
    }
  }, [carModelId])

  const handleTableChange = async (pagination, filters, sorter) => {
    setSorter({
      field: sorter.field,
      order: sorter.order,
    })

    setPagination({
      current: 1,
      // pageSize: 15,
      pageSize: 1000,
    })

    setFilters(filters)

    let q = {
      page: 1,
      // take: 15,
      take: 1000,
    }

    if (searchValue.length) q.keyword = searchValue

    const query = queryString.stringify(q, { arrayFormat: 'comma' })

    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }
  const handleSearch = async (value) => {
    if (value) setSearchValue(value.target.value.trim())

    setPagination({
      current: 1,
      pageSize: 1000,
    })

    let q = {
      page: 1,
      take: 1000,
    }

    if (value && value.target.value.length)
      q.keyword = value.target.value.trim()
    const query = queryString.stringify(q, { arrayFormat: 'comma' })
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }

  useEffect(async () => {
    setLoading(true)

    const q = {
      page: pagination.current,
      take: pagination.pageSize,
    }
    const query = queryString.stringify(q)
    setLoading(true)

    dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then(async (res) => {
        const filters = await getFilters(
          dispatch,
          authorizationLayer,
          stockType,
          markId,
          carModelId,
          res.data.data
        )
        setFiltersData(filters)
      })
      .finally(() => setLoading(false))
  }, [getFilters, dispatch, markId, carModelId])

  const sortedColumns = columns(
    stockType,
    filtersData,
    markId,
    carModelId,
    filters
  ).filter((col) => Object.keys(col).length !== 0)

  const infinityFunction = async () => {
    pagination.current = pagination.current + 1

    let q = {
      page: pagination.current || 1,
      take: pagination.pageSize || 1000,
    }

    if (searchValue.length) q.keyword = searchValue

    const query = queryString.stringify(q, { arrayFormat: 'comma' })

    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData([...data, ...res.data.data])
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }

  const handleStatusesChange = async (checked, item) => {
    if (item === 'reservedCheck') {
      setReservedCheck(checked)
      if (checked) {
        let newData = []
        data.forEach((item) => {
          if (item.client_id !== null) {
            newData.push(item)
          }
        })
        if (newData.length > 0) setData(newData)
      } else {
        const q = {
          page: 1,
          take: pagination.pageSize,
        }
        const query = queryString.stringify(q)
        setLoading(true)
        getCountCars()

        await dispatch(
          authorizationLayer({
            url: `wh-cars/car_task/car/${stockType}?${query}`,
          })
        )
          .then((res) => {
            setData(res.data.data)
            setMeta({
              page: res.data.page,
              take: res.data.take,
              total: res.data.total,
            })
          })
          .finally(() => setLoading(false))
      }
    } else if (item === 'notReservedCheck') {
      setNotReservedCheck(checked)
      if (checked) {
        let newData = []
        data.forEach((item) => {
          if (item.client_id === null) {
            newData.push(item)
          }
        })
        if (newData.length > 0) setData(newData)
      } else {
        const q = {
          page: 1,
          take: pagination.pageSize,
        }
        const query = queryString.stringify(q)
        setLoading(true)
        getCountCars()

        await dispatch(
          authorizationLayer({
            url: `wh-cars/car_task/car/${stockType}?${query}`,
          })
        )
          .then((res) => {
            setData(res.data.data)
            setMeta({
              page: res.data.page,
              take: res.data.take,
              total: res.data.total,
            })
          })
          .finally(() => setLoading(false))
      }
    }
  }

  return (
    <div>
      <Search
        placeholder={t('main.search')}
        allowClear
        style={{ width: 400, marginBottom: 15 }}
        onChange={_.debounce(handleSearch, 500)}
        onSearch={_.debounce(handleSearch, 500)}
      />
      <Tag.CheckableTag
        style={{
          padding: 0,
          borderRadius: 20,
          marginRight: '8px',
          marginLeft: '8px',
        }}
        key={'reservedCheck'}
        checked={reservedCheck}
        onChange={(checked) => {
          handleStatusesChange(checked, 'reservedCheck')
        }}
      >
        <Button className='status' shape='round'>
          <div
            className={
              reservedCheck ? 'status__icon is-active' : 'status__icon'
            }
          ></div>
          <Typography.Text style={{ marginRight: '5px' }}>
            Контракт
          </Typography.Text>
          {countCars ? (
            <Typography.Text style={{ fontWeight: 500 }}>
              {countCars.reservedCount}
            </Typography.Text>
          ) : null}
        </Button>
      </Tag.CheckableTag>
      <Tag.CheckableTag
        style={{
          padding: 0,
          borderRadius: 20,
          margin: 0,
        }}
        key={'notReservedCheck'}
        checked={notReservedCheck}
        onChange={(checked) => {
          handleStatusesChange(checked, 'notReservedCheck')
        }}
      >
        <Button className='status' shape='round'>
          <div
            className={
              notReservedCheck ? 'status__icon is-active' : 'status__icon'
            }
          ></div>
          <Typography.Text style={{ marginRight: '5px' }}>
            Свободные
          </Typography.Text>
          {countCars ? (
            <Typography.Text style={{ fontWeight: 500 }}>
              {countCars.notReservedCount}
            </Typography.Text>
          ) : null}
        </Button>
      </Tag.CheckableTag>

      <SkeletonTable
        height={bodyHeight}
        columns={sortedColumns}
        data={filteredData}
        loading={loading}
        xScroll
        className='xScroll-custom-table--warehouse'
        total={meta?.total}
        currentPage={meta?.page}
        pageSize={1000}
        tableChangeFunction={(_, filters, sorter) =>
          handleTableChange(_, filters, sorter)
        }
        pageSizeOptions={[10, 20, 50, 100]}
        width={1000}
        onRowClickTrue={(e) => {
          openModals(dispatch, {
            type: 'product-details',
            id: e,
            spareStatus: false,
          })
        }}
        onRowClickStatus={() => true}
        cursorPointer
        // infinityFunction={infinityFunction}
      />
    </div>
  )
}

export default T
