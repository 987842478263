import React, { useState, useEffect } from 'react'
import { Button, Dropdown, Drawer, Menu, Slider, Tabs } from 'antd'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import './index.less'
import { useTranslation } from 'react-i18next'
import Loading from '@ui/Loading'

const ContactsInClient = ({ id }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const getData = async () => {
    try {
      setLoading(true)

      const { data } = await dispatch(
        authorizationLayer({
          url: `clients/linked/${id}`,
          method: 'get',
        })
      )
      setData(data)
      setLoading(false)
    } catch (e) {}
  }

  useEffect(() => {
    getData()
  }, [dispatch, id])

  const getNumber = (item) => {
    return item?.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      '$1 $2 $3 $4 $5'
    )
  }

  return (
    <div className='contacts'>
      {loading && <Loading spinner={true} />}
      <div className='contacts__list'>
        <div>
          {data?.map((item, index) => (
            <div
              className='task__client-input-wrap'
              style={{
                padding: '20px 30px ',
                boxShadow: '-3px 0px 14px rgba(212, 216, 220, 0.19)',
              }}
              key={index}
            >
              <div className='task__found-name-box'>
                <div className='task__full-name'>
                  {item?.full_name || item?.name}
                </div>
              </div>
              <div className='task__num-box'>
                <p className='task__num'>+{getNumber(item?.phone_number)}</p>
                {/*<a
                  target='_blank'
                  rel='noreferrer'
                  className='task__wpp-btn'
                  href={`https://api.whatsapp.com/send?phone=${item?.phone_number}`}
                >
                  <img
                    className='task__img'
                    src='https://minio.jcloud.kz/bertha-development/813c19fa-f5a2-46ef-b3bd-7023802aa489-whatsapp.svg'
                    alt='whatsapp'
                  />
                </a>*/}
              </div>

              {item?.data_fullness !== 100 && (
                <div className='task__completed'>
                  <p className='task__completed-text'>
                    {t('request.clientInfo.cardDataFuiel')}{' '}
                    {item?.data_fullness}%
                  </p>

                  <div className='task__progress'>
                    <Slider
                      className='task__slider'
                      style={{ pointerEvents: 'none' }}
                      handleStyle={{ display: 'none' }}
                      value={item?.data_fullness}
                      trackStyle={
                        item?.data_fullness <= 50
                          ? { backgroundColor: '#ff9900' }
                          : { backgroundColor: '#1890FF' }
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContactsInClient
