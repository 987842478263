import { Typography, Collapse, Form, Radio, Row, Col } from 'antd'
import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import { MaskedInput } from 'antd-mask-input'
import { CharacteristicsContext } from '../..'
import { useTranslation } from 'react-i18next'

const MainInfo = () => {
  const { t } = useTranslation()
  const [volumeError, setVolumeError] = useState(false)
  const dispatch = useDispatch()
  const form = Form.useFormInstance()
  const { brandId, modelId, updateInfo, previousValues } = useContext(
    CharacteristicsContext
  )
  const year = Form.useWatch('year', form)
  const carcase = Form.useWatch('carcase', form)
  const generation = Form.useWatch('generation', form)
  const engine = Form.useWatch('engine', form)
  const drive = Form.useWatch('drive', form)
  const transmission = Form.useWatch('transmission', form)
  const modification = Form.useWatch('modification', form)
  const volume = Form.useWatch('volume', form)
  const [activeKey, setActiveKey] = useState([])
  const [collapseInfo, setCollapseInfo] = useState([
    {
      key: 1,
      header: t('warehouse.tab.cars.column.year'),
      formName: 'year',
      content: [],
    },
    {
      key: 2,
      header: t('productDetails.cars.body'),
      formName: 'carcase',
      content: [],
    },
    {
      key: 3,
      header: t('productDetails.cars.generation'),
      formName: 'generation',
      content: [],
    },
    {
      key: 4,
      header: t('productDetails.cars.engine'),
      formName: 'engine',
      content: [],
    },
    {
      key: 5,
      header: t('productDetails.cars.drive'),
      formName: 'drive',
      content: [],
    },
    {
      key: 6,
      header: t('productDetails.cars.transmission'),
      formName: 'transmission',
      content: [],
    },
    {
      key: 7,
      header: t('productDetails.cars.rudder'),
      formName: 'rudder',
      content: [
        {
          name: t('productDetails.cars.left'),
          id: 'left',
        },
        {
          name: t('productDetails.cars.right'),
          id: 'right',
        },
      ],
    },
    {
      key: 8,
      header: t('productDetails.cars.modification'),
      formName: 'modification',
      content: [],
    },
  ])

  useEffect(() => {
    if (modelId) {
      const getYears = async () => {
        await dispatch(
          authorizationLayer({
            url: `wh-cars/references/car_years/${modelId}`,
          })
        ).then(({ data }) => {
          collapseInfo?.map((i) => {
            if (i.formName === 'year') {
              i.content = data
            }

            return { ...i }
          })

          setCollapseInfo([...collapseInfo])
        })
      }
      getYears()
    }

    return () => {
      setCollapseInfo(collapseInfo)
    }
  }, [modelId])

  useEffect(() => {
    if (year) {
      const getCarsases = async () => {
        await dispatch(
          authorizationLayer({
            url: `wh-cars/references/car_body_types/${modelId}/${year}`,
          })
        ).then(({ data }) => {
          collapseInfo?.map((i) => {
            if (i.formName === 'carcase') {
              i.content = data
            }

            return { ...i }
          })

          setCollapseInfo([...collapseInfo])
        })
      }
      getCarsases()
    }

    return () => {
      setCollapseInfo(collapseInfo)
    }
  }, [year])

  useEffect(() => {
    if (carcase) {
      const getGenerations = async () => {
        await dispatch(
          authorizationLayer({
            url: `wh-cars/references/car_generations/${modelId}/${year}/${carcase}`,
          })
        ).then(({ data }) => {
          collapseInfo?.map((i) => {
            if (i.formName === 'generation') {
              i.content = data
            }

            return { ...i }
          })

          setCollapseInfo([...collapseInfo])
        })
      }
      getGenerations()
    }

    return () => {
      setCollapseInfo(collapseInfo)
    }
  }, [carcase])

  useEffect(() => {
    if (generation && modification !== 'other') {
      const getEngines = async () => {
        await dispatch(
          authorizationLayer({
            url: `wh-cars/references/car_engine_types/?car_generation_id=${generation}`,
          })
        ).then(({ data }) => {
          collapseInfo?.map((i) => {
            if (i.formName === 'engine') {
              i.content = data
            }

            return { ...i }
          })

          setCollapseInfo([...collapseInfo])
        })
      }
      getEngines()
    }

    return () => {
      setCollapseInfo(collapseInfo)
    }
  }, [generation, modification])

  useEffect(() => {
    if (engine && modification !== 'other') {
      const getDrives = async () => {
        await dispatch(
          authorizationLayer({
            url: `wh-cars/references/car_drive_types/?car_generation_id=${generation}&car_engine_type_id=${engine}`,
          })
        ).then(({ data }) => {
          collapseInfo?.map((i) => {
            if (i.formName === 'drive') {
              i.content = data
            }

            return { ...i }
          })

          setCollapseInfo([...collapseInfo])
        })
      }
      getDrives()
    }

    return () => {
      setCollapseInfo(collapseInfo)
    }
  }, [engine, modification])

  useEffect(() => {
    if (drive && modification !== 'other') {
      const getTransmissions = async () => {
        await dispatch(
          authorizationLayer({
            url: `wh-cars/references/car_gearbox_types/?car_generation_id=${generation}&car_engine_type_id=${engine}&car_drive_type_id=${drive}`,
          })
        ).then(({ data }) => {
          collapseInfo?.map((i) => {
            if (i.formName === 'transmission') {
              i.content = data
            }

            return { ...i }
          })

          setCollapseInfo([...collapseInfo])
        })
      }
      getTransmissions()
    }

    return () => {
      setCollapseInfo(collapseInfo)
    }
  }, [drive, modification])

  useEffect(() => {
    if (transmission && modification !== 'other') {
      const getModifications = async () => {
        await dispatch(
          authorizationLayer({
            url: `wh-cars/references/car_modifications/?car_generation_id=${generation}&car_engine_type_id=${engine}&car_drive_type_id=${drive}&car_gearbox_type_id=${transmission}`,
          })
        ).then(({ data }) => {
          collapseInfo?.map((i) => {
            if (i.formName === 'modification') {
              i.content = data
            }

            return { ...i }
          })

          setCollapseInfo([...collapseInfo])
        })
      }
      getModifications()
    }

    return () => {
      setCollapseInfo(collapseInfo)
    }
  }, [transmission, modification])

  useEffect(() => {
    if (volume === '') setVolumeError(true)
    else setVolumeError(false)
  }, [volume])

  useEffect(() => {
    if (modification === 'other') {
      const getOtherModifications = async () => {
        await dispatch(
          authorizationLayer({
            url: 'wh-cars/references/car_modifications/all',
          })
        ).then(({ data }) => {
          collapseInfo?.map((i) => {
            if (i.formName === 'drive') {
              i.content = data.carDriveTypes
            } else if (i.formName === 'engine') {
              i.content = data.carEngineTypes
            } else if (i.formName === 'transmission') {
              i.content = data.carGearboxTypes
            }

            return { ...i }
          })
          setCollapseInfo([...collapseInfo])
        })
      }
      getOtherModifications()
      document.getElementById('volume').focus()
    }

    return () => {
      setCollapseInfo(collapseInfo)
    }
  }, [modification])

  const onChangeRadio = (formName, value, collapseIndex) => {
    updateInfo(brandId, modelId)
    for (let i = 0; i < collapseInfo.length; i++) {
      if (collapseInfo[i + 1] && collapseInfo[i].formName === formName) {
        let scrollId = document.getElementById(collapseInfo[i + 1].formName)
        scrollId.scrollIntoView({ behavior: 'smooth' }, true)
      }
    }

    if (formName === 'year' && value !== previousValues[formName]) {
      form.setFieldsValue({
        carcase: undefined,
        generation: undefined,
        engine: undefined,
        drive: undefined,
        transmission: undefined,
        modification: undefined,
        volume: undefined,
      })
    } else if (formName === 'carcase' && value !== previousValues?.carcase) {
      form.setFieldsValue({
        generation: undefined,
        engine: undefined,
        drive: undefined,
        transmission: undefined,
        modification: undefined,
        volume: undefined,
      })
    } else if (
      formName === 'generation' &&
      value !== previousValues?.generation
    ) {
      form.setFieldsValue({
        engine: undefined,
        drive: undefined,
        transmission: undefined,
        modification: undefined,
        volume: undefined,
      })
    } else if (formName === 'engine' && value !== previousValues?.engine) {
      if (modification !== 'other')
        form.setFieldsValue({
          drive: undefined,
          transmission: undefined,
          modification: undefined,
        })
    } else if (formName === 'drive' && value !== previousValues?.drive) {
      if (modification !== 'other')
        form.setFieldsValue({
          transmission: undefined,
          modification: undefined,
        })
    } else if (
      formName === 'transmission' &&
      value !== previousValues?.transmission
    ) {
      if (modification !== 'other')
        form.setFieldsValue({
          modification: null,
        })
    }

    if (form.getFieldValue('modification') !== 'other') {
      for (let i = collapseIndex + 1; i < collapseInfo?.length - 2; i++) {
        collapseInfo[i].content = []
        collapseInfo[7].content = []
      }

      setCollapseInfo([...collapseInfo])
    }
  }

  useEffect(() => {
    setActiveKey(
      collapseInfo
        ?.map((i) => {
          if (i.formName !== 'rudder') {
            if (i.content?.length || modification === 'other') {
              i = i.key

              return i
            }
          } else {
            if (form.getFieldValue('transmission')) {
              if (i.content?.length || modification === 'other') {
                i = i.key

                return i
              }
            }
          }
        })
        ?.filter((i) => i !== undefined)
    )
  }, [collapseInfo])

  const disabledCollapse = (status) => {
    if (status && modification !== 'other') {
      return 'disabled'
    } else if (modification === 'other') {
      return ''
    }
    return ''
  }

  return (
    <>
      <Typography.Title style={{ fontWeight: 500 }} level={5}>
        {t('task.moduleRender.module.carReception.mainInfo')}
      </Typography.Title>
      <Collapse
        ghost
        style={{ maxWidth: 600, marginTop: 20 }}
        activeKey={activeKey}
        onChange={(e) => setActiveKey(e)}
      >
        {collapseInfo?.map((item, collapseIndex) => {
          return (
            <Collapse.Panel
              id={`${item?.formName}`}
              forceRender
              header={item?.header}
              key={item?.key}
              extra={
                <Typography.Text type='secondary'>
                  {item?.content?.map((i) => {
                    if (i === form.getFieldValue(item?.formName)) return i

                    if (i.id === form.getFieldValue(item?.formName))
                      if (
                        item?.formName === 'carcase' ||
                        item?.formName === 'generation'
                      )
                        return i.name
                      else if (
                        item?.formName === 'engine' ||
                        item?.formName === 'drive' ||
                        item?.formName === 'transmission'
                      )
                        return i.value
                      else if (item?.formName === 'rudder') return i.name
                      else if (item?.formName === 'modification')
                        return `${i.engine_capacity} (${i.power} ${i.description})`
                  })}
                </Typography.Text>
              }
              collapsible={disabledCollapse(!item?.content?.length)}
            >
              <Form.Item
                name={item?.formName}
                rules={[
                  {
                    required: true,
                    message: t('common.input.requiredMessage'),
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Radio.Group>
                  <Row gutter={20}>
                    {item?.content?.map((contentItem, index) => {
                      return (
                        <Col
                          key={contentItem?.id || contentItem}
                          style={{ marginBottom: 20 }}
                        >
                          {item?.formName !== 'modification' ? (
                            <Radio.Button
                              value={contentItem?.id || contentItem}
                              onChange={(e) => {
                                onChangeRadio(
                                  item?.formName,
                                  e.target.value,
                                  collapseIndex
                                )
                              }}
                            >
                              <div
                                className='radio-picker'
                                id={contentItem?.id || contentItem}
                              >
                                {contentItem?.value ||
                                  contentItem?.name ||
                                  contentItem}
                              </div>
                            </Radio.Button>
                          ) : (
                            <>
                              <Radio
                                value={contentItem?.id}
                                onChange={(e) =>
                                  onChangeRadio(
                                    item?.formName,
                                    e.target.value,
                                    collapseIndex
                                  )
                                }
                              >
                                <div
                                  className='radio-picker'
                                  id={contentItem?.id}
                                >
                                  {contentItem.engine_capacity}
                                  {` (${contentItem.power} ${contentItem.description})`}
                                </div>
                              </Radio>
                            </>
                          )}
                        </Col>
                      )
                    })}
                    {item.formName === 'modification' && (
                      <Col>
                        <Radio
                          value='other'
                          onChange={(e) =>
                            onChangeRadio(
                              item?.formName,
                              e.target.value,
                              collapseIndex
                            )
                          }
                        >
                          {t(
                            'task.moduleRender.module.carReception.otherModification'
                          )}
                        </Radio>
                      </Col>
                    )}
                  </Row>
                </Radio.Group>
              </Form.Item>
              {modification === 'other' &&
                item?.formName === 'modification' && (
                  <Form.Item
                    name='volume'
                    label={t(
                      'task.moduleRender.module.carReception.engineVolume'
                    )}
                    rules={[
                      {
                        required: true,
                        message: t('common.input.requiredMessage'),
                      },
                    ]}
                    style={{ marginTop: 20 }}
                  >
                    <MaskedInput
                      mask={[
                        {
                          mask: '#[\\.0]',
                          definitions: {
                            '#': /[0-8]/,
                          },
                        },
                        { mask: '\\9' },
                      ]}
                      style={{ width: '200px' }}
                      status={volumeError ? 'error' : false}
                      onChange={(e) => updateInfo(brandId, modelId)}
                      id='volume'
                    />
                  </Form.Item>
                )}
            </Collapse.Panel>
          )
        })}
      </Collapse>
    </>
  )
}

export default MainInfo
