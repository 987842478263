import { memo, useEffect, useState } from 'react'

import { Typography } from 'antd'

import './styles.less'
import { CarReceptionItemWrapper } from '../CarReceptionItemWrapper'

const CarCharacteristics = memo((props) => {
  const { choiceCar, titleItem, taskID, setCarMarkData } = props
  const [state, setState] = useState({
    car: null,
  })



  const setNeededCarCharacteristics = (meta) => {
    const {
      year,
      carBodyType,
      carGeneration,
      carEngineType,
      carExteriorColor,
      carInteriorColor,
      mileage,
      carModification,
      vin_code,
      carDriveType,
      carGearboxType,
      steering_wheel,
      carMark,
      carModel,
      id,
    } = meta.meta

    console.log(carModel)

    setState({
      ...state,
      carMark: { ...carMark },
      carModel: { ...carModel },
      car: {
        year: { title: 'Год выпуска:', name: year, id: `year-${id}` },
        carBodyType: { title: 'Кузов', ...carBodyType },
        carGeneration: { title: 'Поколение:', ...carGeneration },
        carEngineType: { title: 'Двигатель', ...carEngineType },
        carDriveType: { title: 'Привод', ...carDriveType },
        carGearboxType: { title: 'Коробка передач', ...carGearboxType },
        steering_wheel: {
          title: 'Расположение руля',
          name: steering_wheel,
          id: `steering_wheel-${id}`,
        },
        carModification: { title: 'Модификация', ...carModification },
        mileage: { title: 'Пробег', name: mileage, id: `mileage-${id}` },
        carExteriorColor: { title: 'Цвет кузова', ...carExteriorColor },
        carInteriorColor: { title: 'Цвет салона', ...carInteriorColor },
        vin_code: { title: 'VIN', name: vin_code, id: `mileavin_codege-${id}` },
      },
    })
    const carMarkData = {
      fullName: `${carMark.name} ${carModel.name}`,
      name: `${carModel.name}`,
      model: `${carModel.name}`,
      year: `${year}`,
    }
    setCarMarkData(carMarkData)
  }
  useEffect(() => {
    if (choiceCar) {
      const meta = choiceCar.find((item) => {
        return item
      })
      meta && setNeededCarCharacteristics(meta)
    }
  }, [])

  return (
    <CarReceptionItemWrapper titleItem={titleItem}>
      {
        state.carMark &&
        state.carModel &&
        <Typography.Text strong style={{ margin: '0 10px 0 0' }}>
          {state.carMark?.name} {state.carModel?.name}
        </Typography.Text>
      }

      <div className={'car-characteristics'}>
        {state.car &&
          Object.keys(state.car).map((carType) => {
            const currentCar = state.car[carType]
            return (
              <div
                className={'car-characteristics--colum'}
                id={`${currentCar.id}`}
                key={`${currentCar.id}`}
              >
                <Typography.Text type={'secondary'}>
                  {currentCar.title}:
                </Typography.Text>
                <Typography.Text strong style={{ margin: '0 10px 0 0' }}>
                  {currentCar.name}
                </Typography.Text>
              </div>
            )
          })}
      </div>
    </CarReceptionItemWrapper>
  )
})

export default CarCharacteristics
