import React from 'react'

import { Collapse, Row, Col, Typography } from 'antd'

const Specialist = ({ ...props }) => {
  const data = props.servicesData?.specialists || null

  return (
    <>
      {data.map((item) => {
        return (
          <Row
            justify='space-between'
            style={{ padding: '12px 16px' }}
            key={item.id}
          >
            <Col>{item.full_name}</Col>
            <Col
              style={{
                background: '#F5F5F5',
                borderRadius: 2,
                border: '1px solid #F0F0F0',
                height: 24,
                padding: '0 20px',
                textAlign: 'center',
                fontSize: 12,
              }}
            >
              {item.position?.name}
            </Col>
          </Row>
        )
      })}
    </>
  )
}

export default Specialist
