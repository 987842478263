import React, { useContext } from 'react'

import { Tabs } from 'antd'

import './index.less'

import { PausedTasks } from './components'
import { MadeByMe } from './components'
import { TasksContext } from '../..'
import { useTranslation } from 'react-i18next'

const Index = () => {
  const { setVisibleDrawer, setClientInfo, setHistory, setVisibleHistory } =
    useContext(TasksContext)
  const { t } = useTranslation()
  return (
    <div className='box'>
      <Tabs
        defaultActiveKey='4'
        className='tabsbox'
        destroyInactiveTabPane={true}
        type='card'
      >
        <Tabs.TabPane
          className='pausedBox'
          tab={t('main.tab.pendingRequests')}
          key='4'
        >
          <PausedTasks
            setVisibleDrawer={setVisibleDrawer}
            setClientInfo={setClientInfo}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          className='pausedBox'
          tab={t('main.tab.createdByMe')}
          key='5'
          id='scrollableDiv'
        >
          <MadeByMe
            setVisibleDrawer={setVisibleDrawer}
            setClientInfo={setClientInfo}
            setHistory={setHistory}
            setVisibleHistory={setVisibleHistory}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default Index
