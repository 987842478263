import { useState, useEffect } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import useNotification from '@hooks/useNotification'

import { Layout, Button, Drawer, Space } from 'antd'
import { Row, Col, Dropdown, Menu, Modal } from 'antd'
import { Divider, Avatar, Image, Typography, Switch } from 'antd'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import { ExportOutlined, BellOutlined, DownOutlined } from '@ant-design/icons'
import { CloseOutlined, MessageOutlined } from '@ant-design/icons'
import { LOGOUT } from '@store/actions/methods'
import { useTranslation } from 'react-i18next'

import Logo from '@img/logo.svg'

const { Header } = Layout

const { Text } = Typography

const App = () => {
  // useState, routing
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notification = useNotification(true)
  const user = useSelector((state) => state.app.user)
  const { t } = useTranslation()

  // useEffect

  // properties

  const btnIconStyle = {
    border: 'none',
    width: 20,
    height: 20,
    padding: 0,
  }

  const onClick = async ({ key }) => {
    if (key === 'profile') {
      navigate('profile/settings')
    } else if (key === 'exit') {
      beforeLogout()
    }
  }

  const menu = (
    <Menu
      onClick={onClick}
      items={[
        {
          label: t('profile.title'),
          key: 'profile',
          icon: <UserOutlined />,
        },
        {
          label: t('header.exitBtn'),
          key: 'exit',
          icon: <ExportOutlined />,
        },
      ]}
    />
  )

  const onChange = (checked) => {
    console.log(`switch to ${checked}`)
  }

  // Func

  const userName = () => {
    let name = []
    if (user.last_name) name.push(user.last_name)
    if (user.name) name.push(user.name)

    if (name.length === 0) name.push(user.email)

    return name.join(' ')
  }

  if (!user) return null

  const beforeLogout = () => {
    Modal.confirm({
      title: t('header.exitConfirmTitle'),
      cancelText: t('header.exitConfirmCancelText'),
      okText: t('header.exitConfirmOkText'),
      okType: 'danger',
      onOk() {
        dispatch(LOGOUT())
      },
      maskClosable: true,
    })
  }

  return (
    <Layout className='fixed-container'>
      <Layout>
        <Header>
          <Row justify='space-between' align='middle'>
            <Col>
              <Space size={18} align='start'>
                <Link to='/'>
                  <img src={Logo} alt='Logo' />
                </Link>
              </Space>
            </Col>
            <Col>
              <Space size={20}>
                {/* <Space size={22.5}>
                  <Button
                    type="default"
                    size="small"
                    style={btnIconStyle}
                    onClick={notification}
                  >
                    <MessageOutlined className="medium-icon" />
                  </Button>
                  <Button
                    type="default"
                    size="small"
                    style={btnIconStyle}
                    onClick={notification}
                  >
                    <BellOutlined className="medium-icon" />
                  </Button>
                  <Dropdown
                    overlay={menu}
                    // trigger={['click']}
                    placement="bottomLeft"
                    onClick={notification}
                  >
                    <Button
                      type="default"
                      size="medium"
                      style={{
                        border: 'none',
                        padding: 0
                      }}
                    >
                      Помощь
                      <DownOutlined className="medium-icon" />
                    </Button>
                  </Dropdown>
                </Space> */}
                <Divider type='vertical' />
                <Dropdown overlay={menu} placement='bottomLeft'>
                  <Space size={12} style={{ cursor: 'default' }}>
                    <Avatar
                      icon={
                        <UserOutlined style={{ backgroundColor: '#0989FF' }} />
                      }
                      size={32}
                      style={{ backgroundColor: '#0989FF' }}
                    />
                    <Text>{userName()}</Text>
                    {/* <Switch
                      defaultChecked
                      onChange={onChange}
                      style={{ marginLeft: 4 }}
                      className='green-switch'
                    /> */}
                  </Space>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header>
      </Layout>
    </Layout>
  )
}
export default App
