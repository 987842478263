import React, { useState } from 'react'
import { Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Details, Info } from './components'

const Inspection = ({ data }) => {
  const { type, meta, title, choiceCarData } = data
  const year = choiceCarData?.year
  const [selected, setSelected] = useState(null)
  return (
    <>
      <Space size={8} direction='vertical' style={{ width: '100%' }}>
        <Typography.Title style={{ fontWeight: 400 }} level={5}>
          {title}
        </Typography.Title>
        {choiceCarData && (
          <Typography.Title level={5}>
            {choiceCarData?.carMark?.name && `${choiceCarData?.carMark?.name}`}{' '}
            {choiceCarData?.carModel?.name &&
              `${choiceCarData?.carModel?.name}`}
            {year && `,`} {year}
          </Typography.Title>
        )}
        <div style={{ display: 'flex' }}>
          <Details data={{ type, meta, selected, setSelected }} />
          <Info data={{ type, meta, selected }} />
        </div>
      </Space>
    </>
  )
}

export default Inspection
