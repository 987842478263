import React, { useContext } from 'react'
import { ModuleContextConfirm } from '../../render'
import { Typography, Row, Col } from 'antd'
import _ from 'lodash'
import { numberWithSpaces } from '@utils'
import { modules, stockTypes } from '@/constants'
import { useTranslation } from 'react-i18next'

const Sum = () => {
  const { data } = useContext(ModuleContextConfirm)
  const { t } = useTranslation()

  const sum = (o, data) => {
    const stockType = o?.module?.settings?.stockType

    if (stockType === stockTypes.SPARE_PARTS) {
      return _.sumBy(data, (i) => {
        return i.orderAmount * i.price
      })
    }

    return _.sumBy(data, 'price')
  }

  const totalPrice = _.sum(data?.module?.needs?.map((o) => sum(o, o.meta)))

  return (
    <>
      <Row justify='space-between'>
        <Col>
          <Typography.Title level={5}>
            {data?.module?.taskData?.userCreated?.full_name}
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Text type='secondary'>
            {t('warehouse.totalPrice')}
          </Typography.Text>
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col>
          <Typography.Text type='secondary'>
            {data?.module?.taskData?.process?.name}
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Title level={5}>
            {numberWithSpaces(totalPrice)}
          </Typography.Title>
        </Col>
      </Row>
    </>
  )
}

export default Sum
