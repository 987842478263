import { useState, useEffect } from 'react'
import { Input, DatePicker, Form, TimePicker, Select } from 'antd'
import { authorizationLayer } from '@store/actions/methods'
import { useDispatch } from 'react-redux'

import './index.less'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

const PausedModal = ({ ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [contacts, setContacts] = useState()

  const getContactType = async () => {
    try {
      const { data } = await dispatch(
        authorizationLayer({
          url: `processes/contact-types`,
        })
      )
      setContacts(data)
    } catch (e) {
      console.log(e.message)
    }
  }

  useEffect(() => {
    getContactType()
  }, [])


  return (
    <>
      <Form
        form={props.form}
        layout='vertical'
        autoComplete='off'
        style={{ maxWidth: 180 }}
      >
        <Form.Item
          name='contact'
          label='Тип контакта'
          
          rules={[
            {
              required: true,
              message: t('task.footer.pausedModal.changeDateTime'),
            },
          ]}
        >
          <Select className='select' placeholder='Выберите тип контакта'>
            {contacts?.map((contact) => (
              <Select.Option
                key={contact.id}
                value={contact.id}
                style={{ minHeight: 32, width: '100%', borderRadius: '6px' }}
              >
                {contact.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name='date'
          label='Выберите дату'
          rules={[
            {
              required: true,
              message: t('task.footer.pausedModal.changeDateTime'),
            },
          ]}
        >
          <DatePicker
            disabledDate={props.disabledDate}
            format='DD.MM.YYYY'
            style={{ minHeight: 32, width: '100%', borderRadius: '6px' }}
            showToday={false}
            placeholder='Дата'
          />
        </Form.Item>
        <Form.Item
          name='time'
          label='Выберите время'
          rules={[
            {
              required: true,
              message: t('task.footer.pausedModal.changeDateTime'),
            },
          ]}
        >
          <TimePicker
            style={{ width: '100%', height: 32 }}
            placeholder={t('Время')}
            format='HH:mm'
            showToday={false}
            disabledHours={() => [0, 1, 2, 3,4,5,6,7,8,22,23]}
            minuteStep={15}
            popupClassName='timepicker'
            onSelect={(e) => {
              props.form.setFieldsValue({ time: e })
            }}
            className='forms__date'
          />
        </Form.Item>
        <Form.Item
          name='comments'
          label={t('task.actionHistory.modal.warehouse.comment')}
          style={{ marginTop: 24, minWidth: 520 }}
          rules={[
            {
              required: true,
              message: t('task.footer.pausedModal.commentRequired'),
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={1000}
            style={{ width: '100%' }}
            placeholder={t('task.cancelModal.textAreaPlaceholder')}
            className='text-area'
          />
        </Form.Item>
      </Form>
    </>
  )
}

export default PausedModal
