import { memo } from 'react'

import { Empty, Typography } from 'antd'

import { CarReceptionItemWrapper } from '../CarReceptionItemWrapper'
import './styles.less'

const CarOptions = memo((props) => {
  const { checkList, choiceCar } = props
  return (
    <CarReceptionItemWrapper titleItem={'Опции'}>
      {/*Ожидаем массив с объектами где будет название и описание*/}
      {checkList.length >= 1 &&
        checkList.map((check) => {
          return (
            <div className={'car-options'} id={check.id} key={check.id}>
              {choiceCar &&
                choiceCar.map((comment) => {
                  const { id, meta } = comment
                  return (
                    <div id={id} key={id}>
                      {meta && meta.additional_option && (
                        <div>
                          <Typography.Text
                            strong
                            className={'car-options--title'}
                          >
                            Комментарий:
                          </Typography.Text>
                          <div style={{ maxWidth: 600 }}>
                            <Typography.Text id={comment.id} key={comment.id}>
                              {meta.additional_option}
                            </Typography.Text>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              {check.meta ? (
                check.meta.map((metaItem) => {
                  const { name_ru, options } = metaItem
                  return (
                    <div id={metaItem.id} key={metaItem.id}>
                      <Typography.Text strong className={'car-options--title'}>
                        {name_ru}:
                      </Typography.Text>
                      <div style={{ maxWidth: 600 }}>
                        {options && (
                          <Typography.Text className={'car-options--comment'}>
                            {options.map((option, index) => {
                              const putLastMark =
                                options.length === index + 1 ? `.` : `, `
                              return (
                                <Typography.Text id={option.id} key={option.id}>
                                  {`${option.name}${putLastMark} `}
                                </Typography.Text>
                              )
                            })}
                          </Typography.Text>
                        )}
                      </div>
                    </div>
                  )
                })
              ) : (
                <Empty />
              )}
            </div>
          )
        })}
    </CarReceptionItemWrapper>
  )
})
export default CarOptions
