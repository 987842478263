import React, { useEffect, useContext } from 'react'
import { useCallback } from 'react'
import { Input, Form } from 'antd'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import { OptionsBlock } from './components'
import './index.less'
import { Context } from '../..'
import { TaskContext } from '../../../../../../..'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { modules, stockTypes } from '@/constants'

const CheckList = () => {
  // const { t } = useTranslation()
  // const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { taskId, moduleId, selectAuto, checkList, setComment, mainTaskId } =
    useContext(Context)
  // const { setNeedsData, needsData } = useContext(TaskContext)

  useEffect(() => {
    const getOptions = () => {
      const options = {}
      for (const group of checkList) {
        for (const opt of group.options) {
          if (opt.type === 'check_box') {
            options[opt.id] = true
          } else if (opt.type === 'select') {
            options[opt.name] = opt.id
          } else if (opt.type === 'multi_select' && options[opt.name]) {
            options[opt.name].push(opt.id)
          } else if (opt.type === 'multi_select' && !options[opt.name]) {
            options[opt.name] = [opt.id]
          }
        }
      }

      form.setFieldsValue({ options })
    }
    if (checkList) getOptions()
  }, [])

  // const onComment = useCallback(
  //   _.debounce(async (comment) => {
  //     let objWithAdditional = selectAuto || {}
  //     objWithAdditional.additional_option = comment
  //     setComment(comment)

  //     await dispatch(
  //       authorizationLayer({
  //         url: `needs`,
  //         method: 'POST',
  //         data: {
  //           taskId,
  //           moduleId,
  //           mainTaskId,
  //           needs: [
  //             {
  //               type: modules.CAR_RECEPTION,
  //               action: 'choice',
  //               meta: objWithAdditional,
  //               subType: 'choiceCar',
  //             },
  //           ],
  //         },
  //       })
  //     ).then(({ data }) => {
  //       let index = _.findIndex(needsData, (i) => i.subType === 'choiceCar')

  //       if (index !== -1) {
  //         needsData[index] = data.data[0]

  //         setNeedsData([...needsData])
  //       } else setNeedsData([...needsData, ...data.data])
  //     })
  //   }, 500),
  //   [selectAuto]
  // )

  return (
    <div className='checklist'>
      <div className='checklist__title'>
        {/* {t('task.actionHistory.modal.checkList.desc')} */}
        Опции
      </div>
      <Form
        autoComplete='off'
        layout={'vertical'}
        form={form}
        // initialValues={{
        //   desc: selectAuto?.additional_option,
        // }}
        preserve={false}
      >
        {/* <Form.Item name='desc'>
          <Input.TextArea
            placeholder={t(
              'task.moduleRender.module.carReception.checkList.problemDescPlaceholder'
            )}
            showCount
            maxLength={1000}
            className='checklist__comment'
            onChange={(e) => onComment(e.target.value)}
          />
        </Form.Item> */}
        <OptionsBlock
          taskId={taskId}
          moduleId={moduleId}
          mainTaskId={mainTaskId}
        />
      </Form>
    </div>
  )
}

export default CheckList
