import React, { useContext, useState, useEffect } from 'react'
import './index.less'
import external from '@img/external.svg'
import internal from '@img/internal.svg'
import { InspectionContext } from '../..'
import _ from 'lodash'
import data from './data'
import { Space, Badge, Button, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const images = {
  internal,
  external,
}

const Details = () => {
  const { t } = useTranslation()
  const { values, selected, setSelected, type, clearAll } =
    useContext(InspectionContext)
  const { isEmptyInternal, isEmptyExternal } = useContext(InspectionContext)
  const [visible, setVisible] = useState(false)

  const valuesFromTypes = values?.filter((i) =>
    data[type].some((x) => x.name === i.carDetail.short_name)
  )

  return (
    <div className='details'>
      {!selected ? (
        <div className='details__note'>
          {t('task.moduleRender.module.carReception.inspection.dotClickDesc')}
        </div>
      ) : (
        <Button
          type='link'
          style={{
            fontSize: 12,
            height: 18,
            lineHeight: '18px',
            marginBottom: 24,
          }}
          onClick={() => setSelected(null)}
        >
          {t('task.moduleRender.module.carReception.inspection.deselect')}
        </Button>
      )}
      <div className='details__image-car-picker'>
        {data[type].map(({ name, position, lkp, badgeOffset }) => {
          const className = ['dot']
          const containerClassName = ['circle']
          const filled = _.find(values, (v) => {
            return v.carDetail.short_name === name
          })
          let badgeColor = '#697186'

          if (type === 'external') {
            if (isEmptyExternal) {
              className.push('empty')
              containerClassName.push('empty')
              badgeColor = 'rgba(41, 52, 82, 0.4)'
            }
          } else {
            if (isEmptyInternal) {
              className.push('empty')
              containerClassName.push('empty')
              badgeColor = 'rgba(41, 52, 82, 0.4)'
            }
          }

          if (filled) {
            badgeColor = '#0989FF'
            className.push('filled')
            containerClassName.push('filled')
          }

          if (
            filled?.lkp &&
            !filled?.carConfirmations.length &&
            !filled?.comment &&
            !filled?.photos.length
          ) {
            badgeColor = '#697186'
            className.push('filled filled--without')
            containerClassName.push('filled filled--without')
          } else if (
            (filled?.lkp && filled?.carConfirmations.length) ||
            (filled?.lkp && filled?.comment) ||
            (filled?.lkp && filled?.photos.length)
          ) {
            badgeColor = '#0989FF'
            className.push('filled')
            containerClassName.push('filled')
          }

          if (name === selected) {
            className.push('active')
            containerClassName.push('active')
            badgeColor = '#096DD9'
          }

          return (
            <div
              key={name}
              onClick={() => {
                if (type === 'external') {
                  !isEmptyExternal && setSelected(name)
                } else {
                  !isEmptyInternal && setSelected(name)
                }
              }}
              className={containerClassName.join(' ')}
              style={{ ...position }}
            >
              {lkp ? (
                <Badge
                  color={badgeColor}
                  count={filled?.lkp}
                  offset={filled?.lkp > 99 ? [-10, 0] : [0, 0]}
                  className='details__badge'
                  overflowCount={9999}
                  children={
                    !filled?.lkp && <div className={className.join(' ')}></div>
                  }
                />
              ) : (
                <div className={className.join(' ')}></div>
              )}
            </div>
          )
        })}
        <img src={images[type]} alt={type} />
      </div>

      <Button
        shape='round'
        ghost
        type='primary'
        className='details__btn'
        onClick={() => {
          if (type === 'external') {
            !isEmptyExternal && setVisible(true)
          } else {
            !isEmptyInternal && setVisible(true)
          }
        }}
        disabled={!valuesFromTypes?.length}
      >
        {t('task.moduleRender.module.resetAllProblems')}
      </Button>
      <Modal
        // visible={visible}
        open={visible}
        wrapClassName='details__modal'
        width={416}
        bodyStyle={{ padding: '32px 32px 32px 70px' }}
        closable={false}
        onCancel={() => setVisible(false)}
        okText={t('task.moduleRender.module.carReception.checkList.reset')}
        onOk={() => {
          clearAll()
          setVisible(false)
        }}
        okButtonProps={{ type: 'danger' }}
      >
        <ExclamationCircleOutlined
          style={{
            color: '#FF4D4F',
            fontSize: 22,
            position: 'absolute',
            left: 32,
          }}
        />
        <div style={{ fontSize: 16, fontWeight: 700 }}>
          {t('task.moduleRender.module.resetAllDefectsQuestion')}
        </div>
        <div style={{ marginTop: 8 }}>
          {t('task.moduleRender.module.carReception.checkList.confirmDesc')}
        </div>
      </Modal>
    </div>
  )
}

export default Details
