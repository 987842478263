import { createStore, applyMiddleware, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

// reducers
import app from '@store/reducers/methods'

// ---process---

const configureStore = composeWithDevTools(applyMiddleware(thunkMiddleware))(
  createStore
)

const versions = {
  app: '1',
}

const APP_NAME = 'BERTHA_CRM'

const appPersistConfig = {
  key: `${APP_NAME}_app_${versions.app}`,
  version: versions.app,
  storage,
  whitelist: ['token', 'user'],
}

const reducer = combineReducers({
  app: persistReducer(appPersistConfig, app),
})

const store = () => {
  const store = configureStore(reducer)
  const persistor = persistStore(store)

  return { persistor, store }
}

export default store
