import { createContext } from 'react'
import {
  CarReception,
  ChoiceProduct,
  Files,
  Form,
  IWD,
  Sum,
} from './components'
import { modules } from '@/constants'

export const ModuleContextConfirm = createContext()

const Render = ({ data }) => {
  const { key } = data
  let content = null
  if (key === 'SUM') content = <Sum />
  else if (key === modules.CHOICE_PRODUCT) content = <ChoiceProduct />
  else if (key === modules.CAR_RECEPTION) content = <CarReception />
  else if (key === modules.IWD) content = <IWD type={modules.IWD} />
  else if (key === modules.COORD) content = <IWD type={modules.COORD} />
  else if (key === modules.UPLOAD_FILE) content = <Files />
  else if (key === modules.FORM) content = <Form />
  return (
    <ModuleContextConfirm.Provider value={{ data }}>
      {content}
    </ModuleContextConfirm.Provider>
  )
}

export default Render
