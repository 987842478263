import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import moment from 'moment'

import { Card, Col, Row, Select, Empty, DatePicker, Form, Spin } from 'antd'
import { Button } from 'antd'
import { Funnel, Pie, Bar, Column } from '@ant-design/plots'

import { authorizationLayer } from '@store/actions/methods'
import { funnel_config, pie_config, bar_config, column_config } from './config'
import Loading from '@ui/Loading'
import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker

const Created = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [allTask, setallTask] = useState([])

  const [active, setActive] = useState([])
  const [activeList, setActiveList] = useState([])

  const [traffic, setTraffic] = useState([])
  const [trafficList, setTrafficList] = useState([])

  const [type, setType] = useState([])
  const [typeList, setTypeList] = useState([])

  const [cancel, setCancel] = useState([])
  const [cancelList, setCancelList] = useState([])
  const format = 'YYYY-MM-DD'

  const proccess_id = Form.useWatch('proccess_id', form)
  const period = Form.useWatch('period', form)
  const [loading, setLoading] = useState(false)
  const [loadingForSelect, setLoadingForSelect] = useState(true)

  useEffect(() => {
    setLoading(true)

    const getData = async () => {
      try {
        const { data } = await dispatch(
          authorizationLayer({
            url: `/processes/statistics/by-processes/child`,
          })
        )
        setData(data?.data)
        form.setFieldsValue({ proccess_id: data?.data[0].id })
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [dispatch, form])

  useEffect(() => {
    if (!proccess_id) setLoadingForSelect(true)
    else setLoadingForSelect(false)
  }, [proccess_id])

  useEffect(() => {
    setLoading(true)
    const getAllTask = async () => {
      try {
        const q = {
          processId: proccess_id,
        }

        const from = period && moment(period[0]).format(format)
        const to = period && moment(period[1]).format(format)
        if (from && to) {
          q.from = from
          q.to = to
        }

        const query = queryString.stringify(q)

        const { data: response } = await dispatch(
          authorizationLayer({ url: `/processes/statistics/child?${query}` })
        )

        const data = response?.data

        if (!data) return
        if (Object.keys(data).length) {
          setallTask(data)

          setActive(data.activeTasksInfo)
          setActiveList(data.activeTasksInfo.list)

          setTraffic(data.trafficTaskInfo)
          setTrafficList(data.trafficTaskInfo.list)

          setType(data.typeTreatmentInfo)
          setTypeList(data.typeTreatmentInfo.list)

          setCancel(data.canceledTasksInfo)
          setCancelList(data.canceledTasksInfo.list)
        } else {
          setallTask(data)
          setActive(0)
          setActiveList([])

          setTraffic(0)
          setTrafficList([])

          setType(0)
          setTypeList([])

          setCancel(0)
          setCancelList([])
        }
      } finally {
        setLoading(false)
      }
    }
    if (proccess_id) getAllTask()
  }, [proccess_id, period, dispatch])

  return (
    <>
      {
        <div style={{ marginTop: 20, padding: '0 40px 40px' }}>
          <Form
            form={form}
            layout='inline'
            initialValues={{
              proccess_id: data[0]?.name,
              period: [moment().startOf('month'), moment()],
            }}
          >
            <Form.Item name='proccess_id'>
              {!loadingForSelect ? (
                <Select style={{ width: 250 }}>
                  {data?.map((i) => {
                    return (
                      <Select.Option key={i.id} value={i.id}>
                        {i.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              ) : (
                <Button
                  style={{ width: 250 }}
                  icon={<Spin indicator={<LoadingOutlined />} />}
                />
              )}
            </Form.Item>
            <Form.Item name='period'>
              <RangePicker
                style={{ height: '32px', width: '260px' }}
                format='DD.MM.YYYY'
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(0, 'day'))
                }
              />
            </Form.Item>
          </Form>
          <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
            <Col span={12}>
              <Card
                title={t('statistics.funnelOfActiveRequests.title')}
                bodyStyle={{ minHeight: 223 }}
              >
                {!loading ? (
                  <>
                    <div className='chart_text'>
                      <span className='bold'>
                        {`${active?.total || 0} ${t(
                          'statistics.activeTasksCount'
                        )}`}
                      </span>
                    </div>
                    {active?.total ? (
                      <Funnel {...funnel_config(activeList)} />
                    ) : (
                      <Empty />
                    )}
                  </>
                ) : (
                  <Loading spinner />
                )}
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title={t('statistics.requestTraffic.title')}
                bodyStyle={{ minHeight: 223 }}
              >
                {!loading ? (
                  <>
                    <div className='chart_text'>
                      <span className='bold'>
                        {traffic?.total ? traffic?.total : '0'}
                      </span>{' '}
                      {t('statistics.tasksCount')}
                    </div>
                    {traffic?.total ? (
                      <Pie {...pie_config(trafficList)} />
                    ) : (
                      <Empty />
                    )}
                  </>
                ) : (
                  <Loading spinner />
                )}
              </Card>

              <Card
                style={{ marginTop: 20 }}
                title={t('statistics.caseTypes.title')}
                bodyStyle={{ minHeight: 223 }}
              >
                {!loading ? (
                  <>
                    <div className='chart_text'>
                      <span className='bold'>
                        {type?.total ? type?.total : '0'}
                      </span>{' '}
                      {t('statistics.tasksCount')}
                    </div>
                    {type?.total ? (
                      <Bar {...bar_config(typeList)} />
                    ) : (
                      <Empty />
                    )}
                  </>
                ) : (
                  <Loading spinner />
                )}
              </Card>
            </Col>
          </Row>
          <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
            <Col span={24}>
              <Card
                title={t('statistics.reasonsForRejection.title')}
                bodyStyle={{ minHeight: 223 }}
              >
                {!loading ? (
                  <>
                    <div className='chart_text'>
                      <span className='bold'>
                        {cancel?.total ? cancel?.total : '0'}
                      </span>{' '}
                      {t('statistics.cancelledTasksCount')}
                    </div>
                    {cancel?.total ? (
                      <Column {...column_config(cancelList)} />
                    ) : (
                      <Empty />
                    )}
                  </>
                ) : (
                  <Loading spinner />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      }
    </>
  )
}

export default Created
