import { useEffect, useState, createContext, useContext } from 'react'
import { Row, Col } from 'antd'
import { Table, PausedBox, TaskCreate } from './components.settings'
import ClientDrawer from '@ui/ClientDrawer'

export const TasksContext = createContext()

const Tasks = () => {
  const [open, setOpen] = useState(false)
  const [callClientInfo, setCallClientInfo] = useState()

  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [clientInfo, setClientInfo] = useState(null)
  const [visibleHistory, setVisibleHistory] = useState(false)
  const [history, setHistory] = useState(null)

  useEffect(() => {
    if (!open) setCallClientInfo(undefined)
  }, [open])

  return (
    <div style={{ padding: '0 24px', marginTop: 24 }}>
      <TasksContext.Provider
        value={{
          setOpen,
          setCallClientInfo,
          setVisibleDrawer,
          setClientInfo,
          setHistory,
          setVisibleHistory,
          open,
          callClientInfo,
          visibleDrawer,
          clientInfo,
          history,
          visibleHistory,
        }}
      >
        <Row justify='center' wrap={false} gutter={24}>
          <Col style={{ width: 'calc(100% - 378px)' }}>
            <Table />
          </Col>
          <Col>
            <PausedBox />
          </Col>
        </Row>
        <TaskCreate />
        <ClientDrawer
          visibleDrawer={visibleDrawer}
          setVisibleDrawer={setVisibleDrawer}
          clientInfo={clientInfo}
          setClientInfo={setClientInfo}
          setHistory={setHistory}
          history={history}
          visibleHistory={visibleHistory}
          setVisibleHistory={setVisibleHistory}
        />
      </TasksContext.Provider>
    </div>
  )
}

export default Tasks
