import { memo } from 'react'
import { Checkbox, Image, Typography } from 'antd'
import './styles.less'

const SelectedElement = memo((props) => {
  const { element } = props

  return (
    <div className={'selected-element'}>
      {element ? (
        <div className={'selected-element--content'}>
          <Typography.Text
            strong
            className={'selected-element--content--title'}
          >
            {element.name}
          </Typography.Text>

          {element.lkp && (
            <div className={'selected-element--lkp'}>
              <Typography.Text>{element.lkp}</Typography.Text>
            </div>
          )}

          {element.carConfirmations && (
            <div className={'selected-element--checkBoxes'}>
              {element.carConfirmations.map((item) => {
                return (
                  <div style={{ margin: '0 5px 0 0' }} key={item.name}>
                    <Checkbox
                      defaultChecked
                      disabled
                      style={{ margin: '0 10px 0 0' }}
                    />
                    <Typography.Text>{item.name} </Typography.Text>
                  </div>
                )
              })}
            </div>
          )}

          <div className={'selected-element--comment'}>
            <Typography.Text className={'selected-element--comment--title'}>
              Комментарий:
            </Typography.Text>
            {element.comment && (
              <Typography.Text className={'selected-element--comment--text'}>
                {element.comment}
              </Typography.Text>
            )}
          </div>
          {element.photos && (
            <div className={'selected-element--photo'}>
              {element.photos.map((photo) => {
                return (
                  <Image
                    key={photo.photo_id}
                    id={photo.photo_id}
                    src={photo.photo}
                    className={'selected-element--photo--block'}
                  />
                )
              })}
            </div>
          )}
        </div>
      ) : (
        <div className={'selected-element--not-select'}>
          <Typography.Text style={{ fontSize: '14px', color: '#697186' }}>
            Выберите область для просмотра полной информации о неисправности
          </Typography.Text>
        </div>
      )}
    </div>
  )
})

export default SelectedElement
