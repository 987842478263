import React, { useEffect, useState, useContext, useImperativeHandle } from 'react'
import { forwardRef } from 'react'
import './index.less'
import { ModuleContext } from '../../../index'
import { Button, message } from 'antd'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import { CheckCircleOutlined } from '@ant-design/icons'
import { modules } from '@/constants'

const DocumentsFormation = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const { module, taskData, currentNeeds, cardTitle, needsData, setNeedsData } =
    useContext(ModuleContext)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)

  useEffect(() => {
    if (currentNeeds !== undefined) {
      if (currentNeeds.meta?.documents) {
        setStatus(true)
      }
    }
  }, [currentNeeds])

  useImperativeHandle(
    ref,
    () => ({
      getStatus: async () => {
        if (module?.isRequired) return status
        else return true
      },
    }),
    [status]
  )

  const sendNeeds = async () => {
    await dispatch(
      authorizationLayer({
        url: `needs`,
        method: 'POST',
        data: {
          taskId: taskData?.id,
          moduleId: module?.id,
          mainTaskId: taskData?.mainTaskId,
          needs: [
            {
              type: modules.DOC_FORMATION,
              action: 'choice',
              meta: {
                taskId: taskData?.id,
                documents: module?.settings?.documents,
              },
            },
          ],
        },
      })
    )
      .then(() => {
        setStatus(true)
      })
      .finally(() => setLoading(false))
  }

  const showCommercialProposal = async() => {
    setLoading(true)
    for(let i = 0; i <= module?.settings?.documents.length; i++){
      await dispatch(
        authorizationLayer({
          url: `documentation-flow/${taskData.mainTaskId}/${module?.settings?.documents[i].id}/kp`,
          method: 'get'
        })
      ).then((res) => {
        if (res !== undefined){
          let pdfInBase64 = res.data.data.base64;

          let URL = window.URL || window.webkitURL,
            byteChars = window.atob(pdfInBase64),
            bytes = [],
            i = 0;

          for (; i < byteChars.length; i++)
            bytes[i] = byteChars.charCodeAt(i);

          let blob = new Blob([new Uint8Array(bytes)], {type: 'application/pdf'});
          let downloadUrl = URL.createObjectURL(blob);

          let fileLink = document.createElement('a');
          fileLink.href = downloadUrl;
          fileLink.download = taskData?.inner_id + '.pdf';
          fileLink.click();

          //TODO Открытие новой вкладки с отображением документа
          // if(window.navigator && window.navigator.msSaveOrOpenBlob)
          //   window.navigator.msSaveOrOpenBlob(blob);
          // else
          // {
          //   let newWin = window.open(downloadUrl, 'width=800,height=800,menubar=yes,scrollbars=yes,status=yes,resizable=yes');
          //   newWin.focus();
          //   URL.revokeObjectURL(downloadUrl);
          // }
          setStatus(true)
          sendNeeds()
        } else {
          message.error('Не все данные заполнены для формирования документов');
        }
      }).finally(() => setLoading(false))
    }
  }

  return (
    <div>
      {cardTitle}
      <Button
        ghost
        shape='round'
        type='primary'
        loading={loading}
        onClick={() => showCommercialProposal()}
      >
        Сформировать
      </Button>
      <div className='information'>
        {status ? (
          <>
            <CheckCircleOutlined style={{ fontSize: 16, color: '#3BD378' }} />
            <span className='information__title'>Документы сформированы</span>
          </>
        ) : null}
      </div>
    </div>
  )
})

export default DocumentsFormation
