import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { authorizationLayer, SET_APP } from '@store/actions/methods'

import _ from 'lodash'
import { SSE } from '@utils'
import { modules } from '@/constants'

import useBrands from './hooks/useBrands'
import useCarsColors from './hooks/useCarsColors'
import useDefectsByCategories from './hooks/useDefectsByCategories'

import { ClientCard, Confirm, Modules } from './components'
import Loading from '@ui/Loading'

export const TaskContext = createContext()

const Task = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const tasks = useSelector((state) => state.app.tasks)
  const user = useSelector((state) => state.app.user)
  const [loading, setLoading] = useState(false)
  const [taskData, setTaskData] = useState(null)
  const [visibleTaskData, setVisibleTaskData] = useState(null)
  const [needsData, setNeedsData] = useState([])
  const [cancelReasons, setCancelReasons] = useState([])
  const [currentStageData, setCurrentStageData] = useState({})
  const { onChangeBrands } = useBrands()
  const { getExterior, getInterior, getInteriorTypes } = useCarsColors()
  const { getCategories, getCarDetails, getDefects } = useDefectsByCategories()

  const [state, setState] = useState({
    showTaskHistoryModal: false,
    allModules: [],
    mainTaskId: null,
  })

  const TASK_UPDATED = `TASK_UPDATED_${id}`
  const sse = useRef()

  useEffect(() => {
    if (taskData?.mainTaskId !== taskData?.id) {
      setVisibleTaskData(() => ({
        ...taskData,
        process: {
          ...taskData.process,
          stages: taskData?.process?.stages?.filter((item) => {
            const hiddenStage = item?.modules?.filter(
              (module) => module?.isHidden
            )
            return hiddenStage?.length < item?.modules?.length
          }),
        },
      }))
    } else {
      setVisibleTaskData(() => taskData)
    }
  }, [taskData])

  const onUpdatedTaskData = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event
    setTaskData(() => ({ ...data }))

    let index = _.findIndex(tasks, (i) => i.id === data?.id)

    if (index !== -1) {
      tasks[index] = data

      dispatch(SET_APP(['tasks'], [...tasks]))
    }
  }

  useEffect(() => {
    const sse_init = async () => {
      sse.current = await dispatch(SSE())
      sse.current.addEventListener(TASK_UPDATED, onUpdatedTaskData)
    }
    sse_init()
    return () => {
      if (sse.current) {
        sse.current.removeEventListener(TASK_UPDATED, onUpdatedTaskData)
      }
    }
  }, [])

  const getTaskData = async () => {
    setLoading(true)
    try {
      let modulesData = []
      const { data: taskData } = await dispatch(
        authorizationLayer({
          url: `processes/tasks/${id}`,
        })
      )

      const { mainTaskId, process, currentStage } = taskData.data

      const { data: allProcess } = await dispatch(
        authorizationLayer({
          url: `processes/tasks/${mainTaskId}/get-processes-by-main-task-id`,
        })
      )

      const { data: needsData } = await dispatch(
        authorizationLayer({
          url: `needs/${mainTaskId}`,
        })
      )

      if (allProcess.data.length) {
        const resultList = allProcess.data.map((item) => {
          return item.stages.reduce((result, elem) => {
            return result.concat(elem.modules)
          }, [])
        })
        modulesData = [].concat(...resultList)
      }

      if (process.stages) {
        const res = process.stages.reduce((result, item) => {
          return result.concat(item.modules)
        }, [])
        modulesData = [...modulesData, ...res]
      }

      setNeedsData(needsData.data)
      setTaskData(taskData.data)
      setState({ ...state, allModules: modulesData, mainTaskId: mainTaskId })
      setCurrentStageData(currentStage)
      setCancelReasons(currentStage?.cancelReasons)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTaskData()
    return () => {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (
      taskData?.currentStage?.modules?.filter(
        (i) => i.component.componentType.name === modules.CAR_RECEPTION
      ).length
    ) {
      onChangeBrands()
      getExterior()
      getInterior()
      getInteriorTypes()
      getDefects()
      getCategories().then((res) => {
        getCarDetails(
          'external',
          res.find((i) => i.short_name === 'external').id
        )
        getCarDetails(
          'internal',
          res.find((i) => i.short_name === 'internal').id
        )
      })
    }
  }, [taskData])

  const showTaskHistory = () => {
    setState({ ...state, showTaskHistoryModal: true })
  }
  const closeTaskHistory = () => {
    setState({ ...state, showTaskHistoryModal: false })
  }
  return (
    <div style={{ display: 'flex', padding: '0 0 0 24px' }}>
      {loading && <Loading spinner={true} />}
      <TaskContext.Provider
        value={{
          id,
          loading,
          taskData,
          visibleTaskData,
          needsData,
          cancelReasons,
          currentStageData,
          setCurrentStageData,
          setTaskData,
          setVisibleTaskData,
          setNeedsData,
          mainTaskId: state.mainTaskId,
          allModules: state.allModules,
        }}
      >
        {taskData?.coordinationUsers?.length ||
        taskData?.status?.code === 'CANCELLED' ||
        taskData?.status?.code === 'COMPLETED' ||
        taskData?.userId !== user?.id ? (
          <Confirm
            showTaskHistoryModal={state.showTaskHistoryModal}
            closeTaskHistory={closeTaskHistory}
          />
        ) : (
          <Modules
            showTaskHistoryModal={state.showTaskHistoryModal}
            closeTaskHistory={closeTaskHistory}
          />
        )}
        <ClientCard
          loading={loading}
          taskData={taskData}
          id={id}
          setTaskData={setTaskData}
          showTaskHistory={showTaskHistory}
        />
      </TaskContext.Provider>
    </div>
  )
}

export default Task
