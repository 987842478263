import React from 'react'
import _ from 'lodash'
import { Typography, Row, Col, Space, Card } from 'antd'
import { Image, Skeleton, Tag, Divider, Collapse } from 'antd'
import { numberWithSpaces, onCloseModal } from '@utils'
import { useTranslation } from 'react-i18next'
import { stockTypes } from '@/constants'

const { Title, Text } = Typography

const { Panel } = Collapse

const textStyle = {
  fontSize: 16,
}

const PartsDetails = (props) => {
  const {
    loading,
    spareData,
    mainImg,
    setVisible,
    visible,
    activePhoto,
    currentImage,
  } = props
  const { t } = useTranslation()
  return (
    <Card bodyStyle={{ padding: '40px 80px 104px' }}>
      <Row justify='space-between' wrap={false}>
        {!loading ? (
          <Col flex='600px'>
            <Space direction='vertical' size={8}>
              <Title level={3}>{spareData?.name}</Title>
              <Title level={3}>{`${numberWithSpaces(spareData?.price)}`}</Title>
              {spareData?.sparePartCategory && (
                <Text style={textStyle}>
                  {t('productDetails.parts.category')}:
                  {
                    <Text style={textStyle} strong>
                      {spareData?.sparePartCategory?.name}
                    </Text>
                  }
                </Text>
              )}
              <Text style={textStyle}>
                {t('productDetails.parts.article')}: {''}
                {
                  <Text style={textStyle} strong>
                    {spareData?.article_number}
                  </Text>
                }
              </Text>
              <Text style={{ color: '#3BD378', fontSize: 16 }} strong>
                {t('productDetails.parts.inStock')}: {spareData?.amount}
              </Text>
              <Tag
                style={
                  spareData?.type === stockTypes.NEW_AUTO
                    ? {
                        background: '#E6F7FF',
                        border: '1px solid #91D5FF',
                        borderRadius: '2px',
                        color: '#0989FF',
                      }
                    : {
                        background: '#FFF7E6',
                        border: '1px solid #FFD591',
                        borderRadius: '2px',
                        color: '#FA8C16',
                      }
                }
              >
                {spareData?.type === stockTypes.NEW_AUTO
                  ? t('productDetails.parts.newCondition')
                  : t('productDetails.parts.usedCondition')}
              </Tag>
            </Space>
            {spareData?.sparePartCarRelationships?.length ? (
              <>
                <Divider style={{ margin: '16px 0' }} />

                <Collapse
                  bordered={false}
                  style={{ background: 'transparent' }}
                >
                  <Panel
                    header={
                      <Title level={5}>
                        {t('productDetails.parts.brandAndModels')}
                      </Title>
                    }
                    extra={
                      <Text type='secondary'>
                        {spareData?.sparePartCarRelationships?.length}
                      </Text>
                    }
                  >
                    <Space direction='vertical' size={8}>
                      {spareData?.sparePartCarRelationships?.map(
                        (item, index) => {
                          return (
                            <Space size={8} key={index}>
                              <Text style={textStyle}>
                                • {item.carMark?.name}
                              </Text>
                              <Text style={textStyle}>
                                {item.carModel?.name}
                              </Text>
                              {item.carGeneration && (
                                <Text type='secondary' style={textStyle}>
                                  {item.carGeneration?.name} ({' '}
                                  {item.carGeneration?.year_begin} -{' '}
                                  {item.carGeneration?.year_end ===
                                  new Date().getFullYear()
                                    ? t('productDetails.parts.present')
                                    : item.carGeneration?.year_end}{' '}
                                  )
                                </Text>
                              )}
                            </Space>
                          )
                        }
                      )}
                    </Space>
                  </Panel>
                </Collapse>
              </>
            ) : (
              ''
            )}
            {spareData?.description ? (
              <>
                <Divider style={{ margin: '16px 0' }} />
                <Space direction='vertical' size={8}>
                  <Title level={5}>{t('productDetails.parts.desc')} </Title>
                  <div
                    style={{
                      padding: '12px 20px 12px 16px',
                      background: '#F8F8F8',
                      borderRadius: 6,
                      maxWidth: 600,
                    }}
                  >
                    {spareData?.description}
                  </div>
                </Space>
              </>
            ) : (
              ''
            )}
          </Col>
        ) : (
          <Space direction='vertical' size={41} style={{ width: '50%' }}>
            <Skeleton active paragraph={{ rows: 1 }} />
            <Space size={20}>
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
            </Space>
            <Skeleton active />
          </Space>
        )}
        {!loading ? (
          <Col flex='600px'>
            <div
              style={{
                width: 600,
                height: 316,
              }}
            >
              {mainImg}
            </div>
            <div className='images-list'>
              {spareData?.sparePartPhotos
                ?.map((item, index) => {
                  return (
                    <div
                      className={
                        activePhoto === index
                          ? 'images-item active'
                          : 'images-item'
                      }
                      key={item.id}
                      onClick={() => currentImage(item.id)}
                    >
                      <img
                        key={item.id}
                        src={item.photo}
                        alt='car'
                        width='100%'
                      />
                    </div>
                  )
                })
                .slice(0, 5)}

              {spareData?.sparePartPhotos?.length > 5 ? (
                <div
                  className='images-gallery'
                  onClick={() => setVisible(true)}
                >
                  <Text
                    className='images-gallery__text'
                    onClick={() => setVisible(true)}
                  >
                    +{spareData?.sparePartPhotos?.length - 5} фото
                  </Text>
                  <Image.PreviewGroup
                    preview={{
                      visible,
                      onVisibleChange: (value) => {
                        setVisible(value)
                      },
                    }}
                  >
                    {spareData?.sparePartPhotos?.map((item) => {
                      return <Image key={item.id} src={item.photo} />
                    })}
                  </Image.PreviewGroup>
                  <div
                    className='images-gallery__bg'
                    onClick={() => setVisible(true)}
                  ></div>
                </div>
              ) : (
                ''
              )}
            </div>
          </Col>
        ) : (
          <Space direction='vertical' size={40}>
            <Skeleton.Image active style={{ width: 600, height: 316 }} />
          </Space>
        )}
      </Row>
    </Card>
  )
}

export default PartsDetails
