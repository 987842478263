import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ModuleContextConfirm } from '../../render'
import { Typography, Divider, Row, Col, Timeline } from 'antd'
import _ from 'lodash'
import { numberWithSpaces } from '@utils'
import { modules, stockTypes } from '@/constants'
import { useTranslation } from 'react-i18next'
import './index.less'
import { ChoiceCar, Inspection, CheckList, TestDrive } from './components'

const CarReception = () => {
  const { data } = useContext(ModuleContextConfirm)
  const { module, key } = data
  const { needs, moduleItem } = module
  const dispatch = useDispatch()
  const [sortedNeeds, setSortedNeeds] = useState([])
  const { t } = useTranslation()

  const sorted = [
    {
      key: 'choiceCar',
      translation: t(
        'task.moduleRender.module.carReception.tab.charachteristics'
      ),
    },
    {
      key: 'externalInspection',
      translation: t(
        'task.moduleRender.module.carReception.tab.exteriorInspection'
      ),
    },
    {
      key: 'internalInspection',
      translation: t(
        'task.moduleRender.module.carReception.tab.interiorInspection'
      ),
    },
    {
      key: 'checkList',
      translation: t('task.moduleRender.module.carReception.tab.options'),
    },
    {
      key: 'testDrive',
      translation: t('task.moduleRender.module.carReception.tab.testDrive'),
    },
  ]

  useEffect(() => {
    if (needs?.length) {
      needs?.forEach((item) => {
        sorted.forEach((i, index) => {
          if (item.subType === i.key) {
            sortedNeeds[index] = item
            sortedNeeds[index].translation = i.translation
          }
        })
      })

      setSortedNeeds(sortedNeeds)
    }
  }, [needs])

  return (
    <>
      <Row justify='space-between'>
        <Col>
          <Typography.Title level={5}>
            {t('task.actionHistory.commentType.carReception')}
          </Typography.Title>{' '}
        </Col>
      </Row>
      <Divider />
      <Timeline>
        {sorted?.map((i) => {
          let content = null
          const choiceCarData = sortedNeeds?.find(
            (i) => i.subType === 'choiceCar'
          )?.meta
          const currentNeeds = sortedNeeds?.find(
            (item) => item?.subType === i.key
          )?.meta

          if (i.key === 'choiceCar')
            content = (
              <ChoiceCar
                data={{
                  meta: currentNeeds,
                  title: i.translation,
                }}
              />
            )
          else if (
            i.key === 'externalInspection' ||
            i.key === 'internalInspection'
          )
            content = (
              <Inspection
                data={{
                  meta: currentNeeds,
                  title: i.translation,
                  choiceCarData,
                  type:
                    i.key === 'externalInspection' ? 'external' : 'internal',
                }}
              />
            )
          else if (i.key === 'checkList')
            content = (
              <CheckList
                data={{
                  meta: currentNeeds,
                  title: i.translation,
                  choiceCarData,
                }}
              />
            )
          else if (i.key === 'testDrive')
            content = (
              <TestDrive
                data={{
                  meta: currentNeeds,
                  title: i.translation,
                }}
              />
            )

          return (
            <Timeline.Item
              className='confirm-car-reception__timeline'
              key={i.key}
            >
              {content}
            </Timeline.Item>
          )
        })}
      </Timeline>
    </>
  )
}

export default CarReception
