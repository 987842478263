import { Typography } from 'antd'

export const CarReceptionItemWrapper = (props) => {
  const { children, titleItem, id } = props
  return (
    <div id={id}>
      <Typography.Text style={{ fontSize: '16px' }}>
        {titleItem}
      </Typography.Text>
      <div>{children}</div>
    </div>
  )
}
