import { Form, Select, Typography } from 'antd'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { CharacteristicsContext } from '../..'
import { useTranslation } from 'react-i18next'

const Colors = () => {
  const { exteriorColors, interiorColors, interiorTypes } = useSelector((state) => state.app)
  const { updateInfo, brandId, modelId } = useContext(CharacteristicsContext)
  const { t } = useTranslation()

  return (
    <>
      <Typography.Title
        level={5}
        style={{ marginBottom: '20px', fontWeight: 500 }}
      >
        {t('task.moduleRender.module.carReception.colors')}
      </Typography.Title>
      <Form.Item
        label={t('warehouse.tab.cars.column.bodyColor')}
        name='exterior_color'
        rules={[
          {
            required: true,
            message: t('common.input.requiredMessage'),
          },
        ]}
        style={{ maxWidth: 400 }}
      >
        <Select onChange={(e) => updateInfo(brandId, modelId)}>
          {exteriorColors?.map((i) => {
            return (
              <Select.Option key={i.id} value={i.id}>
                <div id={i.id}> {i.name}</div>
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={t('warehouse.tab.cars.column.interiorColor')}
        name='interior_color'
        rules={[
          {
            required: true,
            message: t('common.input.requiredMessage'),
          },
        ]}
        style={{ maxWidth: 400 }}
      >
        <Select
          onChange={(e) => {
            updateInfo(brandId, modelId)
            document
              .getElementById('interior_type')
              .scrollIntoView({ behavior: 'smooth' }, true)
          }}
        >
          {interiorColors?.map((i) => {
            return (
              <Select.Option key={i.id} value={i.id}>
                <div id={i.id}> {i.name}</div>
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label='Тип салона'
        name='interior_type'
        rules={[
          {
            required: true,
            message: t('common.input.requiredMessage'),
          },
        ]}
        style={{ maxWidth: 400 }}
      >
        <Select
          onChange={(e) => {
            updateInfo(brandId, modelId)
          }}
        >
          {interiorTypes?.map((i) => {
            return (
              <Select.Option key={i.id} value={i.id}>
                <div id={i.id}> {i.name}</div>
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    </>
  )
}

export default Colors
