import React from 'react'

import { Typography, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const MainTab = ({ ...props }) => {
  const data = props.productData
  const { t } = useTranslation()

  const content = [
    {
      id: 0,
      title: t('productDetails.cars.vin'),
      desc: data?.vin_code,
    },
    {
      id: 1,
      title: t('productDetails.cars.year'),
      desc: data?.year,
    },
    {
      id: 2,
      title: t('productDetails.cars.body'),
      desc: data?.carBodyType?.name,
    },
    {
      id: 3,
      title: t('productDetails.cars.generation'),
      desc: data?.carGeneration?.name,
    },
    {
      id: 4,
      title: t('productDetails.cars.engine'),
      desc: data?.carEngineType?.value,
    },
    {
      id: 5,
      title: t('productDetails.cars.drive'),
      desc: data?.carDriveType?.value,
    },
    {
      id: 6,
      title: t('productDetails.cars.transmission'),
      desc: data?.carGearboxType?.value,
    },
    {
      id: 7,
      title: t('productDetails.cars.rudder'),
      desc:
        data?.steering_wheel === 'left'
          ? t('productDetails.cars.left')
          : t('productDetails.cars.right'),
    },
    {
      id: 8,
      title: t('productDetails.cars.modification'),
      desc: data?.carModification
        ? `${data?.carModification?.engine_capacity} ${data?.carModification?.power} ${data?.carModification?.description}`
        : '',
    },
  ]

  return (
    <>
      {content.map((item) => {
        return (
          <Row
            justify='space-between'
            key={item.id}
            style={{ padding: '12px 16px' }}
          >
            <Col>
              <Text type='secondary'>{item.title}</Text>
            </Col>
            <Col>
              <Text>{item.desc}</Text>
            </Col>
          </Row>
        )
      })}
    </>
  )
}

export default MainTab
