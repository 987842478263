import { Empty, Space, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const CheckList = ({ data }) => {
  const { meta, title, choiceCarData } = data
  const { t } = useTranslation()

  useEffect(() => {
    meta?.forEach((item) => {
      item.options.forEach((innerItem) => {
        const filteredArray = item.options.filter(
          (j) => j.type === 'multi_select' && j.name === innerItem.name
        )

        let values = []

        filteredArray.forEach((k) => {
          values.push(k.description)
        })

        if (innerItem.type === 'multi_select') {
          innerItem.values = values
        }
      })

      item.options = _.uniqBy(item.options, 'name')
    })
  }, [meta])

  return (
    <>
      <Space direction='vertical' size={8} style={{ width: '100%' }}>
        <Typography.Title style={{ fontWeight: 400 }} level={5}>
          {title}
        </Typography.Title>
        {choiceCarData && (
          <Typography.Text strong>
            {t('task.actionHistory.modal.checkList.comment')}:
          </Typography.Text>
        )}
        <Typography.Text>{choiceCarData?.additional_option}</Typography.Text>
        {meta?.length ? (
          meta?.map((i) => {
            return (
              <React.Fragment key={i.id}>
                <Typography.Text strong>{i.name}</Typography.Text>
                <Typography.Text>
                  {i.options.map((i) => {
                    if (i.type === 'select') {
                      return (
                        <React.Fragment key={i.id}>
                          {i.name}: {i.description}.{' '}
                        </React.Fragment>
                      )
                    } else if (i.type === 'multi_select') {
                      return (
                        <React.Fragment key={i.id}>
                          {i.name}: {i.values?.join(' , ')}.{' '}
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <React.Fragment key={i.id}>
                          {i.description},{' '}
                        </React.Fragment>
                      )
                    }
                  })}
                </Typography.Text>
              </React.Fragment>
            )
          })
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Empty />
          </div>
        )}
      </Space>
    </>
  )
}

export default CheckList
