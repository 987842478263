import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import {
  Button,
  Space,
  Alert,
  Dropdown,
  Menu,
  Slider,
  Typography,
  Row,
  Col,
} from 'antd'
import { Form, Input } from 'antd'
import InputMask from 'react-input-mask'
import { parsePhoneNumber } from 'awesome-phonenumber'
import Loading from '@ui/Loading'
import ClientDrawer from '@ui/ClientDrawer'
import { set } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ExclamationCircleOutlined } from '@ant-design/icons'
const ClientInfo = ({ data }) => {
  const [form] = Form.useForm()
  const { taskData, client, setClient, setTaskData, id } = data
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState(false)
  const [error, setError] = useState(null)
  const [currentStep, setCurrentStep] = useState(1)
  const [beforeBindingVisible, setBeforeBindingVisible] = useState(false)
  const [beforeBindingData, setBeforeBindingData] = useState(null)

  let tel = Form.useWatch('tel', form)

  useEffect(() => {
    if (taskData?.client) {
      setClient(taskData?.client)
    }
  }, [taskData, setClient])

  const handleSearchModal = async (phoneNumber) => {
    if (!phoneNumber) return
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `clients/phone/${phoneNumber}`,
        method: 'get',
      })
    )
      .then(async (res) => {
        if (res.data) {
          setBeforeBindingVisible(true)
          setBeforeBindingData(res.data)
          // setClient(res.data)
          // if (!taskData.clientId) {
          //   setClient(res.data)
          //   await dispatch(
          //     authorizationLayer({
          //       url: 'processes/tasks/binding-client-to-task',
          //       method: 'POST',
          //       data: {
          //         taskId: taskData?.id,
          //         clientId: res.data?.id,
          //       },
          //     })
          //   ).then(
          //     async () =>
          //       await dispatch(
          //         authorizationLayer({
          //           url: `processes/tasks/${id}`,
          //         })
          //       ).then((res) => setTaskData(res?.data?.data))
          //   )
          // }
        } else {
          setCurrentStep(2)
          form.setFieldsValue({ name: taskData?.clientName })
        }
      })
      .finally(() => setLoading(false))
  }

  const bindingNumberToLinked = async (cliendId, action) => {
    await dispatch(
      authorizationLayer({
        url: 'processes/tasks/binding-client-to-task',
        method: 'POST',
        data: {
          taskId: taskData?.id,
          clientId: cliendId,
        },
      })
    )
      .then(async () => {
        if (action) {
          await dispatch(
            authorizationLayer({
              url: `clients/${beforeBindingData?.id}`,
              method: 'patch',
              data: {
                name: taskData?.clientName,
              },
            })
          )
        }
      })
      .finally(
        async () =>
          await dispatch(
            authorizationLayer({
              url: `processes/tasks/${id}`,
            })
          ).then((res) => setTaskData(res?.data?.data))
      )
  }

  const handleCreate = async (phoneNumber) => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `clients`,
        method: 'post',
        data: {
          phone_number: phoneNumber,
          name: form.getFieldValue('name') || '',
        },
      })
    )
      .then(async (res) => {
        setClient(res.data)
        await dispatch(
          authorizationLayer({
            url: 'processes/tasks/binding-client-to-task',
            method: 'POST',
            data: {
              taskId: taskData?.id,
              clientId: res.data.id,
            },
          })
        ).then(() =>
          setTaskData({
            ...taskData,
            clientId: res.data.id,
            client: res.data,
          })
        )
      })
      .finally(() => setLoading(false))
  }
  const onFinish = (values) => {
    const phone = values.tel.replace(/\s/g, '')
    const phoneNumber = values.tel.replace(/[^0-9]/g, '')
    const pn = parsePhoneNumber(phone)
    setIsValid(pn.isValid())
    if (!pn.isValid() && phoneNumber.length === 11) {
      setError({
        validateStatus: 'error',
        help: t('clientCard.phoneValidate'),
      })
    } else {
      setError(null)
    }

    if (!taskData?.client && pn.isValid() && currentStep === 1) {
      handleSearchModal(phoneNumber)
    } else if (currentStep === 2) handleCreate(phoneNumber)
  }
  const getNumber = (item) => {
    return item?.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      '$1 $2 $3 $4 $5'
    )
  }
  const actionDropdownMenu = () => {
    return (
      <Menu
        items={[
          {
            label: (
              <span
                onClick={() => {
                  deleteClient()
                }}
              >
                {t('request.clientInfo.unlinkClient')}
              </span>
            ),
            key: 1,
          },
        ]}
      />
    )
  }
  // const deleteClient = async () => {
  //   // setLoading(true)
  //   // await dispatch(
  //   //   authorizationLayer({
  //   //     url: 'processes/tasks/binding-client-to-task',
  //   //     method: 'POST',
  //   //     data: {
  //   //       taskId: taskData?.id,
  //   //       clientId: null,
  //   //     },
  //   //   })
  //   // )
  //   // .then(async () => {
  //   // setClient(null)
  //   // setCurrentStep(1)
  //   // await dispatch(
  //   //   authorizationLayer({
  //   //     url: `processes/tasks/${id}`,
  //   //   })
  //   // ).then((res) => {
  //   // if (res.data.data) {
  //   //   setTaskData(res.data.data)
  //   // }
  //   // })
  //   // })
  //   // .finally(() => {
  //   // setLoading(false)
  //   // })
  // }
  const deleteClient = async () => {
    form.setFieldsValue({ tel: null })
    setTaskData({
      ...taskData,
      clientId: null,
      client: null,
    })
    setClient(null)
    setCurrentStep(1)
  }

  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [clientInfo, setClientInfo] = useState()
  const openClientCard = (item) => {
    setClientInfo(item)
    setVisibleDrawer(true)
  }

  const wpIcon = () => {
    let result = null
    if (client?.dealer_meta?.whatsapp_icon) {
      result = <a
        target='_blank'
        rel='noreferrer'
        className='task__wpp-btn'
        href={`https://api.whatsapp.com/send?phone=${client?.phone_number}`}
      >
        <img
          className='task__img'
          src='https://minio.jcloud.kz/bertha-development/813c19fa-f5a2-46ef-b3bd-7023802aa489-whatsapp.svg'
          alt='whatsapp'
        />
      </a>
    }
    return result
  }

  return (
    <div>
      {loading && <Loading spinner={true} />}
      {!client ? (
        <Form
          form={form}
          layout='vertical'
          autoComplete='off'
          onFinish={onFinish}
          style={{ width: '100%' }}
          className='task__form'
        >
          <div className='task__client-input-wrap'>
            {!taskData?.client && (
              <Typography.Title level={5} style={{ marginBottom: 20 }}>
                {taskData?.clientName}
              </Typography.Title>
            )}
            <Form.Item name='tel' {...error}>
              <InputMask mask='+7 999 999 99 99' maskChar={null} autoFocus>
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    placeholder={t(
                      'clientCard.addContacts.form.phonePlaceholder'
                    )}
                    className='task__client-input'
                    size='large'
                    onInput={(e) => {
                      form.setFieldsValue({ tel: e.target.value })
                      if (tel.length !== 11) setCurrentStep(1)
                    }}
                  />
                )}
              </InputMask>
            </Form.Item>
            {currentStep === 2 && (
              <Space size={13} direction='vertical' style={{ width: '100%' }}>
                <Alert
                  className='task__alert'
                  message={t('clientCard.addContacts.form.alert')}
                  type='warning'
                  showIcon
                />
                <Form.Item
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: t('clientCard.addContacts.form.nameValidate'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'clientCard.addContacts.form.namePlaceholder'
                    )}
                    className='task__client-input'
                  />
                </Form.Item>
              </Space>
            )}
            {!beforeBindingVisible ? (
              <Button
                type='primary'
                className='task__client-btn task__client-btn--find'
                htmlType='submit'
              >
                {currentStep === 1
                  ? t('clientCard.addContacts.form.searchBtn')
                  : t('clientCard.addContacts.form.saveBtn')}
              </Button>
            ) : (
              <div>
                <ExclamationCircleOutlined
                  style={{ color: '#FF9900', fontSize: 12, marginRight: 8 }}
                />
                <Space direction='vertical' size={4}>
                  <Typography.Text type='secondary'>
                    {t('task.linkedNumberText')}
                  </Typography.Text>
                  {beforeBindingData?.full_name}
                  {taskData?.clientName === null ?
                    <Typography.Text strong>
                      {t('task.changeClientNameEmpty')}
                    </Typography.Text> :
                    <Typography.Text strong>
                      {`${t('task.changeClientName')} "${
                        taskData?.clientName
                      }" ?`}
                    </Typography.Text>
                  }
                </Space>
                <Row justify='space-between' style={{ marginTop: 12 }}>
                  <Col>
                    <Button
                      shape='round'
                      onClick={() =>
                        bindingNumberToLinked(beforeBindingData?.id, false)
                      }
                    >
                      {t('task.cancelChangingName')}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      shape='round'
                      type='primary'
                      onClick={() =>
                        bindingNumberToLinked(beforeBindingData?.id, true)
                      }
                    >
                      {t('task.okChangingName')}
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Form>
      ) : (
        <div className='task__client-input-wrap'>
          <div className='task__found-name-box'>
            <div className='task__full-name'>
              {client?.full_name || client?.name ? (
                client?.full_name || client?.name
              ) : (
                <div style={{ color: '#697186', fontWeight: 400 }}>
                  {t('main.table.column.client.unnamed')}
                </div>
              )}
            </div>
            {taskData.currentModule && (
              <Dropdown
                placement='bottomLeft'
                overlay={() => actionDropdownMenu()}
                trigger={['click']}
              >
                <button className='task__btn task__btn-drop'>
                  <img
                    className='task__img'
                    src='https://minio.jcloud.kz/bertha-development/8fe46d80-ad72-4e84-a0af-94f3b0238669-threedots.svg'
                    alt='dots'
                  />
                </button>
              </Dropdown>
            )}
          </div>
          {(!client?.full_name || !client?.name) && !client?.phone_number ? (
            <div style={{ marginTop: '40px' }}></div>
          ) : (
            <div>
              {client?.phone_number ? (
                <div className='task__num-box'>
                  <p className='task__num'>
                    +{getNumber(client?.phone_number)}
                  </p>
                  {wpIcon()}
                </div>
              ) : (
                <div
                  style={{
                    color: '#697186',
                    fontWeight: 400,
                    marginTop: '20px',
                  }}
                >
                  {t('task.actionHistory.module.phoneEmpty')}
                </div>
              )}
            </div>
          )}
          <div>
            <Button
              className='task__client-card'
              onClick={() => openClientCard(taskData)}
            >
              {t('clientCard.title')}
            </Button>
            {client?.data_fullness !== 100 && (
              <div className='task__completed'>
                <p className='task__completed-text'>
                  {t('request.clientInfo.cardDataFuiel')}{' '}
                  {client?.data_fullness}%
                </p>
                <div className='task__progress'>
                  <Slider
                    className='task__slider'
                    style={{ pointerEvents: 'none' }}
                    handleStyle={{ display: 'none' }}
                    value={client?.data_fullness}
                    trackStyle={
                      client?.data_fullness <= 50
                        ? { backgroundColor: '#ff9900' }
                        : { backgroundColor: '#1890FF' }
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ClientDrawer
        visibleDrawer={visibleDrawer}
        setVisibleDrawer={setVisibleDrawer}
        clientInfo={clientInfo}
        setClientInfo={setClientInfo}
        handleSearchModal={handleSearchModal}
      />
    </div>
  )
}
export default ClientInfo