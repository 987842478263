import { Drawer, Form, Radio, Input } from 'antd'
import { Select, Button, message, Space, Tooltip } from 'antd'
import InputMask from 'react-input-mask'
import { parsePhoneNumber } from 'awesome-phonenumber'
import './index.less'
import { useState, useEffect, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { useNavigate } from 'react-router'
import useTableActions from '@views/NewTasks/hooks/useTableActions'
import { TasksContext } from '../..'
import queryString from 'query-string'
import _ from 'lodash'
import ActiveTask from './components/ActiveTask'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

const TaskCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.app)
  const { open, setOpen, callClientInfo, clean } = useContext(TasksContext)
  const { t } = useTranslation()
  const tasks = useSelector((state) => state.app.tasks)

  const [loading, setLoading] = useState(false)
  const [activeLoading, setActiveLoading] = useState(false)
  const [form] = Form.useForm()
  const [isValid, setIsValid] = useState(false)
  const [error, setError] = useState(null)
  const [processes, setProcesses] = useState([])
  const [activeTask, setActiveTask] = useState()
  const [activeTaskList, setActiveTaskList] = useState([])
  const [users, setUsers] = useState([])

  const [visible, setVisible] = useState(false)
  const { setHistory, setVisibleHistory } = useContext(TasksContext)
  const [hasMore, setHasMore] = useState(false)
  const [createData, setCreateData] = useState()
  const [clientData, setClientData] = useState(null)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  })

  const showDrawer = () => {
    setVisible(true)
  }

  const onCloseActive = () => {
    setVisible(false)
    setPagination({ current: 1, pageSize: 20 })
  }

  const process = Form.useWatch('process', form)
  const userid = Form.useWatch('user', form)
  const tel = Form.useWatch('tel', form)

  const { getTasks } = useTableActions()

  const getProcesses = async () => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: 'processes/process-user',
      })
    ).then(({ data }) => {
      if (data.success) setProcesses(data.data)
      setLoading(false)
    })
  }

  const getUsers = async () => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `processes/process-user/${process}/users`,
      })
    ).then(({ data }) => {
      if (data.success) {
        let userData = data.data
        userData?.forEach((item) => {
          if (item.id === user?.id) {
            item.full_name = t(
              'request.actionHistory.changeUser.drawer.selectCreateForMe'
            )
          }
        })

        setUsers(userData)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    getProcesses()
  }, [dispatch])

  useEffect(() => {
    if (process) {
      getUsers()
    }
  }, [process])

  useEffect(() => {
    if (users) {
      if (users?.length === 1 && users?.some((x) => x.id === user?.id)) {
        form.setFieldsValue({ user: user?.id })
      }
      form.setFieldsValue({ user: users[0]?.id })
    }
  }, [users])

  const onClose = () => {
    setOpen(false)
    form.resetFields()
  }

  const radioBtns = () => {
    const data = [
      {
        key: 'crm',
        title: t('main.table.column.caseType.visit'),
      },
      {
        key: 'call',
        title: t('main.table.column.caseType.call'),
      },
      {
        key: 'web',
        title: t('main.table.column.caseType.internet'),
      },
    ]

    return data
  }

  useEffect(() => {
    if (createData) {
      form.resetFields()
    }
  }, [createData])

  const onFinish = async (values) => {
    setLoading(true)
    let pn = undefined
    let endNumber = undefined
    if (values.tel) {
      pn = parsePhoneNumber(values.tel)
      endNumber = pn.getNumber() ? pn.getNumber() : values.tel

      if (!pn.isValid() && !callClientInfo) return
    }

    await dispatch(
      authorizationLayer({
        url: `router/create-task`,
        method: 'post',
        data: {
          processId: values.process,
          userId: values.user === 'auto' ? null : values.user,
          client_phone_number: endNumber ? (callClientInfo ? endNumber : endNumber.slice(1)) : null,
          typeTreatment: values.appeal_type,
          comment: values.comment ? values.comment : null,
          taskId: callClientInfo ? callClientInfo?.taskId : undefined,
          clientName: values.client_name,
        },
      })
    ).then(async ({ data }) => {
      if (data) message.success(t('common.request.status.completed'))
      else message.error(t('common.request.status.rejected'))

      setCreateData(data)
      setOpen(false)

      if (data.id === user?.id) navigate(`task/${data.taskId}`)

      if (callClientInfo)
        await dispatch(
          authorizationLayer({
            url: `processes/tasks/${callClientInfo?.taskId}`,
            method: 'DELETE',
          })
        ).then(({ data }) => {
          if (data.success) {
            getTasks()
            form.resetFields()
          }
        })
      setLoading(false)
    })
  }

  const checkValidateNumber = useCallback(
    _.debounce(async (e) => {
      const phone = e.replace(/\s/g, '')
      const pn = parsePhoneNumber(phone)

      setIsValid(pn.isValid())

      if (!pn.isValid() && phone) {
        setError({
          validateStatus: 'error',
          help: t('clientCard.phoneValidate'),
        })
        form.setFieldsValue({ client_name: undefined })
        setClientData(null)
      } else {
        setError(null)
        if (phone)
          await dispatch(
            authorizationLayer({
              url: `clients/phone/${phone.slice(1)}`,
              method: 'get',
            })
          ).then(({ data }) => {
            form.setFieldsValue({ client_name: data.name })
            setClientData(data)
          })
      }
    }, 500),
    []
  )

  const onChangeClientName = useCallback(
    _.debounce(async (e) => {
      if (clientData) {
        await dispatch(
          authorizationLayer({
            url: `clients/${clientData?.id}`,
            method: 'patch',
            data: {
              name: e.target.value,
            },
          })
        ).then(({ data }) => {
          let clientDataIndex = _.findIndex(
            tasks,
            (item) => item.client.id === data.id
          )

          let newTasks = tasks

          if (clientDataIndex !== -1) newTasks[clientDataIndex].client = data

          dispatch(SET_APP(['tasks'], newTasks))
        })
      }
    }, 500),
    [clientData]
  )

  useEffect(() => {
    if (!callClientInfo) form.resetFields()
    else
      form.setFieldsValue({
        appeal_type: callClientInfo?.typeTreatment,
        tel: callClientInfo?.phone_number,
      })
  }, [callClientInfo])

  const getActiveTask = async (update = false) => {
    const phone = tel ? tel?.replace(/\s/g, '') : ''
    const pn = parsePhoneNumber(phone)

    if (open && pn.isValid() && process)
      try {
        let q = {
          page: pagination.current,
          limit: pagination.pageSize,
        }

        if (process) {
          q.process_id = process
        }

        if (process && userid !== 'auto') {
          q.user_id = userid
        }

        if (pn.isValid()) {
          q.client_phone_number = phone?.slice(1)
        }

        if (Object.keys(q).length === 2) return
        setActiveLoading(true)

        const query = queryString.stringify(q)

        await dispatch(
          authorizationLayer({
            url: `processes/tasks/active-tasks/get-all?${query}`,
          })
        ).then(({ data }) => {
          if (data?.data.length === 0) setHasMore(false)
          else setHasMore(true)

          setActiveTask(data)
          if (update) {
            setActiveTaskList(data?.data)
          } else {
            setActiveTaskList([...activeTaskList, ...data?.data])
          }

          setActiveLoading(false)
        })
      } catch (e) {
        console.log(e.message)
      }
    else {
      setActiveTask([])
    }
  }

  // useEffect(() => {
  //   getActiveTask()
  // }, [process, userid, pagination.total, pagination.current])

  useEffect(() => {
    getActiveTask()
  }, [pagination.current])

  const timer = useRef()

  useEffect(() => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      getActiveTask(true)
      setPagination({ current: 1, pageSize: 20 })
    }, 500)
  }, [process, userid, tel])

  const tooltopText = () => {
    if (!activeTask?.meta?.total && !tel && !process && !userid) {
      return t('main.createTask.tooltopText')
    } else if (
      (!activeTask?.meta?.total && tel && process && userid) ||
      (!activeTask?.meta?.total && !tel && process && userid) ||
      (!activeTask?.meta?.total && tel && !process && userid)
    ) {
      return t('main.createTask.tooltopTextEmpty')
    }
  }

  return (
    <>
      <Drawer
        title={t('main.createTask.drawerTitle')}
        placement='right'
        onClose={onClose}
        visible={open}
        width={448}
        destroyOnClose={true}
        bodyStyle={{ padding: '24px 40px 40px 24px' }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={(values) => console.log(values)}
          layout='vertical'
          autoComplete='off'
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          initialValues={{
            appeal_type: 'crm',
          }}
        >
          <Form.Item
            name='appeal_type'
            label={t('main.createTask.changeCaseType')}
          >
            <Radio.Group className='task-create__radio-group'>
              {radioBtns().map((item) => {
                return (
                  <Radio.Button
                    key={item.key}
                    value={item.key}
                    className='task-create__radio-btn'
                    disabled={loading}
                  >
                    {item.title}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            {...error}
            name='tel'
            label={t('clientCard.addContacts.form.phonePlaceholder')}
          >
            <InputMask
              mask='+7 999 999 99 99'
              maskChar={null}
              style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
              placeholder='+7(___) ___-__-__'
              onChange={(e) => checkValidateNumber(e.target.value)}
              disabled={loading}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
          <Form.Item name='client_name' label={t('task.create.clientName')}>
            <Input
              style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
              placeholder={t('clientCard.formClient.namePlaceholder')}
              // onChange={(e) => onChangeClientName(e)} 
            />
          </Form.Item>
          <Form.Item
            name='process'
            label={t('main.createTask.changeProcess')}
            rules={[
              {
                required: true,
                message: t('main.createTask.changeProcessValidateMessage'),
              },
            ]}
          >
            <Select
              placeholder={t('main.createTask.processPlaceholder')}
              size='large'
              className='task-create__select'
              disabled={loading}
              showSearch
              filterOption={(input, option) =>
                (option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {processes?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item name='user' label={t('main.createTask.changeUserLabel')}>
            <Select
              placeholder={t('main.createTask.changeUserPlaceholder')}
              size='large'
              className='task-create__select'
              disabled={!process || loading}
            >
              {users?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.full_name || item.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name='comment'
            label={t('main.createTask.enterCommentLabel')}
          >
            <Input.TextArea
              placeholder={t('main.createTask.enterCommentPlaceholder')}
              style={{ borderRadius: 6 }}
              disabled={loading}
            />
          </Form.Item>
          <Space
            size={24}
            style={{ marginTop: 'auto', width: '100%' }}
            direction='vertical'
          >
            <Tooltip
              placement='top'
              title={
                !activeTask?.meta?.total
                  ? 'По указанным полям нет активных заявок'
                  : null
              }
              style={{ width: '100%', display: 'block' }}
              className='task-create__tooltip'
              color='#293452'
              overlayStyle={{ maxWidth: '340px', borderRadius: '6px' }}
            >
              <Button
                type='primary'
                ghost
                shape='round'
                style={{ width: '100%' }}
                size='large'
                loading={activeLoading}
                disabled={!activeTask?.meta?.total || activeLoading}
                onClick={showDrawer}
              >
                {t('main.createTask.activeTasks')}{' '}
                {activeTask?.meta?.total ? activeTask?.meta?.total : ''}
              </Button>
            </Tooltip>

            <Button
              type='primary'
              htmlType='submit'
              shape='round'
              style={{ width: '100%' }}
              size='large'
              // disabled={disabledClients}
              disabled={
                loading ||
                activeTask?.meta?.total >=
                  users?.find((i) =>
                    i.id === 'Распределить автоматически'
                      ? users.length - 1
                      : users.length
                  ) ||
                activeTask?.meta?.total >= 1
              }
              loading={loading}
            >
              {t('main.createTask.createBtn')}
            </Button>
          </Space>
        </Form>
      </Drawer>
      <Drawer
        title={t('main.createTask.activeTasks')}
        placement='right'
        onClose={onCloseActive}
        open={visible}
        width={448}
        destroyOnClose={true}
        bodyStyle={{ padding: '8px 0 10px 0px', overflow: 'hidden' }}
      >
        <ActiveTask
          activeTaskList={activeTaskList}
          setHistory={setHistory}
          setVisibleHistory={setVisibleHistory}
          user={user}
          setPagination={setPagination}
          hasMore={hasMore}
        />
      </Drawer>
    </>
  )
}

export default TaskCreate
