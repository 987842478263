import React, { useState } from 'react'
import { Drawer, Tabs } from 'antd'
import FormClient from './components/FormClient'
import ContactsClient from './components/ContactsInClient'
import History from './components/History'
import { useTranslation } from 'react-i18next'

const CardInClient = ({ openClient, setClientOpen, clientId, setClientId }) => {
  const { t } = useTranslation()
  const onCloseClientCard = () => {
    setClientOpen(false)
    setCurrentTab('1')
  }

  const [currentTab, setCurrentTab] = useState('1')

  const onChange = (key) => {
    setCurrentTab(key)
  }

  return (
    <Drawer
      title={t('clientCard.title')}
      placement='right'
      onClose={onCloseClientCard}
      open={openClient}
      width={450}
      destroyOnClose={true}
      bodyStyle={{
        padding: '0',
        overflow: 'hidden',
      }}
      headerStyle={{ padding: '16px 20px' }}
    >
      <div className='profile'>
        <Tabs
          onChange={onChange}
          className='profile__tabs'
          defaultActiveKey={currentTab}
        >
          <Tabs.TabPane tab={t('clientCard.tabs.data')} key='1'>
            <FormClient id={clientId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('clientCard.tabs.contacts')} key='2'>
            <ContactsClient id={clientId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('clientCard.tabs.serviceHistory')} key='3'>
            <History id={clientId} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Drawer>
  )
}

export default CardInClient
