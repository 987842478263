import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import queryString from 'query-string'
import moment from 'moment'
import _ from 'lodash'
import useFilters from '@views/ChoiceProduct/hooks/useFilters'
import Immutable from 'immutable'
import { modules, stockTypes } from '@/constants'

const useTableActions = (
  id,
  stockType,
  allTasksIds,
  componentName,
  taskTree,
  taskData,
  module
) => {
  const { dataSpareTask, dataSpareNeeds } = useSelector((state) => state.app)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [markId, setMarkId] = useState(null)
  const [carModelId, setCarModelId] = useState(null)
  const [query, setQuery] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
    searchValue: undefined,
    sorter: {
      field: undefined,
      order: undefined,
    },
    filters: undefined,
  })
  const [data, setData] = useState()
  const [needsData, setNeedsData] = useState()
  const [selected, setSelected] = useState([])
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [submitDisabledForSpare, setSubmitDisabledForSpare] = useState(true)
  const [dataForSparePartTask, setDataForSparePartTask] = useState(
    dataSpareTask || []
  )
  const [dataForSparePartNeeds, setDataForSparePartNeeds] = useState(
    dataSpareNeeds || []
  )

  useEffect(() => {
    if (
      dataSpareNeeds &&
      dataForSparePartNeeds &&
      !Immutable.fromJS(dataSpareNeeds).equals(
        Immutable.fromJS(dataForSparePartNeeds)
      )
    ) {
      setDataForSparePartNeeds(dataSpareNeeds)
    }
  }, [dataSpareNeeds])

  const { getFilters, getModelFilters, getTemplateFilters } = useFilters(
    markId,
    carModelId,
    stockType
  )

  useEffect(() => {
    if (markId) getModelFilters()
  }, [markId])

  useEffect(() => {
    if (carModelId) getTemplateFilters()
  }, [carModelId])

  useEffect(() => {
    if (!query.filters?.carMark && query.filters) {
      query.filters.carModel = undefined
    }
  }, [markId])

  useEffect(() => {
    if (!query.filters?.carModel && query.filters) {
      query.filters.carTemplate = undefined
    }
  }, [carModelId])

  const getData = async () => {
    const q = {
      page: query.pagination.current,
      take: query.pagination.pageSize,
      main_task_id: taskData?.mainTaskId,
    }

    if (query.searchValue?.length) q.keyword = query.searchValue

    if (query.sorter.field && query.sorter.order) {
      q.sortElement = query.sorter.field
      q.sortBy =
        query.sorter.order === 'ascend' || !query.sorter.order ? 'ASC' : 'DESC'
    }

    if (query.filters) {
      Object.values(query.filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (query.filters?.carMark) {
            setMarkId(query.filters?.carMark)
            q.car_mark_id = query.filters?.carMark
          } else {
            setMarkId(null)
            query.filters.carModel = null
          }
          if (query.filters?.carMark && query.filters?.carModel) {
            setCarModelId(query.filters?.carModel)
            q.car_model_id = query.filters?.carModel
          } else {
            setCarModelId(null)
            query.filters.carTemplate = null
          }

          if (
            query.filters?.carMark &&
            query.filters?.carModel &&
            query.filters?.carTemplate
          )
            q.car_template_id = query.filters?.carTemplate
          if (query.filters?.status) q.status = query.filters?.status
          if (query.filters?.conditions) q.condition = query.filters?.conditions
          if (query.filters?.carExteriorColor)
            q.car_exterior_color_id = query.filters?.carExteriorColor
          if (query.filters?.carInteriorColor)
            q.car_interior_color_id = query.filters?.carInteriorColor
          if (query.filters?.mileage) q.mileage_to = query.filters?.mileage
          if (query.filters?.year) {
            q.year_from = query.filters?.year[0]
            q.year_to = query.filters?.year[1]
          }
          if (query.filters?.price) {
            q.price_from = query.filters?.price[0]
            q.price_to = query.filters?.price[1]
          }
          if (query.filters?.engine_capacity) {
            q.engine_capacity_from = query.filters?.engine_capacity[0]
            q.engine_capacity_to = query.filters?.engine_capacity[1]
          }

          if (query.filters?.stock_date) {
            q.stock_date_from = moment(query.filters?.stock_date[0]?._d).format(
              'YYYY-MM-DD'
            )
            q.stock_date_to = moment(query.filters?.stock_date[1]?._d).format(
              'YYYY-MM-DD'
            )
          }
          if (query.filters?.sparePartCategory) {
            q.category_id = query.filters?.sparePartCategory
          }
        }
      })
    }

    const params = queryString.stringify(q, { arrayFormat: 'comma' })
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `${
          stockType !== stockTypes.SPARE_PARTS
            ? 'wh-cars/car_task/car'
            : 'wh-parts'
        }/${
          stockType !== stockTypes.SPARE_PARTS ? stockType : 'spare_part_task'
        }?${params}`,
      })
    )
      .then((res) => {
        setData(res.data)
      })
      .finally(() => setLoading(false))
  }

  const getNeeds = async () => {
    await dispatch(
      authorizationLayer({
        url: `needs/${taskData?.mainTaskId}`,
      })
    ).then(({ data }) => {
      const filtered = data.data.filter(
        (i) => i.type === modules.CHOICE_PRODUCT
      )
      const items = filtered?.find(
        (item) => item.module.settings.stockType === stockType
      )

      if (items) {
        setSelected(items.meta)
      }

      setNeedsData(filtered)
    })
  }

  const onSelect = (record) => {
    const exists = _.find(selected, (o) => o.id === record.id)
    if (!exists) {
      setSelected([...selected, record])
    } else {
      setSelected(_.filter(selected, (o) => o.id !== record.id))
    }
  }

  const onSelectAll = (selectedStatus, selectedRows, changeRows) => {
    if (selectedStatus) {
      setSelected([...selected, ...changeRows])
    } else {
      setSelected(_.xorBy(selected, changeRows, 'id'))
    }
  }

  const rowSelection = {
    selectedRowKeys: selected.map((o) => o.id),
    onSelect: onSelect,
    onSelectAll: onSelectAll,
  }

  useEffect(() => {
    if (taskData && module) {
      getData()
      getFilters()
      getNeeds()
    }
  }, [taskData, module])

  useEffect(() => {
    if (selected?.length) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [selected])

  useEffect(() => {
    if (dataSpareNeeds?.length) {
      setSubmitDisabledForSpare(false)
    } else {
      setSubmitDisabledForSpare(true)
    }
  }, [dataSpareNeeds])

  useEffect(() => {
    if (selected?.length) {
      setDataForSparePartNeeds(_.unionBy(selected, dataForSparePartNeeds, 'id'))
    } else {
      setDataForSparePartNeeds([])
    }
  }, [selected])

  return {
    getData,
    loading,
    setQuery,
    query,
    markId,
    carModelId,
    data,
    needsData,
    getNeeds,
    selected,
    rowSelection,
    submitDisabled,
    dataForSparePartNeeds,
    dataForSparePartTask,
    setDataForSparePartTask,
    setDataForSparePartNeeds,
    submitDisabledForSpare,
  }
}

export default useTableActions
