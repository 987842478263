import SelectedItem from './SelectedItem'
import Interaction from './Interaction'
import Coordination from './Coordination'
import CompletedForms from './CompletedForms'
import UploadedFiles from './UploadedFiles'
import { useTranslation } from 'react-i18next'
import CarReception from './CarReception'
import ManagerHeaderOperation from './ManagerHeaderOperation'

import { numberWithSpaces } from '../../../../../utils'

import './styles.less'

const TaskHistoryContainer = (props) => {
  const { needsObj, taskID, allModules, manager, process, totalAmount } = props
  const { t } = useTranslation()
  const {
    choiceProduct,
    carReception,
    interactionWithDepartments,
    Coordination: coordination,
    form,
    uploadFile,
  } = needsObj

  const findTotalPrice = (meta) => {
    return meta.reduce((result, item) => {
      if (item.price) {
        if (item.orderAmount) {
          return result + item.price * item.orderAmount
        } else {
          return result + item.price
        }
      }
      return result
    }, 0)
  }

  return (
    <div className={'task-history-container'} id={'task-history-container'}>
      {manager && (
        <ManagerHeaderOperation
          title={t('task.taskHistory.totalCost')}
          managerName={manager.full_name}
          position={manager.position ? manager.position.name : ''}
          operation={process ? process.name : ''}
          item={numberWithSpaces(totalAmount)}
        />
      )}
      {choiceProduct &&
        choiceProduct.length &&
        choiceProduct.map((item) => {
          return item.meta.length ? (
            <SelectedItem
              meta={item.meta}
              stockType={item.module?.settings?.stockType}
              price={findTotalPrice(item.meta)}
              key={item.id}
            />
          ) : null
        })}

      {carReception && carReception.length && (
        <CarReception
          title={t('task.taskHistory.carReception')}
          carReceptionList={carReception}
          taskID={taskID}
        />
      )}

      {interactionWithDepartments && interactionWithDepartments.length && (
        <Interaction
          interactionList={interactionWithDepartments}
          allModules={allModules}
        />
      )}

      {coordination && coordination.length && (
        <Coordination coordinationList={coordination} allModules={allModules} />
      )}

      {form && form.length && (
        <CompletedForms formList={form} allModules={allModules} />
      )}

      {uploadFile && uploadFile.length && (
        <UploadedFiles uploadFileList={uploadFile} allModules={allModules} />
      )}
    </div>
  )
}

export default TaskHistoryContainer
