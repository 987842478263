import { memo, useCallback, useEffect, useState } from 'react'

import { Table } from 'antd'
import { numberWithSpaces } from '../../../../../../utils'
import WrapperItem from '../WrapperItem'

const SelectedItem = memo((props) => {
  const { stockType, meta, price } = props

  const checkText = useCallback((text) => {
    return text ? text : 'Нет данных'
  }, [])

  const renderRow = useCallback((text) => {
    return <span>{checkText(text)}</span>
  }, [])

  const [state, setState] = useState({
    dataTable: {
      columns: null,
      dataSource: null,
    },
    allDates: {
      brand: {
        title: 'Марка',
        dataIndex: 'brand',
        key: 'brand',
        render: renderRow,
      },
      model: {
        title: 'Модель',
        dataIndex: 'model',
        key: 'model',
        render: renderRow,
      },
      vin: {
        title: 'VIN',
        dataIndex: 'vin',
        key: 'vin',
        render: renderRow,
      },
      cost: {
        title: 'Цена',
        key: 'cost',
        dataIndex: 'cost',
        render: (text) => {
          return text ? numberWithSpaces(text) : 'Нет данных'
        },
      },
      equipment: {
        title: 'Комплектация',
        key: 'equipment',
        dataIndex: 'equipment',
        render: renderRow,
      },
      condition: {
        title: 'Условие',
        key: 'condition',
        dataIndex: 'condition',
        render: renderRow,
      },
      bodyColor: {
        title: 'Цвет кузова',
        key: 'bodyColor',
        dataIndex: 'bodyColor',
        render: renderRow,
      },
      interiorColor: {
        title: 'Цвет салона',
        key: 'interiorColor',
        dataIndex: 'interiorColor',
        render: renderRow,
      },
      issueYear: {
        title: 'Год выпуска',
        key: 'issueYear',
        dataIndex: 'issueYear',
        render: renderRow,
      },
      name: {
        title: 'Наименование',
        key: 'name',
        dataIndex: 'name',
        render: renderRow,
      },
      category: {
        title: 'Категория',
        key: 'category',
        dataIndex: 'category',
        render: renderRow,
      },
      vendorCode: {
        title: 'Артикул',
        key: 'vendorCode',
        dataIndex: 'vendorCode',
        render: renderRow,
      },
      count: {
        title: 'Количество',
        key: 'count',
        dataIndex: 'count',
        render: renderRow,
      },
      totalAmount: {
        title: 'Общая сумма',
        key: 'totalAmount',
        dataIndex: 'totalAmount',
        render: (text) => {
          return text ? numberWithSpaces(text) : 'Нет данных'
        },
      },
    },
  })
  const {
    brand,
    model,
    vin,
    cost,
    equipment,
    condition,
    bodyColor,
    interiorColor,
    issueYear,
    name,
    category,
    vendorCode,
    count,
    totalAmount,
  } = state.allDates

  const returnDataSource = (type, arr) => {
    return arr.map((metaItem) => {
      const {
        id,
        name,
        article_number,
        price: totalAmount,
        carMark,
        carModel,
        vin_code,
        carExteriorColor,
        carInteriorColor,
        carTemplate,
        condition: stipulation,
        year,
        orderAmount,
      } = metaItem

      if (type === 'spare_parts') {
        return {
          key: id,
          name: name,
          vendorCode: article_number,
          count: orderAmount,
          totalAmount: totalAmount * orderAmount,
        }
      }
      if (type === 'test_drive') {
        return {
          key: id,
          brand: carMark?.name,
          model: carModel?.name,
          vin: vin_code,
          bodyColor: carExteriorColor?.name,
          interiorColor: carInteriorColor?.name,
          issueYear: year,
        }
      }
      if (type === 'used') {
        return {
          key: id,
          brand: carMark?.name,
          model: carModel?.name,
          vin: vin_code,
          cost: price,
          condition: stipulation,
        }
      }
      if (type === 'new') {
        return {
          key: id,
          brand: carMark?.name,
          model: carModel?.name,
          vin: vin_code,
          cost: price,
          equipment: carTemplate?.name,
          condition: stipulation,
        }
      }
    })
  }

  const findNeededTableData = (type) => {
    let columns = null
    let dataSource = null
    if (type) {
      if (type === 'spare_parts') {
        columns = [name, category, vendorCode, count, totalAmount]
        dataSource = returnDataSource(type, meta)
      }
      if (type === 'test_drive') {
        columns = [brand, model, vin, bodyColor, interiorColor, issueYear]
        dataSource = returnDataSource(type, meta)
      }
      if (type === 'used') {
        columns = [brand, model, vin, cost, condition]
        dataSource = returnDataSource(type, meta)
      }
      if (type === 'new') {
        columns = [brand, model, vin, cost, equipment, condition]
        dataSource = returnDataSource(type, meta)
      }

      setState({
        ...state,
        dataTable: {
          ...state.dataTable,
          columns: columns,
          dataSource: dataSource,
        },
      })
    }
  }

  const setTitle = (type) => {
    if (type === 'spare_parts') {
      return 'Выбранные запчасти'
    } else if (type === 'test_drive') {
      return 'Выбранные автомобили (Тест-драйв)'
    } else if (type === 'used') {
      return 'Выбранные автомобили (Авто с пробегом)'
    } else if (type === 'new') {
      return 'Выбранные автомобили (Новые автомобили)'
    } else {
      return ''
    }
  }

  const [firstElem] = meta

  useEffect(() => {
    findNeededTableData(stockType)
  }, [stockType])

  return (
    <WrapperItem title={setTitle(stockType)} price={numberWithSpaces(price)}>
      {state.dataTable.columns &&
        state.dataTable.dataSource &&
        state.dataTable.columns.length >= 1 &&
        state.dataTable.dataSource.length >= 1 && (
          <Table
            key={firstElem.id}
            columns={state.dataTable.columns}
            dataSource={state.dataTable.dataSource}
            pagination={false}
            style={{ margin: '0 0 20px 0' }}
          />
        )}
    </WrapperItem>
  )
})

export default SelectedItem
