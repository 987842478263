export const validateVinChar = (char) => {
  // Проверяем, является ли символ латинской буквой или цифрой
  return /^[a-zA-Z0-9]$/.test(char)
}

export const verifyVIN = (vin) => {
  if (vin.length <= 17) {
    return [...vin].every(validateVinChar)
  } else {
    return false
  }
}