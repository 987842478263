import React from 'react'

const DescTab = ({ ...props }) => {
  const desc = props.desc

  return (
    <div
      style={{
        padding: '12px 20px 12px 16px',
        background: '#F8F8F8',
        borderRadius: 6,
        maxWidth: 600,
      }}
    >
      {desc}
    </div>
  )
}

export default DescTab
