import { memo, useEffect, useState } from 'react'

import { Table } from 'antd'
import { CarReceptionItemWrapper } from '../CarReceptionItemWrapper'

const TestDrive = memo((props) => {
  const { testDrive } = props

  const [state, setState] = useState({
    dataSource: null,
    columns: [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
      },
    ],
  })

  useEffect(() => {
    if (testDrive) {
      const result = testDrive.reduce((res, item) => {
        item.meta?.map((metaItem) => {
          const { carCheckPoint, comment } = metaItem
          const obj = {
            key: carCheckPoint.id,
            name: carCheckPoint.name,
            comment: comment,
          }
          res.push(obj)
        })
        return res
      }, [])
      setState({ ...state, dataSource: result })
    }
  }, [testDrive])

  return (
    <CarReceptionItemWrapper titleItem={'Техосмотр'}>
      {state.dataSource && (
        <Table
          columns={state.columns}
          dataSource={state.dataSource}
          pagination={false}
        />
      )}
    </CarReceptionItemWrapper>
  )
})

export default TestDrive
