import React from 'react'
import { Card, Empty, Skeleton, Space, Table, Typography } from 'antd'
import Loading from '@ui/Loading'
import { useVT } from 'virtualizedtableforantd4'

const { Title, Text } = Typography

const SkeletonTable = (props) => {
  const {
    columns,
    data,
    className = 'custom-table',
    pagination = false,
    rowSelection = false,
    pageSizeOptions = [10, 20, 50, 100],
    total,
    take = 10,
    xScroll = false,
    currentPage,
    pageSize,
    tableChangeFunction,
    loading,
    onRowClickTrue,
    onRowClickFalse = () => false,
    onRowClickStatus = () => false,
    cursorPointer = false,
    emptyData = {
      title: '',
      desc: '',
      link: '',
    },
    width,
    height,
    expandable = false,
    disabledRow = false,
    activeRow,
    infinityFunction = false,
    stockType = '',
  } = props

  const [vt] = useVT(
    () => ({
      onScroll: ({ isEnd }) => {
        if (isEnd) {
          if (data.length !== total) infinityFunction()
        }
      },
      scroll: { y: height },
    }),
    [data, total]
  )

  return (
    <Table
      rowKey='id'
      rowSelection={rowSelection}
      tableLayout={xScroll ? 'fixed' : 'auto'}
      className={className}
      rowClassName={disabledRow || activeRow}
      onRow={(record) =>
        onRowClickStatus(record)
          ? {
              onClick: (e) => {
                /* Call some endPoint to log this click event */
                onRowClickTrue(record.id)
              },
              style: cursorPointer
                ? { cursor: 'pointer' }
                : { cursor: 'default' },
            }
          : {
              onClick: (e) => {
                /* Call some endPoint to log this click event */
                onRowClickFalse(record.id)
              },
              style: cursorPointer
                ? { cursor: 'pointer' }
                : { cursor: 'default' },
            }
      }
      scroll={data?.length ? { x: width, y: height } : { x: width }}
      pagination={
        pagination && {
          current: currentPage,
          showSizeChanger: total > take,
          total: total,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptions,
        }
      }
      onChange={tableChangeFunction}
      locale={{
        emptyText: (
          <Card
            bordered={false}
            style={{
              height: height,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              padding: 0,
            }}
          >
            {!loading && data !== undefined ? (
              <Empty
                description={
                  <>
                    <Space size={1} direction='vertical'>
                      <Title level={3}>{emptyData.title}</Title>
                      <Space size={1} direction='vertical'>
                        <Text>{emptyData.desc}</Text>
                        {/* <Link to={emptyData.link}>Узнать подробнее</Link> */}
                      </Space>
                    </Space>
                  </>
                }
              />
            ) : (
              <Loading spinner />
            )}
          </Card>
        ),
      }}
      dataSource={data?.length ? data : []}
      expandable={expandable}
      components={infinityFunction ? { body: vt.body, table: vt.table } : null}
      columns={
        loading
          ? columns.map((column) => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return (
                    <Skeleton
                      key={column.dataIndex}
                      title={true}
                      paragraph={false}
                    />
                  )
                },
              }
            })
          : columns
      }
    />
  )
}

export default SkeletonTable
