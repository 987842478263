import { createRef, useContext, useEffect, useRef, useState } from 'react'
import { Card } from 'antd'

import { TaskContext } from '../..'
import { modules } from '@/constants'

import TaskHistoryModal from '../TaskHistory/TaskHistoryModal'
import { Footer, ModuleRender } from '@views/Task/components'

import './index.less'

const Modules = (props) => {
  const { showTaskHistoryModal, closeTaskHistory } = props
  const { allModules, taskData, visibleTaskData, needsData, id, mainTaskId } =
    useContext(TaskContext)
  const [currenStepStage, setCurrentStepStage] = useState(0)

  const mRefs = useRef([])

  useEffect(() => {
    visibleTaskData?.process?.stages?.find((item, index) => {
      if (item.id === visibleTaskData?.currentStage?.id) {
        setCurrentStepStage(index)
      }
    })
  }, [taskData, visibleTaskData])

  const validateModules = async () => {
    let status = true
    for (let i = 0; i < taskData?.currentStage?.modules.length; i++) {
      const values = await mRefs.current[i]?.current?.getStatus()
      if (!values && !(taskData?.currentStage?.modules[i].isHidden && taskData?.mainTaskId !== taskData?.id)) {
        status = taskData?.currentStage?.modules[i].name
        break
      }
    }
    return status
  }

  return (
    <div className='task__stages'>
      <div className='task__header'>
        {visibleTaskData?.process?.stages?.map((stage, index) => {
          return (
            <div
              className={
                currenStepStage === index || currenStepStage > index
                  ? 'task__header-item is-active'
                  : 'task__header-item '
              }
              key={stage.id}
              style={
                currenStepStage === index
                  ? { fontWeight: 500 }
                  : { color: ' rgba(41, 52, 82, 0.4)' }
              }
            >
              {stage.name}
            </div>
          )
        })}
      </div>
      <div className='task__steps'>
        {visibleTaskData?.currentStage?.modules?.map((item, index) => {
          if (!mRefs.current[index]) mRefs.current[index] = createRef()
          return (
            <Card
              key={item.id}
              style={{
                display: 
                  visibleTaskData?.mainTaskId !== visibleTaskData?.id && item?.isHidden ? 
                  'none' : 'block',
                maxHeight: 660,
                minHeight:
                  item.component.componentType.name === modules.CAR_RECEPTION
                    ? 660
                    : 'auto',
                borderRadius: 6,
                boxShadow: '0px 2px 12px rgba(130, 153, 219, 0.08)',
                border: '1px solid #E3E6E9',
                marginBottom: 20,
              }}
              bodyStyle={{ padding: 20 }}
            >
              <ModuleRender
                ref={mRefs.current[index]}
                data={{
                  module: item,
                }}
              />
            </Card>
          )
        })}
      </div>

      <Footer validateModules={validateModules} />
      {showTaskHistoryModal && (
        <TaskHistoryModal
          needsData={needsData}
          taskData={taskData}
          currenStepStage={currenStepStage}
          taskID={id}
          allModules={allModules}
          open={showTaskHistoryModal}
          closeTaskHistory={closeTaskHistory}
          mainTaskId={mainTaskId}
        />
      )}
    </div>
  )
}

export default Modules
