import { Tooltip, Typography, Button } from 'antd'
import { numberWithSpaces, openModals } from '@utils'
import _ from 'lodash'
import { LinkOutlined } from '@ant-design/icons'
import { t } from 'i18next'

const { Text } = Typography

export const newColumns = (dispatch) => {
  let data = [
    {
      title: t('warehouse.tab.cars.column.brand'),
      dataIndex: 'carMark',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carMark?.name ? record?.carMark?.name : null}
        >
          {record?.carMark?.name ? record?.carMark?.name : null}
        </Tooltip>
      ),
      fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.model'),
      dataIndex: 'carModel',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carModel?.name ? record?.carModel?.name : null}
        >
          {record?.carModel?.name ? record?.carModel?.name : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.vin'),
      dataIndex: 'vin_code',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 180,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.price'),
      dataIndex: 'price',
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
          {`${numberWithSpaces(text)}`}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.equipment'),
      dataIndex: 'carTemplate',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carTemplate?.name ? record?.carTemplate?.name : null}
        >
          {record?.carTemplate?.name ? record?.carTemplate?.name : null}
        </Tooltip>
      ),
      width: 200,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.terms'),
      dataIndex: 'conditions',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.conditions !== null
              ? record?.conditions?.map((item, index) => {
                  return <p key={index}>{item}</p>
                })
              : ''
          }
        >
          {record?.conditions !== null
            ? record?.conditions?.map((item, index) => {
                return <Text key={index}>{item}</Text>
              })
            : ''}
        </Tooltip>
      ),
      width: 140,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          onClick={() =>
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: false,
            })
          }
          icon={<LinkOutlined />}
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
      fixed: 'right',
    },
  ]

  return data
}

export const usedColumns = (dispatch) => {
  let data = [
    {
      title: t('warehouse.tab.cars.column.brand'),
      dataIndex: 'carMark',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carMark?.name ? record?.carMark?.name : null}
        >
          {record?.carMark?.name ? record?.carMark?.name : null}
        </Tooltip>
      ),
      fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.model'),
      dataIndex: 'carModel',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carModel?.name ? record?.carModel?.name : null}
        >
          {record?.carModel?.name ? record?.carModel?.name : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.vin'),
      dataIndex: 'vin_code',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 180,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.price'),
      dataIndex: 'price',
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
          {`${numberWithSpaces(text)}`}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },

    {
      title: t('warehouse.tab.cars.column.terms'),
      dataIndex: 'conditions',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.conditions !== null
              ? record?.conditions?.map((item, index) => {
                  return <p key={index}>{item}</p>
                })
              : ''
          }
        >
          {record?.conditions !== null
            ? record?.conditions?.map((item, index) => {
                return <Text key={index}>{item}</Text>
              })
            : ''}
        </Tooltip>
      ),
      width: 140,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          onClick={() =>
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: false,
            })
          }
          icon={<LinkOutlined />}
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
      fixed: 'right',
    },
  ]

  return data
}

export const testDriveColumns = (dispatch) => {
  let data = [
    {
      title: t('warehouse.tab.cars.column.brand'),
      dataIndex: 'carMark',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carMark?.name ? record?.carMark?.name : null}
        >
          {record?.carMark?.name ? record?.carMark?.name : null}
        </Tooltip>
      ),
      fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.model'),
      dataIndex: 'carModel',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carModel?.name ? record?.carModel?.name : null}
        >
          {record?.carModel?.name ? record?.carModel?.name : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.vin'),
      dataIndex: 'vin_code',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 180,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.bodyColor'),
      dataIndex: 'carExteriorColor',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carExteriorColor?.name
              ? record?.carExteriorColor?.name
              : null
          }
        >
          {record?.carExteriorColor?.name
            ? record?.carExteriorColor?.name
            : null}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.interiorColor'),
      dataIndex: 'carInteriorColor',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carInteriorColor?.name
              ? record?.carInteriorColor?.name
              : null
          }
        >
          {record?.carInteriorColor?.name
            ? record?.carInteriorColor?.name
            : null}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.year'),
      dataIndex: 'year',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          onClick={() =>
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: false,
            })
          }
          icon={<LinkOutlined />}
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
      fixed: 'right',
    },
  ]

  return data
}

export const partsColumns = (dispatch) => {
  let data = [
    {
      title: t('warehouse.tab.parts.column.name'),
      dataIndex: 'name',
      width: 280,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
      fixed: 'left',
    },
    {
      title: t('warehouse.tab.parts.column.category'),
      dataIndex: 'sparePartCategory',
      width: 220,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.sparePartCategory?.name
              ? record?.sparePartCategory?.name
              : null
          }
        >
          {record?.sparePartCategory?.name
            ? record?.sparePartCategory?.name
            : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.article'),
      dataIndex: 'article_number',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.amount'),
      dataIndex: 'orderAmount',
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
          {`${numberWithSpaces(text)}`}
        </Tooltip>
      ),
      width: 120,
    },

    {
      title: t('warehouse.tab.parts.column.price'),
      dataIndex: 'price',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
          {`${numberWithSpaces(text)}`}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },

    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          onClick={() =>
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: true,
            })
          }
          icon={<LinkOutlined />}
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
      fixed: 'right',
    },
  ]

  return data
}
