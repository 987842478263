import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Button, Form, List, Typography, Input } from 'antd'
import useBrands from '@views/Task/hooks/useBrands'
import { CharacteristicsContext } from '../../../Characteristics'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const popular = [
  { name: 'Toyota', order: 1 },
  { name: 'Hyundai', order: 2 },
  { name: 'Kia', order: 3 },
  { name: 'Chevrolet', order: 4 },
  { name: 'ВАЗ (Lada)', order: 5 },
  { name: 'Nissan', order: 6 },
  { name: 'BMW', order: 7 },
  { name: 'Daewoo', order: 8 },
  { name: 'Mercedes-Benz', order: 9 },
  { name: 'Audi', order: 10 },
  { name: 'ГАЗ', order: 11 },
  { name: 'Mazda', order: 12 },
  { name: 'Volkswagen', order: 13 },
  { name: 'Mitsubishi', order: 14 },
  { name: 'Lexus', order: 15 },
]

const Brand = () => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const brand = Form.useWatch('brand', form)
  const { onChangeBrands, brandsWithQuery, loading } = useBrands()
  const [isPopular, setIsPopular] = useState(true)
  const { loadingComponent, brandId, updateInfo, selectAuto, setBrandId } =
    useContext(CharacteristicsContext)

  const groupedBrands = useMemo(() => {
    if (!brandsWithQuery?.length) return

    const map = new Map()
    brandsWithQuery?.forEach((brand) => {
      let group = map.get(brand.letter)
      if (!group) {
        group = {
          letter: brand.letter,
          brands: [],
        }
      }

      group.brands.push(brand)
      map.set(brand.letter, group)
    })

    return [...map.entries()].map(([letter, group]) => ({
      letter,
      brands: group.brands,
    }))
  }, [brandsWithQuery])

  const popularBrands = useMemo(() => {
    if (!brandsWithQuery?.length) return

    return brandsWithQuery
      .filter((brand) => popular.find((p) => p.name === brand.name))
      .sort((a, b) => {
        const a1 = popular.find((p) => p.name === a.name)
        const b1 = popular.find((p) => p.name === b.name)

        return a1.name.localeCompare(b1.name)
      })
  }, [brandsWithQuery])

  useEffect(() => {
    if (brand)
      groupedBrands?.forEach((i) => {
        i.brands?.forEach((x) => {
          if (x.name?.toLowerCase() === brand?.toLowerCase()) {
            form.setFieldsValue({ brand: x.name })
            setBrandId(x.id)
            if (!selectAuto?.carMark?.id) updateInfo(x.id)
          }
        })
      })
  }, [brand])

  return (
    <>
      <Form.Item
        label={t('warehouse.tab.cars.column.brand')}
        name='brand'
        rules={[
          {
            required: true,
            message: t('common.input.requiredMessage'),
          },
        ]}
        style={{ maxWidth: 400 }}
      >
        <Input
          placeholder={t(
            'task.moduleRender.module.carReception.brandPlaceholder'
          )}
          allowClear
          onChange={(e) => {
            setIsPopular(false)
            onChangeBrands(e.target.value ? brand : null)
          }}
        />
      </Form.Item>

      {!brandId && brandsWithQuery?.length ? (
        <List
          bordered={false}
          style={{
            WebkitColumnCount: 4,
            MozColumnCount: 4,
            WebkitColumns: 4,
          }}
          dataSource={isPopular ? popularBrands : groupedBrands}
          loading={{
            indicator: loadingComponent,
            spinning: loading,
            delay: 0,
          }}
          renderItem={(item) =>
            isPopular ? (
              <List.Item
                key={item.id}
                style={{
                  paddingLeft: 32,
                  position: 'relative',
                  border: 'none',
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                  }}
                >
                  {item.letter}
                </div>
                <Typography.Link
                  onClick={() => {
                    setBrandId(item.id)

                    form.setFieldsValue({
                      brand: item.name,
                    })
                    updateInfo(item.id)
                  }}
                >
                  {item.name}
                </Typography.Link>
              </List.Item>
            ) : (
              <List.Item
                key={item.letter}
                style={{
                  paddingLeft: 32,
                  position: 'relative',
                  border: 'none',
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                  }}
                >
                  {item.letter}
                </div>
                <List
                  bordered={false}
                  dataSource={item.brands}
                  renderItem={(innerItem) => (
                    <List.Item key={innerItem.id} style={{ border: 'none' }}>
                      <Typography.Link
                        onClick={() => {
                          setBrandId(innerItem.id)

                          form.setFieldsValue({
                            brand: innerItem.name,
                          })
                          updateInfo(innerItem.id)
                        }}
                      >
                        {innerItem.name}
                      </Typography.Link>
                    </List.Item>
                  )}
                />
              </List.Item>
            )
          }
        />
      ) : null}
      {!brandId && brandsWithQuery?.length ? (
        <Button
          style={{ marginTop: 20, marginBottom: 20, minWidth: 160 }}
          shape='round'
          ghost
          type='primary'
          onClick={() => setIsPopular(!isPopular)}
        >
          {!isPopular
            ? t('task.moduleRender.module.carReception.onlyPopularBtn')
            : t('task.moduleRender.module.carReception.allBrandsBtn')}
        </Button>
      ) : null}
    </>
  )
}

export default Brand
