import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import './index.less'
import {
  Popover,
  Typography,
  Drawer,
  List,
  Badge,
  Space,
  Checkbox,
  Button,
} from 'antd'
import Loading from '@ui/Loading'
import moment from 'moment'
import ClientHistory from '@views/Task/components/ClientCard/components/ClientHistory'
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import { t } from 'i18next'
import { FilterOutlined } from '@ant-design/icons'

const { Text } = Typography

const getPopoverContent = (
  autoDataNew,
  autoDataUsed,
  autoDataTestDrive,
  partsData,
  servicesData
) => {
  return (
    <div
      style={{
        maxHeight: '100px',
        overflowY: 'auto',
      }}
      onClick={(e) => e.stopPropagation()}
      className='custom-popover-inner'
    >
      {autoDataNew?.length ? (
        <Text type='secondary'>{t('clientCard.history.newAuto')}</Text>
      ) : null}
      {autoDataNew?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataUsed?.length ? (
        <Text type='secondary'>{t('clientCard.history.usedAuto')}</Text>
      ) : null}
      {autoDataUsed?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataTestDrive?.length ? (
        <Text type='secondary'>{t('clientCard.history.testDrive')}</Text>
      ) : null}
      {autoDataTestDrive?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {partsData?.length ? (
        <Text type='secondary'>{t('clientCard.history.parts')}</Text>
      ) : null}
      {partsData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {servicesData?.length ? (
        <Text type='secondary'>{t('clientCard.history.service')}</Text>
      ) : null}
      {servicesData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}
//TODO

const defaultPagination = {
  current: 1,
  pageSize: 20,
}

const History = ({ id }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const dispatch = useDispatch()
  const [process, setProcess] = useState()
  const [metaTotal, setMetaTotal] = useState()
  const [filters, setFilters] = useState([])
  const [refresh, setRefresh] = useState(false)

  const [pagination, setPagination] = useState(defaultPagination)

  const getData = async () => {
    try {
      setLoading(true)

      let q = {
        page: pagination.current,
        limit: pagination.pageSize,
        processIds: filters ? filters.join(',') : null,
      }
      const query = queryString.stringify(q)

      const {
        data: { data: items, meta },
      } = await dispatch(
        authorizationLayer({
          url: `processes/tasks/get-tasks-by-client-id/${id}?${query}`,
          method: 'get',
        })
      )

      if (items?.length < 10) setHasMore(false)
      else if (meta?.total <= 10) setHasMore(false)
      else setHasMore(true)

      setRefresh(false)
      setMetaTotal(meta?.total)

      setData([...data, ...items])
      setLoading(false)
    } catch (e) {
      console.log(e.message)
    }
  }

  useEffect(() => {
    getData()
  }, [pagination.current, filters])

  const getAllProcess = async () => {
    await dispatch(
      authorizationLayer({
        url: `/processes/main`,
        method: 'get',
      })
    ).then((res) => {
      if (res) setProcess(res.data.data)
    })
  }

  useEffect(() => {
    getAllProcess()
  }, [])

  useEffect(() => {
    if (refresh) {
      if (pagination.current !== defaultPagination.current) {
        setPagination(defaultPagination)
      } else {
        getData()
      }
    }
  }, [refresh])

  const needsItem = (record) => {
    let autoDataNew = record.clientData ? record.clientData.cars.new : []
    let autoDataUsed = record.clientData ? record.clientData.cars.used : []
    let autoDataTestDrive = record.clientData
      ? record.clientData.cars.test_drive
      : []

    let partsData = record.clientData ? record.clientData?.parts : []
    let servicesData = record.clientData ? record.clientData?.services : []
    return (
      <span
        style={{
          wordWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {!autoDataNew?.length &&
        !autoDataUsed?.length &&
        !autoDataTestDrive?.length &&
        !partsData?.length &&
        !servicesData?.length ? (
          <Text>{t('main.tab.createdByMe.noData')}</Text>
        ) : (
          <Popover
            overlayClassName='custom-popover'
            trigger='hover'
            placement='bottomLeft'
            title={
              <div onClick={(e) => e.stopPropagation()}>{`${t(
                'clientCard.history.selected'
              )} ${
                autoDataNew?.length +
                partsData?.length +
                servicesData?.length +
                autoDataUsed?.length +
                autoDataTestDrive?.length
              }`}</div>
            }
            content={getPopoverContent(
              autoDataNew,
              autoDataUsed,
              autoDataTestDrive,
              partsData,
              servicesData
            )}
          >
            <Text
              style={{
                cursor: 'pointer',
              }}
              // type='secondary'
            >{`${t('clientCard.history.selectedNeed')} ${
              autoDataNew?.length +
              partsData?.length +
              servicesData?.length +
              autoDataUsed?.length +
              autoDataTestDrive?.length
            }`}</Text>
          </Popover>
        )}
      </span>
    )
  }

  const [open, setOpen] = useState(false)
  const [list, setList] = useState()

  useEffect(() => {
    if (filters.length !== 0) {
      setData([])
      setRefresh(true)
    }
  }, [filters, process])

  const showDrawer = (item) => {
    setList(item)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <div className='history'>
      {loading && <Loading spinner={true} />}

      <div
        className='test'
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0px 20px',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <Text style={{ fontSize: '16px', fontWeight: '500' }}>
          {t('calendar.calendar.tasksNumber')} - {metaTotal}
        </Text>
        <Badge count={filters?.length} color='#1890FF'>
          <Popover
            trigger='click'
            content={
              <>
                <Space direction='vertical' size={12}>
                  <Checkbox.Group
                    onChange={(e) => {
                      setFilters(e)
                      setPagination({
                        current: 1,
                        pageSize: 10,
                      })
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'baseline',
                      height: 500,
                      overflowY: 'scroll',
                    }}
                    className='pausedBox__list'
                  >
                    {process?.map((i) => (
                      <Checkbox value={i.id}>{i.name}</Checkbox>
                    ))}
                  </Checkbox.Group>
                </Space>
              </>
            }
            placement='bottomLeft'
          >
            <Button type='link' style={{ fontSize: '15px', padding: '0' }}>
              {t('task.actionHistory.module.filter')} <FilterOutlined />
            </Button>
          </Popover>
        </Badge>
      </div>

      <div
        className='history__list'
        id='scrollableDiv'
        // style={{ overflow: 'scroll', height: '85vh' }}
        // style={{ overflow: 'auto', height: bodyHeight - 58 }}
      >
        {data && (
          <InfiniteScroll
            dataLength={data?.length}
            next={() => {
              setPagination((value) => ({
                ...value,
                current: value.current + 1,
              }))
            }}
            hasMore={hasMore}
            scrollableTarget='scrollableDiv'
            // height={bodyHeight}
          >
            <List
              dataSource={data}
              renderItem={(item) => (
                <>
                  <List.Item
                    key={item.id}
                    className='history__item'
                    onClick={(e) => {
                      e.stopPropagation()
                      showDrawer(item)
                    }}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'normal',
                      padding: '12px 20px 5px 20px',
                      borderBottom: '1px solid #fafafa',
                      cursor: 'pointer',
                    }}
                  >
                    <List.Item.Meta />
                    <div className='history__box'>
                      <p className='history__title history__p'>
                        {needsItem(item)}
                      </p>
                      <p className='history__id history__p'>{item?.inner_id}</p>
                    </div>

                    <div className='history__box history__box--date'>
                      <p className='history__subtitle history__p'>
                        {item?.currentStage?.name}
                      </p>
                      <p className='history__date history__p'>
                        {moment(item?.created_at).format('DD MMMM YYYY, HH:mm')}
                      </p>
                    </div>
                  </List.Item>
                </>
              )}
            />
          </InfiniteScroll>
        )}
      </div>
      <Drawer
        title={t('clientCard.history.actionHistory')}
        placement='right'
        onClose={onClose}
        visible={open}
        width={450}
        destroyOnClose={true}
        bodyStyle={{ padding: '0' }}
      >
        {<ClientHistory data={{ taskData: list }} />}
      </Drawer>
    </div>
  )
}

export default History
