import React from 'react'
import moment from 'moment'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { openModals } from '@utils'
import SkeletonTable from '@ui/SkeletonTable'
import { useTranslation } from 'react-i18next'

const ActiveRequestTable = ({ ...props }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const columns = [
    {
      title: t('activeTasks.table.process'),
      dataIndex: ['process', 'name'],
      width: 329,
      render: (text, record) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {record?.process?.name}
        </span>
      ),
    },
    {
      title: t('activeTasks.table.user'),
      dataIndex: 'user',
      width: 339,
      render: (text, record) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {record?.user?.full_name}
        </span>
      ),
    },
    {
      title: t('activeTasks.table.stage'),
      dataIndex: 'currentStage',
      render: (text, record) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {record?.currentStage?.name}
        </span>
      ),
      width: 340,
    },
    {
      title: t('activeTasks.table.created'),
      dataIndex: 'created_at',
      render: (text) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {moment(text).format('DD MMMM YYYY, hh:mm')}
        </span>
      ),
      width: 233,
    },
    {
      title: t('activeTasks.table.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          style={{ padding: 0 }}
          type='link'
          onClick={() => {
            openModals(dispatch, { type: 'activity', id: record.id })
            props.onCancel()
          }}
        >
          {t('activeTasks.table.actionBtnName')}
        </Button>
      ),
      width: 159,
    },
  ]
  return (
    <>
      <SkeletonTable
        columns={columns}
        data={props.data}
        loading={props.loading}
        useHeightStatus
        useHeightElements={['ant-layout-header']}
        height={80}
      />
    </>
  )
}

export default ActiveRequestTable
