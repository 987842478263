import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import ProtectedRoute from '../auth/ProtectedRoute'

import MainLayout from '@/layout/MainLayout'
import { useSelector } from 'react-redux'
import { NewTasks, Task, Login, Warehouse } from '@views'
import { Profile, Statistic, Calendar, ChoiceProduct, Error } from '@views'

const Routers = ({ notification }) => {
  const token = useSelector((state) => state.app.token)
  const permissions = useSelector((state) => state.app.permissions)

  const mainRouters = () => {
    let data = [
      {
        path: 'warehouse',
        element: <Warehouse />,
        permissions: ['wh_parts', 'wh_new_auto', 'wh_used_auto', 'wh_services'],
      },
      {
        path: 'statistics',
        element: <Statistic />,
        permissions: ['statistic'],
      },
      {
        path: 'calendar',
        element: <Calendar />,
        permissions: ['calendar'],
      },
    ]

    data = data.filter((i) =>
      permissions?.some((x) => i.permissions.includes(x.name))
    )

    return data
  }

  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path='/' element={<MainLayout notification={notification} />}>
            <Route path='/' element={<NewTasks />} />
            {mainRouters().map((i, index) => {
              return <Route key={index} path={i.path} element={i.element} />
            })}
            <Route path='task/:id' element={<Task />} />
          </Route>
          <Route path='profile/settings' element={<Profile />} />
          <Route path='error/:status' element={<Error />} />
          <Route
            path='choice-product/:id/:moduleId'
            element={<ChoiceProduct />}
          />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
        {!token && (
          <>
            <Route path='login' element={<Login />} />
            <Route path='reset-password' element={<Login step={4} />} />
          </>
        )}
      </Routes>
    </>
  )
}

export default Routers
