import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, Drawer, Form, Input, Select } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import './index.less'
import { ModuleContext } from '../../..'
import { SSE } from '@utils'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { modules } from '@/constants'

const DepartmentsInteraction = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.app)
  const [form] = Form.useForm()
  const { module, taskData, currentNeeds, cardTitle, needsData, setNeedsData } =
    useContext(ModuleContext)

  const [loading, setLoading] = useState(false)
  const [loadingUser] = useState(false)
  const [responsibleUser, setResponsibleUser] = useState('')
  const [status, setStatus] = useState()
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [infoVisible, setInfoVisible] = useState(true)
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState([])
  const [change, setChange] = useState(false)
  const [nameButton, setNameButton] = useState(
    t('task.moduleRender.module.coordination.sendRequestBtn'),
  )

  const showDrawer = (requestToChange) => {
    if (requestToChange) {
      setChange(true)
    }
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
    setChange(false)
  }

  useEffect(() => {
    getUsers()
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      getStatus: async () => {
        if (module?.isRequired) {
          return currentNeeds?.meta?.taskStatus === 'COMPLETED'
        } else {
          if (currentNeeds?.meta) {
            return currentNeeds?.meta?.taskStatus === 'COMPLETED' || currentNeeds?.meta?.taskStatus === 'CANCELLED'
          } else {
            return true
          }
        }
      },
    }),
    [currentNeeds],
  )

  const CREATED_TASK_UPDATED = `CREATED_TASK_UPDATED_${taskData?.mainTaskId}_${module?.id}`

  const onUpdated = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event

    if (data?.meta.taskStatus === 'CANCELLED') {
      setInfoVisible(false)
      setStatus(
        <p className='dep-info__status  dep-info__status--error'>
          Отказ. Отправьте заявку повторно
        </p>,
      )
      setDisableSubmit(false)
      setNameButton(
        t('task.moduleRender.module.interactionWithDepartments.resendBtn'),
      )
      setResponsibleUser('')
    } else if (data?.meta?.taskStatus === 'ACTIVE') {
      setInfoVisible(false)
      setStatus(
        <p className='dep-info__status'>
          {t('task.moduleRender.module.interactionWithDepartments.requestSent')}
        </p>,
      )
      setDisableSubmit(true)
    } else if (data?.meta?.taskStatus === 'COMPLETED') {
      setInfoVisible(false)
      setResponsibleUser('')
      setStatus(
        <p className='dep-info__status  dep-info__status--closed'>
          {t(
            'task.moduleRender.module.interactionWithDepartments.completedStatus',
          )}
        </p>,
      )
      setDisableSubmit(true)
      let index = _.findIndex(
        needsData,
        (i) => i.module?.id === data?.module?.id,
      )

      if (index !== -1) {
        needsData[index] = data

        setNeedsData([...needsData])
      } else {
        needsData?.push(data)
        setNeedsData([...needsData])
      }
    }
  }

  const sse = useRef()

  const getUsers = async () => {
    await dispatch(
      authorizationLayer({
        url: `processes/process-user/${module?.settings?.processId}/users`,
      }),
    ).then(({ data }) => {
      if (data.success) {
        let userData = data.data
        userData?.forEach((item) => {
          if (item.id === user?.id) {
            item.full_name = t(
              'request.actionHistory.changeUser.drawer.selectCreateForMe',
            )
          }
        })
        setUsers(userData)
      }
    })
  }

  useEffect(() => {
    if (users) {
      if (users?.length === 1 && users?.some((x) => x.id === user?.id)) {
        form.setFieldsValue({ user: user?.id })
      }
      form.setFieldsValue({ user: users[0]?.id })
    }
  }, [users])

  const onSubmit = async (values) => {
    setLoading(true)
    if (!change) {
      let responsibleUser = ''
      await dispatch(
        authorizationLayer({
          url: `router/create-task`,
          method: 'POST',
          data: {
            client_phone_number: taskData.client?.phone_number,
            processId: module?.settings?.processId,
            userId:
              values.user !== undefined && values.user !== 'auto'
                ? values.user
                : values.user === 'auto'
                  ? null
                  : values.user,
            parentTaskId: taskData.id,
            comment: values.comment !== undefined ? values.comment : null,
            moduleId: module?.id,
          },
        }),
      ).then((res) => {
        let NewTaskId = res.data?.taskId
        responsibleUser = res.data.lastName + ' ' + res.data.name
        setOpen(false)

        dispatch(
          authorizationLayer({
            url: `needs`,
            method: 'POST',
            data: {
              taskId: taskData?.id,
              moduleId: module?.id,
              mainTaskId: taskData?.mainTaskId,
              needs: [
                {
                  type: modules.IWD,
                  meta: {
                    taskId: NewTaskId,
                    taskStatus: 'ACTIVE',
                    responsibleUser,
                  },
                },
              ],
            },
          }),
        ).then((res) => {
          const data = res.data.data[0]
          let index = _.findIndex(
            needsData,
            (i) => i.module?.id === data?.module?.id,
          )

          if (index !== -1) {
            needsData[index] = data

            setNeedsData([...needsData])
          } else {
            needsData?.push(data)
            setNeedsData([...needsData])
          }

          setStatus(
            <p className='dep-info__status'>
              {t(
                'task.moduleRender.module.interactionWithDepartments.requestSent',
              )}
            </p>,
          )
          setDisableSubmit(true)
          setResponsibleUser(responsibleUser)
          setInfoVisible(false)
        })
      })
    } else {
      const need = needsData?.find((i) => i.module.id === module.id)
      if (need)
        await dispatch(
          authorizationLayer({
            url: `processes/tasks/change-user/${need.meta.taskId}`,
            method: 'PATCH',
            data: {
              userId:
                values.user !== undefined && values.user !== 'auto'
                  ? values.user
                  : values.user === 'auto'
                    ? null
                    : values.user,
            },
          }),
        ).then((res) => {
          res = res.data.data
          const newTaskId = res.id
          const responsibleUser = res.user.last_name + ' ' + res.user.name
          setOpen(false)

          dispatch(
            authorizationLayer({
              url: `needs`,
              method: 'POST',
              data: {
                taskId: taskData?.id,
                moduleId: module?.id,
                mainTaskId: taskData?.mainTaskId,
                needs: [
                  {
                    type: modules.IWD,
                    meta: {
                      taskId: newTaskId,
                      taskStatus: 'ACTIVE',
                      responsibleUser,
                    },
                  },
                ],
              },
            }),
          ).then(() => {
            setDisableSubmit(true)
            setResponsibleUser(responsibleUser)
            setInfoVisible(false)
          })
        })
    }
    setLoading(false)
  }

  const getIconStatus = () => {
    if (
      status?.props?.className === 'dep-info__status  dep-info__status--error'
    ) {
      return <CloseCircleOutlined style={{ color: 'red' }} />
    }
    if (status?.props?.className === 'dep-info__status') {
      return <ExclamationCircleOutlined />
    }
    if (
      status?.props?.className === 'dep-info__status  dep-info__status--closed'
    ) {
      return <CheckCircleOutlined style={{ color: 'green' }} />
    }
  }

  useEffect(() => {
    getIconStatus()
  }, [currentNeeds])
  useEffect(() => {
    const sse_init = async () => {
      sse.current = await dispatch(SSE())
      sse.current.addEventListener(CREATED_TASK_UPDATED, onUpdated)
    }
    sse_init()
    return () => {
      if (sse.current) {
        sse.current.removeEventListener(CREATED_TASK_UPDATED, onUpdated)
      }
    }
  }, [])
  useEffect(() => {
    if (currentNeeds !== undefined) {
      setResponsibleUser(currentNeeds.meta.responsibleUser)
      if (currentNeeds.meta?.taskStatus === 'CANCELLED') {
        setInfoVisible(false)
        setStatus(
          <p className='dep-info__status  dep-info__status--error'>
            Отказ. Отправьте заявку повторно
          </p>,
        )
        setDisableSubmit(false)
        setNameButton(
          t('task.moduleRender.module.interactionWithDepartments.resendBtn'),
        )
        setResponsibleUser('')
      } else if (currentNeeds.meta?.taskStatus === 'ACTIVE') {
        setInfoVisible(false)
        setStatus(
          <p className='dep-info__status'>
            {t(
              'task.moduleRender.module.interactionWithDepartments.requestSent',
            )}{' '}
            :
          </p>,
        )
        setDisableSubmit(true)
      } else if (currentNeeds.meta?.taskStatus === 'COMPLETED') {
        setInfoVisible(false)
        setStatus(
          <p className='dep-info__status  dep-info__status--closed'>
            {t(
              'task.moduleRender.module.interactionWithDepartments.completedStatus',
            )}
          </p>,
        )
        setDisableSubmit(true)
        setResponsibleUser('')
      }
    }
  }, [currentNeeds])
  return (
    <div>
      {cardTitle}

      <Button
        ghost
        shape='round'
        type='primary'
        disabled={disableSubmit}
        onClick={() => showDrawer()}
        loading={loading}
        style={{
          display: disableSubmit ? 'none' : 'block',
          marginBottom: disableSubmit ? '0px' : '20px',
        }}
      >
        {nameButton}
      </Button>
      <Button
        ghost
        shape='round'
        type='primary'
        disabled={
          status?.props?.className ===
          'dep-info__status  dep-info__status--closed' ||
          status?.props?.className ===
          'dep-info__status  dep-info__status--error'
        }
        onClick={() => showDrawer(true)}
        loading={loadingUser}
        style={{
          display: status ? 'block' : 'none',
        }}
      >
        Изменить ответственного
      </Button>

      <div
        className='dep-info'
        style={{ display: infoVisible ? 'none' : 'block', marginTop: '12px' }}
      >
        <div className='dep-info__user-wrap'>
          <div className='dep-info__user' style={{ display: 'flex' }}>
            <div style={{ marginRight: '8px' }}>{getIconStatus()}</div>

            <span className='dep-info__user-responsible'>
              {/* {t('task.moduleRender.module.interactionWithDepartments.who')}:{' '}
               */}
              {status}
            </span>
            <span style={{ fontWeight: 500, marginLeft: '5px' }}>
              {' '}
              {responsibleUser}
            </span>
          </div>
          {/* TODO Доработать */}
          {/*<a href={currentNeeds !== undefined ? currentNeeds.meta.taskId : null} className="dep-info__history">История заявки</a>*/}
        </div>
      </div>
      <Drawer
        title={
          change
            ? 'Изменить ответственного'
            : t(
              'task.moduleRender.module.interactionWithDepartments.anotherDep',
            )
        }
        placement='right'
        onClose={onClose}
        open={open}
        width={'448px'}
      >
        <Form
          form={form}
          onFinish={onSubmit}
          onFinishFailed={(values) => console.log(values)}
          layout='vertical'
          autoComplete='off'
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          initialValues={{
            appeal_type: 'crm',
          }}
        >
          <Form.Item name='user' label={t('main.createTask.changeUserLabel')}>
            <Select
              placeholder={t('main.createTask.changeUserPlaceholder')}
              size='large'
              className='task-create__select'
              disabled={!module?.settings?.processId || loading}
            >
              {users?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.full_name || item.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name='comment'
            label={t('main.createTask.enterCommentLabel')}
          >
            <Input.TextArea
              placeholder={t('main.createTask.enterCommentPlaceholder')}
              style={{ borderRadius: 6 }}
              disabled={loading}
            />
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            shape='round'
            style={{ width: '100%', marginTop: 'auto' }}
            size='large'
            loading={loading}
          >
            {t('task.actionHistory.switchUserFromTask.sendBtn')}
          </Button>
        </Form>
      </Drawer>
    </div>
  )
})

export default DepartmentsInteraction
