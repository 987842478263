import WrapperItem from '../WrapperItem'
import RowApplication from '../RowApplication'
import { getNameModule } from '../../../../../../utils'

const Coordination = (props) => {
  const { coordinationList, allModules } = props

  return (
    <WrapperItem title={'Согласование'}>
      {coordinationList.map((item) => {
        const { id, meta } = item
        return (
          <RowApplication
            title={getNameModule(item.module.id, allModules)}
            data={item}
            id={id}
            meta={meta}
            key={id}
          />
        )
      })}
    </WrapperItem>
  )
}

export default Coordination
