import { Collapse, Input, Select, Typography } from 'antd'

import './styles.less'
import { getNameModule } from '../../../../../../utils'
import WrapperItem from '../WrapperItem'

const { Panel } = Collapse

const CompletedForms = (props) => {
  const { formList, allModules } = props

  const renderContent = (item) => {
    if (
      item.type !== 'paragraph' &&
      item.type !== 'select' &&
      item.type !== 'multi_select'
    ) {
      return (
        <Input.TextArea
          value={item.fieldData}
          className='confirm-form__field'
          disabled
        />
      )
    } else if (item.type === 'select' || item.type === 'multi_select') {
      return (
        <Select
          disabled
          className='confirm-form__select'
          value={item.fieldData}
          mode={item.type === 'multi_select' && 'multiple'}
          showArrow={false}
          size='large'
        />
      )
    } else if (item.type === 'paragraph') {
      return (
        <Input.TextArea
          value={item.fieldData}
          className='confirm-form__field'
          disabled
        />
      )
    }
  }

  return (
    <WrapperItem title={'Заполненные формы'}>
      <Collapse className={'completed'}>
        {formList &&
          formList.length &&
          formList.map((item) => {
            const { id, meta } = item
            return (
              <Panel
                header={
                  <Typography.Text strong>
                    {getNameModule(item.module.id, allModules)}
                  </Typography.Text>
                }
                key={id}
                id={id}
                className={'completed--panel'}
              >
                <div className={'completed--meta-container'}>
                  {meta.map((file) => {
                    const { name, formName, id } = file
                    let title
                    if (name) {
                      title = name
                    } else if (formName) {
                      title = formName
                    } else {
                      title = 'Нет данных'
                    }
                    return (
                      <div
                        key={id}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 12,
                        }}
                      >
                        <Typography.Text>{title}</Typography.Text>
                        {renderContent(file)}
                      </div>
                    )
                  })}
                </div>
              </Panel>
            )
          })}
      </Collapse>
    </WrapperItem>
  )
}

export default CompletedForms
