import React from 'react'
import { Space, Typography, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import SkeletonTable from '@ui/SkeletonTable'

const TestDrive = ({ data }) => {
  const { t } = useTranslation()
  const { meta, title } = data

  const columns = [
    {
      title: t('confirm.carRecepton.testDrive.columns.name'),
      dataIndex: 'name',
      width: 334,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carCheckPoint?.name ? record?.carCheckPoint?.name : null
          }
        >
          {record?.carCheckPoint?.name ? record?.carCheckPoint?.name : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('task.actionHistory.modal.checkList.comment'),
      dataIndex: 'comment',
      width: 334,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.comment ? record?.comment : null}
        >
          {record?.comment ? record?.comment : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
  ]
  return (
    <>
      <Space direction='vertical' size={8} style={{ width: '100%' }}>
        <Typography.Title style={{ fontWeight: 400 }} level={5}>
          {title}
        </Typography.Title>
        <SkeletonTable
          data={meta || []}
          columns={columns}
          width='calc(100% - 449px - 100px)'
          className='confirm-choice-product__table'
        />
      </Space>
    </>
  )
}

export default TestDrive
