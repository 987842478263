import React, { useState } from 'react'
import { Divider, Layout } from 'antd'
import './index.less'
import { ClientHistory, ClientInfo } from './components'

const Index = (props) => {
  const [client, setClient] = useState()
  const { taskData, setTaskData, id, loading, showTaskHistory } = props

  return (
    <div>
      {!loading && (
        <Layout.Content className='task__action-history'>
          <ClientInfo
            data={{
              taskData,
              client,
              setClient,
              setTaskData,
              id,
            }}
          />
          <Divider style={{ margin: 0 }} />

          <ClientHistory
            data={{ taskData, client, setClient }}
            showTaskHistory={showTaskHistory}
          />
        </Layout.Content>
      )}
    </div>
  )
}

export default Index
