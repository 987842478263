import React, { useContext } from 'react'
import { ModuleContextConfirm } from '../../render'
import { Typography, Divider, Row, Col, Collapse, Tooltip, Empty } from 'antd'
import { Image } from 'antd'
import { useTranslation } from 'react-i18next'
import { modules } from '@/constants'
import _ from 'lodash'
import doc from '@img/doc-icon.svg'
import xls from '@img/xls-icon.svg'
import pdf from '@img/pdf-icon.svg'

const Files = () => {
  const { data } = useContext(ModuleContextConfirm)
  const { t } = useTranslation()

  const getData = () => {
    const items = []

    if (data.process && _.isArray(data.process.stages)) {
      data.process.stages.forEach((stage) => {
        if (_.isArray(stage.modules)) {
          stage.modules.forEach((module) => {
            if (
              module?.component?.componentType?.name === modules.UPLOAD_FILE &&
              data.module.needs?.find((i) => i.module?.id === module?.id)
            ) {
              items.push(module)
            }
          })
        }
      })
    }

    return items
  }

  const getNeeds = (moduleId) => {
    const currentNeeds = data?.module?.needs?.find(
      (item) => item.module?.id === moduleId
    )

    const { meta } = currentNeeds
    let content = [null, null]
    const photosTypes = ['png', 'jpg', 'jpeg', 'bmp']
    const filesTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf']

    meta?.forEach((item) => {
      const fileType = item.path.split('.').at(-1)

      if (fileType === 'doc' || fileType === 'docx') item.icon = doc
      if (fileType === 'xlsx' || fileType === 'xls') item.icon = xls
      if (fileType === 'pdf') item.icon = pdf

      if (photosTypes.includes(fileType))
        content[0] = (
          <Image.PreviewGroup>
            {meta
              ?.filter((filtered) =>
                photosTypes.includes(filtered.path.split('.').at(-1))
              )
              .map((photos) => {
                return (
                  <div
                    style={{
                      width: 72,
                      height: 72,
                      marginRight: 8,
                      border: '1px solid #D9D9D9',
                      borderRadius: 2,
                      background: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginBottom: 8,
                    }}
                    key={photos.id}
                  >
                    <Image
                      src={photos.path}
                      style={{ width: 60, height: 60 }}
                    />
                  </div>
                )
              })}
          </Image.PreviewGroup>
        )

      if (filesTypes.includes(fileType)) {
        content[1] = meta
          ?.filter((filtered) =>
            filesTypes.includes(filtered.path.split('.').at(-1))
          )
          .map((files) => {
            return (
              <div
                style={{
                  width: 72,
                  height: 72,
                  marginRight: 8,
                  border: '1px solid #D9D9D9',
                  borderRadius: 2,
                  background: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginBottom: 8,
                  cursor: 'pointer',
                }}
                key={files.id}
              >
                <Tooltip title={files.name}>
                  <a href={files.path} download target='blank'>
                    <img src={files.icon} />
                  </a>
                </Tooltip>
              </div>
            )
          })
      }
    })

    return { meta, content }
  }

  return (
    <>
      <Row justify='space-between'>
        <Col>
          <Typography.Title level={5}>
            {t('confirm.files.title')}
          </Typography.Title>{' '}
        </Col>
      </Row>
      <Divider />
      {getData().length ? (
        <Collapse ghost>
          {getData().map((i) => {
            return (
              <Collapse.Panel
                key={i.id}
                header={`${i.name} (${getNeeds(i.id)?.meta?.length})`}
              >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {getNeeds(i.id)?.content?.map((i, index) => {
                    return <React.Fragment key={index}>{i}</React.Fragment>
                  })}
                </div>
              </Collapse.Panel>
            )
          })}
        </Collapse>
      ) : (
        <Empty />
      )}
    </>
  )
}

export default Files
