import React, { useEffect, useContext } from 'react'
import { useCallback } from 'react'
import { Input, Form } from 'antd'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import '../CheckList/index.less'
import { Context } from '../..'
import { TaskContext } from '../../../../../../..'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { modules, stockTypes } from '@/constants'

const Comments = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { taskId, moduleId, selectAuto,  setComment, mainTaskId } =
    useContext(Context)
  const { setNeedsData, needsData } = useContext(TaskContext)


  const onComment = useCallback(
    _.debounce(async (comment) => {
      let objWithAdditional = selectAuto || {}
      objWithAdditional.additional_option = comment
      setComment(comment)

      await dispatch(
        authorizationLayer({
          url: `needs`,
          method: 'POST',
          data: {
            taskId,
            moduleId,
            mainTaskId,
            needs: [
              {
                type: modules.CAR_RECEPTION,
                action: 'choice',
                meta: objWithAdditional,
                subType: 'choiceCar',
              },
            ],
          },
        })
      ).then(({ data }) => {
        let index = _.findIndex(needsData, (i) => i.subType === 'choiceCar')

        if (index !== -1) {
          needsData[index] = data.data[0]

          setNeedsData([...needsData])
        } else setNeedsData([...needsData, ...data.data])
      })
    }, 500),
    [selectAuto]
  )

  return (
    <div className='checklist'>
    <div className='checklist__title'>
      {t('task.actionHistory.modal.checkList.desc')}
    </div>
    <Form
      autoComplete='off'
      layout={'vertical'}
      form={form}
      initialValues={{
        desc: selectAuto?.additional_option,
      }}
      preserve={false}
    >
      <Form.Item name='desc'>
        <Input.TextArea
          placeholder='Укажите дополнительную информацию'
          showCount
          maxLength={250}
          className='checklist__comment'
          onChange={(e) => onComment(e.target.value)}
        />
      </Form.Item>
    </Form>
  </div>
  )
}

export default Comments