import React from 'react'
import external from '@img/external.svg'
import internal from '@img/internal.svg'
import _ from 'lodash'
import detailsData from './data'
import { Badge } from 'antd'

const images = {
  internal,
  external,
}

const Details = ({ data }) => {
  const { type, meta, selected, setSelected } = data

  return (
    <div>
      <div className='details__image-car-picker'>
        {detailsData[type].map(({ name, position, lkp, badgeOffset }) => {
          const className = ['dot']
          const containerClassName = ['circle']
          const filled = _.find(meta, (v) => {
            return v.carDetail.short_name === name
          })
          let badgeColor = '#697186'

          if (filled) {
            badgeColor = '#0989FF'
            className.push('filled')
            containerClassName.push('filled')
          } else {
            className.push('empty')
            containerClassName.push('empty')
            badgeColor = 'rgba(41, 52, 82, 0.4)'
          }

          if (
            filled?.lkp &&
            !filled?.carConfirmations.length &&
            !filled?.comment &&
            !filled?.photos.length
          ) {
            badgeColor = '#697186'
            className.push('filled filled--without')
            containerClassName.push('filled filled--without')
          } else if (
            (filled?.lkp && filled?.carConfirmations.length) ||
            (filled?.lkp && filled?.comment) ||
            (filled?.lkp && filled?.photos.length)
          ) {
            badgeColor = '#0989FF'
            className.push('filled')
            containerClassName.push('filled')
          } else if (
            !filled?.lkp &&
            !filled?.carConfirmations.length &&
            !filled?.comment &&
            !filled?.photos.length
          ) {
            className.push('empty')
            containerClassName.push('empty')
            badgeColor = 'rgba(41, 52, 82, 0.4)'
          }

          if (name === selected) {
            className.push('active')
            containerClassName.push('active')
            badgeColor = '#096DD9'
          }

          return (
            <div
              key={name}
              onClick={() => setSelected(name)}
              className={containerClassName.join(' ')}
              style={{ ...position }}
            >
              {lkp ? (
                <Badge
                  color={badgeColor}
                  count={filled?.lkp}
                  offset={filled?.lkp > 99 ? [-10, 0] : [0, 0]}
                  className='details__badge'
                  overflowCount={9999}
                  children={
                    !filled?.lkp && <div className={className.join(' ')}></div>
                  }
                  onClick={() => setSelected(name)}
                />
              ) : (
                <div className={className.join(' ')}></div>
              )}
            </div>
          )
        })}
        <img src={images[type]} alt={type} />
      </div>
    </div>
  )
}

export default Details
