import { Funnel } from '@antv/g2plot'

export const funnel_config = (data) => ({
  data,
  xField: 'name',
  yField: 'total',
  legend: false,
  height: 700,
  autoFit: false,
  tooltip: false,
  conversionTag: {
    formatter: (datum) => {
      return `${(
        (datum[Funnel.CONVERSATION_FIELD][1] /
          datum[Funnel.CONVERSATION_FIELD][0]) *
        100
      ).toFixed(2)}%`
    },
  },
})

export const pie_config = (data) => ({
  height: 300,
  autoFit: false,
  data,
  angleField: 'total',
  colorField: 'name',
  color: ['#1890FF', '#73D13D', '#FF4D4F'],
  radius: 0.9,
  label: {
    type: 'inner',
    offset: '-30%',
    content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    style: {
      fontSize: 14,
      textAlign: 'center',
    },
  },
  interactions: [
    {
      type: 'element-active',
    },
  ],
})

export const bar_config = (data) => ({
  height: 215,
  data,
  xField: 'total',
  yField: 'name',
  barWidthRatio: 0.8,
  meta: {
    type: {
      alias: '55',
    },
    sales: {
      alias: '123',
    },
  },
  label: {
    position: 'right',
    style: {
      fontWeight: 'bold',
    },
  },
  tooltip: false,
})

export const column_config = (data) => ({
  data,
  xField: 'name',
  yField: 'total',
  label: {
    position: 'middle',
    style: {
      fill: '#FFFFFF',
      opacity: 0.6,
    },
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false,
    },
  },
  tooltip: false,
})
