import { useEffect, useState } from 'react'

import { Badge, Empty, Space } from 'antd'

import { ICON } from '../../../../../../../../assets/img'
import SelectedElement from '../SelectedElement'
import './styles.less'

export const ExternalCarScheme = (props) => {
  // type can be external or internal
  const { meta, type = 'external', setSelectedElement, selectedElement } = props
  const [state, setState] = useState({
    external: [
      {
        name: 'Bumper_front',
        position: { left: 111, top: 8 },
        id: 'Bumper_front',
        value: null,
        carDetail: null,
      },
      {
        name: 'Hood_front',
        position: { left: 111, top: 50 },
        id: 'Hood_front',
        value: null,
        carDetail: null,
      },
      {
        name: 'Hood_rear',
        position: { left: 111, top: 220 },
        id: 'Hood_rear',
        value: null,
        carDetail: null,
      },
      {
        name: 'Windshield_front',
        position: { left: 111, top: 93 },
        id: 'Windshield_front',
        value: null,
        carDetail: null,
      },
      {
        name: 'Windshield_rear',
        position: { left: 111, top: 190 },
        id: 'Windshield_rear',
        value: null,
        carDetail: null,
      },
      {
        name: 'Roof',
        position: { left: 111, top: 140 },
        id: 'Roof',
        value: null,
        carDetail: null,
      },
      {
        name: 'Bumper_rear',
        position: { left: 111, top: 258 },
        id: 'Bumper_rear',
        value: null,
        carDetail: null,
      },
      {
        name: 'Wheel_front_left',
        position: { left: 24, top: 67 },
        id: 'Wheel_front_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Wheel_front_right',
        position: { left: 203, top: 67 },
        id: 'Wheel_front_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Wheel_rear_left',
        position: { left: 27, top: 202 },
        id: 'Wheel_rear_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Wheel_rear_right',
        position: { left: 200, top: 202 },
        id: 'Wheel_rear_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Headlight_front_left',
        position: { left: 48, top: 29 },
        id: 'Headlight_front_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Headlight_front_right',
        position: { left: 175, top: 30 },
        id: 'Headlight_front_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Headlight_rear_left',
        position: { left: 53, top: 236 },
        id: 'Headlight_rear_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Headlight_rear_right',
        position: { left: 170, top: 236 },
        id: 'Headlight_rear_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Wing_front_left',
        position: { left: 49, top: 52 },
        id: 'Wing_front_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Wing_front_right',
        position: { left: 177, top: 52 },
        id: 'Wing_front_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Wing_rear_left',
        position: { left: 56, top: 208 },
        id: 'Wing_rear_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Wing_rear_right',
        position: { left: 165, top: 208 },
        id: 'Wing_rear_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Sill_left',
        position: { left: 22, top: 133 },
        id: 'Sill_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Sill_right',
        position: { left: 205, top: 133 },
        id: 'Sill_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Door_front_left',
        position: { left: 40, top: 113 },
        id: 'Door_front_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Door_front_right',
        position: { left: 180, top: 113 },
        id: 'Door_front_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Glass_front_left',
        position: { left: 68, top: 129 },
        id: 'Glass_front_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Glass_front_right',
        position: { left: 152, top: 129 },
        id: 'Glass_front_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Door_rear_left',
        position: { left: 41, top: 161 },
        id: 'Door_rear_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Door_rear_right',
        position: { left: 180, top: 161 },
        lkp: true,
        badgeOffset: [10, 10],
        id: 'Door_rear_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Glass_rear_left',
        position: { left: 68, top: 175 },
        id: 'Glass_rear_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Glass_rear_right',
        position: { left: 153, top: 175 },
        id: 'Glass_rear_right',
        value: null,
        carDetail: null,
      },
    ],
    internal: [
      {
        name: 'Door_inner_front_left',
        position: { left: 40, top: 118 },
        id: 'Door_inner_front_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Door_inner_front_right',
        position: { left: 180, top: 118 },
        id: 'Door_inner_front_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Door_inner_rear_left',
        position: { left: 40, top: 160 },
        id: 'Door_inner_rear_left',
        value: null,
        carDetail: null,
      },
      {
        name: 'Door_inner_rear_right',
        position: { left: 180, top: 160 },
        id: 'Door_inner_rear_right',
        value: null,
        carDetail: null,
      },
      {
        name: 'Steering_wheel',
        position: { left: 89, top: 93 },
        id: 'Steering_wheel',
        value: null,
        carDetail: null,
      },
      {
        name: 'Drivers_seat',
        position: { left: 96, top: 125 },
        id: 'Drivers_seat',
        value: null,
        carDetail: null,
      },
      {
        name: 'Front_passenger_seat',
        position: { left: 130, top: 125 },
        id: 'Front_passenger_seat',
        value: null,
        carDetail: null,
      },
      {
        name: 'Rear_passenger_seat',
        position: { left: 113.5, top: 179 },
        id: 'Rear_passenger_seat',
        value: null,
        carDetail: null,
      },
      {
        name: 'Transmission',
        position: { left: 113.5, top: 106 },
        id: 'Transmission',
        value: null,
        carDetail: null,
      },
      {
        name: 'Vehicle_dashboard',
        position: { left: 113.5, top: 60 },
        id: 'Vehicle_dashboard',
        value: null,
        carDetail: null,
      },
    ],
    isIssue: false,
  })

  const addCarDetail = (arr) => {
    return arr.map((item) => {
      const result = meta.find((element) => {
        return element.carDetail.short_name === item.name
      })
      return result
        ? {
            ...item,
            isIssue: true,
            carDetail: {
              carConfirmations: result.carConfirmations,
              name: result.carDetail.name,
              short_name: result.carDetail.short_name,
              comment: result.comment,
              lkp: result.lkp,
              photos: result.photos,
            },
          }
        : item
    })
  }

  useEffect(() => {
    if (type === 'external' && meta) {
      const newExternal = addCarDetail(state.external)
      const isIssue = newExternal.find((item) => item.carDetail)

      setState({ ...state, external: newExternal, isIssue: isIssue })
    }
    if (type === 'internal' && meta) {
      const newInternal = addCarDetail(state.internal)
      const isIssue = newInternal.find((item) => item.carDetail)
      setState({ ...state, internal: newInternal, isIssue: isIssue })
    }
  }, [meta])

  return (
    <div className={'carScheme-container'}>
      <Space>
        <div className={'car-scheme'}>
          {type === 'external' && <ICON.external />}
          {type === 'internal' && <ICON.internal />}

          <div className={'car-scheme--badges'}>
            {state[type].map((item) => {
              const { id, position, carDetail } = item

              const checkCount = carDetail
                ? carDetail.lkp
                  ? carDetail.lkp
                  : ' '
                : ' '

              const style = {
                ...position,
                backgroundColor: `${carDetail ? '#0989FF' : '#999EAA'}`,
                cursor: `${carDetail ? 'pointer' : 'default'}`,
                // position: 'absolute',
              }
              return (
                <Badge
                  onClick={
                    carDetail
                      ? () => setSelectedElement(carDetail, type)
                      : () => {}
                  }
                  count={checkCount}
                  style={style}
                  id={id}
                  key={id}
                  className={'car-scheme--badge'}
                  size={'small'}
                  overflowCount={9999}
                />
              )
            })}
          </div>
        </div>
      </Space>
      <div className={'car-scheme--selected-element'}>
        {state.isIssue ? (
          <SelectedElement element={selectedElement} />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  )
}
