import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { authorizationLayer } from '@store/actions/methods'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import SkeletonTable from '@ui/SkeletonTable'
import {
  columnsForServices,
  nestedColumns,
} from '@views/Warehouse/methods/columns'
import { getFilters } from '@views/Warehouse/methods/filters'
import { Input } from 'antd'
import { openModals } from '@utils'
import { useTranslation } from 'react-i18next'
const { Search } = Input

const T = (props) => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [metaData, setMetaData] = useState()
  const { stockType, bodyHeight } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [filtersData, setFiltersData] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })

  const [filters, setFilters] = useState(null)
  const [sorter, setSorter] = useState({
    field: 'category',
    order: 'ascend',
  })

  useEffect(async () => {
    const q = {
      page: pagination.current,
      limit: pagination.pageSize,
    }
    const query = queryString.stringify(q)
    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-services?${query}`,
      })
    )
      .then(({ data }) => {
        setData(data.data)
        setMetaData(data.meta)
      })
      .finally(() => setLoading(false))
  }, [dispatch])

  const handleTableChange = async (pagination, filters, sorter, extra) => {
    setSorter({
      field: sorter.field,
      order: sorter.order,
    })

    setFilters(filters)

    setPagination({
      pageSize: pagination.pageSize,
      current: pagination.current,
    })
    let q = {
      page: pagination.current || 1,
      limit: pagination.pageSize || 10,
    }

    if (searchValue.length) q.keyword = searchValue

    if (Object.keys(sorter)) {
      q.sortElement = sorter.field
      q.sortBy = sorter.order === 'ascend' || !sorter.order ? 'ASC' : 'DESC'
    }

    if (filters) {
      Object.values(filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (filters?.category) q.category = filters?.category
        }
      })
    }

    const query = queryString.stringify(q, { arrayFormat: 'comma' })

    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-services?${query}`,
      })
    )
      .then(({ data }) => {
        setData(data.data)
        setMetaData(data.meta)
      })
      .finally(() => setLoading(false))
  }

  const handleSearch = async (value) => {
    if (value) setSearchValue(value.target.value.trim())

    let q = {
      page: 1,
      limit: 10,
    }

    if (Object.keys(sorter)) {
      q.sortElement = sorter.field
      q.sortBy = sorter.order === 'ascend' || !sorter.order ? 'ASC' : 'DESC'
    }

    if (value && value.target.value.length)
      q.keyword = value.target.value.trim()

    if (filters) {
      Object.values(filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (filters?.category) q.category = filters?.category
        }
      })
    }

    const query = queryString.stringify(q, { arrayFormat: 'comma' })
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `wh-services?${query}`,
      })
    )
      .then(({ data }) => {
        setData(data.data)
        setMetaData(data.meta)
      })
      .finally(() => setLoading(false))
  }

  useEffect(async () => {
    setLoading(true)

    const filters = await getFilters(dispatch, authorizationLayer, stockType)

    setFiltersData(filters)
  }, [getFilters, dispatch])

  const expandedRowRender = (row) => {
    const { carSpecifications } = row
    return (
      <div style={{ padding: 10 }}>
        <SkeletonTable
          columns={nestedColumns}
          data={carSpecifications}
          pagination={false}
          className='custom-table--expandable'
        />
      </div>
    )
  }

  return (
    <div>
      <Search
        placeholder={t('main.search')}
        allowClear
        style={{ width: 400, marginBottom: 15 }}
        onChange={_.debounce(handleSearch, 500)}
        onSearch={_.debounce(handleSearch, 500)}
      />
      <SkeletonTable
        height={bodyHeight - 30}
        columns={columnsForServices(filtersData)}
        data={data}
        loading={loading}
        pagination
        className='xScroll-custom-table--warehouse'
        total={metaData?.total}
        currentPage={metaData?.page}
        pageSize={metaData?.limit}
        tableChangeFunction={handleTableChange}
        pageSizeOptions={[10, 20, 50, 100]}
        onRowClickTrue={(e) => {
          openModals(dispatch, {
            type: 'product-details',
            id: e,
            spareStatus: false,
            stockType,
          })
        }}
        onRowClickStatus={() => true}
        cursorPointer
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record.carSpecifications?.length !== 0,
        }}
      />
    </div>
  )
}

export default T
