import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { Form, Col, Row, Typography, Input, Checkbox } from 'antd'
import './index.less'
import { Context } from '../..'
import _ from 'lodash'
import { TaskContext } from '../../../../../../..'
import { ModuleContext } from '../../../../..'
import { useTranslation } from 'react-i18next'
import { modules } from '@/constants'

const TestDrive = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const checkPoints = Form.useWatch('checkPoints', form)
  const { testDriveResults, taskId, moduleId, selectAuto, mainTaskId } =
    useContext(Context)
  const { setNeedsData, needsData } = useContext(TaskContext)
  const { currentNeeds } = useContext(ModuleContext)
  const [isEmpty, setIsEmpty] = useState(false)

  useEffect(() => {
    const step = currentNeeds?.find((i) => i.subType === 'testDrive')
    if (step?.meta === null) {
      setIsEmpty(false)
    } else if (Array.isArray(step?.meta) && !step?.meta?.length) {
      setIsEmpty(true)
    }
  }, [currentNeeds])

  const needsActions = (data) => {
    let index = _.findIndex(needsData, (i) => i.subType === 'testDrive')

    if (index !== -1) {
      needsData[index] = data.data[0]

      setNeedsData([...needsData])
    } else setNeedsData([...needsData, ...data.data])
  }

  const getData = async () => {
    await dispatch(
      authorizationLayer({
        url: 'wh-cars/used_car_test_drive/car_check_points',
      })
    ).then((res) => setData(res.data))

    let obj = {}

    testDriveResults?.forEach((item) => {
      obj[item.carCheckPoint.id] = item.comment
    })

    form.setFieldsValue({ checkPoints: obj })
  }

  useEffect(() => {
    getData()
  }, [])

  const onChangeIsEmpty = async () => {
    setIsEmpty(!isEmpty)

    await dispatch(
      authorizationLayer({
        url: `needs`,
        method: 'POST',
        data: {
          taskId,
          moduleId,
          mainTaskId,
          needs: [
            {
              type: modules.CAR_RECEPTION,
              action: 'choice',
              meta: !isEmpty ? [] : null,
              subType: 'testDrive',
            },
          ],
        },
      })
    ).then(({ data }) => {
      needsActions(data)
    })
  }

  const onChange = useCallback(
    _.debounce(async () => {
      if (!isEmpty) {
        let checkPoints = Object.getOwnPropertyNames(
          form.getFieldValue('checkPoints')
        ).map((key) => {
          const findName = data?.find((o) => o.id === key)
          return {
            carCheckPoint: {
              id: key,
              name: findName?.name,
            },
            comment: form.getFieldValue('checkPoints')[key]?.trim()
              ? form.getFieldValue('checkPoints')[key].trim()
              : null,
          }
        })

        await dispatch(
          authorizationLayer({
            url: `needs`,
            method: 'POST',
            data: {
              taskId,
              moduleId,
              mainTaskId,
              needs: [
                {
                  type: modules.CAR_RECEPTION,
                  action: 'choice',
                  meta: checkPoints?.filter((i) => i.comment !== null),
                  subType: 'testDrive',
                },
              ],
            },
          })
        ).then(({ data }) => {
          needsActions(data)
        })
      }
    }, 500),
    [data]
  )

  useEffect(() => {
    if (isEmpty && form.getFieldValue('checkPoints')) {
      let obj = {}
      Object.getOwnPropertyNames(form.getFieldValue('checkPoints')).forEach(
        (key) => {
          obj[key] = null
        }
      )

      form.setFieldsValue({ checkPoints: obj })
    }
  }, [isEmpty])

  return (
    <div className='test-drive'>
      <Checkbox
        style={{ marginBottom: 24 }}
        checked={isEmpty}
        onChange={onChangeIsEmpty}
      >
        {t('task.moduleRender.module.carReception.testDrive.continueWithOut')}
      </Checkbox>
      <Form
        autoComplete='off'
        layout={'vertical'}
        form={form}
        initialValues={{
          checkPoints: checkPoints,
        }}
      >
        {data?.map((item) => {
          return (
            <div key={item.id}>
              <Form.Item
                label={
                  <Typography.Title style={{ fontWeight: 500 }} level={5}>
                    {item.name}
                  </Typography.Title>
                }
                name={['checkPoints', item.id]}
              >
                <Input.TextArea
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.problemDescPlaceholder'
                  )}
                  maxLength={1000}
                  showCount
                  className='test-drive__textarea'
                  onChange={() => onChange()}
                  disabled={isEmpty}
                />
              </Form.Item>
            </div>
          )
        })}
      </Form>
    </div>
  )
}

export default TestDrive
