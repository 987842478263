import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";

const Marker = (props) => {
  const { itemWidth, startTime, endTime } = props;
  const [position, setPosition] = useState(0);
  const timer = useRef();

  const timelinePosition = useCallback(() => {
    let hour = moment().hour();
    let minute = moment().minute();

    if (hour < startTime) {
      hour = startTime;
      minute = 0;
    } else if (hour > endTime) {
      hour = endTime;
      minute = 30;
    }

    let position = itemWidth * (hour - startTime) * 2;
    position += (minute / 30) * itemWidth;
    setPosition(position);
  }, [itemWidth, startTime, endTime]);

  useEffect(() => {
    timelinePosition();
    timer.current = setInterval(timelinePosition, 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, [timelinePosition]);

  return (
    <div className="timeline__wrapper">
      <div className="timeline" style={{ left: position }}>
        <div className="dot" />
      </div>
    </div>
  );
};

export default Marker;
