import { useEffect } from 'react'
import * as actions from '@store/constants/methods'
import { useNavigate } from 'react-router-dom'
import AXIOS from 'axios'
import { persistor } from '../../index'
import i18n from '../../i18n'

export const LOGOUT = () => {
  return (dispatch) => {
    persistor.purge()
    dispatch(SET_APP(['token'], null))
    dispatch(SET_APP(['user'], null))
    dispatch({ type: 'INIT' })
  }
}

export const axios = AXIOS.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const resInterceptor = (response) => {
      return response
    }

    const errInterceptor = (error) => {
      if (error.response.status === 500 || error.response.status === 404)
        navigate(`error/${error.response.status}`)

      return Promise.reject(error)
    }

    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    )

    return () => axios.interceptors.response.eject(interceptor)
  }, [navigate])

  return children
}

let cancelToken
let controller

export const SET_APP = (path, value) => ({
  type: actions.SET_APP,
  path,
  value,
})
export const SET_APP_BY_PARAM = (path, param, value) => ({
  type: actions.SET_APP_BY_PARAM,
  path,
  param,
  value,
})
export const SET_APPS = (arr) => ({ type: actions.SET_APPS, arr })
export const PUSH_APP = (path, value) => ({
  type: actions.PUSH_APP,
  path,
  value,
})
export const UNSHIFT_APP = (path, value) => ({
  type: actions.UNSHIFT_APP,
  path,
  value,
})
export const REMOVE_APP_BY_PARAM = (path, key, value) => ({
  type: actions.REMOVE_APP_BY_PARAM,
  path,
  key,
  value,
})

export const authorizationLayer = (params) => {
  return async (dispatch, getState) => {
    const { token } = await getState().app

    // if (params.controller) params.controller.abort()

    // controller = new AbortController()

    if (!params.headers)
      params.headers = {
        accepts: 'application/json',
        ['Accept-Language']: i18n.language,
        ['Accept-type']: 'crm',
      }

    if (token) params.headers.Authorization = `Bearer ${token}`

    return axios({
      headers: params.headers,
      method: params.method,
      url: params.url,
      data: params.data,
      params: params.query,
      // signal: controller.signal,
    }).catch((error) => {
      if (error?.response?.status == 401) {
        persistor.purge()
        dispatch(SET_APP(['token'], null))
        dispatch(SET_APP(['user'], null))
        dispatch({ type: 'INIT' })
      }

      // if (error?.response?.status == 400) window.location.href = `/error/${404}`
    })
  }
}

export const GET_DATA_QUERY =
  ({ url, dataName, method = 'get', query }) =>
  async (dispatch) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    cancelToken = AXIOS.CancelToken.source()

    try {
      const { data } = await dispatch(
        authorizationLayer({
          url: `/${url}`,
          method,
          query,
        })
      )
      dispatch(SET_APPS([{ path: [dataName], value: data }]))
    } catch (error) {
    } finally {
    }
  }

export const POST_DATA = (url, formData) => async (dispatch) => {
  dispatch(SET_APP(['loader'], true))
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = AXIOS.CancelToken.source()

  try {
    return await dispatch(
      authorizationLayer({
        url: `/${url}`,
        method: 'post',
        data: formData,
        // cancelToken: cancelToken.token
      })
    )
  } catch (error) {
    if (error.response.data.statusCode == 409) {
      dispatch(SET_APP(['email_error'], true))
    }
  } finally {
    await dispatch(SET_APP(['loader'], false))
  }
}
