import React, { useEffect, useState } from 'react'
import { Radio, Typography, Form, Input, Select, Switch } from 'antd'
import { Divider } from 'antd'
import InputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import Loading from '@ui/Loading'
import { useTranslation } from 'react-i18next'
import DynamicField from '../../../DynamicField'

import { parsePhoneNumber } from 'awesome-phonenumber'

import './index.less'

const { Title, Text } = Typography

const FormClient = ({ id }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [client, setClient] = useState(null)
  const { t } = useTranslation()

  const client_type = Form.useWatch('client_type', form)

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const { data } = await dispatch(
          authorizationLayer({
            url: `clients/${id}`,
            method: 'get',
          })
        )

        setClient(data)
        form.setFieldsValue(data)
        setLoading(false)
      } catch (e) {
        console.log(e.message)
      }
    }
    getData()
  }, [dispatch, id, form])

  return (
    <div
      style={{
        marginTop: '12px',
        padding: '0px 20px 20px 20px',
        height: '85vh',
        overflowY: 'auto',
      }}
      className='form__scroll'
    >
      {loading && <Loading spinner={true} />}

      <Title style={{ fontSize: '15px', fontWeight: '500' }} level={5}>
        {t('clientCard.formClient.title')}
      </Title>

      <Form
        form={form}
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '10px',
        }}
        layout='vertical'
        autoComplete='off'
        initialValues={{
          client_type: 'individual',
        }}
      >
        <div className='form__type'>
          <Text>{t('clientCard.formClient.clientType')}</Text>
          <Form.Item style={{ marginTop: '10px' }} name='client_type'>
            <Radio.Group className='form__radio-list'>
              <Radio.Button className='form__radio' value='individual'>
                {t('clientCard.formClient.individual')}
              </Radio.Button>
              <Radio.Button className='form__radio' value='legal'>
                {t('clientCard.formClient.legal')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            {...error}
            name='phone_number'
            label={t('clientCard.formClient.phoneLabel')}
          >
            <InputMask
              mask='+7 999 999 99 99'
              maskChar={null}
              style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
              placeholder='+7(___) ___-__-__'
              // onChange={(e) => checkValidateNumber(e.target.value)}
              disabled
            >
              {(inputProps) => <Input disabled {...inputProps} />}
            </InputMask>
          </Form.Item>

          {client_type === 'individual' && (
            <Form.Item name='name' label={t('clientCard.formClient.nameLabel')}>
              <Input
                placeholder={t('clientCard.formClient.namePlaceholder')}
                disabled
                style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
              />
            </Form.Item>
          )}

          {client_type === 'individual' && (
            <Form.Item
              name='last_name'
              label={t('clientCard.formClient.surnameLabel')}
            >
              <Input
                placeholder={t('clientCard.formClient.surnamePlaceholder')}
                disabled
                style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
              />
            </Form.Item>
          )}

          {client_type === 'individual' && (
            <Form.Item
              name='middle_name'
              label={t('clientCard.formClient.middleNameLabel')}
            >
              <Input
                placeholder={t('clientCard.formClient.middleNamePlaceholder')}
                disabled
                style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
              />
            </Form.Item>
          )}

          {client_type === 'legal' && (
            <Form.Item
              name='business_type'
              label={t('clientCard.formClient.businessTypeLabel')}
            >
              <Select
                placeholder={t('clientCard.formClient.businessTypePlaceholder')}
                size='large'
                className='task-create__select'
                disabled
              >
                <Select.Option value='ip'>
                  {t('clientCard.formClient.IP')}
                </Select.Option>
                <Select.Option value='too'>
                  {t('clientCard.formClient.LLP')}
                </Select.Option>
                <Select.Option value='ao'>
                  {t('clientCard.formClient.JSC')}
                </Select.Option>
              </Select>
            </Form.Item>
          )}

          {client_type === 'legal' && (
            <Form.Item
              name='name'
              label={t('clientCard.formClient.companyNameLabel')}
            >
              <Input
                disabled
                style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
                placeholder={t('clientCard.formClient.companyNamePlaceholder')}
              />
            </Form.Item>
          )}

          {client_type === 'individual' && (
            <Form.Item
              name='gender'
              label={t('clientCard.formClient.genderLabel')}
            >
              <Select
                disabled
                placeholder={t('clientCard.formClient.genderPlaceholder')}
                size='large'
                className='task-create__select'
              >
                <Select.Option value='male'>
                  {t('clientCard.formClient.male')}
                </Select.Option>
                <Select.Option value='female'>
                  {t('clientCard.formClient.female')}
                </Select.Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name='address'
            label={t('clientCard.formClient.adressLabel')}
          >
            <Input
              disabled
              placeholder={t('clientCard.formClient.adressPlaceholder')}
              style={{ height: 40, padding: '8px 12px', borderRadius: 6 }}
            />
          </Form.Item>
          <Form.Item
            label={t('clientCard.formClient.consentLabel')}
            name='data_collection_consent'
            valuePropName='checked'
          >
            <Switch disabled />
          </Form.Item>
          {client?.fields_group.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <Divider />
                <Text style={{ marginBottom: 24, display: 'block' }} strong>
                  {item.name}
                </Text>
                {item.fields?.map((i) => {
                  return (
                    <DynamicField
                      key={i.id}
                      data={{
                        loading,
                        fieldData: i,
                        id,
                      }}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        </div>
      </Form>
    </div>
  )
}

export default FormClient
