import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useCallback } from 'react'
import { Select, Typography, Button, Modal } from 'antd'
import { Form, Collapse, Checkbox, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { GET_DATA_QUERY } from '@store/actions/methods'
import { authorizationLayer } from '@store/actions/methods'
import _ from 'lodash'
import './index.less'
import { Context } from '../../../..'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { TaskContext } from '../../../../../../../../..'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { modules } from '@/constants'

const { Panel } = Collapse

const OptionSection = ({ section, onChange }) => {
  const { t } = useTranslation()
  const groups = useMemo(() => {
    const inputs = Object.getOwnPropertyNames(section.carOptions).map((key) => {
      const options = section.carOptions[key]
      return {
        name: key,
        options: section.carOptions[key],
        type: options[0].type,
      }
    })

    return {
      selects: inputs.filter((i) => i.type === 'select'),
      multiselects: inputs.filter((i) => i.type === 'multi_select'),
      checkboxes: inputs.filter((i) => i.type === 'check_box'),
    }
  }, [section.carOptions])

  return (
    <div>
      <Row gutter={20}>
        {groups.selects.map((select) => (
          <Col
            key={select.name}
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 16 }}
          >
            <Form.Item name={['options', select.name]} label={select.name}>
              <Select
                allowClear
                placeholder={t(
                  'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                )}
                options={select.options.map((option) => ({
                  label: option.value,
                  value: option.id,
                }))}
                className='checklist__select'
                onChange={onChange(select)}
              />
            </Form.Item>
          </Col>
        ))}
        {groups.multiselects.map((select) => (
          <Col
            key={select.name}
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 16 }}
          >
            <Form.Item name={['options', select.name]} label={select.name}>
              <Select
                mode='multiple'
                maxTagCount='responsive'
                allowClear
                placeholder={t(
                  'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                )}
                options={select.options.map((option) => ({
                  label: option.value,
                  value: option.id,
                }))}
                className='checklist__select'
                onChange={onChange(select)}
              />
            </Form.Item>
          </Col>
        ))}
        <Row gutter={20}>
          {groups.checkboxes.map((checkbox) => (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 16 }}
              key={checkbox.name}
            >
              <Form.Item
                name={['options', checkbox.options[0].id]}
                valuePropName='checked'
                style={{ width: '200px', marginRight: '20px' }}
              >
                <Checkbox onChange={onChange(checkbox)}>
                  {checkbox.name}
                </Checkbox>
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Row>
    </div>
  )
}

const OptionsBlock = ({ taskId, moduleId, mainTaskId }) => {
  const dispatch = useDispatch()
  const { car_options } = useSelector((state) => state.app)
  const form = Form.useFormInstance()
  const [visible, setVisible] = useState(false)
  const [categoryTitle, setCategoryTitle] = useState('')
  const [category, setCategory] = useState()
  const { checkList } = useContext(Context)
  const { needsData, setNeedsData } = useContext(TaskContext)
  const [change, setChange] = useState(false)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (selected.length === 0) {
      let data = []
      if (car_options) {
        data = car_options?.map((c) => {
          const car_option = { ...c }
          const isSelected = _.find(checkList, { id: c.id })
          if (isSelected) return { ...isSelected }
          delete car_option.carOptions
          return { ...car_option, options: [] }
        })
        setSelected(data)
      }
    }
  }, [car_options, checkList])

  useEffect(() => {
    dispatch(
      GET_DATA_QUERY({
        url: `wh-cars/references/car_options/`,
        dataName: 'car_options',
      })
    )
  }, [])

  const onChange = (section) => (val) => (e) => {
    const blockIndex = _.findIndex(selected, { id: section.id })
    setChange(true)

    if (val.type === 'select') {
      if (!e) {
        const arr = _.filter(
          selected[blockIndex].options,
          (o) => o.name != val.name
        )
        selected[blockIndex].options = [...arr]
        return setSelected([...selected])
      }
      const item = _.find(val.options, { id: e })
      const itemIndex = _.findIndex(selected[blockIndex].options, {
        name: item.name,
      })

      if (itemIndex === -1) {
        selected[blockIndex].options.push(item)
        setSelected([...selected])
      } else {
        selected[blockIndex].options[itemIndex] = item
        setSelected([...selected])
      }
    } else if (val.type === 'multi_select') {
      const items = _.filter(val.options, (o) => e.indexOf(o.id) !== -1)
      const arr = _.filter(
        selected[blockIndex].options,
        (o) => o.name != val.name
      )
      selected[blockIndex].options = [...arr, ...items]
      setSelected([...selected])
    } else if (val.type === 'check_box') {
      const item = val.options[0]
      if (e.target.checked) {
        selected[blockIndex].options.push(item)
        setSelected([...selected])
      } else {
        const arr = _.filter(
          selected[blockIndex].options,
          (o) => o.name != val.name
        )
        selected[blockIndex].options = [...arr]
        setSelected([...selected])
      }
    }
  }

  const sendNeeds = useCallback(
    _.debounce(async (selected) => {
      await dispatch(
        authorizationLayer({
          url: `needs`,
          method: 'POST',
          data: {
            taskId: taskId,
            moduleId: moduleId,
            mainTaskId: mainTaskId,
            needs: [
              {
                type: modules.CAR_RECEPTION,
                action: 'choice',
                meta: selected.filter((i) => i.options?.length).length
                  ? selected.filter((i) => i.options?.length)
                  : null,
                subType: 'checkList',
              },
            ],
          },
        })
      ).then(({ data }) => {
        let index = _.findIndex(needsData, (i) => i.subType === 'checkList')

        if (index !== -1) {
          needsData[index] = data.data[0]

          setNeedsData([...needsData])
        } else setNeedsData([...needsData, ...data.data])
      })
    }, 500),
    []
  )

  useEffect(() => {
    if (change) sendNeeds(selected)
  }, [selected])

  const extraContent = (section) => {
    let status = false
    if (form.getFieldValue('options'))
      Object.values(section.carOptions).forEach((key) => {
        key.forEach((i) => {
          if (i.type === 'check_box') {
            if (Object.keys(form.getFieldValue('options')).includes(i.id)) {
              if (form.getFieldValue('options')[i.id] === true) {
                status = true
              }
            }
          } else if (i.type === 'select') {
            if (Object.values(form.getFieldValue('options')).includes(i.id)) {
              status = true
            }
          } else if (i.type === 'multi_select') {
            Object.values(form.getFieldValue('options')).forEach((o) => {
              if (Array.isArray(o)) {
                if (o.includes(i.id)) {
                  status = true
                }
              }
            })
          }
        })
      })

    if (!status)
      return (
        <Typography.Text type='secondary'>
          {t('task.moduleRender.module.carReception.checkList.empty')}
        </Typography.Text>
      )
    else
      return (
        <Button
          type='link'
          className='checklist__options-clear'
          onClick={(e) => {
            setVisible(true)
            e.stopPropagation()
            setCategoryTitle(section.name)
            setCategory(section)
          }}
        >
          {t('task.moduleRender.module.carReception.checkList.reset')}
        </Button>
      )
  }

  const onClear = () => {
    if (!category) return
    Object.keys(category.carOptions).forEach((key) => {
      const options = category.carOptions[key]
      options.forEach((o) => {
        if (o.type === 'select') {
          form.setFieldsValue({
            options: {
              [o.name]: undefined,
            },
          })
        } else if (o.type === 'multi_select') {
          form.setFieldsValue({
            options: {
              [o.name]: [],
            },
          })
        } else if (o.type === 'check_box') {
          form.setFieldsValue({
            options: {
              [o.id]: false,
            },
          })
        }
      })
    })
    setChange(true)
    const blockIndex = _.findIndex(selected, { id: category.id })
    selected[blockIndex].options = []
    setSelected([...selected])
  }

  return (
    <div className='checklist__options'>
      <Collapse className='checklist__collapse' bordered={false} ghost>
        {car_options?.map((section) => {
          return (
            <Panel
              forceRender
              header={<div style={{ fontWeight: 600 }}>{section.name}</div>}
              key={section.id}
              extra={extraContent(section)}
            >
              <OptionSection section={section} onChange={onChange(section)} />
            </Panel>
          )
        })}
      </Collapse>
      <Modal
        visible={visible}
        wrapClassName='checklist__modal'
        width={416}
        bodyStyle={{ padding: '32px 32px 32px 70px' }}
        closable={false}
        onCancel={() => setVisible(false)}
        okText={t('task.moduleRender.module.carReception.checkList.reset')}
        onOk={() => {
          onClear()
          setVisible(false)
        }}
        okButtonProps={{ type: 'danger' }}
      >
        <ExclamationCircleOutlined
          style={{
            color: '#FF4D4F',
            fontSize: 22,
            position: 'absolute',
            left: 32,
          }}
        />
        <div style={{ fontSize: 16, fontWeight: 700 }}>
          {t('task.moduleRender.module.carReception.checkList.reset')} «
          {categoryTitle}»?
        </div>
        <div style={{ marginTop: 8 }}>
          {t('task.moduleRender.module.carReception.checkList.confirmDesc')}
        </div>
      </Modal>
    </div>
  )
}

export default OptionsBlock
