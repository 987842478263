const data = {
  external: [
    {
      name: 'Bumper_front',
      position: { left: 111, top: 8 },
      lkp: true,
      badgeOffset: [-3, -10],
    },
    {
      name: 'Hood_front',
      position: { left: 111, top: 50 },
      lkp: true,
      badgeOffset: [-3, -10],
    },
    {
      name: 'Hood_rear',
      position: { left: 111, top: 220 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Windshield_front',
      position: {
        left: 111,
        top: 93,
      },
    },
    {
      name: 'Windshield_rear',
      position: { left: 111, top: 190 },
    },
    {
      name: 'Roof',
      position: { left: 111, top: 140 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Bumper_rear',
      position: { left: 111, top: 250 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Wheel_front_left',
      position: { left: 21, top: 64 },
    },
    {
      name: 'Wheel_front_right',
      position: { left: 200, top: 64 },
    },
    {
      name: 'Wheel_rear_left',
      position: { left: 24, top: 199 },
    },
    {
      name: 'Wheel_rear_right',
      position: { left: 197, top: 199 },
    },
    {
      name: 'Headlight_front_left',
      position: { left: 48, top: 29 },
    },
    {
      name: 'Headlight_front_right',
      position: { left: 173, top: 29 },
    },
    {
      name: 'Headlight_rear_left',
      position: { left: 53, top: 236 },
    },
    {
      name: 'Headlight_rear_right',
      position: { left: 170, top: 236 },
    },
    {
      name: 'Wing_front_left',
      position: { left: 50, top: 70 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Wing_front_right',
      position: { left: 170, top: 70 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Wing_rear_left',
      position: { left: 56, top: 208 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Wing_rear_right',
      position: { left: 165, top: 208 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Sill_left',
      position: { left: 25, top: 140 },
    },
    {
      name: 'Sill_right',
      position: { left: 197, top: 140 },
    },
    {
      name: 'Door_front_left',
      position: { left: 40, top: 113 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Door_front_right',
      position: { left: 180, top: 113 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Glass_front_left',
      position: { left: 68, top: 115 },
    },
    {
      name: 'Glass_front_right',
      position: { left: 152, top: 115 },
    },
    {
      name: 'Door_rear_left',
      position: { left: 41, top: 161 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Door_rear_right',
      position: { left: 180, top: 161 },
      lkp: true,
      badgeOffset: [10, 10],
    },
    {
      name: 'Glass_rear_left',
      position: { left: 68, top: 163 },
    },
    {
      name: 'Glass_rear_right',
      position: { left: 153, top: 163 },
    },
  ],
  internal: [
    {
      name: 'Door_inner_front_left',
      position: { left: 40, top: 118 },
    },
    {
      name: 'Door_inner_front_right',
      position: { left: 180, top: 118 },
    },
    {
      name: 'Door_inner_rear_left',
      position: { left: 40, top: 160 },
    },
    {
      name: 'Door_inner_rear_right',
      position: { left: 180, top: 160 },
    },
    {
      name: 'Steering_wheel',
      position: { left: 86, top: 100 },
    },
    {
      name: 'Drivers_seat',
      position: { left: 92, top: 125 },
    },
    {
      name: 'Front_passenger_seat',
      position: { left: 128, top: 125 },
    },
    {
      name: 'Rear_passenger_seat',
      position: { left: 111, top: 175 },
    },
    {
      name: 'Transmission',
      position: { left: 111, top: 110 },
    },
    {
      name: 'Vehicle_dashboard',
      position: { left: 108, top: 74 },
    },
  ],
}

export default data
