import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Form, List, Typography, Input } from 'antd'
import { authorizationLayer } from '@store/actions/methods'
import queryString from 'query-string'
import _ from 'lodash'
import { CharacteristicsContext } from '../../../Characteristics'
import { useTranslation } from 'react-i18next'

const Model = () => {
  const dispatch = useDispatch()
  const form = Form.useFormInstance()
  const model = Form.useWatch('model', form)
  const { loadingComponent, setModelId, brandId, modelId } = useContext(
    CharacteristicsContext
  )
  const { updateInfo, selectAuto } = useContext(CharacteristicsContext)
  const [modelData, setModelData] = useState()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const getModels = async (model) => {
    setLoading(true)
    let q = {}
    if (model?.trim()) q.name = model?.trim()
    const query = queryString.stringify(q)

    await dispatch(
      authorizationLayer({
        url: `wh-cars/references/car_models/${brandId}?${query}`,
      })
    )
      .then((res) => {
        setModelData(res.data)
      })
      .finally(() => setLoading(false))
  }

  const onChangeModels = useCallback(
    _.debounce((query) => getModels(query), 500),
    [brandId]
  )

  const groupedModels = useMemo(() => {
    const map = new Map()
    modelData?.forEach((item) => {
      let group = map.get(item.letter)
      if (!group) {
        group = {
          letter: item.letter,
          models: [],
        }
      }

      group.models.push(item)
      map.set(item.letter, group)
    })

    return [...map.entries()].map(([letter, group]) => ({
      letter,
      models: group.models,
    }))
  }, [modelData])

  useEffect(() => {
    if (brandId) onChangeModels()
  }, [brandId, onChangeModels])

  useEffect(() => {
    if (model)
      groupedModels?.forEach((i) => {
        i.models?.find((x) => {
          if (x.name?.toLowerCase() === model?.toLowerCase()) {
            form.setFieldsValue({ model: x.name })
            setModelId(x.id)
            if (!selectAuto?.carModel?.id) updateInfo(brandId, x.id)
          }
        })
      })
  }, [model])

  return (
    <>
      <Form.Item
        label={t('warehouse.tab.cars.column.model')}
        name='model'
        rules={[
          {
            required: true,
            message: t('common.input.requiredMessage'),
          },
        ]}
        style={{ maxWidth: 400 }}
      >
        <Input
          placeholder={t(
            'task.moduleRender.module.carReception.brandPlaceholder'
          )}
          allowClear
          onChange={(e) => {
            onChangeModels(e.target.value ? model : null)
          }}
          disabled={!brandId}
        />
      </Form.Item>
      {brandId && !modelId && groupedModels?.length ? (
        <List
          itemLayout='horizontal'
          grid={{ column: 1 }}
          style={{
            WebkitColumnCount: 4,
            MozColumnCount: 4,
            WebkitColumns: 4,
            marginBottom: 20,
          }}
          bordered={false}
          dataSource={groupedModels}
          loading={{
            indicator: loadingComponent,
            spinning: loading,
            delay: 0,
          }}
          renderItem={(item) => (
            <List.Item
              key={item.letter}
              style={{ paddingLeft: 32, position: 'relative', border: 'none' }}
            >
              <div
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  position: 'absolute',
                  left: 0,
                }}
              >
                {item.letter}
              </div>
              <List
                bordered={false}
                dataSource={item.models}
                renderItem={(innerItem) => (
                  <List.Item key={innerItem.id} style={{ border: 'none' }}>
                    <Typography.Link
                      onClick={() => {
                        updateInfo({ carModel: { id: innerItem.id } })
                        form.setFieldsValue({
                          model: innerItem.name,
                        })
                        setModelId(innerItem.id)
                        updateInfo(brandId, innerItem.id)
                      }}
                    >
                      {innerItem.name}
                    </Typography.Link>
                  </List.Item>
                )}
              />
            </List.Item>
          )}
        />
      ) : null}
    </>
  )
}

export default Model
