import { useState, useEffect } from 'react'
import { authorizationLayer } from '@store/actions/methods'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { onCloseModal } from '@utils'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '@ui/Loading'

import '../../index.less'

const HistoryCar = (id) => {
  const [historyOfCar, setHistoryOfCar] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const modals = useSelector((state) => state.app.modals)

  const getCarsHistory = async () => {
    if (!id.id) return
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `wh-cars/cars/history/${id.id}`,
        method: 'get',
      })
    )
      .then((res) => {
        if (res.data) setHistoryOfCar(res.data)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getCarsHistory()
  }, [id])
  console.log(historyOfCar)

  return (
    <div className='historyCar'>
      {loading && <Loading spinner={true} />}
      {}
      <ul className='historyCar__list'>
        {historyOfCar?.length > 0 ? (
          historyOfCar?.map((car) => {
            return (
              <div key={car.id}>
                <li
                  className='historyCar__item'
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`task/${car.id}`)
                    onCloseModal(dispatch, modals, 'product-details')
                  }}
                >
                  <div className='historyCar__name-box'>
                    <p className='historyCar__name'>{car.process.name}</p>
                    <p className='historyCar__id'>{car.inner_id}</p>
                  </div>
                  <div className='historyCar__needs-box'>
                    <p className='historyCar__needs'>{car.currentStage.name}</p>
                    <p className='historyCar__date'>
                      2 окт 2022 18:00
                      {moment(car.created_at).format('DD MMMM YYYY, HH:mm')}
                    </p>
                  </div>
                  <div className='historyCar__date-box'>
                    <p className='historyCar__text'>{car.clientName}</p>
                  </div>
                </li>
              </div>
            )
          })
        ) : (
          <div className='notasks'>нет данных</div>
        )}
      </ul>
    </div>
  )
}

export default HistoryCar
