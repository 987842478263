import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import { New, Used, Parts, Services } from './Tables'
import './index.less'
import { useSelector } from 'react-redux'
import useHeight from '@hooks/useHeight'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SET_APP } from '@store/actions/methods'
import { stockTypes } from '@/constants'

const { TabPane } = Tabs

const Screen = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [tab, setTab] = useState()

  const permissions = useSelector((state) => state.app.permissions)
  const tabRedux = useSelector((state) => state.app.tabs)

  const setTest = () => {
    let tabs = []

    if (permissions?.find((p) => p.name.includes('wh_new_auto'))) {
      tabs.push({
        name: t('clientCard.history.newAuto'),
        key: 'new',
      })
    }
    if (permissions?.find((p) => p.name.includes('wh_used_auto'))) {
      tabs.push({
        name: t('warehouse.tab.usedAuto'),
        key: 'used',
      })
    }
    if (permissions?.find((p) => p.name.includes('wh_parts'))) {
      tabs.push({
        name: t('clientCard.history.parts'),
        key: 'parts',
      })
    }
    if (permissions?.find((p) => p.name.includes('wh_services'))) {
      tabs.push({
        name: t('clientCard.history.service'),
        key: 'services',
      })
    }

    return tabs
  }

  const onChange = (tab) => {
    setTab(tab)
    dispatch(SET_APP(['tabs'], tab))
  }

  const { bodyHeight } = useHeight([
    'ant-layout-header',
    'ant-tabs',
    'ant-input-search',
    'modal__popup',
  ])

  useEffect(() => {
    let result = setTest().find((i) => tabRedux === i.key)
    if (setTest().length) setTab(result?.key)
    if (setTest().length && !tabRedux) setTab(setTest()[0].key)
  }, [permissions, tabRedux])

  return (
    <>
      <div style={{ backgroundColor: 'white', padding: '0 40px' }}>
        <Tabs activeKey={tab} onChange={onChange}>
          {setTest()?.map((tab) => (
            <TabPane tab={tab.name} key={tab.key} active />
          ))}
        </Tabs>
      </div>

      <div
        style={{
          marginTop: 15,
          padding: '0 40px',
        }}
      >
        {tab === 'new' && (
          <New stockType={stockTypes.NEW_AUTO} bodyHeight={bodyHeight} />
        )}
        {tab === 'used' && (
          <Used stockType={stockTypes.USED_AUTO} bodyHeight={bodyHeight} />
        )}
        {tab === 'parts' && (
          <Parts stockType={stockTypes.SPARE_PARTS} bodyHeight={bodyHeight} />
        )}
        {tab === 'services' && (
          <Services stockType='services' bodyHeight={bodyHeight} />
        )}
      </div>
    </>
  )
}

export default Screen
