import _ from 'lodash'
import { PUSH_APP, SET_APP } from '@store/actions/methods'
import { EventSourcePolyfill } from 'event-source-polyfill'

export const sortAlphaNumeric = (a, b) => {
  a = _.toString(a)
  b = _.toString(b)

  return a.localeCompare(b)
}

export const openModals = (dispatch, data) => {
  dispatch(PUSH_APP(['modals'], data))
}

export const onCloseModal = (dispatch, modals, type) => {
  dispatch(
    SET_APP(
      ['modals'],
      _.filter(modals, (c) => c.type !== type)
    )
  )
}

export const numberWithSpaces = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  } else return 0
}

export const phoneFormat = (x) => {
  if (x) {
    return x
      .toString()
      .replace(/\D/g, '')
      .replace(/(\d{1})(\d{1,5})/, '$1 $2')
      .replace(/(\d{3})(\d{1,5})/, '$1 $2')
      .replace(/(\d{5})(\d{1,5})/, '$1 $2')
      .replace(/(\d{3})(\d{1,5})/, '$1 $2')
  } else return null
}

let sse

export const SSE = () => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      const { token } = getState().app

      if (sse) return resolve(sse)
      sse = new EventSourcePolyfill(process.env.REACT_APP_SSE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      sse.addEventListener('open', (e) => {
        resolve(sse)
      })

      sse.addEventListener('error', (e) => {
        if (e.type === 'error') {
          reject(e.message)
        } else if (e.type === 'exception') {
          reject(e.error)
        }
      })
      sse.addEventListener('close', (e) => {
        sse = new EventSourcePolyfill(process.env.REACT_APP_SSE_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      })
    } catch (e) {
      console.log(e.message)
    }
  })
}

export const getNameModule = (moduleID, modules) => {
  let result
  if (Array.isArray(modules)) {
    result = modules.find((module) => module.id === moduleID)
  }

  return result ? result.name : 'Нет данных'
}

export const changeStructureDataTHToObj = (needsList, modules) => {
  if (!modules) {
    return needsList.reduce((result, item) => {
      if (result[item.type]) {
        result[item.type].push(item)
      } else {
        result[item.type] = [item]
      }

      return result
    }, {})
  } else {
    const foundModuleIdList = modules.reduce((result, item) => {
      result.push(item.id)
      return result
    }, [])
    return needsList.reduce((result, item) => {
      const checkModuleID = foundModuleIdList.find(
        (id) => id === item?.module?.id
      )
      if (checkModuleID) {
        if (result[item.type]) {
          result[item.type].push(item)
        } else {
          result[item.type] = [item]
        }
      }

      return result
    }, {})
  }
}
