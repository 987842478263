import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { authorizationLayer } from '@store/actions/methods'
import _, { filter } from 'lodash'
import {
  Space,
  Typography,
  Popover,
  Row,
  Button,
  Checkbox,
  List,
  Input,
  Badge,
} from 'antd'
import { phoneFormat, SSE } from '@utils'

import Loading from '@ui/Loading'
import moment from 'moment'

import queryString from 'query-string'

import { useNavigate } from 'react-router'
import useHeight from '@hooks/useHeight'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { FilterOutlined } from '@ant-design/icons'

const { Text } = Typography

const getPopoverContent = (
  autoDataNew,
  autoDataUsed,
  autoDataTestDrive,
  partsData,
  servicesData
) => {
  return (
    <div
      style={{
        maxHeight: '100px',
        overflowY: 'auto',
      }}
      onClick={(e) => e.stopPropagation()}
      className='custom-popover-inner'
    >
      {autoDataNew?.length ? (
        <Text type='secondary'>{t('clientCard.history.newAuto')}</Text>
      ) : null}
      {autoDataNew?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataUsed?.length ? (
        <Text type='secondary'>{t('clientCard.history.usedAuto')}</Text>
      ) : null}
      {autoDataUsed?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataTestDrive?.length ? (
        <Text type='secondary'>{t('clientCard.history.testDrive')}</Text>
      ) : null}
      {autoDataTestDrive?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {partsData?.length ? (
        <Text type='secondary'>{t('clientCard.history.parts')}</Text>
      ) : null}
      {partsData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {servicesData?.length ? (
        <Text type='secondary'>{t('clientCard.history.service')}</Text>
      ) : null}
      {servicesData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const defaultPagination = {
  current: 1,
  pageSize: 10,
}

const MadeByMe = ({
  setVisibleDrawer,
  setClientInfo,
  setHistory,
  setVisibleHistory,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState()
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState(defaultPagination)
  const [refresh, setRefresh] = useState(false)
  const { bodyHeight } = useHeight(['ant-layout-header'])
  const [search, setSearch] = useState()
  const [hasMore, setHasMore] = useState(true)
  const user = useSelector((state) => state.app.user)
  const { t } = useTranslation()
  const [filters, setFilters] = useState([])
  const [process, setProcess] = useState()
  const [metaTotal, setMetaTotal] = useState()

  const CREATED_TASKS_UPDATED = `CREATED_TASKS_UPDATED_${user?.id}`

  const onUpdatedTasks = (e) => {
    const event = JSON.parse(e.data)
    const eventData = event?.data

    if (data?.length) {
      let sliceTasks = data?.slice(0, -1)

      setData([eventData, ...sliceTasks])
    }
  }

  const sse = useRef()

  useEffect(() => {
    if (data?.length) {
      const sse_init = async () => {
        sse.current = await dispatch(SSE())
        sse.current.addEventListener(CREATED_TASKS_UPDATED, onUpdatedTasks)
      }
      sse_init()
      return () => {
        if (sse.current) {
          sse.current.removeEventListener(CREATED_TASKS_UPDATED, onUpdatedTasks)
        }
      }
    }
  }, [data])

  const loadMoreData = async () => {
    try {
      setLoading(true)

      let q = {
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: search,
        processIds: filters ? filters.join(',') : null,
      }
      const query = queryString.stringify(q)

      const {
        data: { data: items, meta },
      } = await dispatch(
        authorizationLayer({
          url: `processes/tasks/get-tasks-created-by-user?${query}`,
          method: 'get',
        })
      )

      if (items?.length < 10) setHasMore(false)
      else if (meta?.total <= 10) setHasMore(false)
      else setHasMore(true)

      setRefresh(false)
      setMetaTotal(meta?.total)

      setData([...data, ...items])
      setLoading(false)
    } catch (e) {
      console.log(e.message)
    }
  }
  useEffect(() => {
    loadMoreData()
  }, [pagination.current, filters])

  const getAllProcess = async () => {
    await dispatch(
      authorizationLayer({
        url: `/processes/main`,
        method: 'get',
      })
    ).then((res) => {
      if (res) setProcess(res.data.data)
    })
  }

  useEffect(() => {
    getAllProcess()
  }, [])

  useEffect(() => {
    if (refresh) {
      if (pagination.current !== defaultPagination.current) {
        setPagination(defaultPagination)
      } else {
        loadMoreData()
      }
    }
  }, [refresh])

  useEffect(() => {
    if (search !== undefined) {
      setData([])
      setRefresh(true)
    }
  }, [search])

  useEffect(() => {
    if (filters.length) {
      setData([])
      setRefresh(true)
    }
  }, [filters])

  const nameInfo = (record) => {
    return (
      <div>
        {record.client?.phone_number ? (
          <Typography.Text>
            {record.client?.full_name ||
            record.client?.name ||
            record.client.phone_number
              ? record.client?.full_name ||
                record.client?.name ||
                `+${phoneFormat(record.client?.phone_number)}`
              : t('main.table.column.client.unnamed')}
          </Typography.Text>
        ) : (
          <Typography.Text>
            {t('main.table.column.client.unnamed')}
          </Typography.Text>
        )}
      </div>
    )
  }

  const needsItem = (record) => {
    let autoDataNew = record.clientData ? record.clientData.cars.new : []
    let autoDataUsed = record.clientData ? record.clientData.cars.used : []
    let autoDataTestDrive = record.clientData
      ? record.clientData.cars.test_drive
      : []

    let partsData = record.clientData ? record.clientData?.parts : []
    let servicesData = record.clientData ? record.clientData?.services : []
    return (
      <span
        style={{
          wordWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {!autoDataNew?.length &&
        !autoDataUsed?.length &&
        !autoDataTestDrive?.length &&
        !partsData?.length &&
        !servicesData?.length ? (
          <Text type='secondary'>{t('main.tab.createdByMe.noData')}</Text>
        ) : (
          <Popover
            overlayClassName='custom-popover'
            trigger='hover'
            placement='bottomLeft'
            title={
              <div onClick={(e) => e.stopPropagation()}>{`${t(
                'clientCard.history.selected'
              )} ${
                autoDataNew?.length +
                partsData?.length +
                servicesData?.length +
                autoDataUsed?.length +
                autoDataTestDrive?.length
              }`}</div>
            }
            content={getPopoverContent(
              autoDataNew,
              autoDataUsed,
              autoDataTestDrive,
              partsData,
              servicesData
            )}
          >
            <Text
              style={{
                cursor: 'pointer',
              }}
            >{`${t('clientCard.history.selectedNeed')} ${
              autoDataNew?.length +
              partsData?.length +
              servicesData?.length +
              autoDataUsed?.length +
              autoDataTestDrive?.length
            }`}</Text>
          </Popover>
        )}
      </span>
    )
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '20px 20px 0',
          justifyContent: 'space-between',
        }}
      >
        <Text style={{ fontSize: '16px', fontWeight: '500' }}>
          {t('calendar.calendar.tasksNumber')} - {metaTotal}
        </Text>
        <Badge count={filters?.length} color='#1890FF'>
          <Popover
            trigger='click'
            content={
              <div>
                <Space direction='vertical' size={12}>
                  <Checkbox.Group
                    onChange={(e) => {
                      setFilters(e)
                      setPagination({
                        current: 1,
                        pageSize: 10,
                      })
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'baseline',
                      height: 500,
                      overflowY: 'scroll',
                    }}
                    className='pausedBox__list'
                  >
                    {process?.map((i) => (
                      <Checkbox className='pausedBox__checkbox' value={i.id}>
                        {i.name}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Space>
              </div>
            }
            placement='bottomLeft'
          >
            <Button type='link' style={{ fontSize: '15px', padding: '0' }}>
              {t('task.actionHistory.module.filter')} <FilterOutlined />
            </Button>
          </Popover>
        </Badge>
      </div>

      <div style={{ padding: '8px 20px 0px 20px' }}>
        <Input.Search
          placeholder={t('main.tab.createdByMe.search')}
          allowClear
          style={{
            borderRadius: 6,
            width: '100%',
          }}
          onChange={_.debounce((e) => setSearch(e.target.value), 300)}
          className='search-input'
        />
      </div>

      <div
        className='pausedBox__list'
        id='scrollableDiv'
        style={{ overflow: 'auto', height: bodyHeight - 58 }}
      >
        {data && (
          <InfiniteScroll
            dataLength={data?.length}
            next={() => {
              setPagination((value) => ({
                ...value,
                current: value.current + 1,
              }))
            }}
            hasMore={hasMore}
            scrollableTarget='scrollableDiv'
          >
            <List
              dataSource={data}
              renderItem={(item) => (
                <>
                  <List.Item
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`task/${item.id}`)
                      // setHistory(item)
                      // setVisibleHistory(true)
                    }}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'normal',
                      padding: '6px 12px 5px 20px',
                      borderBottom: '1px solid #fafafa',
                      cursor: 'pointer',
                    }}
                    className='pausedBox__item'
                    key={item.id}
                  >
                    <List.Item.Meta />

                    <div className='pausedBox__name-box'>
                      <p className='pausedBox__name'>{nameInfo(item)}</p>
                      <p className='pausedBox__id'>{item.inner_id}</p>
                    </div>
                    <div className='pausedBox__needs-box'>
                      <p
                        className='pausedBox__needs'
                        style={{ color: '#293452' }}
                      >
                        {needsItem(item)}
                      </p>
                      <p className='pausedBox__date'>
                        {moment(item.created_at).format('DD MMMM YYYY, HH:mm')}
                      </p>
                    </div>
                    <p className='pausedBox__text'>{item.currentStage?.name}</p>
                  </List.Item>
                </>
              )}
            />
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export default MadeByMe
