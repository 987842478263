import {useMemo} from "react";
import moment from 'moment'

const compareValues = (object1, object2, fieldSort) => {
  switch(fieldSort) {
    case 'carMark':
    case 'carModel':
    case 'carTemplate':
    case 'carInteriorColor':
    case 'carExteriorColor':
      return object1[fieldSort]?.name.localeCompare(object2[fieldSort]?.name);
    case 'client':
    case 'user':
      return (object1[fieldSort] ? object1[fieldSort]?.full_name : '').localeCompare(object2[fieldSort] ? object2[fieldSort]?.full_name : '');
    case 'carGearboxType':
      return object1[fieldSort]?.short_value.localeCompare(object2[fieldSort]?.short_value);
    case 'price':
    case 'mileage':
    case 'engine_capacity':
    case 'year':
      return object1[fieldSort] - object2[fieldSort];
    case 'vin_code':
    case 'status':
      return object1[fieldSort].localeCompare(object2[fieldSort]);
    case 'created_at':
      if(!object1.stock_date && !object2.stock_date)
        return moment(new Date()).diff(moment(new Date()), 'days')
      else if(!object1.stock_date) 
        return moment(new Date()).diff(moment(object2.stock_date), 'days')
      else if(!object2.stock_date) 
        return moment(object1.stock_date).diff(moment(new Date()), 'days')
      return moment(new Date()).diff(moment(object1.stock_date), 'days') - moment(new Date()).diff(moment(object2.stock_date), 'days');
    case 'stock_date':
      return moment(object1[fieldSort] ? object1[fieldSort] : new Date()).diff(moment(object2[fieldSort] ? object2[fieldSort] : new Date()), 'days')
    case 'conditions':
      return (object1[fieldSort] ? object1[fieldSort]?.join(' ') : '').localeCompare(object2[fieldSort] ? object2[fieldSort]?.join(' ') : '');
    default:
      break;
  }
}

const selectValues = (value, query) => {
  for(let filter in query) {
    switch(filter) {
      case 'carMark':
      case 'carModel':
      case 'carTemplate':
      case 'carInteriorColor':
      case 'carExteriorColor':
      case 'client':
      case 'user':
      case 'carGearboxType':
        if(!query[filter].includes(value[filter]?.id))
          return false;
        break;
      case 'price':
      case 'mileage':
        if(!((query[filter][0] === undefined && value[filter] <= query[filter][1]) || (value[filter] >= query[filter][0] && query[filter][1] === undefined) || (value[filter] >= query[filter][0] && value[filter] <= query[filter][1])))
          return false;
        break;
      case 'status':
      case 'year':
      case 'engine_capacity':
        if(!query[filter].includes(value[filter]))
          return false;
        break;
      case 'vin_code':
        if(!value[filter]?.toUpperCase().includes(query[filter][0]?.toUpperCase()))
          return false;
        break;
      case 'created_at':
        if(!((query[filter][0] === undefined && moment(new Date()).diff(moment(value.stock_date), 'days') - 1 <= query[filter][1]) || 
          (moment(new Date()).diff(moment(value.stock_date), 'days') - 1 >= query[filter][0] && query[filter][1] === undefined) || 
          (moment(new Date()).diff(moment(value.stock_date), 'days') - 1 >= query[filter][0] && moment(new Date()).diff(moment(value.stock_date), 'days') - 1 <= query[filter][1])))
          return false;
        break;
      case 'stock_date':
        if(!moment(value.stock_date).isBetween(query[filter][0], query[filter][1]))
          return false;
        break;
      case 'conditions':
        if(value[filter]?.length)
          return false;
        break;
      default:
        break
    }
  }
  return true;
}

export const useSortedData = (data, sort = {field: 'carMark', order: 'ascend'}) => {
  const sortedData = useMemo(() => {
    if(sort?.order === 'ascend') {
      return [...data].sort((a, b) => compareValues(a, b, sort.field,))
    } else if(sort?.order === 'descend') {
      return [...data].sort((a, b) => compareValues(b, a, sort.field))
    }
    return data;
  }, [sort?.order, sort.field, data])

  return sortedData;
}

export const useFilteredData = (data, query, sort) => {
  for(let filter in query) {
    if(!query[filter]) {
      delete query[filter]
    }
  }

  const sortedData = useSortedData(data, sort);
  
  const sortedAndSearchedData = useMemo(() => {
    return sortedData.filter(row => selectValues(row, query)
  )}, [query, sortedData])
  return sortedAndSearchedData;
}