const UPLOAD_FILE = 'uploadFile'
const CHOICE_PRODUCT = 'choiceProduct'
const FORM = 'form'
const IWD = 'interactionWithDepartments'
const COORD = 'Coordination'
const CAR_RECEPTION = 'carReception'
const DOC_FORMATION = 'documentFormation'

export const modules = {
  UPLOAD_FILE,
  CHOICE_PRODUCT,
  FORM,
  IWD,
  COORD,
  CAR_RECEPTION,
  DOC_FORMATION,
}

const NEW_AUTO = 'new'
const USED_AUTO = 'used'
const SPARE_PARTS = 'spare_parts'
const TEST_DRIVE = 'test_drive'

export const stockTypes = {
  NEW_AUTO,
  USED_AUTO,
  SPARE_PARTS,
  TEST_DRIVE,
}
