import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Empty, Modal } from 'antd'

import { changeStructureDataTHToObj } from '../../../../../utils'

import BSteps from '../../../../../ui/BSteps'
import TaskHistoryContainer from '../TaskHistoryContainer'

import './styles.less'
import { authorizationLayer } from '../../../../../store/actions/methods'
import { useDispatch } from 'react-redux'

const TaskHistoryModal = (props) => {
  const {
    taskData,
    currenStepStage,
    closeTaskHistory,
    allModules,
    open,
    taskID,
    mainTaskId,
  } = props
  const [state, setState] = useState({
    needsObj: null,
    totalAmount: 0,
  })
  const dispatch = useDispatch()
  const getNeeds = async (mainTaskID) => {
    try {
      const { data: needsData } = await dispatch(
        authorizationLayer({
          url: `needs/${mainTaskID}`,
        })
      )
      if (Array.isArray(needsData.data) && needsData.data.length) {
        const changedData = changeStructureDataTHToObj(needsData.data)
        let totalAmount = 0
        if (changedData && changedData?.choiceProduct) {
          changedData.choiceProduct.map((item) => {
            totalAmount += findTotalPrice(item.meta)
          })
        }
        setState({
          ...state,
          needsObj: changedData,
          totalAmount: totalAmount,
        })
      }
    } catch (error) {
      console.log('error', error)
    } finally {
    }
  }
  const { t } = useTranslation()

  const modalRender = (node) => {
    const { props } = node
    const childrenMAP = props.children.map((item) => {
      return item && item.type === 'button' ? { ...item, type: 'div' } : item
    })
    const children = childrenMAP.filter((item) => item && item)
    return {
      ...node,
      props: { ...props, children },
    }
  }
  const findTotalPrice = (meta) => {
    return meta.reduce((result, item) => {
      if (item.price) {
        if (item.orderAmount) {
          return result + item.price * item.orderAmount
        } else {
          return result + item.price
        }
      }
      return result
    }, 0)
  }
  useEffect(() => {
    getNeeds(mainTaskId)
  }, [mainTaskId])

  const { user: manager, process } = taskData
  return (
    <Modal
      id={'TaskModalItems'}
      title={t('task.taskHistory.titleTasks')}
      className={'task-modal'}
      footer={null}
      open={open}
      modalRender={modalRender}
      closeIcon={
        <Button
          type={'ghost'}
          shape='round'
          onClick={closeTaskHistory}
          style={{ right: '77px' }}
        >
          Закрыть
        </Button>
      }
      bodyStyle={{
        width: '100%',
        height: '100vh',
        padding: 0,
        backgroundColor: '#F0F2F4',
      }}
    >
      <div className={'task-modal--container'}>
        <div className={'task-modal--content'}>
          {state.needsObj ? (
            <>
              <div className={'task-modal--steps'}>
                <BSteps
                  stages={taskData?.process?.stages}
                  status={taskData?.status?.code}
                  currentOrder={currenStepStage}
                />
              </div>

              <TaskHistoryContainer
                needsObj={state.needsObj}
                taskID={taskID}
                allModules={allModules}
                manager={manager}
                process={process}
                totalAmount={state.totalAmount}
              />
            </>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default TaskHistoryModal
