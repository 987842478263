import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

const useDefectsByCategories = () => {
  const dispatch = useDispatch()
  const { categories } = useSelector((state) => state.app)

  const getCategories = async () => {
    const { data: categories } = await dispatch(
      authorizationLayer({
        url: `wh-cars/car_details/category`,
      })
    )

    dispatch(SET_APP(['categories'], categories))

    return categories
  }

  const getDefects = async () => {
    const { data: defects } = await dispatch(
      authorizationLayer({
        url: `wh-cars/car_confirmation_types`,
      })
    )
    dispatch(SET_APP(['defects'], defects))
  }

  const getCarDetails = async (type, id) => {
    const category = _.find(categories, { short_name: type })

    const { data } = await dispatch(
      authorizationLayer({
        url: `wh-cars/car_details/${id}`,
      })
    )
    dispatch(SET_APP([`car_details_${type}`], data))
  }

  return { getCategories, getDefects, getCarDetails }
}

export default useDefectsByCategories
