import React, { useMemo, useState, useEffect } from 'react'
import { Space, Typography, Popover, Row, Col, Tooltip, Button } from 'antd'
import moment from 'moment'
import { phoneFormat } from '@utils'
import { useNavigate } from 'react-router'
import useHeight from '@hooks/useHeight'
import { MessageOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const { Text } = Typography
const getPopoverContent = (
  autoDataNew,
  autoDataUsed,
  autoDataTestDrive,
  partsData,
  servicesData
) => {
  return (
    <div
      style={{
        maxHeight: '100px',
        overflowY: 'auto',
      }}
      onClick={(e) => e.stopPropagation()}
      className='custom-popover-inner'
    >
      {autoDataNew?.length ? (
        <Text type='secondary'>{t('clientCard.history.newAuto')}</Text>
      ) : null}
      {autoDataNew?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataUsed?.length ? (
        <Text type='secondary'>{t('clientCard.history.usedAuto')}</Text>
      ) : null}
      {autoDataUsed?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataTestDrive?.length ? (
        <Text type='secondary'>{t('clientCard.history.testDrive')}</Text>
      ) : null}
      {autoDataTestDrive?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {partsData?.length ? (
        <Text type='secondary'>{t('clientCard.history.parts')}</Text>
      ) : null}
      {partsData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {servicesData?.length ? (
        <Text type='secondary'>{t('clientCard.history.service')}</Text>
      ) : null}
      {servicesData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const PickedDate = ({ props }) => {
  const navigate = useNavigate()
  const { bodyHeight } = useHeight(['ant-layout-header'])
  const { t } = useTranslation()
  let weekDaysMin = moment()._locale._weekdaysMin
  weekDaysMin = weekDaysMin.slice(1)
  weekDaysMin.push(moment()._locale._weekdaysMin[0])

  const {
    data,
    getTask,
    setVisibleDrawer,
    setClientInfo,
    filteredData,
    setWeek,
    weekDays,
    setGetTask,
  } = props

  const formatDate = useMemo(() => {
    return data?.map((item) => moment(item.pausedTo).format('YYYY-MM-DD')) || []
  }, [data])

  const styleDate = (d) => {
    const today = moment().format('YYYY-MM-DD')
    let clsName = 'pausedBox__num-item'

    if (formatDate.includes(d.format('YYYY-MM-DD'))) {
      clsName = clsName + ' pausedBox__num-item--dot'
    }
    if (today?.includes(d.format('YYYY-MM-DD'))) {
      clsName = clsName + ' pausedBox__num-item--today'
    }

    if (getTask === d) {
      clsName = clsName + ' pausedBox__num-item--clicked'
      if (formatDate.includes(d.format('YYYY-MM-DD'))) {
        clsName =
          clsName + ' pausedBox__num-item--dot pausedBox__num-item--white'
      }
    }

    return clsName
  }

  // const needsItem = (record) => {
  //   let autoDataNew = record.clientData ? record.clientData.cars.new : []
  //   let autoDataUsed = record.clientData ? record.clientData.cars.used : []
  //   let autoDataTestDrive = record.clientData
  //     ? record.clientData.cars.test_drive
  //     : []

  //   let partsData = record.clientData ? record.clientData?.parts : []
  //   let servicesData = record.clientData ? record.clientData?.services : []
  //   return (
  //     <span
  //       style={{
  //         wordWrap: 'break-word',
  //         wordBreak: 'break-word',
  //       }}
  //     >
  //       {!autoDataNew?.length &&
  //       !autoDataUsed?.length &&
  //       !autoDataTestDrive?.length &&
  //       !partsData?.length &&
  //       !servicesData?.length ? (
  //         <Text type='secondary'>
  //           {t('main.tab.pendingRequests.needIsNotSelected')}
  //         </Text>
  //       ) : (
  //         <Popover
  //           overlayClassName='custom-popover'
  //           trigger='hover'
  //           placement='bottomLeft'
  //           title={
  //             <div onClick={(e) => e.stopPropagation()}>{`${t(
  //               'clientCard.history.selected'
  //             )} ${
  //               autoDataNew?.length +
  //               partsData?.length +
  //               servicesData?.length +
  //               autoDataUsed?.length +
  //               autoDataTestDrive?.length
  //             }`}</div>
  //           }
  //           content={getPopoverContent(
  //             autoDataNew,
  //             autoDataUsed,
  //             autoDataTestDrive,
  //             partsData,
  //             servicesData
  //           )}
  //         >
  //           <Text
  //             style={{
  //               cursor: 'pointer',
  //             }}
  //             // type='secondary'
  //           >{`${t('clientCard.history.selectedNeed')} ${
  //             autoDataNew?.length +
  //             partsData?.length +
  //             servicesData?.length +
  //             autoDataUsed?.length +
  //             autoDataTestDrive?.length
  //           }`}</Text>
  //         </Popover>
  //       )}
  //     </span>
  //   )
  // }

  const nameInfo = (record) => {
    return (
      <div>
        {record.client?.phone_number ? (
          <Typography.Text>
            {record.client?.full_name ||
            record.client?.name ||
            record.client.phone_number
              ? record.client?.full_name ||
                record.client?.name ||
                `+${phoneFormat(record.client?.phone_number)}`
              : t('main.table.column.client.unnamed')}
          </Typography.Text>
        ) : (
          <Typography.Text>
            {t('main.table.column.client.unnamed')}
          </Typography.Text>
        )}
      </div>
    )
  }

  return (
    <div className='pausedBox__main'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '12px 20px 0px',
        }}
      >
        <button
          className='pausedBox__btn'
          onClick={() => setWeek((w) => w - 1)}
        >
          <div className='pausedBox__arrow pausedBox__arrow--left'></div>
        </button>
        <div className='pausedBox__table'>
          <ul className='pausedBox__day'>
            {weekDaysMin.map((i, index) => {
              let item = i.split('')
              let UpperCase = item[0].toUpperCase()
              item[0] = UpperCase
              item = item.join('')

              return (
                <li key={index} className='pausedBox__day-item'>
                  {item}
                </li>
              )
            })}
          </ul>

          <ul className='pausedBox__num'>
            {data &&
              weekDays.map((d) => {
                return (
                  <li
                    className={styleDate(d)}
                    key={d}
                    onClick={() => setGetTask(d)}
                  >
                    {d.format('DD')}
                  </li>
                )
              })}
          </ul>
        </div>
        <button
          className='pausedBox__btn'
          onClick={() => setWeek((w) => w + 1)}
        >
          <div className='pausedBox__arrow pausedBox__arrow--right'></div>
        </button>
      </div>

      <ul
        className='pausedBox__list '
        style={{ overflow: 'scroll ', height: bodyHeight - 140 }}
      >
        {filteredData?.length > 0 ? (
          filteredData?.map((item) => {
            return (
              <div key={item.id}>
                <li
                  className='pausedBox__item'
                  style={{ zIndex: '-1' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`task/${item.id}`)
                  }}
                >
                  <div className='pausedBox__name-box'>
                    <p className='pausedBox__name'>{nameInfo(item)}</p>
                    <p className='pausedBox__id'>{item.inner_id}</p>
                  </div>
                  <div className='pausedBox__needs-box'>
                    <p className='pausedBox__needs'>{item.contactType ? item.contactType?.name : 'нет типа контакта'}</p>
                    <p className='pausedBox__date'>
                    {moment.utc(item.pausedTo).format('DD MMMM YYYY, HH:mm ')}
                    </p>
                  </div>
                  <div className='pausedBox__date-box'>
                    <p className='pausedBox__text'>{item.currentStage?.name}</p>
                    <Tooltip
                      placement='bottomLeft'
                      title={item.pauseComment}
                      arrowPointAtCenter
                      color='#293452'
                      overlayStyle={{ borderRadius: '6px' }}
                    >
                      <Button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          height: 17,
                          paddingRight: 0,
                          paddingLeft: 8,
                        }}
                      >
                        <MessageOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </li>
              </div>
            )
          })
        ) : (
          <div className='notasks'>{t('main.tab.pendingRequests.noData')}</div>
        )}
      </ul>
    </div>
  )
}

export default PickedDate
