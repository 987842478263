import React from 'react'
import _ from 'lodash'
import { Typography, Row, Col, Space, Card, Divider } from 'antd'
import { Tabs, Skeleton } from 'antd'
import { numberWithSpaces } from '@utils'

const { Title, Text } = Typography

const { TabPane } = Tabs

const ServicesDetails = (props) => {
  const { loading, servicesData, tabsComponents } = props
  return (
    <Card bodyStyle={{ padding: '40px 80px 104px' }}>
      <Row justify='space-between' wrap={false}>
        {!loading ? (
          <Col flex='600px'>
            <Space direction='vertical' size={8}>
              <Title level={3}>{servicesData?.name}</Title>
              <Title level={3}>
                {servicesData?.cost
                  ? `от ${numberWithSpaces(servicesData?.cost)}`
                  : null}
              </Title>
              {servicesData?.category !== null ? (
                <Text>
                  Категория:{' '}
                  {<Text strong>{servicesData?.category?.name}</Text>}
                </Text>
              ) : (
                ''
              )}
            </Space>
            <Divider />
            <Tabs defaultActiveKey='1' style={{ marginTop: 28 }}>
              {tabsComponents.map((item) => {
                return (
                  <TabPane key={item.id} tab={item.title}>
                    <div style={{ marginTop: 16 }}>{item.content}</div>
                  </TabPane>
                )
              })}
            </Tabs>
          </Col>
        ) : (
          <Space direction='vertical' size={41} style={{ width: '50%' }}>
            <Skeleton active paragraph={{ rows: 1 }} />
            <Space size={20}>
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
            </Space>
            <Skeleton active />
          </Space>
        )}
      </Row>
    </Card>
  )
}

export default ServicesDetails
