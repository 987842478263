import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import queryString from 'query-string'
import moment from 'moment'

const useTableActions = () => {
  const { tasksQuery, tasks } = useSelector((state) => state.app)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [statusesLoading, setStatusesLoading] = useState(false)
  const [query, setQuery] = useState({
    pagination: {
      current: tasksQuery?.pagination.current || 1,
      pageSize: tasksQuery?.pagination.pageSize || 10,
    },
    searchValue: tasksQuery?.searchValue,
    calendarValue: tasksQuery?.calendarValue,
    statuses: tasksQuery?.statuses || [],
  })
  const [updateTasks, setUpdateTasks] = useState()
  const [updateTaskData, setUpdateTaskData] = useState()

  useEffect(() => {
    if (
      (updateTasks &&
        tasksQuery?.pagination.current === 1 &&
        !tasksQuery?.searchValue &&
        tasksQuery?.statuses?.includes('NEW') &&
        !tasksQuery?.calendarValue?.length) ||
      (updateTasks &&
        tasksQuery?.pagination.current === 1 &&
        !tasksQuery?.searchValue &&
        !tasksQuery?.statuses?.length &&
        !tasksQuery?.calendarValue?.length)
    ) {
      let sliceTasks = tasks.slice(0, -1)

      if (tasks?.length < 10)
        dispatch(SET_APP(['tasks'], [updateTasks, ...tasks]))
      else dispatch(SET_APP(['tasks'], [updateTasks, ...sliceTasks]))
    }
  }, [updateTasks])

  useEffect(() => {
    if (updateTaskData) {
      let index = undefined
      tasks?.forEach((item) => {
        if (item.id === updateTaskData?.id) {
          index = tasks?.findIndex((i) => i.id === updateTaskData?.id)

          tasks[index] = updateTaskData
        }
      })
      if (tasks) dispatch(SET_APP(['tasks'], [...tasks]))
    }
  }, [updateTaskData])

  const getTasks = async () => {
    const q = {
      page: query.pagination.current,
      limit: query.pagination.pageSize,
    }

    if (query.calendarValue?.length) {
      q.from = moment(query.calendarValue[0]?._d).format('YYYY-MM-DD')
      q.to = moment(query.calendarValue[1]?._d).format('YYYY-MM-DD')
      q.orderBy = 'ASC'
    }

    if (query.searchValue?.length) q.keyword = query.searchValue

    if (query.statuses?.length) q.statuses = query.statuses.join(',')

    dispatch(SET_APP(['tasksQuery'], query))

    const params = queryString.stringify(q)
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `processes/tasks?${params}`,
      })
    )
      .then(({ data }) => {
        dispatch(SET_APP(['tasks'], data.data))
        dispatch(SET_APP(['tasksMeta'], data.meta))
      })
      .finally(() => setLoading(false))
  }

  const getStatuses = async () => {
    setStatusesLoading(true)
    await dispatch(
      authorizationLayer({
        url: 'processes/statuses',
      })
    )
      .then(({ data }) => dispatch(SET_APP(['statuses'], data.data)))
      .finally(() => setStatusesLoading(false))
  }

  return {
    getTasks,
    loading,
    setQuery,
    query,
    getStatuses,
    setUpdateTasks,
    setUpdateTaskData,
    statusesLoading,
  }
}

export default useTableActions
