const useHeight = (elements) => {
  let height = 0

  elements?.forEach((name) => {
    const el = document.body.getElementsByClassName(name)
    if (el.length > 0) {
      height += el[0].offsetHeight
    }
  })

  const bodyHeight = document.body.offsetHeight - height - 40 - 120

  return { height, bodyHeight }
}

export default useHeight
