import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { List, Tooltip } from 'antd'
import './index.less'
import Marker from './Marker'
import Loading from '@ui/Loading'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

let getHours = (start, end) => {
  return Array.from({ length: end + 1 - start }, (_, i) => i).reduce((r, h) => {
    const hour = start + h
    r.push(moment({ hour, minute: 0 }).format('HH:mm'))
    if (hour !== end) {
      r.push(moment({ hour, minute: 30 }).format('HH:mm'))
    }
    return r
  }, [])
}

const itemColors = ['#96E3B6', '#FDD689', '#C3C6CF']

const Timeline = (props) => {
  const { users, data, loading } = props
  const displayLength = 16
  const startTime = 9
  const endTime = 21
  const [itemWidth, setItemWidth] = useState(0)
  const { t } = useTranslation()

  const body = useRef()

  useEffect(() => {
    if (body.current) {
      let itemWidth = body.current.offsetWidth / displayLength
      if (itemWidth < 300 / 4) itemWidth = 300 / 4
      setItemWidth(itemWidth)
    }
  }, [])

  const hours = getHours(startTime, endTime)

  const renderItem = (item, index) => {
    const startAt = moment(item.user_service_time).utc()
    const endAt = moment(item.user_service_time).utc()

    const startAtHour = moment(item.user_service_time).utc().format('HH')
    const startAtMinute = moment(item.user_service_time).utc().format('mm')

    let left = itemWidth * (startAtHour - startTime) * 2
    left += (startAtMinute / 30) * itemWidth

    const width = itemWidth

    const userIndex = _.findIndex(users, { id: item.user_id })

    if (userIndex === -1) return null

    const itemStyle = {
      backgroundColor: 'red',
      top: userIndex * 60,
      left,
      width,
    }

    // if (endAt.diff(moment()) < 0) itemStyle.backgroundColor = itemColors[0]
    // else if (startAt.diff(moment()) < 0)
    //   itemStyle.backgroundColor = itemColors[1]
    // else if (endAt.diff(moment()) > 0) itemStyle.backgroundColor = itemColors[2]
    itemStyle.backgroundColor = itemColors[1]
    return (
      <Tooltip title={item.whServiceTask.wh_service.name} arrowPointAtCenter>
        <div key={index} className='board__item' style={itemStyle}>
          {item.whServiceTask.wh_service.name}
        </div>
      </Tooltip>
    )
  }

  return (
    <div className='board-calendar'>
      <div className='board__list'>
        <div className='board__list__header'>
          {t('calendar.board.timeline.allSpecialists')}
        </div>
        {users.map((user, index) => (
          <List.Item key={index} className='list__item'>
            <List.Item.Meta
              title={user.name}
              description={user.position.name}
            />
          </List.Item>
        ))}
      </div>
      <div ref={body} className='board__content'>
        <div className='board__content__header'>
          {hours.map((hour) => (
            <div
              key={hour}
              className='content__header__item'
              style={{ width: itemWidth, minWidth: itemWidth }}
            >
              {hour}
            </div>
          ))}
        </div>
        <div className='board__content__body'>
          <Marker
            itemWidth={itemWidth}
            startTime={startTime}
            endTime={endTime}
          />
          {loading ? (
            <Loading spinner={true} />
          ) : (
            <div className='board__content__items'>{data.map(renderItem)}</div>
          )}
          {users.map((user, index) => (
            <div key={index} className='board__content__row'>
              {hours.map((hour) => (
                <div
                  key={hour}
                  className='content__item'
                  style={{ width: itemWidth, minWidth: itemWidth }}
                ></div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

Timeline.defaultProps = {
  users: [],
  data: [],
}

export default Timeline
