import React, { useState, useEffect, useRef } from 'react'
import Volume from './Volume'

const Player = ({ url }) => {
  const [trackProgress, setTrackProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [mute, setMute] = useState(true)

  const toggle = () => setIsPlaying(!isPlaying)

  // Refs
  const audioRef = useRef(new Audio(url))
  const intervalRef = useRef()
  const isReady = useRef(false)

  // Destructure for conciseness
  const { duration } = audioRef.current

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : '0%'
  const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #0989FF), color-stop(${currentPercentage}, #E3E6E9))
  `

  const startTimer = () => {
    clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        audioRef.current.pause()
        setIsPlaying(false)
      } else {
        setTrackProgress(audioRef.current.currentTime)
      }
    }, [1000])
  }

  const onScrub = (value) => {
    clearInterval(intervalRef.current)
    audioRef.current.currentTime = value
    setTrackProgress(audioRef.current.currentTime)
  }

  const onScrubEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true)
    }
    startTimer()
  }

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
      startTimer()
    } else {
      audioRef.current.pause()
    }
  }, [isPlaying])

  useEffect(() => {
    audioRef.current.pause()
    audioRef.current = new Audio(url)
    setTrackProgress(audioRef.current.currentTime)

    if (isReady.current) {
      audioRef.current.play()
      setIsPlaying(true)
      startTimer()
    } else {
      isReady.current = true
    }
  }, [url])

  useEffect(() => {
    return () => {
      audioRef.current.pause()
      clearInterval(intervalRef.current)
    }
  }, [])

  const pauseIcon = (
    <div style={{ width: '25px', height: '25px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
        fill='#0989ff'
      >
        <path
          fillRule='evenodd'
          d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  )
  const playIcon = (
    <div style={{ width: '25px', height: '25px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
        fill='#0989ff'
      >
        <path
          fillRule='evenodd'
          d='M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  )

  const formatDuration = (duration) => {
    let moment = require('moment')
    require('moment-duration-format')
    return moment.duration(duration, 'seconds').format('mm:ss', { trim: false })
  }

  const parseLink = () => {
    const urlSplitted = url.split('/')
    return `${urlSplitted[urlSplitted.length - 2]}_${
      urlSplitted[urlSplitted.length - 1]
    }.mp3`
  }

  return (
    <div className='audio__container'>
      <button className='audio__circle task__btn' onClick={toggle}>
        {isPlaying ? pauseIcon : playIcon}
      </button>
      <input
        className='progress'
        type='range'
        value={trackProgress}
        step='1'
        min='0'
        max={duration ? duration : `${duration}`}
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        style={{
          background: trackStyling,
        }}
      />

      <div className='bar__time-box'>
        <span className='bar__time'>
          {formatDuration(audioRef.current.currentTime)} /{' '}
          {formatDuration(duration)}
        </span>
      </div>
      <div style={{ display: 'flex' }}>
        <Volume mute={mute} audioRef={audioRef} setMute={setMute} />
        <div className='audio__download'>
          <a
            href={url}
            download={parseLink(url)}
            target='_blank'
            className='audio__download-link'
          >
            <svg
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.75 15H14.25V13.5H3.75M14.25 6.75H11.25V2.25H6.75V6.75H3.75L9 12L14.25 6.75Z'
                fill='#0989FF'
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Player
