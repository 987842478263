import { notification } from 'antd'

const useNotification = (infoText, type) => {
  return () => {
    // if (type === 'success')
    notification[type]({
      message: infoText.message,
      description: infoText.description,
      duration: 3,
    })
  }
}

export default useNotification
