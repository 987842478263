import React from 'react'

import { Collapse, Row, Col, Typography } from 'antd'
const { Panel } = Collapse
const { Text } = Typography

const Characteristics = ({ ...props }) => {
  const data = props.characteristicsData?.carSpecification || null

  return (
    <>
      <Collapse bordered={false} style={{ background: 'transparent' }}>
        {data?.map((item) => {
          return (
            <Panel header={item.name} key={item.id} className='custom-collapse'>
              {item.specifications?.map((innerItem, index) => {
                return (
                  <Row
                    justify='space-between'
                    key={index}
                    style={{ padding: '12px 16px' }}
                  >
                    <Col>
                      <Text type='secondary'>{innerItem.name}</Text>
                    </Col>
                    <Col>
                      <Text>{innerItem.value}</Text>
                    </Col>
                  </Row>
                )
              })}
            </Panel>
          )
        })}
      </Collapse>
    </>
  )
}

export default Characteristics
