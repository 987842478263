import React, { useContext } from 'react'
import { ModuleContextConfirm } from '../../render'
import {
  Typography,
  Divider,
  Row,
  Col,
  Collapse,
  Input,
  Select,
  Empty,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { modules } from '@/constants'
import _ from 'lodash'
import './index.less'

const Form = () => {
  const { data } = useContext(ModuleContextConfirm)
  const { t } = useTranslation()

  const getData = () => {
    const items = []

    if (data.process && _.isArray(data.process.stages)) {
      data.process.stages.forEach((stage) => {
        if (_.isArray(stage.modules)) {
          stage.modules.forEach((module) => {
            if (
              module?.component?.componentType?.name === modules.FORM &&
              data.module?.needs?.find((i) => i.module?.id === module?.id)
            ) {
              items.push(module)
            }
          })
        }
      })
    }

    return items
  }

  const getNeeds = (moduleId) => {
    const currentNeeds = data.module?.needs?.find(
      (item) => item.module?.id === moduleId
    )

    const { meta } = currentNeeds

    return meta
  }

  const renderContent = (item) => {
    if (
      item.type !== 'paragraph' &&
      item.type !== 'select' &&
      item.type !== 'multi_select'
    )
      return (
        <Input
          value={item.fieldData}
          className='confirm-form__field'
          disabled
        />
      )
    else if (item.type === 'select' || item.type === 'multi_select')
      return (
        <Select
          disabled
          className='confirm-form__select'
          value={item.fieldData}
          mode={item.type === 'multi_select' && 'multiple'}
          showArrow={false}
          size='large'
        />
      )
    else if (item.type === 'paragraph')
      return (
        <Input.TextArea
          value={item.fieldData}
          className='confirm-form__field'
          disabled
        />
      )
  }

  return (
    <>
      <Row justify='space-between'>
        <Col>
          <Typography.Title level={5}>
            {t('confirm.form.title')}
          </Typography.Title>{' '}
        </Col>
      </Row>
      <Divider />
      {getData().length ? (
        <Collapse ghost>
          {getData().map((i) => {
            return (
              <Collapse.Panel key={i.id} header={i.name}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {getNeeds(i.id)?.map((item) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 12,
                        }}
                      >
                        <Typography.Text>{item.name}</Typography.Text>
                        {renderContent(item)}
                      </div>
                    )
                  })}
                </div>
              </Collapse.Panel>
            )
          })}
        </Collapse>
      ) : (
        <Empty />
      )}
    </>
  )
}

export default Form
