import WrapperItem from '../WrapperItem'
import RowApplication from '../RowApplication'
import { getNameModule } from '../../../../../../utils'

const Interaction = (props) => {
  const { interactionList, allModules = null } = props

  return (
    <WrapperItem title={'Взаимодействие с другими отделами'}>
      {interactionList.map((item) => {
        const { id, meta } = item
        return (
          <RowApplication
            title={getNameModule(item.module?.id, allModules)}
            data={item}
            id={id}
            meta={meta}
            key={id}
          />
        )
      })}
    </WrapperItem>
  )
}

export default Interaction
