import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Button, Card, Form } from 'antd'
import './index.less'
import * as steps from './steps'
import { useDispatch } from 'react-redux'
import { SET_APP, axios, authorizationLayer } from '@store/actions/methods'
import i18n from '../../i18n'
import moment from 'moment'

const Auth = ({ step = 1 }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  const [isError, setIsError] = useState('')
  const [isMailError, setIsMailError] = useState('')
  const [passwordValidate, setPasswordValidate] = useState('')

  const newPassword = Form.useWatch('new_password', form)
  const [forgotStep, setForgotStep] = useState(step)
  const queryToken = location.search.slice(7)
  const [loading, setLoading] = useState(false)
  const CISCodeList = [
    'az',
    'hy',
    'be',
    'kk',
    'ky',
    'ru',
    'tg',
    'tk',
    'uz',
    'uk',
  ]

  useEffect(() => {
    if (CISCodeList.includes(i18n.language)) {
      i18n.changeLanguage('ru')
    }
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    if (forgotStep === 1) {
      await axios
        .post(
          `auth/login`,
          {
            username: values.email,
            password: values.password,
          },
          {
            headers: {
              ['Accept-Language']: i18n.language,
              ['Accept-type']: 'crm',
            },
          }
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch(SET_APP(['token'], data.data.access_token))
            dispatch(SET_APP(['user'], data.data.user))

            const origin = location.state?.from?.pathname || '/'

            i18n.changeLanguage(data.data.user.locale)
            moment.locale(data.data.user.locale)

            navigate(origin)
          }
        })
        .catch((error) => {
          if (error.response.status) {
            setIsError(error.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (forgotStep === 2) {
      await axios
        .post(
          'auth/forgot-password',
          {
            email: values.email,
          },
          {
            headers: {
              ['Accept-Language']: i18n.language,
              ['Accept-type']: 'crm',
            },
          }
        )
        .then(({ data }) => {
          if (data.success) {
            setForgotStep(3)
          }
        })
        .catch((error) => {
          if (error.response.status) {
            setIsMailError(
              error.response.data.error || error.response.data.message
            )
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (forgotStep === 4) {
      if (queryToken) {
        await axios
          .post(
            'auth/reset-password',
            {
              password: values.new_password,
            },
            {
              headers: {
                Authorization: `Bearer ${queryToken}`,
                ['Accept-Language']: i18n.language,
                ['Accept-type']: 'crm',
              },
            }
          )
          .then(() => navigate('/'))
          .catch((error) => {
            if (error.response.status) {
              setPasswordValidate(
                error.response.data.validationErrors ||
                  error.response.data.message
              )
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className='auth'>
      <Card
        className='auth__card'
        bodyStyle={{
          padding: '60px 24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {steps.renderTitle(forgotStep)}

        <Form
          className='auth__form'
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          {steps.renderContent(
            forgotStep,
            setForgotStep,
            newPassword,
            isError,
            isMailError,
            passwordValidate
          )}
          {steps.renderBtn(forgotStep, setForgotStep, loading)}
        </Form>
      </Card>
    </div>
  )
}

export default Auth
